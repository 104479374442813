import {memoize, startsWith} from "lodash";

const guessSelectedCountry = memoize((inputNumber, country, onlyCountries) => {
    const secondBestGuess = onlyCountries.find((o: any) => o.iso2 === country);
    if (inputNumber.trim() === '') { return secondBestGuess; }

    const bestGuess = onlyCountries.reduce(
        (selectedCountry: any, currentCountry: any) => {
            if (startsWith(inputNumber, currentCountry.dialCode)) {
                if (currentCountry.dialCode.length > selectedCountry.dialCode.length) {
                    return currentCountry;
                }
                if (
                    currentCountry.dialCode.length === selectedCountry.dialCode.length &&
                    currentCountry.priority < selectedCountry.priority
                ) {
                    return currentCountry;
                }
            }
            return selectedCountry;
        },
        { dialCode: '', priority: 10001 },
        this
    );

    if (!bestGuess.name) { return secondBestGuess; }
    return bestGuess;
});

const countries = {
    gr: 'Grekland',
    nl: 'Nederländerna',
    be: 'Belgien',
    es: 'Spanien',
    it: 'Italien',
    at: 'Österrike',
    gb: 'Storbritannien',
    dk: 'Danmark',
    pl: 'Polen',
    de: 'Tyskland',
    th: 'Thailand',
    fi: 'Finland',
    ee: 'Estland',
    se: 'Sverige',
    fr: 'Frankrike',
    no: 'Norge'
};

export { guessSelectedCountry, countries };
