import Journey from "@folksam-digital/model/lib/constants/Journey";
import {Constants} from "@folksam-digital/model";
import {CmsHelper} from "./cms/CmsHelper";
import {ICmsContext} from "../cms";

type PageTitleGeneratorFn = (data: any) => string;
type PageTitleRegistryType = Record<string, PageTitleGeneratorFn>;

export const carJourneyPageTitleGenerator: PageTitleGeneratorFn = (data) => {

    switch (data?.policy?.insuredObject?.carInsuranceType) {
        case Constants.Car.InsuranceType.Brand:
            return "title.brand.message";
        case Constants.Car.InsuranceType.BrandOther:
            return "title.brandOther.message";
        case Constants.Car.InsuranceType.Partner:
            return "title.partner.message";
        default:
            return "title.message";
    }
};

const customizedPageTitleRegistry: PageTitleRegistryType = {
    [Journey.Car.Id]: carJourneyPageTitleGenerator
};

export const pageTitleHelper = (journeyId: string, data: any, context: ICmsContext): string => {

    let messageId: string

    if(customizedPageTitleRegistry.hasOwnProperty(journeyId)) {
        messageId = customizedPageTitleRegistry[journeyId](data);
    } else {
        messageId = "title.message";
    }

    return CmsHelper.withPrefix(context, messageId);
}
