import * as React from "react";
import {injectIntl} from "react-intl";
import isEmpty from "lodash/isEmpty";
import memoize from "lodash/memoize";
import {BoatCover, Cover, CoverPlan} from "@folksam-digital/model";
import {FormComponentBase} from "../../FormComponentBase";
import {FormContext, IFormContext} from "../../FormContext";
import {IDropDownMetadata} from "../../input";
import {IModalProps} from "../../output";
import {AddOnCardView} from "./AddOnCardView";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";
import {CmsContext, ICmsContext} from "../../../../../cms";

interface IAddOnCardContainerInternalMetadata {
    coverId: number;
    addOnId: string;
    selectionDisabled?: boolean;
    modal?: IModalProps;
    model?: string;
    selectedCoverPlanPath?: string;
    showHighestCompensation?: boolean;
}

class AddOnCardContainerInternal extends FormComponentBase<BoatCover, IAddOnCardContainerInternalMetadata & IDropDownMetadata, {}> {
    public static contextType = FormContext;
    public context!: IFormContext;

    constructor(props: any, context: IFormContext) {
        super(props, context);

        this.onCardChange = this.onCardChange.bind(this);
    }

    public render() {
        const { intl, formData } = this.props;
        const { coverId, addOnId, selectionDisabled, modal, model, showHighestCompensation } = this.metadata;
        const cover = this.findCover(this.context.data, coverId) as Cover;
        const pbbUnitValue = this.context.data.policy.premium.pbbUnitValue ? this.context.data.policy.premium.pbbUnitValue : 0;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <AddOnCardView
                        coverId={coverId}
                        addOnId={addOnId}
                        intl={intl}
                        checked={formData && formData?.selected ? true : false}
                        disabled={false}
                        label={!selectionDisabled ?
                            formData && formData?.selected
                                ? intl.formatMessage({id: "general.button.product.added.text"})
                                : intl.formatMessage({id: "general.button.product.add.text"})
                            : ""}
                        isLoading={isEmpty(cover)}
                        onCardChange={this.onCardChange}
                        selectionDisabled={selectionDisabled}
                        modalProps={modal}
                        cover={cover}
                        model={model}
                        prefix={CmsHelper.getPrefix(cmsContext)}
                        pbbUnitValue={pbbUnitValue}
                        showHighestCompensation={showHighestCompensation}
                    />
                )}
            </CmsContext.Consumer>
        );
    }

    private async onCardChange(event: any): Promise<void> {
        const newData = this.props.formData as Cover;
        newData.selected = !!event.target.checked;
        this.props.onChange(newData);
    }

    private findCover = memoize((data: any, id: number) => {
        let covers: Cover[] = [] as Cover[];
        const coverPlan = this.metadata.selectedCoverPlanPath ? data.policy[this.metadata.selectedCoverPlanPath] : data.policy.coverPlan;

        if (data.policy?.premium?.coverPlans) {
            covers = data.policy.premium.coverPlans.find((row: CoverPlan) => row.id === coverPlan)?.covers as Cover[];
        } else if (data.policy?.premium?.covers) {
            covers = data.policy.premium.covers as Cover[];
        }

        if (!isEmpty(covers)) {
            for (const cover of covers) {
                if (cover.id === id) {
                    return cover;
                }
            }
        }
    });
}

const AddOnCardContainer = injectIntl(AddOnCardContainerInternal);
export { AddOnCardContainer };
