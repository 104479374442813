import * as React from "react";
import merge from "lodash/merge";
import {
    defaultTheme,
    IconPlasterInverted,
    IconStethoscope,
    IconHospital,
    IconTooth,
    IconToothAidInverted,
    IconHospitalInverted,
    IconPerson
} from "@folksam-digital/ui";
import { IconType } from "@folksam-digital/model";

interface IAttributes {
    style?: any;
    fill?: string;
    width?: number;
    height?: number;
}

interface IIconProps {
    iconId: string;
    attrs?: IAttributes;
}

class Icon extends React.Component<IIconProps>  {
    private renderIcon(id:string, styles: any): React.ReactElement | undefined {
        let icon;
        switch (id) {
            case IconType.IconPlasterInverted:
                icon = <IconPlasterInverted {...styles}/>;
                break;
            case IconType.IconStethoscope:
                icon = <IconStethoscope {...styles}/>;
                break;
            case IconType.IconHospital:
                icon = <IconHospital {...styles}/>;
                break;
            case IconType.IconTooth:
                icon = <IconTooth {...styles}/>;
                break;
            case IconType.IconToothAidInverted:
                icon = <IconToothAidInverted {...styles}/>;
                break;
            case IconType.IconHospitalInverted:
                icon = <IconHospitalInverted {...styles}/>;
                break;
            case IconType.IconPerson:
                icon = <IconPerson {...styles}/>;
                break;
            default:
                icon = undefined;
        }
        return icon;
    }
    public render(): React.ReactNode {
        const defaultStyles = {
            style: {marginRight: "1.5rem"},
            fill: defaultTheme.colors.senary3,
            width: 40,
            height: 40
        }
        const { iconId, attrs } = this.props;
        const iconStyles = merge(defaultStyles, attrs);

        return (
            <>
                { this.renderIcon(iconId, iconStyles) }
            </>
        )
    }
}

export {Icon};
