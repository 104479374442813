import React, {FunctionComponent, ReactElement, PropsWithoutRef} from "react";
import isFunction from "lodash/isFunction";

interface IDynamicComponent {
    component: FunctionComponent<any> | ReactElement<any>;
    ref: React.Ref<any>;
    [key: string]: any;
}

// Use a type predicate to indicate that Component is a FunctionComponent if the function returns true
const isFunctionComponent = (Component: any): Component is FunctionComponent<any> => {
    return isFunction(Component);
}

export const DynamicComponent = React.forwardRef(({component: Component, ...props}: PropsWithoutRef<IDynamicComponent>, ref: React.Ref<any>) => {
    if (isFunctionComponent(Component)) {
        return <Component ref={ref} { ...props }/>
    } else {
        return React.cloneElement(Component as ReactElement<any>, {...props, ref})
    }
});
