import {PlaceTypes} from "@folksam-digital/model";
import autocompletionRequestBuilder from "../helpers/autocompletionRequestBuilder";
import Country from "@folksam-digital/model/lib/constants/Country";

const defaultAutocompletionRequest = {
    componentRestrictions: {country: [Country.Sweden.alpha2Code]},
    types: [PlaceTypes.Establishment], // Table 3: Types supported in place autocomplete requests https://developers.google.com/places/supported_types
};

export type IGetPlacePredictions = (value: string, callback: (result: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => void) => void;

export function getPlacePredictions(this: any, value: string, callback: (result: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => void) {
    const {autocompletionRequest = defaultAutocompletionRequest} = this.metadata;

    this.autocompleteService.getPlacePredictions(
        {
            ...autocompletionRequestBuilder(autocompletionRequest),
            sessionToken: this.state.sessionToken,
            input: value
        },
        callback
    );
}

export type IFetchSuggestionsCallback = (suggestions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => void;

export function fetchSuggestionsCallback(this: any, suggestions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus): void {
    const {autocompleteOK} = this.state;
    const {autocompletionRequest} = this.metadata;

    if (status !== autocompleteOK) {
        console.error('Autocomplete service error.');
    }

    if (autocompletionRequest?.types?.includes(PlaceTypes.Country) && suggestions) {
        suggestions = suggestions.filter((suggestion: google.maps.places.AutocompletePrediction) => suggestion.types.some(type => type === "country"));
    }

    this.setState({
        isLoading: false,
        suggestions: suggestions || [],
    });
}

export type IFetchSuggestionsFn = (this: any, value: string) => void;

export function fetchSuggestions(this: any, value: string): void {
    this.setState({isLoading: true});
    this.getPlacePredictions(value, this.fetchSuggestionsCallback);
}

export function initializeServiceCallBack(this: any): void {
    if (this.metadata?.googleMapOptions && this.props.formData) {
        // fetch suggestion via input value and set up marker in google map.
        this.getPlacePredictions(this.props.formData, this.valuePlaceCallBack);
    }
}

export function initializeService(this: any): void {
    if (!window.google) {
        console.error('[react-google-places-autocomplete]: Google script not loaded');
        this.retryInitialize();

        return;
    }

    if (!window.google.maps) {
        console.error('[react-google-places-autocomplete]: Google maps script not loaded');
        this.retryInitialize();

        return;
    }

    if (!window.google.maps.places) {
        console.error('[react-google-places-autocomplete]: Google maps places script not loaded');
        this.retryInitialize();

        return;
    }

    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.placesService = new window.google.maps.places.PlacesService(this.divRef.current);
    const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    this.geocoder = new window.google.maps.Geocoder();

    this.setState({
        autocompleteOK: window.google.maps.places.PlacesServiceStatus.OK,
        sessionToken
    }, this.initializeServiceCallBack);
}

export function retryInitialize(this: any): NodeJS.Timeout {
    return setTimeout(() => {
        this.initializeService();
    }, 1000);
}
