import * as React from "react";
import {Step} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {
    withBackground: boolean;
}

export class StepSectionBodyOutput extends PanelComponentBase<string, IMetadata, {}> {
    public render() {

        const {withBackground} = this.metadata;

        return (
            <Step.SectionBody withBackground={withBackground}>
                {this.props.properties?.map(p => p.content)}
            </Step.SectionBody>
        );
    }
}
