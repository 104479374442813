export enum JourneyErrorType {
    noCoverPlanAvailable = "noCoverPlanAvailable",
    insufficientInformationAboutVehicle = "insufficientInformationAboutVehicle",
    userTooYoung = "userTooYoung",
    incorrectRegistrationNumberType = "incorrectRegistrationNumberType",
    userAlreadyHasInsuranceOnVehicle = "userAlreadyHasInsuranceOnVehicle",
    unsuitableVehicleTypeForProduct = "unsuitableVehicleTypeForProduct",
    unsuitableSpecificVehicleTypeForProduct = "unsuitableSpecificVehicleTypeForProduct",
    mopedCarInsteadOfMoped = "mopedCarInsteadOfMoped",
    mopedInsteadOfMopedCar = "mopedInsteadOfMopedCar",
    homeBuildingTooManyBuildings = "homeBuildingTooManyBuildings",
}
