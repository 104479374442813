import {DirectDebit} from "@folksam-digital/model";
import forIn from "lodash/forIn";
import merge from "lodash/merge";
import {validateSsn} from "../Helpers/validation/isValidSsn";
import {normalizeValue} from "../Helpers/normalize";
import {SsnFormatter} from "@folksam-digital/services";
import {PhoneNumberFormatter} from "../Helpers/PhoneNumberFormatter";
import {TPossibleString} from "./DirectDebitValidator";
import {ICmsContext} from "../cms";
import {injectable} from "inversify";

export interface IValidation {
    required?: boolean,
    regex?: {
        pattern: string,
        errorMessage: any
    }
}

export interface IError {
    [key: string]: any;
}

@injectable()
export class ValidatorBase {
    protected catalogParams: Pick<ICmsContext, "catalogName" | "folderName">;
    protected readonly validations: {[key: string]: IValidation};

    public validate(data: DirectDebit): IError {
        let errors: {[key: string]: any} = {};

        forIn(this.validations, (value: IValidation, key: string) => {
            const actualKey = key as keyof DirectDebit;
            errors = merge({}, errors, this.validateField(actualKey, data[actualKey]));
        });

        return errors;
    }

    public validateField<T extends keyof DirectDebit>(formField: string, fieldValue?: DirectDebit[T]): IError {
        const errors: IError = {};
        const validation: IValidation = this.validations[formField];

        if (validation.required === true) {
            if (!fieldValue) {
                errors[formField] = "general.form.error.required";
            }
        }

        if (fieldValue && validation.regex) {
            const {pattern, errorMessage} = validation.regex;

            // We have pattern validation only for string fields
            const value = fieldValue.toString() as string;

            if (!value.match(pattern)) {
                errors[formField] = errorMessage;
            }
        }

        if (formField === "ssn") {
            const ssnError = validateSsn(fieldValue as TPossibleString, "general.personSsnInput.error.format");
            if (ssnError) {
                errors[formField] = ssnError;
            }
        }

        if (formField === "termsAndConditions") {
            if (fieldValue) {
                delete errors[formField];
            }
        }

        return errors;
    }

    // Removes dash sign if exists on ssn and save in state for later use, otherwise Idit services are failing
    public removeDashFromSsn(ssn?: string): string | undefined {
        return normalizeValue(SsnFormatter.formatWithoutDash, ssn);
    }

    // Removes dash, spaces from phone number
    public removeSpecialCharsFromPhoneNumber(phoneNumber?: any): any {
        return normalizeValue(PhoneNumberFormatter.removeSpecialChars, phoneNumber);
    }
}
