import {IBannerIconBrandLogoGenerator} from "./bannerIconBrandLogoHelper";
import {IntlShape} from "react-intl";
import {CoopIcon, GenericUnionIcon, LararforsakringarIcon} from "@folksam-digital/ui";
import {iconLogoRenderer} from "./bannerIconLogoRenderer";
import {Constants} from "@folksam-digital/model";
import {FeatureToggleUtils} from "@folksam-digital/services";
import {findKey} from "lodash";


export const genericUnionBannerIconLogoGenerator: IBannerIconBrandLogoGenerator = (formData, cmsContext, intl: IntlShape) => {
    let groupName: string = '';
    let groupId: string = "";

    // Home Vacation agentId 09009 && Lärarförsäkringar
    if (formData?.policy?.partner === Constants.Partners.Lararforsakringar && formData?.policy?.agentId === Constants.AgentId.Lararforsakringar) {
        const constants = {[`${Constants.Partners.Lararforsakringar}`]: Constants.Partners.Lararforsakringar};

        return iconLogoRenderer({
            constants,
            iconsCollection: {},
            itemKey: Constants.Home.Partner.Brand.Lararforsakringar,
            itemName: "groupName",
            IconComponent: LararforsakringarIcon,
            cmsContext,
            intl
        });
    }

    if (!formData?.group?.name && !formData?.policy?.insuredObject?.group?.name && !formData?.policy?.group?.name) {
        return undefined;
    }

    if(formData?.group?.name) {
        groupName = formData?.group?.name;
    }

    if(formData?.policy?.group?.name) {
        groupName = formData?.policy?.group?.name;
    }

    if(formData?.policy?.insuredObject?.group) {
        groupName = formData?.policy?.insuredObject?.group?.name;
        groupId = formData?.policy?.insuredObject?.group?.id;
    }

    const excludedGroups: string[] = Constants.IHomeGroupIdValues; // Not a member, don't know options

    if (groupId && excludedGroups.includes(groupId)) {
        if (formData?.policy?.partner !== Constants.Partners.Coop || !FeatureToggleUtils.isFeatureEnabled("coop")) {
            return;
        }


        const constants = {[`${Constants.Partners.Coop}`]: `${findKey(Constants.Partners, (partner) => partner === Constants.Partners.Coop)}`};

        return iconLogoRenderer({
            constants,
            iconsCollection: {},
            itemKey: formData?.policy?.partner,
            itemName: "groupName",
            IconComponent: CoopIcon,
            cmsContext,
            intl
        });
    }

    return iconLogoRenderer({
        constants: {},
        iconsCollection: {},
        itemKey: groupName,
        itemName: "groupName",
        cmsContext,
        IconComponent: GenericUnionIcon,
        intl
    });
}

