import * as React from "react";

import {FormInput, P} from "@folksam-digital/ui";
import {PhoneNumberInput} from "../../components/common/PhoneInput";

interface IFormFieldControllerProps {
    isReadOnly: boolean;
    readOnlyFormatter?: (value: any) => any;
    value: any;
    onChange: (event: any) => void;
    id: string;
    invalid: boolean;
    [key: string]: any;
}

export const FormFieldController = ({isReadOnly, readOnlyFormatter, value, ...props}: IFormFieldControllerProps) => {
    if (isReadOnly) {
        const readOnlyValue = readOnlyFormatter ? readOnlyFormatter(value) : value;

        return (
                <P>{readOnlyValue}</P>
        );
    }

    if (props.id === "phoneNumber") {
        return <PhoneNumberInput formData={value} {...props} />
    }

    return (
        <FormInput value={value} {...props}/>
    )
};
