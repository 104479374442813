import {Event, IDocumentGenerationContext} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IDocumentService} from "../../IDocumentService";
import {DigitalApiServiceBase} from "./DigitalApiServiceBase";

@injectable()
export class DocumentService extends DigitalApiServiceBase implements IDocumentService {
    public async generateDocument<TData = any>(event: Event, context: IDocumentGenerationContext<TData>): Promise<void> {
        const path = `/document/${event}`;

        const http = super.getHttpClient(path);
        await http.post<IDocumentGenerationContext<TData>>(path, context);
    }
}
