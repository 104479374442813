import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Button, DisplayIcon, IconBankID} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../../../cms";
import {JourneyType} from "../../../../analytics/new/BaseAnalytics";
import {UrlHelper} from "@folksam-digital/services";

interface IProps {
    myPagesButtonTitle?: string;
    completionQueryString?: string;
}

export class MyPagesButton extends React.Component<IProps>{
    public static contextType = CmsContext;
    public context!: ICmsContext;
    public render() {
        let url: string;
        url = Constants.Links.signInUrlProtected;

        if (this.context.folderName === JourneyType.Claim){
            url = Constants.Links.signInUrlProtectedClaim;
        }

        if (this.context.folderName === JourneyType.Product){
            url = Constants.Links.signInUrlProtectedProduct;
        }

        if(this.props?.completionQueryString) {
            url += `?${this.props.completionQueryString}`;
        }

        url = UrlHelper.getUrlWithoutSecureSubDomain(window.location.origin, url);
        return (
            <Button full={true} onClick={() => window.open(url, "_self")}>
                <DisplayIcon style={{marginRight: 8}} baseline={true} large={false} icon={<IconBankID/>}/>
                <FormattedMessage id={this.props?.myPagesButtonTitle ?? "general.success.bannerPanel.myPages.header"}/>
            </Button>
        );
    }
}
