import * as React from "react";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {Constants, DocumentUpload, FileUploadThemes} from "@folksam-digital/model";
import {Button, ConstrainWidth, defaultTheme, DescriptionListResponsive, IconDoc, Spacing} from "@folksam-digital/ui";
import {injectIntl} from "react-intl";
import {RemoveButton} from "../containers/personSsnInput/RemoveButton";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import first from "lodash/first";
import last from "lodash/last";
import concat from "lodash/concat";
import {IFileUploadClient} from "@folksam-digital/services/lib/IFileUploadClient";
import {createId} from "../../../../Helpers/createId";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {purgeUnwantedChars} from "./helpers/purgeUnwantedChars";
import {AxiosProgressEvent} from "axios";

export interface IFileUploadInternalMetadata {
    help?: string;
    buttonText: string;
    allowMultiple?: boolean;
    theme?: FileUploadThemes;
    classNames?: string;
    unsupportedFileFormat?: string;
    maxFileSizeMessage?: string;
    maxFileCountMessage?: string;
    maxFileSizeInMB?: number;           // If you set custom file size, make sure that fileSize limit
    maxFilesTotalSizeInMB?: number;     // in GridFsFileStorage.ts(packages/digital-api/src/db/GridFsFileStorage.ts) is adjusted as well
}

interface IFileUploadState {
    documents?: DocumentUpload[];
    isUploading: boolean;
    selectedFile: any;
    loaded: number;
    error?: string;
}

interface IThemeStyle {
    zebra: boolean;
    themeId?: number;
    style?: object;
    rowBackground?: boolean;
}

const MAX_FILE_COUNT: number = 30;

class FileUploadInternal extends InputComponentBase<DocumentUpload[], IFileUploadInternalMetadata, IFileUploadState> {
    private readonly inputRef: React.RefObject<HTMLInputElement>;
    private fileClient: IFileUploadClient;

    constructor(props: IInputComponentProps<DocumentUpload[], IFileUploadInternalMetadata>) {
        super(props);

        this.inputRef = React.createRef();
        this.fileClient = container.get<IFileUploadClient>(Types.FileUploadClient);

        this.state = {
            documents: this.props.formData ? this.props.formData : [],
            isUploading: false,
            selectedFile: 0,
            loaded: 0,
        };

        this.onRemove = this.onRemove.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    getTheme(themeId?: FileUploadThemes): IThemeStyle {
        const style = [
            {
                zebra: true
            },
            {
                zebra: false,
                themeId: 1,
                style: {
                    backgroundColor: "white"
                }
            }
        ] as IThemeStyle[];

        return style[themeId || 0];
    }

    onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (this.checkFileSize(event) && this.checkFileExtension(event) && this.checkIsFileCountValid(event)) {
            this.setState({
                selectedFile: files,
                loaded: 0
            }, this.onClickHandler);
        }
    }

    onClickHandler = async () => {
        let additionalFileSize = 0;
        const newFiles: any[] = [];
        try {
            const data = new FormData();
            for (const file of this.state.selectedFile) {
                additionalFileSize += file.size;
                const cleanFileName = purgeUnwantedChars(file.name)
                data.append("file", file, cleanFileName);
                const id = createId();
                newFiles.push({id, size: file.size, name: file.name})
            }

            if (this.metadata.maxFilesTotalSizeInMB && this.context.filesSizeTooBig(additionalFileSize, this.getMaxFileSize(this.metadata.maxFilesTotalSizeInMB))) {
                throw new Error(CmsHelper.withGeneralPrefix("form.error.fileUpload.limit"));
            }

            this.setState({isUploading: true});
            this.context.appendFiles(newFiles);

            this.context.updateUploadStatus(this.state.selectedFile.length);
            const response = await this.fileClient.uploadFile(data, {
                headers: {
                    [`${Constants.ContactDetails.ExternalContactNumber}`]: this.context?.data?.contact?.externalContactNumber
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    this.setState({
                        loaded: (progressEvent.loaded / (progressEvent.progress ?? 0) * 100),
                    })
                },
            }).catch(() => {
                // Prevent displaying error msg from response to end user
                throw new Error(CmsHelper.withGeneralPrefix("form.error.fileUpload.uploadError"))
            });

            if (this.inputRef?.current) {
                this.inputRef.current.value = "";
            }

            if (response?.data?.result && response?.data?.result?.length !== 0) {
                this.setState((state) => {
                    let documents = state.documents || [];
                    let error;

                    if (Array.isArray(response.data.result)) {
                        const result = response.data.result.filter((file: {id?: string; fileName: string; size: number}) => {
                            return !!file?.id;
                        });

                        if (result.length > 0) {
                            documents = concat(documents, result);
                        }

                        if (documents.length < response.data.result.length) {
                            error = CmsHelper.withGeneralPrefix("form.error.fileUpload.uploadError");
                        }
                    }

                    return {
                        documents,
                        isUploading: false,
                        error: error,
                    }
                }, () => {
                    this.context.updateUploadedFiles({newFiles, uploadedFiles: this.state.documents});
                    this.context.updateSessionStorage(this.props.uiSchema.model, this.state.documents);
                    this.onChangeWithValidation(this.state.documents);
                    this.context.updateUploadStatus(-Math.abs(response?.data?.result?.length));
                });
            } else {
                this.setState({isUploading: false});
            }
        } catch (e) {
            if (e instanceof Error) {
                this.setState({isUploading: false, error: e.message});
                this.context.updateUploadStatus(0);
                this.context.removeFiles(newFiles);
            }
        }
    };

    checkFileExtension(event: any) {
        const acceptedFileFormats = Constants.FolksamSupportedFileFormats;
        const files = event.target.files;

        for (const file of files) {
            const fileExtension: string = last(file.name.split(".")) || "";
            if (fileExtension && !acceptedFileFormats.includes(`.${fileExtension.toLocaleLowerCase()}`)) {
                this.setState({error: this.metadata.unsupportedFileFormat ?? CmsHelper.withGeneralPrefix("form.error.unsupportedFileFormat")});
                return false;
            }
        }

        return true;
    }

    checkFileSize(event: any): boolean {
        const files = event.target.files;
        let error;
        // tslint:disable-next-line:prefer-for-of
        for (let x = 0; x < files.length; x++) {
            if (files[x].size > this.getMaxFileSize(this.metadata.maxFileSizeInMB)) {
                error = this.metadata.maxFileSizeMessage ?? CmsHelper.withGeneralPrefix("form.error.legacy.maxFileSize");
            }

            if (files[x].size <= 0) {
                error = CmsHelper.withGeneralPrefix("form.error.fileIsEmpty");
            }
        }

        if (!!error) {
            this.setState({error: error});
        }

        return !error;
    }

    getMaxFileSize(maxFileSizeInMB?: number): number {
        return this.convertMBInBytes(maxFileSizeInMB ?? Constants.Validation.maxFileSizeMB);
    }

    checkIsFileCountValid(event: any): boolean {
        const files = event.target.files;
        const errors = [];
        if (Constants.LegacyClaimJourneyIds.includes(this.context.journeyId) && (files.length + this.state.documents?.length) > MAX_FILE_COUNT) {
            errors.push(this.metadata.maxFileCountMessage ?? CmsHelper.withGeneralPrefix("form.error.legacy.maxFileCount"));
        }

        if (errors.length) {
            this.setState({error: first(errors)});
        }

        return !errors.length;
    }

    public render(): React.ReactNode {
        const {name} = this.props;
        const {buttonText, allowMultiple, theme, classNames} = this.metadata;
        const {documents} = this.state;
        const themeDetails = this.getTheme(theme);
        const acceptedFileFormats = Constants.FolksamSupportedFileFormats;

        return (
            <FormFieldLayout classNames={classNames} {...this.getLayoutProps()}
                             error={this.state.error || this.getError()}>
                <>
                    <Spacing type={"padding"} y={2}>
                        {documents && documents.length > 0 ? this.renderSelectedFiles(documents, themeDetails) : null}
                    </Spacing>
                    {!documents || documents.length === 0 || allowMultiple ?
                        <Button key={`${name}FileInputBtn`}
                                type='button'
                                isBusy={this.state.isUploading}
                                onClick={this.handleOnClick}
                                disabled={this.state.isUploading}
                                full
                                outline
                        >
                            <input
                                id={`${name}FileInput`}
                                type={"file"}
                                hidden={true}
                                ref={this.inputRef}
                                accept={acceptedFileFormats?.join(",")}
                                multiple={allowMultiple}
                                onChange={this.onChangeHandler}
                            />
                            <IconDoc style={{marginRight: 10}} height={25} fill={defaultTheme.colors.white}/>
                            {this.props.intl.formatMessage({id: buttonText})}
                        </Button> : null
                    }
                </>
            </FormFieldLayout>
        );
    }

    private convertMBInBytes(megaBytes: number): number {
        return megaBytes * 1024 * 1024;
    }

    private renderSelectedFiles(documents: DocumentUpload[], themeDetails: IThemeStyle): JSX.Element[] {
        return documents.map((document: DocumentUpload, idx: number) => {
            return (
                <ConstrainWidth key={`SelectedFileWrapper${idx}`}
                                style={
                                    {
                                        backgroundColor: defaultTheme.colors.white,
                                        borderRadius: defaultTheme.borderRadius.xl,
                                        borderWidth: defaultTheme.borderWidths.default,
                                        borderColor: defaultTheme.colors.transparent,
                                        boxShadow: defaultTheme.shadows.xs,
                                        marginTop: defaultTheme.margin[3],
                                        marginBottom: defaultTheme.margin[3],
                                        ...themeDetails.style
                                    }
                                }>
                    <DescriptionListResponsive.Row
                        key={`${this.props.name}SelectedFile${idx}`}
                        zebra={themeDetails.zebra}
                        spacingRight={'5'}
                        spacingLeft={'5'}
                    >
                        <DescriptionListResponsive.Term>
                            <span style={{
                                color: defaultTheme.colors.primary1,
                                verticalAlign: "middle",
                                wordBreak: "break-word"
                            }}>
                                {document?.fileName}
                            </span>
                        </DescriptionListResponsive.Term>
                        <DescriptionListResponsive.Definition>
                            <RemoveButton
                                index={idx}
                                onClick={this.onRemove}
                                uniqueKey={`${this.props.name}RemoveDocument${idx}`}
                            />
                        </DescriptionListResponsive.Definition>
                    </DescriptionListResponsive.Row>
                </ConstrainWidth>
            )
        });
    }

    private onRemove(idx: number) {
        let currentDocuments: DocumentUpload[] | undefined = this.state.documents ?? [];
        const documentToRemoveId = currentDocuments[idx].id;
        if (currentDocuments.length === 1) {
            currentDocuments = undefined;
        } else {
            currentDocuments.splice(idx, 1);
        }

        if (documentToRemoveId) {
            this.fileClient.removeFile(documentToRemoveId, {
                headers: {
                    [`${Constants.ContactDetails.ExternalContactNumber}`]: this.context?.data?.contact?.externalContactNumber
                }
            });
            this.context.removeFiles([{id: documentToRemoveId}]);
            this.context.updateSessionStorage(this.props.uiSchema.model, currentDocuments);
        }

        this.setState({
            documents: currentDocuments
        });
        this.onChangeWithValidation(currentDocuments);
    }

    private handleOnClick() {
        this.inputRef.current?.click();
    }
}

const FileUpload = injectIntl(FileUploadInternal);

export {FileUpload, FileUploadThemes}
