import * as React from "react";
import {FormattedMessage} from "react-intl";
import {OutputComponentBase} from "./OutputComponentBase";
import {FlexRow, Headings, Spacing, defaultTheme as theme} from "@folksam-digital/ui";

interface IMetadata {
    spacingTop?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    spacingBottom?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
}

export class FlexRowOutput extends OutputComponentBase<void, IMetadata, {}> {
    public render() {
        const {spacingTop = '8', spacingBottom = '5'} = this.metadata;
        const title = this.schema.title;

        return (
            <Spacing type={"margin"} top={spacingTop} bottom={spacingBottom}>
                <FlexRow>
                    {title && <Headings.H4 style={{margin: 0, lineHeight: theme.leading.tight}}><FormattedMessage id={title}/></Headings.H4>}
                </FlexRow>
            </Spacing>)
    }

}
