import {DeepPartial, Field, Questionnaire} from "@folksam-digital/model";
import find from "lodash/find";
import {answerHeaderMapping, defaultComponentMapping, questionMapping} from "./claim/GeneratorConfigClaim";
import {ICmsParams} from "./IGenerator";

export class QuestionnaireUtils {
    public static findQuestionByLineId(questionnaire: Questionnaire[], lineId: number | null): Questionnaire | undefined {
        let questionFound = find(questionnaire, {lineId: lineId});

        if (!questionFound) {
            for (const question of questionnaire) {
                if (question.innerQuestionnaire) {
                    questionFound = this.findQuestionByLineId(question.innerQuestionnaire, lineId);

                    if (questionFound) {
                        return questionFound;
                    }
                }
            }
        }

        return questionFound as Questionnaire;
    }

    public static generateInitialField(question: Questionnaire, cmsParams: ICmsParams, index?: number): DeepPartial<Field> {
        // override based on specific questionId
        if (questionMapping(cmsParams, index)[question.questionId!]) {
            return questionMapping(cmsParams, index)[question.questionId!];
        }

        if (!question.answerDefinition) {
            return {} as DeepPartial<Field>;
        }

        // override based on question.answerDefinition.answerHeaderId
        if (answerHeaderMapping(cmsParams)[question.answerDefinition.answerHeaderId!]) {
            return answerHeaderMapping(cmsParams)[question.answerDefinition.answerHeaderId!];
        }

        return defaultComponentMapping(question);
    }
}
