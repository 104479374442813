import sanitizeHtml from "sanitize-html";

export default function sanitizeField(value: string): string {
    return sanitizeHtml(value, {
        allowedTags: [],
        textFilter: function(text: string, tagName: string) {
            return text.replace(/&(?:amp|#38|lt|#60|gt|#62|apos|#39|quot|#34);/g, "");
        }
    });
}
