import {Spacing} from "@folksam-digital/ui";
import React from "react";
import {FormattedMessage} from "react-intl";
import {ModalIframe} from "../journey/form/output";

export const LabelModal = ({url, modalTitle}: any) => {
    if (!url || !modalTitle) {
        return null;
    }

    return (
        <Spacing type={"padding"} bottom={"md"}>
            <ModalIframe url={url} title={<FormattedMessage id={modalTitle}/>}/>
        </Spacing>
    )
}
