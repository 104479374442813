import * as React from "react";
import {CancelModalButton} from "./loggedIn/CancelModalButton";

interface IJourneyErrorNavButtonProps {
    [key: string]: any;
}

export const JourneyErrorNavButton = (props: IJourneyErrorNavButtonProps) => {
    return <CancelModalButton { ...props } />
};
