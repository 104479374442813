import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Markdown } from "./Markdown";
import {MarkdownListTypes} from "@folksam-digital/model";

interface IFormattedMarkdownProps {
    messageKey: string;
    defaultMessageKey?: string;
    messageValue?: { [key: string]: string | number | boolean | Date | null | undefined };
    listType?: MarkdownListTypes
}

class FormattedMarkdown extends React.Component<IFormattedMarkdownProps & WrappedComponentProps> {

    public render() {
        const { messageKey, defaultMessageKey, messageValue, listType } = this.props;
        const translation = this.props.intl.formatMessage({id: messageKey, defaultMessage: defaultMessageKey}, messageValue);

        return(
            <Markdown source={translation} listType={listType} />
        );
    }
}

export default React.memo(injectIntl(FormattedMarkdown, {forwardRef: true}))
