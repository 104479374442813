import * as React from "react";
import { Step, Spacing } from "@folksam-digital/ui";
import {OutputComponentBase} from "../output/OutputComponentBase";

interface FooterContainerMetadata {
    theme?: number
}

export class FooterContainer extends OutputComponentBase<void, FooterContainerMetadata, {}>{
    public render() {
        return (
            <Step.Footer>
                <Spacing type="padding" x="md" y="md">
                    {this.props.properties?.map(p => p.content)}
                </Spacing>
            </Step.Footer>
        );
    }
}
