import {ConfigHelper} from "@folksam-digital/services";

type callbackFunction = (cbValue: boolean) => void;

let languageCode: string;

const loadGoogleMaps = (language: string, callback?: callbackFunction) => {
    const existingScript = document.getElementById('googleMaps') as HTMLEmbedElement;
    const apiKey = ConfigHelper.getValue("GOOGLE_API_KEY");
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=Function.prototype&language=${language}&libraries=places`;

    if (!existingScript) {
        tryCallCallback(false, callback);
        handleGoogleMapsScript(src, language, callback);
    } else if (existingScript && languageCode !== language) {
        tryCallCallback(false, callback);
        document.body.removeChild(existingScript);
        handleGoogleMapsScript(src, language, callback);
    } else {
        tryCallCallback(true, callback);
    }
};

function tryCallCallback(value: boolean, callback?: callbackFunction) {
    if (callback) {
        callback(value);
    }
}

function handleGoogleMapsScript(src: string, language: string, callback?: callbackFunction) {
    loadGoogleMapsScript(src, () => {
        setLanguageCode(language);
        tryCallCallback(true, callback);
    });
}

function loadGoogleMapsScript(src: string, callback: () => void) {
    const script = document.createElement('script');
    script.src = src;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
        callback();
    };
}

function setLanguageCode(language: string) {
    languageCode = language;
}

export {loadGoogleMaps};
