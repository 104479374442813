import * as React from "react";

export interface ILayoutComponentProps {
    journeyId?: string,
    formData: any,
    viewContext?: any;
    children?: React.ReactNode;
}

export abstract class LayoutComponentBase<TProps extends ILayoutComponentProps, TState>
    extends React.PureComponent<TProps, TState> {

}
