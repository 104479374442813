import * as H from 'history';

import {IFormContext} from "../../FormContext";
import {ScrollTypes} from "./NavigationHelperConstant";

export function onBtnClick(event: any, url: string, context: IFormContext, props: { history: H.History }) {
    if (url.includes("#")) {
        const urlSplit = url.split("#");
        const element = document.getElementById(urlSplit[1]);

        if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY;
            window.scroll({
                top: y,
                behavior: 'smooth'
            })
        } else {
            context.enableExecuteScroll(ScrollTypes.Anchor);
            props.history.push({
                pathname: `${urlSplit[0]}`,
                hash: `#${urlSplit[1]}`
            });
        }
    } else {
        context.enableExecuteScroll(ScrollTypes.Default);
        props.history.push(url);
    }
}

