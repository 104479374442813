import IncomeChangeJourneySuccess from "./IncomeChangeSuccess";
import IncomeAddonJourneySuccess from "./IncomeAddOnSuccess";
import {IRoute} from "../../../IRoute";
import {Constants} from "@folksam-digital/model";

const IncomeInsuranceSuccessRoutes: IRoute[] = [
    {
        path: Constants.Journey.IncomeChange.Id,
        component: IncomeChangeJourneySuccess,
        journeyType: Constants.JourneyType.JourneyProtected,
        featureName: Constants.Journey.IncomeChange.Id
    },
    {
        path: Constants.Journey.IncomeAddon.Id,
        component: IncomeAddonJourneySuccess,
        journeyType: Constants.JourneyType.JourneyProtected,
        featureName: Constants.Journey.IncomeAddon.Id
    },
];

export default IncomeInsuranceSuccessRoutes;
