import {Constants} from "@folksam-digital/model";

type GetHeaderFn = (journeyId: string, data: any) => string;

const getHeaderByJourneyId: GetHeaderFn = (journeyId, data) => {
    switch (journeyId) {
        case Constants.Journey.Cat.Id:
        case Constants.Journey.Dog.Id:
            const eligibleToBuy = data?.policy?.isEligible;
            if (!eligibleToBuy) {
                return "success.banner.application.heading";
            } else {
                return "success.banner.heading";
            }
        default:
            return "success.banner.heading";
    }
};

export default getHeaderByJourneyId;
