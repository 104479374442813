import * as React from 'react';
import {CmsContext, ICmsContext} from './CmsContext';

export function withCmsContextConsumer<P extends {cmsContext?: ICmsContext}>(Component: React.ComponentType<P>) {
    return function CmsComponent(props: Pick<P, Exclude<keyof P, keyof 'cmsContext'>>) {
        return (
            <CmsContext.Consumer>
                {(contexts: any) => <Component {...props as P} cmsContext={{...contexts}} />}
            </CmsContext.Consumer>
        )
    }
}
