import {Constants, PolicySearchResult} from "@folksam-digital/model";
import {
    BaseAnalytics,
    IOnTriggerDataLayerEventParams,
    JourneyType,
    SatelliteEvents,
    SatelliteEventTransactionTypes,
} from "./BaseAnalytics";
import {ShoppingCartMap} from "../old/ShoppingCartAnalytics";
import {IMessages} from "../../cms";
import {StorageKeys} from "../../services";

export interface ShoppingCartMapNew extends Omit<ShoppingCartMap, "product_id" | "transaction_id" | "groupid" | "pagename" | "pagetype" | "sitesection1" | "sitesection2" | "sitesection3" | "sitesection4"> {
    productId: Array<number | string> | string;
    transactionId: string;
    transactionPageStart?: string;
    transactionPageComplete?: string;
    groupId: string;
}

export class ShoppingCartAnalytics extends BaseAnalytics {
    private getPaymentMethod(messages: IMessages, data?: any): string {// Added from product analytics
        return messages[`general.policy.paymentDetails.paymentMethod.${data?.paymentDetails?.paymentMethod}`] ?
            messages[`general.policy.paymentDetails.paymentMethod.${data?.paymentDetails?.paymentMethod}`] : "";
    }

    private getPaymentFrequency(messages: IMessages, data?: any): string {// Added from product analytics
        return messages[`general.policy.paymentDetails.paymentFrequency.${data?.paymentDetails?.paymentFrequency}`] ?
            messages[`general.policy.paymentDetails.paymentFrequency.${data?.paymentDetails?.paymentFrequency}`] : "";
    }

    private getAgentId(params: IOnTriggerDataLayerEventParams): string {
        const sessionParams: any = this.sessionStorage.get(StorageKeys.PARAMS);
        return sessionParams?.agentId ? sessionParams.agentId : Constants.AgentId.Policy;
    }

    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        let productIds: Array<number | string> = [];
        let productUnits: Array<number> = [];
        let productPrices: Array<number> = [];

        if (params?.data?.member?.offers !== undefined) {
            const memberResult = this.getProductIds(params.data.member.offers);

            productIds = productIds.concat(memberResult.productIds);
            productUnits = productUnits.concat(memberResult.productUnits);
            productPrices = productPrices.concat(memberResult.productPrices);
        }

        if (params?.data?.partner !== undefined && params.data.partner.offers !== undefined) {
            const partnerResult = this.getProductIds(params.data.partner.offers);

            productIds = productIds.concat(partnerResult.productIds);
            productUnits = productUnits.concat(partnerResult.productUnits);
            productPrices = productPrices.concat(partnerResult.productPrices);
        }

        if (params?.data?.children !== undefined && params.data.children?.length > 0) {
            for (const child of params.data.children) {
                const childrenResult = this.getProductIds(child?.offers);

                productIds = productIds.concat(childrenResult.productIds);
                productUnits = productUnits.concat(childrenResult.productUnits);
                productPrices = productPrices.concat(childrenResult.productPrices);
            }
        }

        if(productIds.length === 0) {
            productIds = ['GMI-PRODUCTS-TO-CHOOSE'];
        }

        let trackKey = `${SatelliteEventTransactionTypes.TransactionPage}_${params.event}`;
        const dataLayerObj: Omit<ShoppingCartMapNew, "groupId"> = {
            age: params?.data?.member?.contact?.age,
            union: params?.data?.group?.id,
            productId: productIds,
            product_units: productUnits,// @ToDo - shouldn't it be productUnits ?
            product_price: productPrices,// @ToDo - shouldn't it be productPrices ?
            revenue: 0,
            transactionId: this.getTransactionId(),
            eventName: trackKey,
            gender: params.data?.member?.contact?.gender ? params.messages[`general.adobe.gender.${params.data?.member?.contact?.gender}`] : "",
            payment_method: this.getPaymentMethod(params.messages, params.data),// @ToDo - shouldn't it be paymentMethod ?
            payment_period: this.getPaymentFrequency(params.messages, params.data),// @ToDo - shouldn't it be paymentPeriod ?
            agentId: this.getAgentId(params),
        };

        if (Array.isArray(dataLayerObj.product_price)) {
            dataLayerObj.revenue = dataLayerObj.product_price.reduce((partialSum, a) => partialSum + a, 0);
        }

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        this.setDataLayer(dataLayerObj, trackKey, params, JourneyType.Product);
    }

    private getProductIds(offers: PolicySearchResult[]): {
        productIds: number[];
        productUnits: number[];
        productPrices: number[];
    } {
        const productIds: Array<number> = [];
        const productUnits: Array<number> = [];
        const productPrices: Array<number> = [];

        for (const offer of offers) {
            if (offer.selected === true) {
                productIds.push(offer.productId!);
                productUnits.push(1);
                productPrices.push(offer.yearlyPremium!);
            }
        }

        return {
            productIds,
            productUnits,
            productPrices
        };
    }
}
