export const extractQueryParams = (identifierKeys: string[], search: string): { [key: string]: string; } => {
    const params: {[key: string]: string} = {};
    for (const key of identifierKeys) {
        const param = new URLSearchParams(search).get(key);
        if (param) {
            params[key] = param;
        }
    }

    return params;
};
