import * as React from "react";
import {defaultTheme, ErrorHeaderIcon, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import {DynamicTitle} from "../../components/general/DynamicTitle";
import {HeaderLayoutNew} from "../../components";
import {IErrorParams} from "./journeyErrorTypes";
import {JourneyErrorNavButton} from "../../components/journey/navigation/JourneyErrorNavButton";
import {CmsContext, ICmsContext} from "../../cms";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {ComponentBackgroundWrapper} from "../../components/ComponentBackgroundWrapper";
import flowRight from "lodash/flowRight";
import injectSheet from "react-jss";
import classNames from "classnames";
import {PropsWithChildren} from "react";

interface IProps {
    params: IErrorParams;
    classes?: any;
}
const cssStyles = {
    wrapper: {
        backgroundColor: defaultTheme.colors.primary5,
        height: '100vh',
        position: 'fixed',
        width: '100%'
    }
}

class JourneyErrorPageInternal extends React.Component<PropsWithChildren<IProps>> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    public render(): React.ReactNode {
        const {params, classes} = this.props;

        return (
            <div className={classNames(classes.wrapper)}>
                <DynamicTitle keyId={CmsHelper.withPrefix(this.context, "title.message")}/>
                    <HeaderLayoutNew
                        themeId={1}
                        journeyId={params?.journeyId || ""}
                        formData={params?.defaultData}
                        navButton={JourneyErrorNavButton}
                        backUrl={params?.backUrl}
                        translations={{
                            header: CmsHelper.withPrefix(this.context, "banner.heading"),
                            headerText: params.headerText,
                        }}
                        params={{
                            icon: <ErrorHeaderIcon />,
                            bigCircle: true,
                            centered: true
                        }}
                        isErrorPage={true}
                    />
                <ComponentBackgroundWrapper theme={3}>
                    <MaxWidthWrapper compact={true}>
                        <Step.SectionBody>
                            <Spacing type={"margin"} bottom={"lg"}>
                                {this.props.children}
                            </Spacing>
                        </Step.SectionBody>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </div>
        );
    }
}
// @ts-ignore
const JourneyErrorPage = flowRight(injectSheet(cssStyles, {inject: ["classes"]} as any))(JourneyErrorPageInternal);
export default JourneyErrorPage;
