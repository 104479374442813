import * as React from "react";
import {memo} from "react";

export const OverlayBlock = memo(() => {
    const overlayStyles = {
        position: "absolute" as "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(120, 120, 120, 0.5)"
    };

    return (
        <div style={overlayStyles}><></></div>
    );
});
