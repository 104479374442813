import * as React from "react";
import {CardNew as CardNewFUI} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import {OutputComponentBase} from "./OutputComponentBase";
import {TooltipOutput} from "./TooltipOutput";

interface IMetadata {
    message: string;
    tooltip?: string;
    descriptionMessage?: string;
}

class CardNewTypography extends OutputComponentBase<string, IMetadata, {}> {
    render() {

        const { message, tooltip, descriptionMessage } = this.metadata;

        const typography =
            <CardNewFUI.Typography element={'h4'} variation={'primary'}>
                <FormattedMessage id={message} />
            </CardNewFUI.Typography>;

        return(
            <>
                {!tooltip && typography}
                {tooltip &&
                    <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                        {typography}
                        {tooltip && <TooltipOutput messageKey={tooltip}/>}
                    </div>}
                {descriptionMessage && <CardNewFUI.Typography element={'span'} variation={'secondary'}>
                    <FormattedMessage id={descriptionMessage} />
                </CardNewFUI.Typography>}
            </>
        );
    }
}

export { CardNewTypography }
