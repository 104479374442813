import * as React from "react";
import {
    defaultTheme as theme,
    DescriptionList,
    IconCaretRightV2,
    Badge,
    useViewContext
} from "@folksam-digital/ui";
import {ReactNode} from "react";

interface IProps {
    icon: ReactNode,
    term: string,
    definition: string
}

export const Slide = (props: IProps) => {
    const { icon, term, definition} = props;
    const viewContext = useViewContext();

    const termStyle = {
        fontWeight: theme.fontWeights.semiBold,
        fontSize: theme.textSizes.base,
        fontFamily: theme.fonts.serif
    };

    const definitionStyle = {
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.textSizes.sm,
        fontFamily: theme.fonts.mono
    };

    const badgeStyle = {
        width: '42px',
        height: '42px',
        marginRight: '10px'
    };

    const descriptionListStyles = {
        display: viewContext.isMobile ? 'flex' : 'block',
        alignItems: 'center',
        background: theme.colors.white,
        borderRadius: theme.borderRadius['10'],
        boxShadow: theme.shadows.xs,
        maxWidth: '450px',
        height: '92px',
        marginTop: theme.margin['4']
    };


    return (
      <DescriptionList style={descriptionListStyles} >
          <DescriptionList.Row spacingLeft={'2'}>
              <Badge style={badgeStyle} color="pink5" icon={icon}/>
              <DescriptionList.Content>
                  <DescriptionList.Term style={termStyle}>
                      {term}
                  </DescriptionList.Term>
                  <DescriptionList.Definition style={definitionStyle}>
                      {definition}
                  </DescriptionList.Definition>
              </DescriptionList.Content>
              <DescriptionList.Icon>
                  <IconCaretRightV2 style={{ color: theme.colors.senary1 }} />
              </DescriptionList.Icon>
          </DescriptionList.Row>
      </DescriptionList>
    )
}
