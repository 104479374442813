import {defaultTheme} from "@folksam-digital/ui";

export const componentStyles = {
    priceBox: {
        container: {
            borderRadius: "4px",
            height: "45px",
            "text-align": "center",
        },
        containerGrey: {
            backgroundColor: defaultTheme.colors.senary3,
        },
        containerTransparent: {
            textAlign: "center" as const,
        },
        containerFlex: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        text: {
            fontSize: defaultTheme.textSizes["6"],
            color: defaultTheme.colors.primary1,
            lineHeight: "45px",
        },
    },
    singleCoverTitle: {
        color: defaultTheme.colors.primary1,
        paddingBottom: defaultTheme.padding.sm,
    },
    markdown: {
        paddingBottom: defaultTheme.padding.sm,
    },
    ssnInput: {
        card: {
            borderRadius: defaultTheme.borderRadius['3'],
            padding: defaultTheme.padding.sm,
            backgroundColor: defaultTheme.colors.white
        },
        description: {
            paddingBottom: defaultTheme.padding.sm,
            marginBottom: defaultTheme.margin.sm,
            borderBottom: `1px solid ${defaultTheme.colors.senary3}`
        }
    }
};

export const schemaTitleStyle = {
    color: defaultTheme.colors.senary2,
    marginLeft: '5px',
    fontFamily: defaultTheme.fonts.mono,
    fontWeight: defaultTheme.fontWeights.regular,
    fontSize: defaultTheme.textSizes.sm,
    marginTop: '5px'
}

