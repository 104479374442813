import {ButtonResponsive, Form, Grid, MaxWidthWrapper, Spacing, AutoComplete} from "@folksam-digital/ui";
import * as React from "react";
import {FormFieldController} from "../../../../Routers/DirectDebit/FormFieldController";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {FormContext, IFormContext} from "../../form/FormContext";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import sanitizeField from "../../form/input/helpers/sanitizeField";
import {container} from "../../../../inversify.config";
import {ContentHeaderHomeFieldValidator} from "../../../../model";
import {Types} from "../../../../Types";
import {IError} from "../../../../model/ValidatorBase";
import isEmpty from "lodash/isEmpty";
import {SsnFormatter, UrlHelper} from "@folksam-digital/services";
import {InputType, JourneyBase, PlaceTypes, Constants, nameof} from "@folksam-digital/model";
import {Address} from "@folksam-digital/model";
import {IInitParams} from "../../../../services";
import get from "lodash/get";
import merge from "lodash/merge";
import isEqualWith from "lodash/isEqualWith";
import cloneDeep from "lodash/cloneDeep";
import {IGooglePlaceInputState, IGooglePlacesAutoCompleteMeta} from "../../form/input";
import {IInputRendererFn, inputRenderer} from "../../form/input/googleAutocomplete/inputRenderer";
import flowRight from "lodash/flowRight";
import withGoogleMaps from "../../form/withGoogleMaps";
import {
    fetchSuggestions,
    fetchSuggestionsCallback,
    getPlacePredictions,
    IFetchSuggestionsCallback, IFetchSuggestionsFn, IGetPlacePredictions,
    initializeService,
    retryInitialize
} from "../../form/input/googleAutocomplete/autocompleteHelper";
import debounce from "lodash/debounce";
import {ISuggestionsRendererFn, SuggestionsRenderer} from "../../form/input/googleAutocomplete/SuggestionsRenderer";
import {
    ISetPlace,
    setPlace,
    renewSessionToken
} from "../../form/input/googlePlaces/placesHelper";
import HomeBaseModel from "@folksam-digital/model/lib/model/HomeBaseModel";
import {normalizeValue} from "../../../../Helpers/normalize";
import pick from "lodash/pick";

interface IContentHeaderHomeFieldsProps extends WrappedComponentProps {
    closeModal: () => void;
}

export interface IHomeData {
    ssn: string;
    address: Address;
    addressSearchable?: string;
    address_components?: google.maps.GeocoderAddressComponent[];
}

interface IContentHeaderHomeFieldsState extends IGooglePlaceInputState {
    data: IHomeData;
    errors: { [key: string]: any };
}

class ContentHeaderHomeFieldsInternal extends React.Component<IContentHeaderHomeFieldsProps, IContentHeaderHomeFieldsState> {
    public static contextType = FormContext;
    public context!: IFormContext;
    private validator: ContentHeaderHomeFieldValidator;
    public ref: React.RefObject<HTMLInputElement>;
    public divRef: React.RefObject<HTMLDivElement>;
    private autocompleteService: google.maps.places.AutocompleteService;
    private placesService: google.maps.places.PlacesService;
    private geocoder: google.maps.Geocoder;
    private metadata: IGooglePlacesAutoCompleteMeta;
    private readonly retryInitialize: () => NodeJS.Timeout;
    private readonly initializeService: () => void;
    private readonly fetchSuggestions: IFetchSuggestionsFn;
    private readonly getPlacePredictions: IGetPlacePredictions;
    private readonly fetchSuggestionsCallback: IFetchSuggestionsCallback;
    private readonly inputRenderer: IInputRendererFn;
    private readonly suggestionsRenderer: ISuggestionsRendererFn;
    private readonly setPlace: ISetPlace;
    private readonly renewSessionToken: () => void;

    public constructor(props: IContentHeaderHomeFieldsProps, context: IFormContext) {
        super(props, context);

        this.metadata = {
            autocompletionRequest: {
                componentRestrictions: {country: [Constants.Country.Sweden.alpha2Code]},
                types: [PlaceTypes.Address],
            },
            placesRequest: {
                fields: ["formatted_address", "address_components"]
            }
        };

        this.validator = container.get<ContentHeaderHomeFieldValidator>(Types.ContentHeaderHomeFieldValidator);
        this.ref = React.createRef();
        this.divRef = React.createRef();

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
        this.isInvalid = this.isInvalid.bind(this);
        this.retryInitialize = retryInitialize.bind(this);
        this.initializeService = initializeService.bind(this);
        this.initializeServiceCallBack = this.initializeServiceCallBack.bind(this);
        this.valuePlaceCallBack = this.valuePlaceCallBack.bind(this);
        this.fetchSuggestions = debounce(fetchSuggestions.bind(this), this.metadata.debounce || 300);
        this.getPlacePredictions = getPlacePredictions.bind(this);
        this.fetchSuggestionsCallback = fetchSuggestionsCallback.bind(this);
        this.inputRenderer = inputRenderer.bind(this);
        this.setPlace = setPlace.bind(this);
        this.setPlaceValueCallback = this.setPlaceValueCallback.bind(this);
        this.renewSessionToken = renewSessionToken.bind(this);
        this.getFormattedObjectAddress = this.getFormattedObjectAddress.bind(this);
        this.parseAddressComponentsFields = this.parseAddressComponentsFields.bind(this);

        let addressSearchable = "";

        if (context.data.policy.insuredObject?.address?.newAddress !== false) {
            addressSearchable = this.getFormattedObjectAddress(cloneDeep(context.data.policy.insuredObject?.address!));
        }

        this.state = {
            data: {
                address: cloneDeep(context.data.policy.insuredObject?.address!),
                ssn: context.data.contact.ssn,
                addressSearchable
            },
            errors: {},
            isLoading: false,
            autocompleteOK: null,
            suggestions: []
        };
    }

    public componentDidMount() {
        const errors = this.validator.validate(this.state.data);
        if (!isEmpty(errors)) {
            this.setState({errors});
        }
        this.initializeService();
    }

    getFormattedObjectAddress(address: Address) {
        return sanitizeField(Object.values(pick(address, [nameof(HomeBaseModel.address.address), nameof(HomeBaseModel.address.postalCode), nameof(HomeBaseModel.address.city)])).join(", "));
    }

    initializeServiceCallBack() {
        this.getPlacePredictions(this.getFormattedObjectAddress(this.state.data.address), this.valuePlaceCallBack);
    }

    private valuePlaceCallBack(suggestions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus): void {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const firstFoundSuggestion = suggestions.find(suggestion => suggestion.description && suggestion.place_id);

            if (firstFoundSuggestion) {
                this.setPlace(firstFoundSuggestion)
            }
        } else {
            // status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            const data = merge({}, this.state.data);
            delete data.address_components;
            const fieldError = this.validator.validateField("addressSearchable", data, this.state.data.addressSearchable);
            const errors = this.mergeErrors(fieldError, "addressSearchable");

            this.setState({data, errors});
        }
    }

    private setPlaceValueCallback(record: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            const tempAddressObj = this.parseAddressComponentsFields(record.address_components!);
            const data = {
                ...this.state.data,
                address_components: record.address_components,
                address: tempAddressObj,
                addressSearchable: this.getFormattedObjectAddress(tempAddressObj)
            } as IHomeData;

            const fieldError = this.validator.validateField("addressSearchable", data, this.getFormattedObjectAddress(tempAddressObj));
            const errors = this.mergeErrors(fieldError, "addressSearchable");

            this.setState({data, errors});

            this.renewSessionToken();
        }
    }

    private parseAddressComponentsFields(addressComponentsFields: google.maps.GeocoderAddressComponent[]): Address {
        const tempAddress = {} as Address;

        const streetNumberObj = addressComponentsFields.find((el: any) => el.types.includes("street_number"));
        const addressObj = addressComponentsFields.find((el: any) => el.types.includes("route"));
        tempAddress.address = `${addressObj?.long_name}${streetNumberObj ? " " : ""}${streetNumberObj ? streetNumberObj?.long_name : ""}`;

        const cityObj = addressComponentsFields.find((el: any) => el.types.includes("postal_town"));
        if (!this.state.data.address.newAddress) {
            tempAddress.city = cityObj?.long_name;
        }

        const postalCode = addressComponentsFields.find((el: any) => el.types.includes("postal_code"));
        if (postalCode) {
            tempAddress.postalCode = postalCode.long_name;
        }

        tempAddress.newAddress = this.state.data.address.newAddress;

        return tempAddress;
    }

    changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isLoading: false});

        if (event.target.value.length > 0) {
            this.fetchSuggestions(event.target.value);
        }

        this.handleOnChange(event);
    };

    handleResultSelect = (record: google.maps.places.AutocompletePrediction) => {
        this.setPlace(record);
    };

    private handleOnChange(event: any) {
        const {id, value} = event.target;
        const data = merge(this.state.data, {[id]: sanitizeField(value)});
        this.setState({data});
    }

    private handleBlur(event: any) {
        const {id, value} = event.target;
        let fieldValue = get(this.state.data, id);
        if (typeof fieldValue === "string") {
            fieldValue = sanitizeField(fieldValue.trim());
        }

        const data = merge(this.state.data, {[id]: fieldValue});
        const fieldError = this.validator.validateField(id, data, get(data, id));
        const errors = this.mergeErrors(fieldError, id);

        this.setState({data, errors}, () => {
            if (this.state.data?.address_components) {
                const tempAddressObj = this.getFormattedObjectAddress(this.parseAddressComponentsFields(this.state.data.address_components));
                if (value !== tempAddressObj) {
                    this.getPlacePredictions(value, this.valuePlaceCallBack);
                }
            }
        });
    }

    private async handleOnSubmit(event: any) {
        event.preventDefault();

        const currentData: JourneyBase<any> = this.context.data;
        this.setState({errors: {}});

        const errors = this.validator.validate(this.state.data);
        const {data} = this.state;
        const currentSsn = SsnFormatter.convertFormat(currentData.contact.ssn, true);

        if (isEmpty(errors.ssn)) {
            data.ssn = normalizeValue(SsnFormatter.formatWithoutDash, data.ssn)!;
        }

        if (!isEmpty(errors)) {
            this.setState({errors});
            return;
        }
        const initAddress = Object.values(currentData.policy.insuredObject?.address!);
        const address = Object.values(data.address);
        const customizer = (ca: string[], a: string[]) => {
            if (ca === a) {
                return true;
            }
        };

        data.ssn = SsnFormatter.convertFormat(data.ssn, true);
        if (!isEqualWith(initAddress, address, customizer) || data.ssn !== currentSsn) {
            const paramObj: IInitParams = {
                ssn: data.ssn,
                gatuadress: data.address.address,
                postnr: data.address.postalCode
            };
            if (!data.address.newAddress) {
                paramObj.hemadress = "N";
            }

            this.context.restartJourneyWithNewData(paramObj, UrlHelper.getPurchaseJourneyUrl(this.context.journeyId));
        }

        this.props.closeModal();
    }

    protected isInvalid(name: string): boolean {
        return !!this.state.errors[name]
    }

    public render() {
        const {intl} = this.props;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Form onSubmit={this.handleOnSubmit}>
                        <MaxWidthWrapper compact={true}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xs>
                                        <Spacing type={"margin"} bottom={"sm"}>
                                            <FormFieldLayout
                                                required={true}
                                                title={<FormattedMessage
                                                    id={CmsHelper.withGeneralPrefix("home.contentHeader.address.title")}/>}
                                                id={"contentHeader-addressSearchable-field"}
                                                error={this.state.errors["addressSearchable"]}
                                                invalid={this.isInvalid("addressSearchable")}
                                            >
                                                <AutoComplete
                                                    ref={this.ref}
                                                    loading={this.state.isLoading}
                                                    onSuggestionSelect={this.handleResultSelect}
                                                    suggestions={this.state.suggestions}
                                                    renderSuggestions={SuggestionsRenderer}
                                                    onChange={this.changeValue}
                                                    idPrefix={"addressSearchable"}
                                                    renderInput={this.inputRenderer}
                                                    value={this.state.data.addressSearchable}
                                                    placeholder={intl.formatMessage({id: CmsHelper.withGeneralPrefix("home.contentHeader.address.placeholder")})}
                                                    invalid={this.isInvalid("addressSearchable")}
                                                />
                                                <div ref={this.divRef}/>
                                            </FormFieldLayout>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col xs>
                                        <Spacing type={"margin"} bottom={"sm"}>
                                            <FormFieldLayout
                                                required={true}
                                                title={<FormattedMessage
                                                    id={CmsHelper.withGeneralPrefix("home.contentHeader.ssn.title")}/>}
                                                id={"contentHeader-ssn-field"}
                                                error={this.state.errors["ssn"]}
                                                invalid={this.isInvalid("ssn")}
                                            >
                                                <FormFieldController
                                                    id={"ssn"}
                                                    value={this.state.data.ssn}
                                                    invalid={this.isInvalid("ssn")}
                                                    isReadOnly={false}
                                                    placeholder={intl.formatMessage({id: CmsHelper.withGeneralPrefix("home.contentHeader.ssn.placeholder")})}
                                                    onChange={this.handleOnChange}
                                                    onBlur={this.handleBlur}
                                                    type={InputType.tel}
                                                />
                                            </FormFieldLayout>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row style={{marginBottom: 25}}>
                                    <Grid.Col xs>
                                        <ButtonResponsive type='submit' full>
                                            <FormattedMessage
                                                id={CmsHelper.withGeneralPrefix("home.contentHeader.submit")}/>
                                        </ButtonResponsive>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </MaxWidthWrapper>
                    </Form>)
                }
            </CmsContext.Consumer>);
    }

    private mergeErrors(fieldError: IError, fieldId: string): IError {
        let errors = this.state.errors || {};

        if (isEmpty(fieldError)) {
            Object.keys(errors).forEach(key => {
                if (key === fieldId) {
                    delete errors[key];
                    return;
                }
            });
        } else {
            errors = merge({}, errors, fieldError);
        }

        return errors;
    }

}

const ContentHeaderHomeFields = flowRight(withGoogleMaps, injectIntl)(ContentHeaderHomeFieldsInternal);

export {ContentHeaderHomeFields};
