import * as React from "react";
import {ChangeEvent} from "react";
import {injectIntl} from "react-intl";
import {FormInput} from "@folksam-digital/ui";
import {IPersonSsnInputMetadata} from "../containers/personSsnInput/ContainerBase";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import sanitizeField from "./helpers/sanitizeField";
import {InputType, REGEXP} from "@folksam-digital/model";
import {SsnFormatter} from "@folksam-digital/services";

interface IPersonSsnInputProps extends IInputComponentProps<any, IPersonSsnInputMetadata> {
    onInputChange: (value: string) => void;
    value: string | undefined;
    error: string | undefined;
}

class PersonSsnInput extends InputComponentBase<any, IPersonSsnInputMetadata, any, IPersonSsnInputProps> {
    constructor(props: any) {
        super(props);

        this.isFieldInvalid = this.isFieldInvalid.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    public render() {
        const {name, value} = this.props;
        const {placeholder} = this.metadata;

        return (
            <FormInput
                id={name}
                autoFocus={false}
                placeholder={placeholder && this.props.intl.formatMessage({id: placeholder})}
                value={value}
                invalid={this.isFieldInvalid()}
                onChange={this.onInputChange}
                type={InputType.tel}
                showCheckMark={this.inputMeetsPatternCheck()}
            />
        );
    }

    private inputMeetsPatternCheck() {
        const {value} = this.props;
        return !!(value && (value.match(REGEXP.SSN_PATTERN) || SsnFormatter.formatWithoutDash(value)!.match(REGEXP.SSN_STARTS_19_OR_20_WHEN_12_SYMBOLS)) && !this.isFieldInvalid());
    }

    private async onInputChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        this.props.onInputChange(sanitizeField(event.target.value));
    }

    private isFieldInvalid(): boolean {
        return (this.props.rawErrors && this.props.rawErrors.length > 0) || !!this.props.error;
    }
}

export default injectIntl(PersonSsnInput);
