import * as React from "react";
import {EnvelopeIcon, FormInput, IconContactPhone} from "@folksam-digital/ui";
import {injectIntl, IntlShape} from "react-intl";
import isEmpty from "lodash/isEmpty";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {IBreakPoint} from "../../layout/helpers";
import {FormFieldLayout} from "../../../FormFieldLayout";
import sanitizeField from "./helpers/sanitizeField";
import {removeInputFilter, setInputFilter} from "./helpers/numberInputHandler";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {Breakpoints, InputMode, InputType, REGEXP} from "@folksam-digital/model";

export interface IMetadata {
    placeholder?: string;
    type?: InputType;
    inputMode?: InputMode;
    prefix?: string;
    suffix?: string;
    classNames?: string;
    help?: string;
    breakpoints?: IBreakPoint;
    isDisabled?: boolean;
    showCheckMark?: boolean;
}

class TextBoxInternal extends InputComponentBase<string, IMetadata, {}> {
    ref: React.RefObject<any>;
    private fieldNotEmptyOnBlur: boolean;

    constructor(props: IInputComponentProps<string, IMetadata>) {
        super(props);
        this.handleOnBlurCallbacks = this.handleOnBlurCallbacks.bind(this);

        this.onChange = this.onChange.bind(this);
        this.ref = React.createRef();
    }

    onChange(event: any) {
        let value = !event.target.value ? "" : sanitizeField(event.target.value);

        // Replace decimal . to decimal ,
        // Android Browsers offer only . as a decimal symbol
        if (this.metadata.inputMode === InputMode.decimal) {
            value = value.replaceAll(".", ",");
        }

        this.props.onChange(value);
    }

    componentDidMount(): void {
        this.fieldNotEmptyOnBlur = this.props.formData?.trim().length > 0;

        if (this.metadata.inputMode === InputMode.decimal) {
            const priceRexExp = new RegExp(REGEXP.PRICE_NUMBER);

            setInputFilter(this.ref.current, (value) => {
                return isEmpty(value) || priceRexExp.test(value);
            });
        } else if (this.metadata.inputMode === InputMode.numeric) {
            const numberRexExp = new RegExp(REGEXP.NUMERIC);

            setInputFilter(this.ref.current, (value) => {
                return isEmpty(value) || numberRexExp.test(value);
            });
        }
    }

    componentWillUnmount(): void {
        if (this.metadata.inputMode === InputMode.decimal || this.metadata.inputMode === InputMode.numeric) {
            removeInputFilter(this.ref?.current);
        }
    }

    public render() {
        const {
            formData,
            name,
            disabled,
            readonly,
            autofocus,
            intl,
        } = this.props;

        const maxLength = this.schema.maxLength;

        const {
            placeholder,
            type = "text",
            inputMode,
            prefix,
            suffix,
            breakpoints,
            isDisabled,
        } = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()} breakpoints={this.getBreakpoints(type, inputMode, breakpoints)}>
                <FormInput
                    id={name}
                    autoFocus={autofocus}
                    ref={this.ref}
                    prefix={this.getPrefix(intl, prefix, inputMode, type)}
                    suffix={suffix && intl.formatMessage({id: suffix})}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    value={formData}
                    disabled={disabled || isDisabled}
                    readOnly={readonly}
                    invalid={this.isInvalid()}
                    maxLength={maxLength || 100}
                    onChange={this.onChange}
                    onBlur={this.handleOnBlurCallbacks}
                    type={type}
                    inputMode={inputMode || this.props?.inputMode}
                    showCheckMark={this.showCheckMarkCondition(!disabled || !isDisabled, suffix || prefix)}
                />
            </FormFieldLayout>
        );
    }

    private showCheckMarkCondition(isFieldEnabled?: boolean, isSuffixOrPrefix?: string) {
        return isFieldEnabled && this.fieldNotEmptyOnBlur && !isSuffixOrPrefix && this.isValid()
    }

    private handleOnBlurCallbacks(event: any) {
        if (this.props.onBlur && typeof this.props.onBlur === "function") {
            this.props.onBlur(event);
        }

        let trimmedFormValue = !this.props.formData ? "" : this.props.formData.trim();

        if (this.props.onBlurValueFormatter) {
            trimmedFormValue = this.props.onBlurValueFormatter(trimmedFormValue);
        }

        this.props.onChange(trimmedFormValue);
        this.onBlurWithValidation(event, trimmedFormValue);
        this.fieldNotEmptyOnBlur = trimmedFormValue.length > 0
    }

    private getPrefix = (intl: IntlShape, prefix?: string, inputMode?: InputMode, inputType?: any) => {
        if (inputType && inputType === InputType.tel) {
            return <IconContactPhone/>
        }
        if (inputMode && inputMode === InputMode.email) {
            return <EnvelopeIcon/>
        }
        return prefix && intl.formatMessage({id: prefix});
    }
    private getBreakpoints = (inputType: string, inputMode?: InputMode, breakpoints?: IBreakPoint): IBreakPoint => {

        if (breakpoints) {
            return {...breakpoints};
        }
        if (inputType === InputType.tel) {
            return {...defaultBreakpoints.phoneNumberInput};
        }
        if (inputMode === InputMode['registrationNumber']) {
            return {...defaultBreakpoints.registrationNumberInput};
        }
        if (inputMode === InputMode['email']) {
            return {...defaultBreakpoints.emailInput};
        }
        return {[Breakpoints.md]: 12}

    }
}

const TextBox = injectIntl(TextBoxInternal);

export {TextBox};
