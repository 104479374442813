import * as React from "react";
import { InputComponentBase } from "./InputComponentBase";

interface IMetadata {
}

export class Hidden extends InputComponentBase<string, IMetadata, {}> {
    public render() {
        const {
            formData,
            name,
        } = this.props;

        return (
            <input name={name} type="hidden" value={formData} />
        )
    }
}
