import {IOptCardPolicy} from "../../components/optInOptOut/form/input/OptCard";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import {Constants, DeepPartial} from "@folksam-digital/model";
import {isMemberPolicy, isCoInsuredPolicy, isChildCoInsuredPolicy, isSeniorCoInsuredPolicy} from "../../components/optInOptOut/form/container/helpers";

export function groupPolicies(policies?: DeepPartial<IOptCardPolicy>[]): {[key: string]: DeepPartial<IOptCardPolicy>[]} {
    if (isEmpty(policies)) {
        return {};
    }

    return groupBy(policies, (value) => {
        if (isMemberPolicy(value.associatedInsTypeId)) {
            return Constants.AssociatedInsuranceTypes.member
        }

        if (isCoInsuredPolicy(value.associatedInsTypeId)) {
            return Constants.AssociatedInsuranceTypes.coInsured
        }

        if (isChildCoInsuredPolicy(value.associatedInsTypeId)) {
            return Constants.AssociatedInsuranceTypes.childCoInsured
        }

        if (isSeniorCoInsuredPolicy(value.associatedInsTypeId)) {
            return Constants.AssociatedInsuranceTypes.seniorCoInsured
        }
    });
}
