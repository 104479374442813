import * as React from "react";
import {DescriptionListResponsive, DescriptionList} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";

export interface IRowDefinition {
    term: string | JSX.Element;
    definition: JSX.Element;
    directionRow?: boolean;
}

interface ISummaryCardRowList {
    list: IRowDefinition[];
    bottomSeparator?: boolean;
}

export const SummaryCardRowList = (props: ISummaryCardRowList) => {
    return <DescriptionListResponsive breakpoint={12}>
        {props.list.map((param: any, idx: number) => (
            <DescriptionListResponsive.Row key={idx}>
                <DescriptionList.Content directionRow={param?.directionRow}>
                    <DescriptionListResponsive.Term>
                        {(typeof param.term === 'string') ? <FormattedMarkdown messageKey={param.term}/> : param.term}
                    </DescriptionListResponsive.Term>
                    <DescriptionListResponsive.Definition>
                        {param.definition}
                    </DescriptionListResponsive.Definition>
                </DescriptionList.Content>
            </DescriptionListResponsive.Row>
        ))}
    </DescriptionListResponsive>
};
