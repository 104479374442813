import merge from "lodash/merge";
import moment from "moment"
import {JourneyBase, Cover, Constants, CoverPlan, Discount} from "@folksam-digital/model";
import {
    BaseAnalytics,
    BaseMapping,
    IOnTriggerDataLayerEventParams, PageTypes
} from "./BaseAnalytics";
import {StorageKeys} from "../../services";
import uniq from "lodash/uniq";
import {IMessages} from "../../cms";
import {UrlHelper, PbbHelper, FeatureToggleUtils} from "@folksam-digital/services";
import {SatelliteEvents} from "../new/BaseAnalytics";

export interface JourneyMapping extends BaseMapping {
    product_id: Array<string>;
    product_units: Array<number>;
    product_price: Array<number>;
    transaction_id: string;
    age: string;
    pregnancy_week?: number;
    insurance_amount?: number;
    agentId?: string;
    item_year?: string;
    payment_method?: string;
    payment_period?: string;
    gender?: string;
    discount?: string[];
    domain?: string;
    milage?: string;
    prev_insurance?: string;
    revenue?: number;
    transaction_start?: string;
    transaction_step2?: string;
    transaction_step3?: string;
    transaction_step4?: string;
    transaction_step5?: string;
    transaction_step6?: string;
    transaction_complete?: string;
    pageurl?: string;
    union?: string;
    event?: string;
    residents?: number;
    area?: number;
    address?: "Ja" | "Nej";
    partner?: string;
}

interface IProductAnalyticsCode {
    [key: string]: string;
}

interface IProductData {
    productId: string[];
    productUnits: number[];
    productPrice: number[];
}

interface IProductCoverPlanAdd {
    productIds: string[];
    coverPlan?: string;
    productCode: string;
    withProdSuffix?: boolean;
}

interface IProductAddonAdd {
    productIds: string[];
    addon?: Cover;
    productCode: string;
    withProdSuffix?: boolean;
}

interface ICustomProperties {
    dataLayerObj: JourneyMapping;
    messages: IMessages;
    journeyId?: string;
    currentStepIndex?: number;
    data?: JourneyBase<any>
    currentStepName?: string;
    dataChanged?: boolean;
}

interface IAppendTransactionStep {
    dataLayerObj: JourneyMapping;
    stepsTotal: number;
    currentStepName?: string;
    currentStepIndex?: number;
}

const productAnalyticsCodes = {
    // @ToDo - Specific Product constants be moved and used from Analytics.ProductCode
    // Accident Simple less than 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA2000}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA1000}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA500}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.financialDisability}_1_${Constants.Journey.Accident.Id}`]: "VOF",

    // Accident Simple over 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA2000}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA1000}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA500}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_3_${Constants.Journey.Accident.Id}`]: "VOP",

    // Accident and Illness less than 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.AccidentAndIllness.AI2000}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.AccidentAndIllness.AI1000}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",

    // Individual Child
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Large}`]: "BOS",
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Medium}`]: "BOS",
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Base}`]: "BOS",

    // Pregnancy
    [`${Constants.Product.Pregnancy.id}_${Constants.CoverPlan.Pregnancy.Extended}`]: "GRU",
    [`${Constants.Product.Pregnancy.id}_${Constants.CoverPlan.Pregnancy.Basic}`]: "GRG",

    // Member Child
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Large}`]: "BOSM",
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Medium}`]: "BOSM",
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Base}`]: "BOSM",

    // Running race
    [`${Constants.Product.RunningRace.id}_${Constants.CoverPlan.RunningRace.Extended}`]: "MLF",
    [`${Constants.Product.RunningRace.id}_${Constants.CoverPlan.RunningRace.Basic}`]: "MLF",
} as IProductAnalyticsCode;

const accidentAndIllnessProductLineGroup: number = 1000157;

export class JourneyAnalytics extends BaseAnalytics {
    protected getPageName(params: IOnTriggerDataLayerEventParams): string {
        let pageName: string = super.getPageName(params);
        // @ToDo - if statement can be moved to BaseAnalytics when other type analytic requirements change regards "|" symbol implementation and is same as Products
        if (pageName === "" && typeof params.currentStepIndex === 'number') {
            pageName = params.messages["general.adobe.step"] + (params.currentStepIndex + 1);
        }

        return pageName;
    }

    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.product.${params.journeyId!.toString()}`];
        const pageName: string = this.getPageName(params);
        const pageType = params.messages[`general.adobe.product.pageType`];
        const pageStart = params.messages[`general.adobe.product.pageStart`];

        let productData: IProductData = {} as IProductData;
        if (params.data) {
            productData = this.mapProductIdToAnalyticsCode(params.data, params.journeyId!);
        }

        let dataLayerObj: JourneyMapping = {
            age: (params.data?.contact?.dateOfBirth && moment.utc(params.data?.contact?.dateOfBirth)?.year()?.toString()) || "",
            gender: params.data?.contact?.gender ? params.messages[`general.adobe.gender.${params.data.contact.gender}`] : "",
            customer_id: await this.getUserId(params.data?.contact?.externalContactNumber),
            pagetype: pageType,
            pagename: `${pageStart}|${pageType}|${journeyName}${pageName}`,
            pageurl: UrlHelper.getPurchaseAnalyticsPageURL(params?.journeyId!, window.origin, params?.currentStep),
            sitesection1: `${pageStart}`,
            sitesection2: `${pageStart}|${pageType}`,
            sitesection3: `${pageStart}|${pageType}|${journeyName}`,
            sitesection4: `${pageStart}|${pageType}|${journeyName}${pageName}`,
            product_id: productData.productId,
            product_units: productData.productUnits,
            product_price: productData.productPrice,
            transaction_id: this.getTransactionId(),
            agentId: this.getAgentId(params),
        };

        if (this.getPaymentMethod(params.messages, params.data)) {
            dataLayerObj.payment_method = this.getPaymentMethod(params.messages, params.data);
        }

        if (this.getPaymentFrequency(params.messages, params.data)) {
            dataLayerObj.payment_period = this.getPaymentFrequency(params.messages, params.data);
        }

        if (this.isCoopEnabled(params.data, params.journeyId)) {
            dataLayerObj.partner = Constants.Partners.Coop;
        }

        const customProperties: ICustomProperties = {
            dataLayerObj,
            messages: params?.messages,
            journeyId: params?.journeyId,
            currentStepIndex: params.currentStepIndex,
            data: params?.data,
            currentStepName: params.currentStep,
            dataChanged: params?.dataChanged
        };

        dataLayerObj = this.addCustomProperties(customProperties);

        let trackKey = `${params.transactionType}_${params.event}`;
        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        if (params.currentStep === "error") {
            dataLayerObj.event = trackKey;
            trackKey = PageTypes.SPAPage;
        }

        this.setDataLayer(dataLayerObj, trackKey);
    }

    private mapProductIdToAnalyticsCode(data: JourneyBase<any>, journeyId: string): IProductData {
        const productId = data.policy?.productId;
        const coverIds: (number | string)[] = [];
        const covers: Cover[] = data.policy?.covers;
        let optionNumber: number | undefined;

        if (covers) {
            for (const cover of Object.values(covers)) {
                if (!cover?.selected || !cover?.id) {
                    continue;
                }

                // Can be improved
                // If cover plan is Traffic, ignore Assistance cover
                if (journeyId === Constants.Journey.MobileHome.Id && data?.policy.coverPlan === Constants.CoverPlan.MobileHome.Traffic) {
                    continue;
                }

                coverIds.push(cover.id);
            }
        }

        if (data.policy?.coverPlan) {
            coverIds.push(data.policy.coverPlan);
        }

        const coverPlan = data.policy?.premium?.coverPlans?.find((row: CoverPlan) => row.id === data?.policy?.coverPlan);
        const mapOptionNumber = () => {
            for (const cover of coverPlan.covers as Cover[]) {
                if (cover?.id !== accidentAndIllnessProductLineGroup) {
                    continue;
                }
                optionNumber = cover.optionNumber;
            }
        }
        switch (journeyId) {
            case Constants.Journey.Accident.Id:
                if (coverPlan?.covers) {
                    mapOptionNumber();
                } else {
                    optionNumber = data?.contact?.age >= 65 ? 3 : 1;
                }
                break;
            case Constants.Journey.AccidentAndIllness.Id:
                if (coverPlan?.covers) {
                    mapOptionNumber();
                } else {
                    optionNumber = data?.contact?.age >= 65 ? undefined : 2;
                }
                break;
        }

        const mappedProductId: string[] = [];
        const mappedProductUnits: number[] = [];
        const mappedProductPrice: number[] = [];

        coverIds.forEach((element: number | string) => {
            let id: string = `${productId}_${element}`;
            if (optionNumber) {
                id = `${id}_${optionNumber}_${journeyId}`;
            }

            if (productAnalyticsCodes[id]) {
                if (element.toString() === Constants.CoverPlan.MemberChild.Large
                    || element.toString() === Constants.CoverPlan.MemberChild.Medium) {
                    mappedProductId.push(`${productAnalyticsCodes[id]}_${element.toString().substring(0, element.toString().indexOf("_0"))}`);
                } else {
                    mappedProductId.push(`${productAnalyticsCodes[id]}_${element}`);
                }
            } else {
                mappedProductId.push(id);
            }

            mappedProductUnits.push(1);
            mappedProductPrice.push(this.mapProductPrice(data, element));
        });

        return {
            productId: mappedProductId,
            productUnits: mappedProductUnits,
            productPrice: mappedProductPrice
        };
    }

    private mapProductPrice(data: JourneyBase<any>, coverId: number | string) {
        const coverPlan = data.policy?.premium?.coverPlans?.find((row: CoverPlan) => row.id === coverId);

        if (coverPlan) {
            return coverPlan?.yearlyPremium;
        } else if (data.policy.covers && data.policy.premium && data.policy.premium.covers) {
            for (const cover of data.policy.premium.covers as Cover[]) {
                if (cover.id === coverId) {
                    return cover.yearlyPremium;
                }
            }
        }
    }

    private getInsuranceAmount(dataLayerObj: JourneyMapping, mainCoverId: number, data?: JourneyBase<any>): JourneyMapping {
        if (data?.policy?.coverPlan) {
            const coverPlanDetails = PbbHelper.getCoverPlanDetails(data, data.policy.coverPlan, mainCoverId);
            if (coverPlanDetails.insuranceAmount) {
                dataLayerObj.insurance_amount = coverPlanDetails.insuranceAmount;
            }
        }

        return dataLayerObj;
    }

    private addCustomProperties(customProperties: ICustomProperties): JourneyMapping {
        const {messages, journeyId, currentStepIndex, currentStepName, data, dataChanged} = customProperties;
        let {dataLayerObj} = customProperties;

        const journeyName = messages[`general.adobe.product.${journeyId!.toString()}`];
        const pageName: string = this.getPageNameCustom(messages, journeyId, currentStepIndex, currentStepName);

        const setPageNameAndSiteSectionName = (dataObj: JourneyMapping) => {
            return merge({}, dataObj, this.getPageNameAndSiteSectionName(messages, dataLayerObj, journeyName, pageName, journeyId));
        }

        const setBaseCustomProperties = (dataObj: JourneyMapping): JourneyMapping => {
            dataObj.agentId = Constants.AgentId.Policy;
            dataObj.discount = uniq(data?.policy?.premium?.discounts?.map((discount: Discount) => discount.reason));
            dataObj.domain = "folksam.se";
            dataObj = setPageNameAndSiteSectionName(dataObj);
            dataObj.product_id = this.getProductId(journeyId, data);

            return dataObj;
        };

        switch (journeyId) {
            case Constants.Journey.Accident.Id:
            case Constants.Journey.AccidentAndIllness.Id:
                dataLayerObj = setPageNameAndSiteSectionName(dataLayerObj);
                dataLayerObj = this.getInsuranceAmount(dataLayerObj, Constants.Product.AccidentAndIllness.mainCoverId, data);
                break;
            case Constants.Journey.Pregnancy.Id:
                dataLayerObj = setPageNameAndSiteSectionName(dataLayerObj);
                customProperties.dataLayerObj.pregnancy_week = customProperties.data?.policy?.insuredObject?.noOfWeekPregnant;
                break;
            case Constants.Journey.IndividualChild.Id:
                dataLayerObj = setPageNameAndSiteSectionName(dataLayerObj);
                dataLayerObj = this.getInsuranceAmount(dataLayerObj, Constants.Product.IndividualChild.mainCoverId, data);
                break;
            case Constants.Journey.MemberChild.Id:
                dataLayerObj = setPageNameAndSiteSectionName(dataLayerObj);
                dataLayerObj = this.getInsuranceAmount(dataLayerObj, Constants.Product.MemberChild.mainCoverId, data);
                break;
            case Constants.Journey.Trailer.Id:
            case Constants.Journey.Caravan.Id:
            case Constants.Journey.HeavyMotorcycle.Id:
            case Constants.Journey.Moped.Id:
            case Constants.Journey.LightMotorcycle.Id:
            case Constants.Journey.SnowmobileAtv.Id:
            case Constants.Journey.MobileHome.Id:
            case Constants.Journey.EpaAndATractor.Id:
            case Constants.Journey.SmallTractor.Id:
            case Constants.Journey.MopedCar.Id:
                dataLayerObj = setBaseCustomProperties(dataLayerObj);
                dataLayerObj.item_year = data?.policy?.insuredObject?.yearOfManufacture || "";
                dataLayerObj = this.appendTransactionStepData({dataLayerObj, stepsTotal: 5, currentStepIndex, currentStepName});

                customProperties.dataLayerObj = dataLayerObj;
                break;
            case Constants.Journey.LightTruck.Id:
            case Constants.Journey.Car.Id:
                dataLayerObj = setBaseCustomProperties(dataLayerObj);
                dataLayerObj.item_year = data?.policy?.insuredObject?.yearOfManufacture || "";

                if (Array.isArray(dataLayerObj.product_price)) {
                    dataLayerObj.revenue = dataLayerObj.product_price.reduce((partialSum, a) => partialSum + a, 0);
                }

                if (data?.policy?.insuredObject?.drivingDistancePerYear) {
                    dataLayerObj.milage = data?.policy?.insuredObject?.drivingDistancePerYear.label;
                }
                // In production when data is not changed furthest step set window.datalayer is remembered
                if (dataChanged === false) {
                    dataLayerObj = window.datalayer;
                } else {
                    dataLayerObj = this.appendTransactionStepDataOneByOne({dataLayerObj, stepsTotal: 5, currentStepIndex, currentStepName});
                }

                customProperties.dataLayerObj = dataLayerObj;
                break;
            case Constants.Journey.Cat.Id:
            case Constants.Journey.Dog.Id:
                dataLayerObj = setBaseCustomProperties(dataLayerObj);
                if (data?.policy?.insuredObject?.prevInsurance?.insured) {
                    dataLayerObj.prev_insurance = data?.policy?.insuredObject?.prevInsurance?.placeOfIssue?.label;
                }
                if (data?.policy?.insuredObject?.birthDate) {
                    dataLayerObj.item_year = moment.utc(data.policy.insuredObject.birthDate).year().toString();
                }
                dataLayerObj = this.appendTransactionStepData({dataLayerObj, stepsTotal: 6, currentStepIndex, currentStepName});

                customProperties.dataLayerObj = dataLayerObj;
                break;
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeVacation.Id:
            case Constants.Journey.HomeCondominium.Id:
                dataLayerObj = setBaseCustomProperties(dataLayerObj);
                dataLayerObj = this.appendTransactionStepData({dataLayerObj, stepsTotal: 5, currentStepIndex, currentStepName});
                if (data?.policy?.insuredObject?.numberOfResidents) {
                    dataLayerObj.residents = Number(data?.policy?.insuredObject?.numberOfResidents);
                }
                if (data?.policy?.insuredObject?.livingArea) {
                    dataLayerObj.area = Number(data?.policy?.insuredObject?.livingArea);
                }
                if (data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome) {
                    const legacyGroupCondition = data?.policy?.insuredObject?.group?.id && data?.policy?.insuredObject?.group?.name;
                    const iditGroupCondition = data?.targetEnv === Constants.TargetEnvironment.Idit && data?.policy?.insuredObject?.group?.name;

                    if ((legacyGroupCondition) || (iditGroupCondition)) {
                        dataLayerObj.union = String(data?.policy?.insuredObject?.group?.id);
                    }
                }
                if (journeyId === Constants.Journey.HomeVacation.Id) {
                    // newAddress question/answer reversed
                    dataLayerObj.address = data?.policy?.insuredObject?.address?.newAddress === true ? "Ja" : "Nej";
                } else {
                    dataLayerObj.address = data?.policy?.insuredObject?.address?.newAddress === false ? "Ja" : "Nej";
                }

                customProperties.dataLayerObj = dataLayerObj;
                break;
            default:
                break;
        }

        return customProperties.dataLayerObj;
    }

    private removeExistingWindowDataLayerKey(key: string) {
        if (Object.keys(window.datalayer).includes(key)) {
            delete window.datalayer[key];
        }
    }

    private appendTransactionStepDataOneByOne(params: IAppendTransactionStep): JourneyMapping {
        const currentJourneyStep = (params?.currentStepIndex || 0) + 1;

        // remove any previously existing transaction step due to back, forward navigation before setting current transaction step value
        if (currentJourneyStep > 1 || params?.currentStepIndex === undefined) {
            this.removeExistingWindowDataLayerKey("transaction_start");
        }
        if (!this.isStepTypeSuccess(params.currentStepName || "")) {
            this.removeExistingWindowDataLayerKey("transaction_complete");
        }
        for (let stepIndex = 2; stepIndex <= params.stepsTotal; stepIndex++) {
            const removableTransactionStep = `transaction_step${stepIndex}`;
            this.removeExistingWindowDataLayerKey(removableTransactionStep);
        }

        // set current transaction step
        if (Number(params?.currentStepIndex) === 0) {
            params.dataLayerObj.transaction_start = "transaction_start";
        } else if (this.isStepTypeSuccess(params.currentStepName || "")) {
            params.dataLayerObj.transaction_complete = "transaction_complete"
        } else if (currentJourneyStep > 1) {
            // @ts-ignore
            params.dataLayerObj[`transaction_step${currentJourneyStep}`] = `transaction_step${currentJourneyStep}`;
        }

        return params.dataLayerObj;
    }

    private appendTransactionStepData(params: IAppendTransactionStep): JourneyMapping {
        for (let stepIndex = 2; stepIndex <= params.stepsTotal; stepIndex++) {
            // @ts-ignore
            params.dataLayerObj[`transaction_step${stepIndex}`] = params?.currentStepIndex === stepIndex - 1 ? `transaction_step${stepIndex > 1 ? stepIndex : ""}` : "";
        }
        params.dataLayerObj.transaction_start = Number(params?.currentStepIndex) === 0 ? "transaction_start" : "";
        params.dataLayerObj.transaction_complete = this.isStepTypeSuccess(params.currentStepName || "") ? "transaction_complete" : "";

        return params.dataLayerObj;
    }

    private getPaymentMethod(messages: IMessages, data?: JourneyBase<any>): string {
        return messages[`general.policy.paymentDetails.paymentMethod.${data?.policy?.paymentDetails?.paymentMethod}`] ?
            messages[`general.policy.paymentDetails.paymentMethod.${data?.policy?.paymentDetails?.paymentMethod}`] : "";
    }

    private getPaymentFrequency(messages: IMessages, data?: JourneyBase<any>): string {
        return messages[`general.policy.paymentDetails.paymentFrequency.${data?.policy?.paymentDetails?.paymentFrequency}`] ?
            messages[`general.policy.paymentDetails.paymentFrequency.${data?.policy?.paymentDetails?.paymentFrequency}`] : "";
    }

    private getProductId(journeyId?: string, data?: JourneyBase<any>): string[] {
        let productIds: string[] = [];
        const policyCover = data?.policy?.covers;
        const policyCoverPlan = data?.policy?.coverPlan;
        const productCode = Constants.Analytics.LegacyProductCode[journeyId!];
        const homeProductCode = data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome ?
            Constants.Analytics.LegacyProductCode[`${journeyId}${Constants.HomeGroupType.KHome}`] : data?.policy?.productCode ?
                data.policy.productCode : productCode;

        switch (journeyId) {
            case Constants.Journey.SnowmobileAtv.Id:
                let snowMobilKey = "SNS" as keyof typeof Constants.Analytics.Prefix.SnowmobileAtv;

                if (data?.policy?.productCode) {
                    snowMobilKey = data?.policy?.productCode as keyof typeof Constants.Analytics.Prefix.SnowmobileAtv;
                }

                return this.createProductIdMessage(Constants.Analytics.Prefix.SnowmobileAtv[snowMobilKey], policyCoverPlan);
            case Constants.Journey.HeavyMotorcycle.Id:
                const key = `${journeyId}_${data?.policy?.insuranceType}`;
                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[key], policyCoverPlan);
            case Constants.Journey.MobileHome.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode, withProdSuffix: true});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode, withProdSuffix: true});

                return productIds;
            case Constants.Journey.Moped.Id:
                if (!data?.policy?.insuredObject?.registrationNumber) {
                    return this.createProductIdMessage(Constants.LegacyProductCodes.MopedWithoutRegistrationNumber, policyCoverPlan);
                }

                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
            case Constants.Journey.MopedCar.Id:
                if (!data?.policy?.insuredObject?.registrationNumber) {
                    return this.createProductIdMessage(Constants.LegacyProductCodes.MopedCarWithoutRegistrationNumber, policyCoverPlan);
                }

                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
            case Constants.Journey.Cat.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.lifeInsurance, productCode, withProdSuffix: true});
                productIds = this.addProductAddon({productIds, addon: policyCover?.dentalDisease, productCode, withProdSuffix: true});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode, withProdSuffix: true});

                return productIds;
            case Constants.Journey.Dog.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.lifeInsurance, productCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.breedingInsurance, productCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode});

                return productIds;
            case Constants.Journey.LightTruck.Id:
                let productCodeTruck = Constants.Analytics.Prefix.LightTruck;

                if (data?.policy?.insuredObject?.truckType) {
                    productCodeTruck = `${productCodeTruck}_${data.policy.insuredObject.truckType}`;
                }

                productIds = this.addProductAddon({productIds, addon: policyCover?.rentalCar, productCode: productCodeTruck});
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode: productCodeTruck});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCodeTruck});

                return productIds;
            case Constants.Journey.Car.Id:
                const productCodeCar = `${data?.policy?.insuredObject?.brand}`;
                // car is exception to not have prodcutCode combined with addon.Id.
                productIds = this.addProductAddon({productIds, addon: policyCover?.rentalCar, productCode: ""});
                productIds = this.addProductAddon({productIds, addon: policyCover?.assistance, productCode: ""});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCodeCar});

                return productIds;
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeCondominium.Id:
                productIds = this.addProductAddon({productIds, addon: policyCover?.travelInsurance, productCode: homeProductCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.golfInsurance, productCode: homeProductCode});
                productIds = this.addProductAddon({productIds, addon: policyCover?.smallBoatInsurance, productCode: homeProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeProductCode});

                return productIds;
            case Constants.Journey.HomeStudent.Id:
                const revertAccidentInsuranceIdConcat = (accidentInsuranceCover: any, replacePattern: string) => {
                    if (accidentInsuranceCover?.id) {
                        accidentInsuranceCover.id = accidentInsuranceCover.id.replace(replacePattern, "")
                    }

                    return accidentInsuranceCover;
                };

                if (data?.targetEnv === Constants.TargetEnvironment.Idit) {
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsurance,
                        productCode: homeProductCode
                    })
                } else {
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsurance?.id?.includes("FT") ? revertAccidentInsuranceIdConcat(policyCover?.accidentInsurance, "FT") : policyCover?.accidentInsurance,
                        productCode: homeProductCode
                    });
                    productIds = this.addProductAddon({
                        productIds,
                        addon: policyCover?.accidentInsuranceRoomMate?.id?.includes("MF") ? revertAccidentInsuranceIdConcat(policyCover?.accidentInsuranceRoomMate, "MF") : policyCover?.accidentInsuranceRoomMate,
                        productCode: homeProductCode
                    });
                }

                productIds = this.addProductAddon({productIds, addon: policyCover?.travelInsurance, productCode: homeProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeProductCode});

                if (data?.policy?.insuredObject?.numberOfResidents === 2) {
                    productIds?.push(`${homeProductCode}_MFO`);
                }

                return productIds;
            case Constants.Journey.EpaAndATractor.Id:
                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: productCode});

                return productIds;
            case Constants.Journey.HomeVacation.Id:
                const homeVacationProductCode = data?.policy?.insuredObject?.wantsInsureThings === false ? Constants.LegacyProductCodes.HomeVacationNoInsuranceForThings : Constants.LegacyProductCodes.HomeVacation;
                productIds = this.addProductAddon({productIds, addon: policyCover?.smallBoatInsurance, productCode: homeVacationProductCode});

                productIds = this.addProductCoverPlan({productIds, coverPlan: policyCoverPlan, productCode: homeVacationProductCode});
                return productIds;
            default:
                return this.createProductIdMessage(Constants.Analytics.LegacyProductCode[journeyId!], policyCoverPlan);
        }
    }

    private addProductCoverPlan({productIds, coverPlan, productCode, withProdSuffix = false}: IProductCoverPlanAdd): string[] {
        if (coverPlan) {
            const prodSuffix = withProdSuffix ? `_${Constants.Analytics.Suffix.Prod}` : '';

            productIds.push(`${productCode}_${coverPlan}${prodSuffix}`);
        } else if (productCode) {
            productIds.push(productCode);
        }

        return productIds;
    }

    private addProductAddon({productIds, addon, productCode, withProdSuffix = false}: IProductAddonAdd): string[] {
        if (addon?.selected && addon?.available) {
            const prodSuffix = withProdSuffix ? `_${Constants.Analytics.Suffix.Prod}` : '';

            productIds.push(`${productCode}${productCode ? '_' : ''}${addon.id}${prodSuffix}`);
        }

        return productIds;
    }

    private createProductIdMessage(productCode: string, coverPlan: string | undefined): string[] {
        if (!coverPlan) {
            return [`${productCode}_${Constants.Analytics.Suffix.Prod}`];
        }

        return [`${productCode}_${coverPlan}_${Constants.Analytics.Suffix.Prod}`];
    }

    private getAgentId(params: IOnTriggerDataLayerEventParams): string | undefined {
        const sessionParams: any = this.sessionStorage.get(StorageKeys.PARAMS);
        switch (params.journeyId) {
            case Constants.Journey.IndividualChild.Id:
            case Constants.Journey.AccidentAndIllness.Id:
            case Constants.Journey.Accident.Id:
            case Constants.Journey.MemberChild.Id:
            case Constants.Journey.Pregnancy.Id:
            case Constants.Journey.RunningRace.Id:
            case Constants.Journey.SportsTravel.Id:
                return sessionParams?.agentId;
        }
    }

    private getPageNameCustom(messages: IMessages, journeyId?: string, currentStepIndex?: number, currentStepName: string = ""): string {
        let pageName: string = "";
        let key: string = "";

        if (currentStepIndex !== undefined) {
            switch (journeyId) {
                case Constants.Journey.Accident.Id:
                case Constants.Journey.AccidentAndIllness.Id:
                case Constants.Journey.Pregnancy.Id:
                case Constants.Journey.IndividualChild.Id:
                case Constants.Journey.MemberChild.Id:
                    key = `general.adobe.step.${journeyId}.${Number(currentStepIndex) + 1}`;
                    break;
                case Constants.Journey.Cat.Id:
                case Constants.Journey.Dog.Id:
                case Constants.Journey.LightTruck.Id:
                case Constants.Journey.Car.Id:
                case Constants.Journey.HomeRental.Id:
                case Constants.Journey.HomeApartment.Id:
                case Constants.Journey.HomeStudent.Id:
                case Constants.Journey.HomeBuilding.Id:
                case Constants.Journey.HomeVacation.Id:
                case Constants.Journey.HomeCondominium.Id:
                    key = `general.adobe.legacyStep.${journeyId}.${Number(currentStepIndex) + 1}`;
                    break;
                default:
                    key = `general.adobe.legacyStep.${Number(currentStepIndex) + 1}`;
                    break;
            }

            pageName = messages[key];
        }

        if (this.isStepTypeError(currentStepName)) {
            pageName = messages["general.adobe.error"];
        } else if (this.isStepTypeSuccess(currentStepName)) {
            pageName = messages["general.adobe.legacyStep.success"];
        }

        return pageName;
    }

    private getPageNameAndSiteSectionName(messages: IMessages, dataLayerObj: any, journeyName: string, pageName: string, journeyId?: string) {
        const pageType = messages[`general.adobe.product.pageType`];
        const pageStart = messages[`general.adobe.product.pageStart`];
        const pageSectionReview = messages[`general.adobe.product.pageSection.review`];

        switch (journeyId) {
            case Constants.Journey.Accident.Id:
            case Constants.Journey.AccidentAndIllness.Id:
            case Constants.Journey.Pregnancy.Id:
            case Constants.Journey.IndividualChild.Id:
            case Constants.Journey.MemberChild.Id:
            case Constants.Journey.EpaAndATractor.Id:
            case Constants.Journey.MopedCar.Id:
            case Constants.Journey.SmallTractor.Id:
            case Constants.Journey.LightTruck.Id:
            case Constants.Journey.Car.Id:
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeVacation.Id:
            case Constants.Journey.HomeCondominium.Id:
                dataLayerObj.pagename = `${pageStart}|${pageType}|${journeyName}|${pageName}`;
                dataLayerObj.sitesection1 = `${pageStart}`;
                dataLayerObj.sitesection2 = `${pageStart}|${pageType}`;
                dataLayerObj.sitesection3 = `${pageStart}|${pageType}|${journeyName}`;
                dataLayerObj.sitesection4 = `${pageStart}|${pageType}|${journeyName}|${pageName}`;
                break;
            default:
                dataLayerObj.pagename = `${pageStart}|${pageType}|${journeyName}|${pageSectionReview}|${pageName}`;
                dataLayerObj.sitesection1 = `${pageStart}`;
                dataLayerObj.sitesection2 = `${pageStart}|${pageType}`;
                dataLayerObj.sitesection3 = `${pageStart}|${pageType}|${journeyName}`;
                dataLayerObj.sitesection4 = `${pageStart}|${pageType}|${journeyName}|${pageSectionReview}`;
                dataLayerObj.sitesection5 = `${pageStart}|${pageType}|${journeyName}|${pageSectionReview}|${pageName}`;
                break;
        }
        return dataLayerObj;
    }

    private isCoopEnabled(data?: JourneyBase<any>, journeyId?: string): boolean {
        switch (journeyId) {
            case Constants.Journey.Car.Id:
            case Constants.Journey.Cat.Id:
            case Constants.Journey.Dog.Id:
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeVacation.Id:
                if (FeatureToggleUtils.isFeatureEnabled("coop") && data?.policy?.partner === Constants.Partners.Coop) {
                    return true;
                }
        }
        return false;
    }
}
