import * as React from "react";
import {get} from "lodash";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import {DownloadLinkOutputBase} from "../../../journey/form";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {DescriptionList} from "@folksam-digital/ui";

export default class GroupPDFDownloadLinkOutput extends DownloadLinkOutputBase {
    public render() {

        const {
            modelPath
        } = this.metadata;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <FormContext.Consumer>
                        {(context: IFormContext) => {
                            const {id} = get(context.data, modelPath!);
                            const url: string = cmsContext.catalog!.messages[CmsHelper.withGroupCommonPrefix(`link_${id}`)] ? cmsContext.catalog!.messages[CmsHelper.withGroupCommonPrefix(`link_${id}`)] : cmsContext.catalog!.messages[CmsHelper.withGroupCommonPrefix("link_default")];
                            return (
                                <DescriptionList.Row>
                                    <DescriptionList.Definition>
                                        {this.renderLink(url)}
                                    </DescriptionList.Definition>
                                </DescriptionList.Row>

                            );
                        }}
                    </FormContext.Consumer>
                )}
            </CmsContext.Consumer>
        );
    }
}
