import React from "react";
import ReactMarkdown from 'react-markdown';
import {List, Link, DynamicList} from "@folksam-digital/ui";
import {ModalIframe} from "./journey/form";
import {MarkdownListTypes} from "@folksam-digital/model";
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import {Components} from "react-markdown/lib/ast-to-react";

const schema = {
    ...defaultSchema,
    attributes: {
        ...defaultSchema.attributes,
        a: [
            // Preserve default allowed attributes for 'a'
            // @ts-ignore
            ...(defaultSchema.attributes.a || []),
            // Explicitly allow 'href' attribute on 'a' tags
            ['href', 'tel', 'http', 'https', 'mailto'],
        ],
    },
    protocols: {
        ...defaultSchema.protocols,
        // @ts-ignore
        href: [...(defaultSchema.protocols.href || []), 'tel'], // Allow 'tel:' in href
    }
};

const isReactElement = (element: string | number | React.ReactElement | Iterable<React.ReactNode>): element is React.ReactElement => {
    return typeof element === "object";
}

export enum NodeTypes {
    root = 'root',
    text = 'text',
    break = 'break',
    paragraph = 'paragraph',
    emphasis = 'emphasis',
    strong = 'strong',
    thematicBreak = 'thematicBreak',
    blockquote = 'blockquote',
    delete = 'delete',
    link = 'link',
    image = 'image',
    linkReference = 'linkReference',
    imageReference = 'imageReference',
    table = 'table',
    tableHead = 'tableHead',
    tableBody = 'tableBody',
    tableRow = 'tableRow',
    tableCell = 'tableCell',
    list = 'list',
    listItem = 'listItem',
    definition = 'definition',
    heading = 'heading',
    inlineCode = 'inlineCode',
    code = 'code',
    html = 'html',
    virtualHtml = 'virtualHtml'
}

interface IMarkdownProps {
    source: string;
    listType?: MarkdownListTypes;
    greyedText?: boolean;
    customFont?: string | string[];
    fontSize?: string;
}

const Paragraph: React.FC<any> = (props: any) => {
    return <span>{props.children}</span>;
}

const ListWrapper = ({listType, greyedText, customFont, fontSize}: any) => (props: any) => {
    const childrenList = props.children.filter((element: string | React.ReactElement) => {
        return typeof element !== "string";
    });

    if (listType === MarkdownListTypes.BULLET) {
        return <List bullet={true} list={childrenList}/>
    }
    if (listType === MarkdownListTypes.ORDERED) {
        return <List ordered={true} list={childrenList}/>
    }

    const filteredChildrenList = React.Children.toArray(childrenList).map((child1: any) => {
        const updatedChildren = React.Children.toArray(child1.props.children).filter((child2: any) => {
            return !(child2 && child2.type === 'input' && child2.props.type === 'checkbox');
        });

        let isStrong = false;
        for (let i = 0; i < 2; i++) {
            const child2 = updatedChildren[i];
            if (child2 && isReactElement(child2)) {
                isStrong = child2.type === "strong";
                break;
            }
        }

        return React.cloneElement(child1, { ...child1.props, isStrong, children: updatedChildren });
    });

    return <DynamicList greyedText={greyedText} list={filteredChildrenList} customFont={customFont} fontSize={fontSize} />;
}

export class Markdown extends React.PureComponent<IMarkdownProps> {
    public render() {
        const {source, greyedText, listType, customFont, fontSize} = this.props;
        const renderers: Components = {
            p: Paragraph as any,
            ul: ListWrapper({listType, greyedText, customFont, fontSize}) as any,
            li: (props: any): any => props.children,
            link: (props: React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>): any => this.renderLinkComponent(props),
            a: (props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>): any => this.renderLinkComponent(props as any)
        };

        return (
            <ReactMarkdown
                components={renderers}
                unwrapDisallowed={true}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw as any, [rehypeSanitize, schema]]}
            >{source}</ReactMarkdown>
        );
    }

    private renderLinkComponent(props: React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement> | React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
        const {href, children} = props;

        if (Array.isArray(children) && children[0].includes(":modal") && href) {
            const title = children[0].replace(":modal", "");
            return <ModalIframe title={title} url={href} isBold={false}/>;
        } else {
            return <Link to={href}>{children}</Link>;
        }
    }
}

export function markdown(source: string) {
    return <Markdown source={source}/>
}
