import * as React from "react";
import {FormInputTimePicker} from "@folksam-digital/ui";
import {IInputComponentProps} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import moment, {Moment} from "moment";
import {injectIntl} from "react-intl";
import isEmpty from "lodash/isEmpty";
import 'rc-time-picker/assets/index.css';
import {DateTimeBase, IDateTimeComponentMetadataBase, IDateTimeComponentStateBase} from "./DateTimeBase";
import isDate from "./helpers/isDate";
import {hourMinuteSecondFormat, IDateFormats, yearMonthDayFormat} from "@folksam-digital/model";

interface IMetadata extends Pick<IDateTimeComponentMetadataBase, "format" | "placeholder" | "breakpoints"> {
    formatDate?: IDateFormats;
}

interface IState extends Pick<IDateTimeComponentStateBase, "date" | "disabled"> {
}

class TimeComponentInternal extends DateTimeBase<string, IMetadata, IState,
    IInputComponentProps<string, IMetadata>> {
    constructor(props: IInputComponentProps<string, IMetadata>) {
        super(props);

        this.state = {
            date: undefined,
            disabled: false,
        };

        this.handleOnChange = this.handleOnChange.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.formData) {
            const date = moment.utc(this.props.formData, this.getFormatMask(this.getIntlDateTimeFormat()));
            this.setState({
                date: date.toDate()
            });
        }
    }

    public render() {
        const {formData, intl, name,} = this.props;
        const {placeholder} = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()}>
                <FormInputTimePicker
                    id={name}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    value={isDate(this.state.date) ? this.formatMomentObject(formData) : undefined}
                    defaultOpenValue={moment()}
                    format={this.getFormatMask(this.getIntlTimeFormat())}
                    showSecond={/s/i.test(this.getFormatMask(this.getIntlTimeFormat()))}
                    onChange={this.handleOnChange}
                    invalid={this.isInvalid()}
                    disabled={this.state.disabled}
                />
            </FormFieldLayout>
        );
    }

    private formatMomentObject(formData: any): Moment | undefined {
        if (formData) {
            return moment(moment(formData).format(this.getFormatMask(this.getIntlDateTimeFormat())));
        }
    }

    private getIntlTimeFormat(): Intl.DateTimeFormatOptions {
        return this.metadata.format ? this.metadata.format.reactIntl : hourMinuteSecondFormat.reactIntl;
    }

    private getIntlDateTimeFormat(): Intl.DateTimeFormatOptions {
        const dateFormat = this.metadata.formatDate ? this.metadata.formatDate.reactIntl : yearMonthDayFormat.reactIntl;
        return {...dateFormat, ...this.getIntlTimeFormat()};
    }

    protected getDateFormat(): undefined {
        return undefined
    }

    private handleOnChange(momentObj: Moment | null) {
        const {date, dateIso} = this.convertDateValue(momentObj);

        if (!moment(this.props.formData).isSame(dateIso)) {
            this.onChangeWithValidation(dateIso);
        }
        this.setState({date: date});

    }

    private convertDateValue(value: Moment | null) {
        const res = this.processDate(value);
        let date;
        let dateIso;

        if (res !== undefined) {
            date = res.toDate();
            dateIso = res.toISOString(true);
        }

        return {date, dateIso}
    }

    private processDate(value: Moment | null): Moment | undefined {
        if (!isEmpty(value)) {
            if (moment.isMoment(value)) {
                // process moment object
                const date = moment.utc(value);
                const isValid = moment.utc(date).isValid();

                if (!isValid) {
                    return undefined;
                } else {
                    return date;
                }
            }
        } else {
            return undefined;
        }
    }

}

const TimeComponent = injectIntl(TimeComponentInternal);
export {TimeComponent};
