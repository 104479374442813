import {extractQueryParams} from "../../Helpers/extractQueryParams";
import {IInitParams, StorageKeys} from "../../services";
import React, {useState} from "react";
import {useLocation} from "react-router";
import {IToggleProps} from "./featureToggle/withToggle";

const initParams = ({sessionStorage, location}: any): void => {
    let params: IInitParams = {};
    const sessionParams: IInitParams = sessionStorage.get(StorageKeys.PARAMS) || {};
    const journeyId = location.pathname.split("/").pop();

    if (location.search) {
        params = extractQueryParams(["groupid"], location.search) as IInitParams;
    }

    params = {...sessionParams, ...params};

    if (journeyId && !params.journeyId) {
        params.journeyId = journeyId;
    }

    sessionStorage.set(StorageKeys.PARAMS, params);
}

const withGroupInsuranceParams = <P extends IToggleProps>(dependencies: any) => {
    return (WrappedComponent: React.ComponentType<P>) => (props: P) => {
        const location = useLocation();
        useState(() => {
            initParams({...dependencies, location});
            return true;
        });

        return <WrappedComponent {...props}/>;
    }
};

export {withGroupInsuranceParams};
