import * as React from "react";
import {AutoComplete} from "@folksam-digital/ui";
import {createUseStyles} from "react-jss";
import classNames from "classnames";

export interface IPetDiagnose {
    ID: string;
    DIAGNOSISNAME: string;
}

interface ISuggestionsRenderer {
    idPrefix: string;
    activeSuggestion: number | null | undefined;
    suggestions: any[];
    suggestionFields: string[];
    onSuggestionSelect: (suggestion: string) => void;
    className: string;
    open: boolean;
    capitalize?: boolean;
    fields: string[];
    onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
}

export type ISuggestionsRendererFn = (object: ISuggestionsRenderer) => React.ReactElement;

export const useStyles = createUseStyles({
    suggestionsContainer: {
        overflowY: "scroll",
        maxHeight: "20em",
    },
    capitalize: {
        textTransform: 'capitalize'
    }
});

export const SuggestionsRenderer = ({
    idPrefix,
    activeSuggestion,
    suggestions,
    onSuggestionSelect,
    className,
    open,
    onMouseDown,
    capitalize,
    suggestionFields = ['description']
}: ISuggestionsRenderer): React.ReactElement => {
    const classes = useStyles();

    return (
        <div
            className={classNames(className, classes.suggestionsContainer, {[classes.capitalize]: capitalize})}
        >
            {suggestions.map((suggestion, index) => {
                return (
                    <AutoComplete.Suggestion
                        key={`${index}-${idPrefix}`}
                        active={activeSuggestion === index}
                        suggestion={suggestion}
                        onSelect={onSuggestionSelect}
                        onMouseDown={onMouseDown}
                        open={open}
                    >
                        {suggestionFields.map(suggestionField => suggestion[suggestionField]).join(', ')}
                    </AutoComplete.Suggestion>
                );
            })}
        </div>
    );
}
