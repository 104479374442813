import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {IUtilService} from "../../IUtilService";
import {Workflow} from "./Workflow";
import {GetSystemDate, GetLocalServerDate} from "@folksam-digital/model";
import {setCurrentTime} from "@folksam-digital/services";

export class UtilService extends WorkflowServiceBase implements IUtilService {
    public async getSystemDate(): Promise<void> {
        const data = await super.execute<void, GetSystemDate>(Workflow.Utils.GetSystemDate);
        setCurrentTime(data.systemDate);
    }

    public async getLocalServerDate(): Promise<void> {
        const data = await super.execute<void, GetLocalServerDate>(Workflow.Utils.GetLocalServerDate);
        setCurrentTime(data.localServerDate);
    }
}
