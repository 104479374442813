import * as React from "react";
import {InputComponentBase} from "./InputComponentBase";
import {Grid, QuestionSkeleton, SelectionCoverageCard, Spacing} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CoverPlan} from "@folksam-digital/model";
import {Markdown} from "../../../Markdown";
import {ModalIframe} from "../output";
import {PbbHelper} from "@folksam-digital/services";
import {IBreakPoint} from "../../layout/helpers";

interface IMetadata {
    mainCoverId?: number;
    modal: {
        title: string;
        url: string;
    };
    breakpoints: IBreakPoint;
    fullView: boolean;
}

class SelectionCoverageCardsInternal extends InputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;

    public render() {
        if (this.context?.data?.policy?.premium?.coverPlans) {
            const coverPlans: CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);

            return (
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) => (
                        <FormFieldGroupLayout
                            id={this.props.name}
                            error={this.getError()}
                        >
                            <Grid.Row>
                                {
                                    coverPlans.map((coverPlan: CoverPlan) => {
                                        return coverPlan?.id && this.renderCoverPlan(coverPlan.id, cmsContext);
                                    })
                                }
                            </Grid.Row>
                        </FormFieldGroupLayout>
                    )}
                </CmsContext.Consumer>
            );
        } else {
            return <QuestionSkeleton/>;
        }
    }

    private renderCoverPlan(coverPlanId: string, cmsContext: ICmsContext) {
        const {
            formData,
            intl
        } = this.props;

        const {modal, mainCoverId, breakpoints, fullView} = this.metadata;
        const coverPlanDetails = PbbHelper.getCoverPlanDetails(this.context.data, coverPlanId, mainCoverId!);
        const imageAttributes = CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, `coverageImage_${coverPlanId}`), cmsContext.catalog!);
        const modalTitle = (<>
            <div className={"helpTextCircle-icon"}>i</div>
            <FormattedMessage id={modal.title}/></>);
        const coverPlanCount: number = Object.values(this.context.data.policy.premium.coverPlans).length;
        const coverPlanLargeBreakpoints: number = fullView ? 12 : coverPlanCount === 2 ? 5 : Math.floor(12 / coverPlanCount);
        const coverPlanMediumBreakpoints: number = fullView ? 12 : Math.floor(12 / coverPlanCount);
        const {disableButton} = this.context;

        return (
            <Grid.Col
                lg={breakpoints ? breakpoints.lg : coverPlanLargeBreakpoints}
                md={breakpoints ? breakpoints.md : coverPlanMediumBreakpoints}
                key={coverPlanId}
            >
                <>
                    <Spacing type={"margin"} top={"sm"}/>
                    <SelectionCoverageCard
                        id={coverPlanId}
                        heading={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.heading`)}, coverPlanDetails)}
                        price={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.price`)}, coverPlanDetails)}
                        value={coverPlanId}
                        checked={formData === coverPlanId}
                        onChange={() => !disableButton && this.onChangeWithValidation(coverPlanId)}
                        image={imageAttributes}
                        buttonText={{
                            unselected: <FormattedMessage
                                id={"general.package.unselected"}/>,
                            selected: <FormattedMessage
                                id={"general.package.selected"}/>
                        }}
                    >
                        <Markdown
                            source={this.props.intl.formatMessage({id: `${CmsHelper.getPrefix(cmsContext)}.sizeOfInsurance.package.${coverPlanId}.list`})}/>
                        <Spacing type={'margin'} top={'6'}>
                            <ModalIframe url={modal.url} title={modalTitle}/>
                        </Spacing>
                    </SelectionCoverageCard>
                </>
            </Grid.Col>
        )
    }
}

const SelectionCoverageCards = injectIntl(SelectionCoverageCardsInternal);
export {SelectionCoverageCards};
