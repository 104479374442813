import {REGEXP} from "@folksam-digital/model";
import isEmpty from "lodash/isEmpty";
import {SsnFormatter} from "@folksam-digital/services";
import castArray from "lodash/castArray";
import doesSsnMatch from "./doesSsnMatch";
import {normalizeValue} from "../normalize";

export function isValidSsn(ssn?: string, reservedSsn?: string | string[]) {
    return !validateComplexSsn(ssn, reservedSsn);
}

export function validateSsn(ssnInputValue?: string, errorMessage?: string, includeCoordinationNumber?: boolean, required?: boolean): string | undefined {
    if (required === undefined && isEmpty(ssnInputValue)) {
        return errorMessage || "general.form.error.required";
    } else if (typeof required === "boolean" && required && isEmpty(ssnInputValue)) {
        // respect empty value for optional field
        return errorMessage || "general.form.error.required";
    }

    if (ssnInputValue && !ssnInputValue.match(REGEXP.SSN_PATTERN)) {
        return errorMessage || "general.personSsnInput.error.pattern";
    }

    if (ssnInputValue &&
        (!SsnFormatter.isValidSsn(ssnInputValue!, includeCoordinationNumber)
            || !normalizeValue(SsnFormatter.formatWithoutDash, ssnInputValue)!.match(REGEXP.SSN_STARTS_19_OR_20_WHEN_12_SYMBOLS)
        )
    ) {
        return errorMessage || "general.personSsnInput.error.format";
    }
}

export function validateComplexSsn(ssn: string = "", reservedSsn?: string | string[], ssnAlreadyInUseErrorMessage?: string, includeCoordinationNumber: boolean = false, required?: boolean): string | undefined {
    const reservedSsnList = reservedSsn ? castArray(reservedSsn) : [];
    const error = validateSsn(ssn, undefined, includeCoordinationNumber, required);

    if (error) {
        return error;
    }

    // Check weather ssn number from input field is not the same as loaded dataPath ssn number
    if (!isEmpty(reservedSsnList)) {
        const ssnFound = reservedSsnList.some((tempSsn: string) => {
            return doesSsnMatch(tempSsn, ssn!);
        });

        if (ssnFound) {
            return ssnAlreadyInUseErrorMessage || "general.personSsnInput.error.ssnAlreadyInUse";
        }
    }
}
