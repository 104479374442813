import * as React from "react";
import {Modal, defaultTheme, ButtonResponsive, Spinner, Spacing} from "@folksam-digital/ui";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {createUseStyles} from "react-jss";
import {useEffect, useState} from "react";
import QRCode from "qrcode"
import {Constants} from "@folksam-digital/model";
import useDevice from "./util/useDevice";

const useStyles = createUseStyles(() => ({
        container: {
            textAlign: "center",
            marginBottom: defaultTheme.padding.sm
        },
        qrCode: {
            margin: "0 auto"
        },
        loaderContainer: {
            display: "flex",
            justifyContent: "center",
            margin: "15px 0px"
        }
    })
);

const qrCodeContainerId = "qrCodeContainer";

export const SigningInitModal: React.FC<any> = ({
                                                    signingStatus,
                                                    cmsContext,
                                                    qrData,
                                                    autostartToken
                                                }: any): React.ReactElement => {
    const classes = useStyles();
    const device = useDevice();
    const [initOnAnotherDeviceMobile, setInitOnAnotherDeviceMobile] = useState(false);
    const startUrl = `bankid:///?autostarttoken=${autostartToken}&redirect=null`;

    useEffect(() => {
        const qrContainerElement = document.getElementById(qrCodeContainerId);
        if (!!qrContainerElement) {
            qrContainerElement.innerHTML = "";
        }
        if (qrData) {
            QRCode.toCanvas(qrData, {width: 200}, function (err, canvas) {
                if (err) {
                    throw err;
                }

                const container = document.getElementById(qrCodeContainerId);
                if (container) {
                    container.appendChild(canvas);
                }
            });
        }
    }, [qrData]);

    return (
        <Modal
            isOpen={signingStatus === Constants.Signing.statusCodes.folksam.success.notStarted}
            showCloseButton={false}
            onRequestClose={() => undefined}
        >
            <Modal.Header>
                <div>
                    <FormattedMarkdown
                        messageKey={CmsHelper.withPrefix(cmsContext, "form.modal.initSigning.title")}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    <FormattedMarkdown
                        messageKey={CmsHelper.withPrefix(cmsContext, "form.modal.initSigning.description")}/>
                    {((device.isMobileOrTablet && initOnAnotherDeviceMobile) || !device.isMobileOrTablet) ?
                    <div className={classes.qrCode} id={qrCodeContainerId}>
                        <div className={classes.loaderContainer}>
                            <Spinner large={true}/>
                        </div>
                    </div> :
                    <Spacing type='margin' top={"lg"} />
                    }
                    <ButtonResponsive outline={true} onClick={() => window.open(startUrl, "_self")}>
                        <FormattedMarkdown
                            messageKey={CmsHelper.withPrefix(cmsContext, "form.modal.initSigning.initOnCurrentDeviceBtn.title")}/>
                    </ButtonResponsive>
                    {device.isMobileOrTablet &&
                    <>
                    <Spacing type='margin' top={"lg"} />
                    <ButtonResponsive asLink={true}
                                      onClick={() => setInitOnAnotherDeviceMobile(!initOnAnotherDeviceMobile)}>
                        <FormattedMarkdown
                            messageKey={CmsHelper.withPrefix(cmsContext, "form.modal.initSigning.initOnAnotherDeviceMobileBtn.title")}/>
                    </ButtonResponsive>
                    </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
