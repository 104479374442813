export enum StorageKeys {
    JOURNEY_FORM_DATA_KEY = "JOURNEY_FORM_DATA",
    JOURNEY_ERROR_DATA_KEY = "JOURNEY_ERROR_DATA",
    DIRECT_DEBIT_FORM_DATA_KEY = "DIRECT_DEBIT_FORM_DATA",
    DIRECT_DEBIT_SIGNING_STATUS_KEY = "DIRECT_DEBIT_SIGNING_STATUS",
    PARAMS = "params",
    ANALYTICS_FLOW_COMPLETED = "ANALYTICS_FLOW_COMPLETED",
    COMPLETED_JOURNEY_ID = "COMPLETED_JOURNEY_ID",
    SESSION_CONTEXT = "SESSION_CONTEXT"
}

export interface IDraftService {
    hasDraft(sessionKey?: StorageKeys): boolean;
    getDraft<T = any>(sessionKey?: StorageKeys): T;
    updateDraft(data: any, sessionKey?: StorageKeys): void;
    removeDraft(sessionKey?: StorageKeys): void;
}
