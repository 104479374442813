import React from 'react';
import { Constants } from "@folksam-digital/model";
import {ChildCoverIconBlue, ChildCoverIconPink} from "@folksam-digital/ui";

export const PackageSelectionCardIcons = {
    [Constants.Journey.MemberChild.Id]: {
        [Constants.CoverPlan.MemberChild.Extended]: <ChildCoverIconBlue />,
        [Constants.CoverPlan.MemberChild.Basic]: <ChildCoverIconPink />,
    },
    [Constants.Journey.IndividualChild.Id]: {
        [Constants.CoverPlan.IndividualChild.Extended]: <ChildCoverIconBlue />,
        [Constants.CoverPlan.IndividualChild.Basic]: <ChildCoverIconPink />,
    }
};
