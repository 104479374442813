import * as React from "react";

export const Marker: React.FC<google.maps.MarkerOptions> = ({map, position, visible}) => {
    const [marker, setMarker] = React.useState<google.maps.Marker>();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    React.useEffect(() => {
        if (marker) {
            marker.setOptions({map, position, visible});
        }
    }, [marker, map, position, visible]);

    return null;
};
