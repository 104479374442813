import {BaseErrorMessage} from "./BaseErrorMessage";
import {Constants} from "@folksam-digital/model";

export class MopedCarInsteadOfMoped extends BaseErrorMessage {
    protected determineMessage(): void {
        switch (this.journeyId) {
            case Constants.Journey.Moped.Id:
                this.message = "contactForm.mopedCarInsteadOfMoped.description";
                break;
            default:
                this.message = "contactForm.description";
        }
    }
}
