import React from "react";
import {InformationBoxInternal} from "./InformationBox";
import {InformationBoxContainer} from "../containers/InformationBoxContainer";
import {defaultTheme as theme} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsContext, ICmsContext} from "../../../../cms";
import {ModalLink} from "./ModalLink";

export class InformationBoxWithModal extends InformationBoxInternal {

    public render() {
        const {priceNotification, colorTheme = "primary", breakpoints, classNames, withTooltipIcon, readMoreText, modalHeader, modalContent, listType} = this.metadata;
        const hasModal = !!(modalHeader || modalContent);

        return(
            <>
                <InformationBoxContainer ref={this.infoBoxRef} breakpoints={breakpoints} classNames={classNames} theme={colorTheme} withTooltipIcon={withTooltipIcon}>
                    <CmsContext.Consumer>
                        {(cmsContext: ICmsContext) => (
                            <FormattedMarkdown listType={listType} messageKey={this.getTitle(cmsContext)}
                                               messageValue={this.extractMessageValues(cmsContext)}/>
                        )}
                    </CmsContext.Consumer>
                    {hasModal && readMoreText &&
                    <ModalLink style={{padding: `${theme.padding.sm} 0 ${theme.padding.sm} 0`}}
                               linkText={readMoreText}
                               modalHeader={modalHeader}
                               modalContent={modalContent}
                    />}
                    {priceNotification && this.renderPriceNotification()}
                </InformationBoxContainer>
            </>
        );
    };
}
