import {
    ClaimBase,
    ContactHasValidPoliciesRequest,
    ContactHasValidPoliciesResponse,
    CustomerEngagementResponse,
    QuestionnaireResponse, IJourneySchema
} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IDigitalClaimService} from "../../IDigitalClaimService";
import {IStorage} from "../../IStorage";
import {IWorkflowServiceConfig, WorkflowServiceBase} from "./WorkflowServiceBase";
import {ISessionContextManager} from "../../ISessionContextManager";
import {IClaimService} from "../../IClaimService";
import {Workflow} from "./Workflow";
import {FilterRequestPayload, JourneyHelper, SsnFormatter} from "@folksam-digital/services";

@injectable()
export class ClaimService extends WorkflowServiceBase implements IClaimService {
    private readonly digitalClaimService: IDigitalClaimService;

    public constructor(config: IWorkflowServiceConfig, sessionStorage: IStorage, sessionContextManager: ISessionContextManager, digitalClaimService: IDigitalClaimService) {
        super(config, sessionStorage, sessionContextManager);

        this.digitalClaimService = digitalClaimService;
    }

    public async createClaim(claimType: string, data: ClaimBase<any>, locale: string): Promise<ClaimBase<any>> {
        if (!data.policyHolder) {
            // Set empty object so condition evaluation doesn't fail for flows without added policyHolder question in step 1
            data.policyHolder = {};
        }
        return this.digitalClaimService.creatClaim(claimType, data, locale);
    }

    public async contactHasValidPolicies({ssn, claimPrimaryCauseOfLoss, eventDate}: ContactHasValidPoliciesRequest): Promise<ContactHasValidPoliciesResponse["result"]> {
        const response: ContactHasValidPoliciesResponse = await super.execute<ContactHasValidPoliciesRequest, ContactHasValidPoliciesResponse>(
            Workflow.Claim.ContactHasValidPolicies,
            {
                ssn: SsnFormatter.convertFormat(ssn, true),
                claimPrimaryCauseOfLoss,
                eventDate
            }
        );

        return response.result;
    }

    public async getQuestionnaire(claimType: string, data: ClaimBase<any>, schema: IJourneySchema): Promise<QuestionnaireResponse | undefined> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        const endpoint = Workflow.forClaim(JourneyHelper.getObfuscatedJourneyId(claimType)).GetQuestionnaire;
        return super.execute<ClaimBase<any>, QuestionnaireResponse>(endpoint, data);
    }

    public async getQuestionnaireItems(claimType: string, data: ClaimBase<any>, schema: IJourneySchema): Promise<QuestionnaireResponse | undefined> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        const endpoint = Workflow.forClaim(JourneyHelper.getObfuscatedJourneyId(claimType)).GetQuestionnaireItems;
        return super.execute<ClaimBase<any>, QuestionnaireResponse>(endpoint, data);
    }

    public async getContactEngagement(claimType: string): Promise<any> {
        return super.execute<ClaimBase<any>, CustomerEngagementResponse>(
            Workflow.forClaim(JourneyHelper.getObfuscatedJourneyId(claimType)).getContactEngagement
        );
    }
}
