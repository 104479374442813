import {
    ComponentType,
    Constants,
    DeepPartial, DependencyRelationTypes,
    Field,
    IAnswer,
    IOptionSelected, ParentAnswerTypes,
    ParenthesisTypes,
    Questionnaire
} from "@folksam-digital/model";
import {ICmsParams} from "../IGenerator";
import {componentModelMapping, conditionOperatorTypes} from "../product/GeneratorConfig";
import {QuestionnaireUtils} from "../QuestionnaireUtils";

export class QuestionnaireSummaryConditionGenerator {
    private readonly questionnaire: Questionnaire[];
    private readonly modelDisplayConditionsPathPrefix: string;
    private readonly cmsParams: ICmsParams;

    constructor(questionnaire: Questionnaire[], modelDisplayConditionsPathPrefix: string, cmsParams: ICmsParams) {
        this.questionnaire = questionnaire;
        this.modelDisplayConditionsPathPrefix = modelDisplayConditionsPathPrefix;
        this.cmsParams = cmsParams;
    }

    public getAnswerDefinition(question: Questionnaire, questionComponent: DeepPartial<Field>, questionnaireAnswersPath: string, questionAnswer: {
        key: string,
        questionnaireAnswerValue: IAnswer
    }): string {
        let answerDefinition: string = "";

        if (Number(question?.answerDefinition?.inputTypeId) === Constants.Questionnaire.InputTypes.TextBox) {
            if (question?.answerDefinition?.answerHeaderId === Constants.Questionnaire.AnswerHeader.NumericTextBox) {
                if (questionComponent.component === ComponentType.CurrencyInputField) {
                    answerDefinition = `{{#formatNumber}} {{${questionnaireAnswersPath}.${questionAnswer.key}.value}} {{/formatNumber}} {{#translate}} {{general.price.suffix}} {{/translate}}`;
                } else {
                    answerDefinition = String(questionAnswer.questionnaireAnswerValue.value);
                }
            } else if (questionAnswer?.questionnaireAnswerValue?.upptecData) {
                answerDefinition = `${String(questionAnswer?.questionnaireAnswerValue?.upptecData?.originalProduct?.brand)} ${String(questionAnswer?.questionnaireAnswerValue?.upptecData?.originalProduct?.name)}`;
            } else if (questionAnswer?.questionnaireAnswerValue?.contact) {
                answerDefinition = `${questionAnswer?.questionnaireAnswerValue?.contact?.firstName} ${questionAnswer?.questionnaireAnswerValue?.contact?.lastName}`;
            } else if (question?.answerDefinition?.answerHeaderId === Constants.Questionnaire.AnswerHeader.DatePickerLong) {
                answerDefinition = `{{#formatDate}} {{${questionnaireAnswersPath}.${questionAnswer.key}.value}} {{/formatDate}}`
            } else {
                answerDefinition = String(questionAnswer.questionnaireAnswerValue.value);
            }
        }
        else if (questionComponent.component === ComponentType.DropDownMultiSelect){
            answerDefinition = Object.values(questionAnswer.questionnaireAnswerValue.option || {}).map((answer: IOptionSelected) => answer.label).join(", ");
        } else if (question?.answerDefinition?.referenceTableId) {
            answerDefinition = `{{#extractClassifierByIdValue}} {{${questionnaireAnswersPath}.${questionAnswer.key}.id}} : ${question?.answerDefinition?.referenceTableId} {{/extractClassifierByIdValue}}`
        } else if (question?.answerDefinition?.answerValues) {
            answerDefinition = String(question?.answerDefinition?.answerValues?.find(answerVal => Number(answerVal.id) === Number(questionAnswer.questionnaireAnswerValue.id))?.title);
        }

        return answerDefinition;
    }

    public createQuestionnaireDisplayConditions(): { questionLineIdId: number | string, displayCondition: string }[] {
        return this.questionnaire.map(question => {
            return {questionLineIdId: question.lineId, displayCondition: this.createDisplayConditions(question)}
        });
    }

    public createDisplayConditions(question: Questionnaire): string {
        let displayCondition: string = "";

        const lineDependencies = question.lineDependencies;
        if (lineDependencies) {
            const conditionCount = lineDependencies.length - 1;

            for (const conditionIndex in lineDependencies) {

                const condition = lineDependencies[conditionIndex];

                // Check if specific ruleResult is present
                // It has to be compared against boolean true
                if (condition.ruleResult !== undefined) {

                    // Add condition operator (AND, OR) if ruleResult is present with out existing parent question and
                    // there are more than 1 dependency
                    if (conditionCount > 0 && Number(conditionIndex) > 0 && condition.parentLineId === null && condition.parentAnswerId === null) {
                        displayCondition += ` ${conditionOperatorTypes[condition.dependencyRelationType]} `;
                    }

                    displayCondition += `${condition.ruleResult} == true`;
                }

                // If there is a dependency on existing parent question
                if (condition.parentLineId !== null && (condition.parentAnswerId !== null || condition.parentAnswerIds !== undefined || condition.parentAnswerValue !== undefined)) {
                    const parentQuestion = QuestionnaireUtils.findQuestionByLineId(this.questionnaire, condition.parentLineId);

                    if (parentQuestion) {
                        const parentField = QuestionnaireUtils.generateInitialField(parentQuestion, this.cmsParams);

                        if (!parentField.component) {
                            continue;
                        }

                        // Add condition operator (AND, OR) if ruleResult is present with existing parent question
                        // Add condition operator (AND, OR) if parent question is present and it is not the first dependency
                        if ((condition.ruleResult !== undefined && conditionCount === 0) || (conditionCount > 0 && Number(conditionIndex) > 0)) {
                            displayCondition += ` ${conditionOperatorTypes[condition.dependencyRelationType]} `;
                        }

                        if (condition?.parenthesis === ParenthesisTypes.OPEN) {
                            displayCondition += `(`;
                        }

                        const model = this.createQuestionLineModel(condition.parentLineId, parentField);
                        if (condition.parentAnswerIds !== undefined) {
                            const answerIds = condition.parentAnswerIds?.split(",");

                            if (answerIds?.length) {
                                const answerIdsCount = answerIds.length - 1;

                                let multiAnswerDisplayCondition = "";
                                for (const answerIdIndex in answerIds) {
                                    if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.NOT_EQUAL) {
                                        multiAnswerDisplayCondition += `${model} != ${answerIds[answerIdIndex]}`;
                                    } else {
                                        multiAnswerDisplayCondition += `${model} == ${answerIds[answerIdIndex]}`;
                                    }

                                    if (Number(answerIdIndex) < Number(answerIdsCount)) {
                                        if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.NOT_EQUAL) {
                                            multiAnswerDisplayCondition += ` ${conditionOperatorTypes[DependencyRelationTypes.AND]} `
                                        } else {
                                            multiAnswerDisplayCondition += ` ${conditionOperatorTypes[DependencyRelationTypes.OR]} `
                                        }
                                    }
                                }

                                displayCondition += `(${multiAnswerDisplayCondition})`
                            }
                        } else if (condition.parentAnswerValue !== undefined) {
                            if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.LESS_EQUALS_THAN) {
                                displayCondition += `${model} <= "${condition.parentAnswerValue}"`;
                            } else if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.LESS_THAN) {
                                displayCondition += `${model} < "${condition.parentAnswerValue}"`;
                            } else if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.GREATER_EQUALS_THAN) {
                                displayCondition += `${model} >= "${condition.parentAnswerValue}"`;
                            } else if (condition?.parentAnswerTypeDesc === ParentAnswerTypes.GREATER_THAN) {
                                displayCondition += `${model} > "${condition.parentAnswerValue}"`;
                            }
                        } else if (condition.parentAnswerId && parentField.component === ComponentType.DropDownMultiSelect) {
                            const parentAnswerValueKey = `["${condition.parentAnswerId}"]`;
                            displayCondition += `(${model} && ${model}${parentAnswerValueKey} && ${model}${parentAnswerValueKey}.value == "${condition.parentAnswerId}")`;
                        } else {
                            displayCondition += `${model} == ${condition.parentAnswerId}`;
                        }

                        if (condition?.parenthesis === ParenthesisTypes.CLOSE) {
                            displayCondition += `)`;
                        }
                    }
                }
            }
        }

        return displayCondition;
    }

    private createQuestionLineModel(lineId: number, field: DeepPartial<Field>): string {
        return `${this.modelDisplayConditionsPathPrefix}._${lineId}.${componentModelMapping[field.component!]}`;
    }
}
