import React from "react";
import {IAuthenticationContext, AuthenticationContext} from "./AuthenticationContext";
import {AuthenticationProvider} from "./AuthenticationProvider";
import {UrlHelper} from "@folksam-digital/services";
import {FormContext, IFormContext} from "../components/journey/form/FormContext";

export interface IWithAuthenticationContext {
    authenticationContext: IAuthenticationContext;
}

interface IWithAuthenticationProps {
    journeyId?: string;
}

function withAuthentication<T extends IWithAuthenticationContext & IWithAuthenticationProps>(
    WrappedComponent: React.ComponentType<T>
) {
    return class AuthenticatedComponent extends React.Component<T> {
        public static contextType = FormContext;
        context!: IFormContext;

        public render() {

            if (UrlHelper.isFlowProtected(this.props?.journeyId!)) {
                return (
                    <AuthenticationProvider journeyId={this.props?.journeyId}>
                        <AuthenticationContext.Consumer>
                            {(value: IAuthenticationContext) =>
                                <WrappedComponent {...(this.props as T)} authenticationContext={value} />
                            }
                        </AuthenticationContext.Consumer>
                    </AuthenticationProvider>
                );
            }

            return <WrappedComponent {...(this.props as T)} />
        }
    };
}

export default withAuthentication;
