import isEmpty from "lodash/isEmpty";
import castArray from "lodash/castArray";
import doesSsnMatch from "./doesSsnMatch";
import {validateSsn} from "./isValidSsn";
import {OrgNumberFormatter} from "@folksam-digital/services/lib/utils/OrgNumberFormatter";

export function validateComplexSsnOrOrgNumber(ssn: string = "", reservedSsn?: string | string[], errorMessage?: string): string | undefined {
    const reservedSsnList = reservedSsn ? castArray(reservedSsn) : [];
    const ssnError = validateSsn(ssn);
    const orgNumberValid = OrgNumberFormatter.isValidOrgNumber(ssn);

    if (ssnError) {
        if (!orgNumberValid) {
            return "general.personSsnOrOrgNumberInput.error.format";
        }
    }

    // Check weather ssn number from input field is not the same as loaded dataPath ssn number
    if (!isEmpty(reservedSsnList)) {
        const ssnFound = reservedSsnList.some((tempSsn: string) => {
            return doesSsnMatch(tempSsn, ssn!);
        });

        if (ssnFound) {
            return errorMessage || "general.personSsnInput.error.ssnAlreadyInUse";
        }
    }
}
