import {ReactNode} from "react";
import {IconCatColored, IconDogColored, TruckIcon, IconVilla, ApartmentIcon, HomeVacationIcon, MopedIconCompact, HeavyMotorcycleSmall} from "@folksam-digital/ui";
import {CompactCoverListTableIconTypes} from "@folksam-digital/model";

export const CompactCoverListTableIcons: Record<string, ReactNode> = {
    [CompactCoverListTableIconTypes.CatIcon]: IconCatColored,
    [CompactCoverListTableIconTypes.DogIcon]: IconDogColored,
    [CompactCoverListTableIconTypes.LightTruckIcon]: TruckIcon,
    [CompactCoverListTableIconTypes.HomeBuildingIcon]: IconVilla,
    [CompactCoverListTableIconTypes.HomeApartmentIcon]: ApartmentIcon,
    [CompactCoverListTableIconTypes.HomeVacationIcon]: HomeVacationIcon,
    [CompactCoverListTableIconTypes.MopedIconCompact]: MopedIconCompact,
    [CompactCoverListTableIconTypes.HeavyMotorcycleSmall]: HeavyMotorcycleSmall
};
