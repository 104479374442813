import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {IPanelComponentBaseProps, PanelComponentBase} from "./PanelComponentBase";
import {
    Accordion,
    B,
    Button,
    defaultTheme as theme,
    Expandable,
    Grid,
    IconCheckmark,
    IconCloseV2, Spacing,
    IExpandableParams,
    IconEditV2
} from "@folksam-digital/ui";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import {FormContext, IFormContext} from "../FormContext";
import {onBtnClick, Template} from "../output/helpers";
import {RemoveButton} from "../input/actionInput/RemoveButton";
import {IIdSchema} from "../FormComponentBase";
import {ButtonWithModal} from "../input/actionInput/ButtonWithModal";
import get from "lodash/get";
import {IOnValidationError} from "@folksam-digital/comp-library";

import {scroller} from "react-scroll/modules";
import injectSheet from "react-jss";
import flowRight from "lodash/flowRight";
import {Actions} from "./AccordionPanelConstants";
import {findKeyInObj} from "../../../../util/findKeyInObj";
import {evaluate} from "../../../../util/filterDataArray";

interface IAccordionPanelMetadata {
    properties: [];
    expandedPath?: string;
    keepObject?: { [key: string]: boolean };
    definition?: string;
    showRemoveButton?: boolean;
    showClearButtonWhenFieldWithValue?: string;
    closeText?: string;
    clearFormText?: string;
    editText?: string;
    removeText?: string;
    index?: string;
    pathFilter?: string;
    defaultValue?: any;
    padded?: boolean;
    removeSectionModalTitle?: string;
    fieldId?: string;
    stepName: string;
    schemaBaseName: string;
    deletePathFromStateOnHide?: boolean;
    forceScrollToFieldIdOnRemove?: boolean;
    shouldAddNewEntry?: boolean;
    pathToModel?: string;
    excludeClearButton?: boolean;
    pathToMaxEntries?: string;
}

interface IAccordionPanelInternalProps extends Omit<IPanelComponentBaseProps<void, IAccordionPanelMetadata & WrappedComponentProps>, "formContext"> {
    formContext: {
        validateField: (fieldIdSchema: { $id: string }, uiSchema: any) => { stack: string }[];
        getCurrentStepErrorList: (idSchema: any, uiSchema: any) => Promise<IOnValidationError[]>;
    };
    idSchema: IIdSchema,
    classes: {
        [key: string]: string;
    },
}

enum Elements {
    button = "button",
    header = "header",
}

const styles = {
    "accordionBody": {
        overflow: `visible !important`,
    }
};

class AccordionPanelInternal extends PanelComponentBase<void, IAccordionPanelMetadata & WrappedComponentProps, {}, IAccordionPanelInternalProps> {
    public static contextType = FormContext;
    context!: IFormContext;

    private readonly path: string;

    constructor(props: any, context?: any) {
        super(props, context);
        this.path = `expanded_${this.uiSchema.name}`;
        this.submitAccordion = this.submitAccordion.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
    }

    public async componentDidMount(): Promise<void> {
        await this.openCardOnErrorsOrEmpty();
    }

    public async componentWillUnmount(): Promise<void> {
        // for cases when in same step multiple type(schemaBaseName) cards are displayed and some can be hidden.
        if (this.metadata.deletePathFromStateOnHide && this.props.uiSchema.displayCondition && !await evaluate(this.context.data, this.props.uiSchema.displayCondition)) {
            // when condition isn't met card path is cleared from expandedState
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.DeletePath});
        } else {
            // in case component Unmount(e.g.due to display condition), necessary to recheck expandState to handle Next button enable/disable correctly.
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.Check, ...this.metadata});
        }
    }

    public async componentDidUpdate(prevProps: any): Promise<void> {
        if (this.getExpandedState() !== undefined && !this.getExpandedState() && await this.hasErrors()) {
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.Open});
        }
    }

    private static someDirty(fields: { [key: string]: any }) {
        if (isEmpty(fields)) {
            return true;
        }
        return some(fields, {dirty: true});
    }


    private static someDirtyNoEmptyValues(fields: { [key: string]: any }) {
        if (isEmpty(fields)) {
            return false;
        }
        return some(fields, {dirty: true});
    }

    private static allRequiredCompleted(fields: { [key: string]: any }) {
        if (isEmpty(fields)) {
            return true;
        }
        return !some(fields, {required: true, completed: false});
    }

    public render() {
        const {
            showRemoveButton,
            showClearButtonWhenFieldWithValue,
            closeText,
            clearFormText,
            editText,
            removeSectionModalTitle,
            padded = true,
            excludeClearButton = false
        } = this.metadata;
        const {name} = this.uiSchema;
        const {classes} = this.props;

        const result = this.context.getAllFormFields(this.props.idSchema.$id);

        return (
            <Spacing type="margin" bottom="sm">
                <Expandable initialExpanded={this.getInitialExpanded()}>
                    <Expandable.Section id={this.path}>
                        {({handleClick, expanded}: IExpandableParams) => {
                            const icons = [];

                            if (!expanded) {
                                icons.push(
                                    <Button editButton
                                        type="button"
                                        fontSize={"small"}
                                        key="edit"
                                        onClick={() => {
                                            this.editAccordion(expanded, handleClick);
                                        }}
                                    >
                                        <IconEditV2 style={{paddingRight: "4px", width: 18, height: 18}} />
                                        <FormattedMessage id={editText || "general.accordionPanel.button.edit"} />
                                    </Button>
                                );
                            }

                            if (showRemoveButton || (showClearButtonWhenFieldWithValue && get(this.context.data, showClearButtonWhenFieldWithValue))) {
                                icons.push(
                                    <RemoveButton key="remove"
                                                  id={`removeButton${name}`}
                                                  onChange={this.onRemoveClick}
                                                  modalHeaderMessageId={removeSectionModalTitle}
                                                  style={{ marginLeft: "0.5rem"}}
                                    />
                                );
                            }

                            return (
                                <Accordion theme={'secondary'}>
                                    <Accordion.Header theme={'secondary'}
                                                      id={name}
                                                      key={name}
                                                      onClick={() => {
                                                          this.submitAccordion(Elements.header, expanded, handleClick);
                                                      }}
                                                      iconRight={icons}
                                                      expanded={expanded}
                                    >
                                        {this.renderTitle()}
                                    </Accordion.Header>
                                    <Accordion.Body expanded={expanded} theme={'secondary'} padded={padded}
                                                    footer={true} className={classes.accordionBody}>
                                        {this.props.properties?.map((p) => p.content)}
                                    </Accordion.Body>
                                    <Accordion.Footer expanded={expanded} padded={padded} borderTop={true}>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Col style={{marginRight: theme.margin['3']}}>
                                                    <Button small={true}
                                                            id={`confirmButton${name}`}
                                                            outline={true}
                                                            onClick={(event: any) => {
                                                                this.submitAccordion(Elements.button, expanded, handleClick);
                                                                onBtnClick(event, `#${name}`, this.context, this.props);
                                                            }}
                                                            disabled={!AccordionPanelInternal.allRequiredCompleted(result)}
                                                            type="button">
                                                        <FormattedMessage
                                                            id={closeText || "general.accordionPanel.button.ok"}/>
                                                        <IconCheckmark style={{marginLeft: 5}}
                                                                       fill="none" width={14}
                                                                       height={14}/>
                                                    </Button>
                                                </Grid.Col>
                                                <Grid.Col>
                                                    {!excludeClearButton &&
                                                        <ButtonWithModal
                                                            onChange={this.onClearClick}
                                                            triggerButton={
                                                                <Button small={true}
                                                                        id={`clearButton${name}`}
                                                                        outline={true}
                                                                        disabled={!AccordionPanelInternal.someDirty(result)}
                                                                        type="button">
                                                                    <FormattedMessage
                                                                        id={clearFormText || "general.accordionPanel.button.clear"}/>
                                                                    <IconCloseV2 style={{marginLeft: 5, color: "currentColor"}}
                                                                                 width={14}
                                                                                 height={14}/>
                                                                </Button>
                                                            }
                                                            headerMessage={
                                                                <FormattedMessage
                                                                    id={removeSectionModalTitle || "general.clear.confirmation"}/>
                                                            }
                                                        />}
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>
                                    </Accordion.Footer>
                                </Accordion>
                            );
                        }}
                    </Expandable.Section>
                </Expandable>
            </Spacing>
        );
    }

    private async submitAccordion(element: Elements, expanded: boolean, handleClick: () => void) {

        if (!expanded && element === Elements.header) {
            return;
        }

        const hasErrors = await this.hasErrors();

        if (!hasErrors) {
            handleClick();
            this.context.dispatchAccordionStatus({
                path: this.path,
                action: Actions.Submit,
                hasErrors, ...this.metadata
            });
        } else {
            await this.props.formContext.validateField(this.props.idSchema, this.props.uiSchema);
        }

    }

    private async editAccordion(expanded: boolean, handleClick: () => void) {
        if (!expanded) {
            handleClick();
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.Open});
        }
    }

    private onRemoveClick() {
        const isOnlyCard = this.metadata.pathFilter && get(this.context.data, this.metadata.pathFilter)?.length === 1;

        if (isOnlyCard && this.metadata.showClearButtonWhenFieldWithValue && get(this.context.data, this.metadata.showClearButtonWhenFieldWithValue)) {
            this.onClearClick();
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.Open});
            return;
        }

        this.context.addToRemove({pathFilter: this.metadata.pathFilter, index: this.metadata.index});
        this.context.dispatchAccordionStatus({
            path: this.path,
            action: Actions.Delete,
            schemaBaseName: this.metadata.schemaBaseName
        });
        if ((isOnlyCard || this.metadata.forceScrollToFieldIdOnRemove) && this.metadata.fieldId) {
            scroller.scrollTo(this.metadata.fieldId, {
                smooth: true,
                duration: 300,
                delay: this.metadata.forceScrollToFieldIdOnRemove ? 250 : 0
            });
        }
    }

    private onClearClick() {
        this.context.addToReset({
            pathFilter: this.metadata.pathFilter,
            index: this.metadata.index,
            defaultValue: this.metadata.defaultValue,
            keepObject: this.metadata.keepObject
        });
    }

    private renderTitle(): React.ReactNode {
        let title;
        if (this.metadata.definition) {
            title = (<B><Template template={this.metadata.definition} data={this.context.data}/></B>);
        } else {
            title = this.schema.title;
        }

        return <span>{title}</span>;
    }

    private getInitialExpanded(): string {
        const expandedState = this.getExpandedState();
        if (expandedState === undefined || expandedState === true) {
            return this.path;
        }
        return "";
    }

    private getExpandedState(): boolean | undefined {
        return this.context.data?.uiState?.accordion ? get(this.context.data?.uiState?.accordion[this.context?.currentStepName], this.path) : undefined;
    }

    private async hasErrors(): Promise<boolean> {
        let errors = await this.props.formContext.getCurrentStepErrorList(this.props.idSchema, this.props.uiSchema);
        // Filter out errors that are not from this Accordion panel
        const accordionContentFormData: any = this.props.properties!
            .map(obj => obj.content.props.schema?.properties ? obj.content.props.schema?.properties : {[obj.content.key]: obj.content.props.schema});
        if (accordionContentFormData) {
            errors = errors.filter(err => findKeyInObj(accordionContentFormData, err.stack.split(":")[0]))
        }
        return errors.length > 0;
    }

    private async openCardOnErrorsOrEmpty() {
        const hasErrors = await this.hasErrors();
        if (hasErrors || this.getExpandedState() === undefined) {
            const result = this.context.getAllFormFields(this.props.idSchema.$id);
            const formFieldsHasBeenTouched = AccordionPanelInternal.someDirty(result);
            // Prevent validating empty values on inital accordion load
            if (AccordionPanelInternal.someDirtyNoEmptyValues(result)) {
                await this.props.formContext.validateField(this.props.idSchema, this.props.uiSchema);
            }
            if (!formFieldsHasBeenTouched && AccordionPanelInternal.allRequiredCompleted(result)) {
                // when all fields are empty and completed(e.g. optional) - skip initial dispatching of Actions.Open, which in turn will keep Next button enabled for UX initial simplification.
            } else {
                this.context.dispatchAccordionStatus({path: this.path, action: Actions.Open});
            }
        } else {
            this.context.dispatchAccordionStatus({path: this.path, action: Actions.Check, ...this.metadata});
        }
    }
}

const AccordionPanel = flowRight(
    injectIntl,
    injectSheet(styles, {inject: ["classes"]} as any),
)(AccordionPanelInternal);

export {AccordionPanel};
