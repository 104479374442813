import * as React from "react";
import { BannerIcon, ButtonResponsive, IButtonResponsiveProps } from "@folksam-digital/ui";
import { FormattedMessage } from "react-intl";
import { omit } from "lodash";
import Icons from "../../../icons";

interface IProps {
    theme: 1 | 2 | 3;
    button: {
        title: string;
        redirectCallback?: () => any;
        url?: string;
        primary?: boolean;
    } & IButtonResponsiveProps,
    image?: {
        src?: string;
        alt?: string;
    };
    translations: {
        header: string;
        subheader?: string;
    }
}

export class BannerPanel extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);

        this.handleRedirect = this.handleRedirect.bind(this);
    }

    public render() {

        const {
            theme,
            button,
            image,
            translations
        } = this.props;

        return (
            <BannerIcon
                image={image}
                theme={theme}
            >
                <BannerIcon.Header>
                    <FormattedMessage id={translations.header} />
                </BannerIcon.Header>
                <BannerIcon.Subheader>
                    <FormattedMessage id={translations.subheader!} />
                </BannerIcon.Subheader>
                <div style={{ marginTop: '-16px' }}>
                    <ButtonResponsive
                        onClick={button.redirectCallback || this.handleRedirect}
                        {...omit(this.props.button, ['redirectCallback', 'title', 'url'])}
                    >
                        <div style={{height: 24, width: 24, marginRight: 10}}>
                            {Icons.BankId()}
                        </div>
                        <FormattedMessage id={button.title}/>
                    </ButtonResponsive>
                </div>
            </BannerIcon>
        );
    }

    private handleRedirect() {
        const {url} = this.props.button;
        window.open(url, "_blank");
    }
}
