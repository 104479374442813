import * as React from "react";
import { DownloadLinkOutputBase } from "./DownloadLinkOutputBase";

export class DownloadLinkOutput extends DownloadLinkOutputBase {
    public render() {

        const {
            url
        } = this.metadata;

        return (
            <>
                {this.renderLink(url)}
            </>
        );
    }
}
