import * as React from "react";
import {IconBird, IconContactPhone, IconDrum, IconHeart, IconLike, IconStar} from "@folksam-digital/ui";
import {IBannerIconNewListItem} from "./bannerIconListHelper";

export interface IBannerIconNewListConfig {
    [key: string]: IBannerIconNewListItem
}

export const bannerIconListConfig: IBannerIconNewListConfig = {
    topRated: {text: 'iconList.topRated', icon: <IconStar/>},
    machineDamageInsurance: {text: 'iconList.machineDamageInsurance', icon: <IconLike/>},
    environmentalChoice: {text: 'iconList.environmentalChoice', icon: <IconBird/>},
    customerSatisfaction: {text: 'iconList.customerSatisfaction', icon: <IconLike/>},
    emergencyService: {text: 'iconList.emergencyService', icon: <IconContactPhone/>},
    safeHelp: {text: 'iconList.safeHelp', icon: <IconHeart/>},
    // For Building Insurance buy flow legacy - A/B-test FD-10114
    topRatedVariant: {text: 'iconList.topRatedVariant', icon: <IconStar/>},
    possibiltyOfCollectiveDiscountsVariant: {text: 'iconList.possibiltyOfCollectiveDiscountsVariant', icon: <IconDrum/>},
    safeHelpVariant: {text: 'iconList.safeHelpVariant', icon: <IconLike/>},
};
