import * as React from 'react';
import {CmsCatalogs, CmsProvider, ICmsCatalogs, ICmsContext} from "../../cms";

const withCmsProvider = <P extends {cmsContext?: ICmsContext}>(catalogName: keyof ICmsCatalogs) => (Component: React.ComponentType<P>) => {
    const cmsCatalog = CmsCatalogs[catalogName];

    return function CmsComponent(props: Pick<P, Exclude<keyof P, "cmsContext">>) {
        return (
            <CmsProvider catalog={cmsCatalog}>
                <Component {...props as P} />
            </CmsProvider>
        )
    }
};

export default withCmsProvider;
