export interface CmsCatalogDetails {
    folderName: string,
    catalogName: string,
}

export interface ICmsCatalogs {
    directDebit: CmsCatalogDetails;
    directDebitError: CmsCatalogDetails;
    accident: CmsCatalogDetails;
    accidentAndIllness: CmsCatalogDetails;
    pregnancy: CmsCatalogDetails;
    memberChild: CmsCatalogDetails;
    individualChild: CmsCatalogDetails;
    runningRace: CmsCatalogDetails;
    error: CmsCatalogDetails;
    optInOptOut: CmsCatalogDetails;
    optInOptOutError: CmsCatalogDetails;
    shoppingCart: CmsCatalogDetails;
    pregnancyComplications: CmsCatalogDetails;
    homeStudent: CmsCatalogDetails;
    personalAccident: CmsCatalogDetails;
    illness: CmsCatalogDetails;
    sportsTravel: CmsCatalogDetails;
    mobilePhone: CmsCatalogDetails;
    delayedLuggage: CmsCatalogDetails;
    glass: CmsCatalogDetails;
    glassInVacationHome: CmsCatalogDetails;
    fire: CmsCatalogDetails;
    fireInVacationHome: CmsCatalogDetails;
    theft: CmsCatalogDetails;
    theftInVacationHome: CmsCatalogDetails;
    burglary: CmsCatalogDetails;
    burglaryInVacationHome: CmsCatalogDetails;
    storm: CmsCatalogDetails;
    stormInVacationHome: CmsCatalogDetails;
    nature: CmsCatalogDetails;
    natureInVacationHome: CmsCatalogDetails;
    claimError: CmsCatalogDetails;
    bicycle: CmsCatalogDetails;
    water: CmsCatalogDetails;
    waterInVacationHome: CmsCatalogDetails;
    installedHeater: CmsCatalogDetails;
    installedHeaterInVacationHome: CmsCatalogDetails;
    householdAppliances: CmsCatalogDetails;
    householdAppliancesInVacationHome: CmsCatalogDetails;
    otherDamageOrLoss: CmsCatalogDetails;
    incapacity: CmsCatalogDetails;
    illnessDuringTravel: CmsCatalogDetails;
    accidentDuringTravel: CmsCatalogDetails;
    trailer: CmsCatalogDetails;
    caravan: CmsCatalogDetails;
    cancelledTravel: CmsCatalogDetails;
    delayedTraveler: CmsCatalogDetails;
    heavyMotorcycle: CmsCatalogDetails;
    lightMotorcycle: CmsCatalogDetails;
    moped: CmsCatalogDetails;
    snowmobileAtv: CmsCatalogDetails;
    mobileHome: CmsCatalogDetails;
    cat: CmsCatalogDetails;
    dog: CmsCatalogDetails;
    dogClaim: CmsCatalogDetails;
    catClaim: CmsCatalogDetails;
    incomeAddon: CmsCatalogDetails;
    incomeChange: CmsCatalogDetails;
    vehicleHitAndRunAccident: CmsCatalogDetails;
    vehicleCollision: CmsCatalogDetails;
    vehicleSingleAccident: CmsCatalogDetails;
    vehicleTheftOrBurglary: CmsCatalogDetails;
    vehicleVandalism: CmsCatalogDetails;
    vehicleCollisionWithAnimal: CmsCatalogDetails;
    vehicleEngineDamage: CmsCatalogDetails;
    vehicleFire: CmsCatalogDetails;
    vehicleMisfuelling: CmsCatalogDetails;
    vehicleInteriorDamage: CmsCatalogDetails;
    vehicleLossOfKey: CmsCatalogDetails;
    lightTruck: CmsCatalogDetails;
    epaAndATractor: CmsCatalogDetails;
    mopedCar: CmsCatalogDetails;
    smallTractor: CmsCatalogDetails;
    car: CmsCatalogDetails;
    homeRental: CmsCatalogDetails;
    homeBuilding: CmsCatalogDetails;
    homeApartment: CmsCatalogDetails;
    homeVacation: CmsCatalogDetails;
    homeCondominium: CmsCatalogDetails;
    homeAddOns: CmsCatalogDetails;
    horse: CmsCatalogDetails;
    lightElectricalVehicle: CmsCatalogDetails;
    accidentSpareTime: CmsCatalogDetails;
}

export const CmsCatalogs: ICmsCatalogs = {
    directDebit: {
        folderName: "directDebit",
        catalogName: "directDebit",
    },
    directDebitError: {
        folderName: "directDebit",
        catalogName: "directDebitError",
    },
    accident: {
        folderName: "product",
        catalogName: "accident",
    },
    accidentAndIllness: {
        folderName: "product",
        catalogName: "accidentAndIllness",
    },
    pregnancy: {
        folderName: "product",
        catalogName: "pregnancy",
    },
    memberChild: {
        folderName: "product",
        catalogName: "memberChild",
    },
    individualChild: {
        folderName: "product",
        catalogName: "individualChild",
    },
    runningRace: {
        folderName: "product",
        catalogName: "runningRace",
    },
    sportsTravel: {
        folderName: "product",
        catalogName: "sportsTravel",
    },
    error: {
        folderName: "product",
        catalogName: "error",
    },
    optInOptOut: {
        folderName: "groupInsurance",
        catalogName: "optInOptOut",
    },
    optInOptOutError: {
        folderName: "groupInsurance",
        catalogName: "optInOptOutError",
    },
    shoppingCart: {
        folderName: "groupInsurance",
        catalogName: "shoppingCart",
    },
    pregnancyComplications: {
        folderName: "claim",
        catalogName: "pregnancyComplications"
    },
    homeStudent: {
        folderName: "product",
        catalogName: "homeStudent",
    },
    personalAccident: {
        folderName: "claim",
        catalogName: "personalAccident"
    },
    illness: {
        folderName: "claim",
        catalogName: "illness"
    },
    mobilePhone: {
        folderName: "claim",
        catalogName: "mobilePhone"
    },
    delayedLuggage: {
        folderName: "claim",
        catalogName: "delayedLuggage"
    },
    glass: {
        folderName: "claim",
        catalogName: "glass"
    },
    glassInVacationHome: {
        folderName: "claim",
        catalogName: "glassInVacationHome"
    },
    fire: {
        folderName: "claim",
        catalogName: "fire"
    },
    fireInVacationHome: {
        folderName: "claim",
        catalogName: "fireInVacationHome"
    },
    theft: {
        folderName: "claim",
        catalogName: "theft"
    },
    theftInVacationHome: {
        folderName: "claim",
        catalogName: "theftInVacationHome"
    },
    burglary: {
        folderName: "claim",
        catalogName: "burglary"
    },
    burglaryInVacationHome: {
        folderName: "claim",
        catalogName: "burglaryInVacationHome"
    },
    storm: {
        folderName: "claim",
        catalogName: "storm"
    },
    stormInVacationHome: {
        folderName: "claim",
        catalogName: "stormInVacationHome"
    },
    nature: {
        folderName: "claim",
        catalogName: "nature"
    },
    natureInVacationHome: {
        folderName: "claim",
        catalogName: "natureInVacationHome"
    },
    claimError: {
        folderName: "claim",
        catalogName: "claimError"
    },
    bicycle: {
        folderName: "claim",
        catalogName: "bicycle"
    },
    water: {
        folderName: "claim",
        catalogName: "water"
    },
    waterInVacationHome: {
        folderName: "claim",
        catalogName: "waterInVacationHome"
    },
    installedHeater: {
        folderName: "claim",
        catalogName: "installedHeater"
    },
    installedHeaterInVacationHome: {
        folderName: "claim",
        catalogName: "installedHeaterInVacationHome"
    },
    householdAppliances: {
        folderName: "claim",
        catalogName: "householdAppliances"
    },
    householdAppliancesInVacationHome: {
        folderName: "claim",
        catalogName: "householdAppliancesInVacationHome"
    },
    otherDamageOrLoss: {
        folderName: "claim",
        catalogName: "otherDamageOrLoss"
    },
    incapacity: {
        folderName: "claim",
        catalogName: "incapacity",
    },
    illnessDuringTravel: {
        folderName: "claim",
        catalogName: "illnessDuringTravel",
    },
    accidentDuringTravel: {
        folderName: "claim",
        catalogName: "accidentDuringTravel",
    },
    trailer: {
        folderName: "product",
        catalogName: "trailer",
    },
    caravan: {
        folderName: "product",
        catalogName: "caravan",
    },
    cancelledTravel: {
        folderName: "claim",
        catalogName: "cancelledTravel",
    },
    delayedTraveler: {
        folderName: "claim",
        catalogName: "delayedTraveler",
    },
    heavyMotorcycle: {
        folderName: "product",
        catalogName: "heavyMotorcycle",
    },
    lightMotorcycle: {
        folderName: "product",
        catalogName: "lightMotorcycle",
    },
    moped: {
        folderName: "product",
        catalogName: "moped",
    },
    snowmobileAtv: {
        folderName: "product",
        catalogName: "snowmobileAtv",
    },
    mobileHome: {
        folderName: "product",
        catalogName: "mobileHome",
    },
    cat: {
        folderName: "product",
        catalogName: "cat",
    },
    dog: {
        folderName: "product",
        catalogName: "dog",
    },
    dogClaim: {
        folderName: "claim",
        catalogName: "dog",
    },
    catClaim: {
        folderName: "claim",
        catalogName: "cat",
    },
    horse: {
        folderName: 'product',
        catalogName: 'horse'
    },
    incomeAddon: {
        folderName: "incomeInsurance",
        catalogName: "incomeAddon",
    },
    incomeChange: {
        folderName: "incomeInsurance",
        catalogName: "incomeChange",
    },
    vehicleCollision: {
        folderName: "claim",
        catalogName: "vehicleCollision"
    },
    vehicleHitAndRunAccident: {
        folderName: "claim",
        catalogName: "vehicleHitAndRunAccident"
    },
    vehicleSingleAccident: {
        folderName: "claim",
        catalogName: "vehicleSingleAccident"
    },
    vehicleTheftOrBurglary: {
        folderName: "claim",
        catalogName: "vehicleTheftOrBurglary"
    },
    vehicleVandalism: {
        folderName: "claim",
        catalogName: "vehicleVandalism"
    },
    vehicleCollisionWithAnimal: {
        folderName: "claim",
        catalogName: "vehicleCollisionWithAnimal"
    },
    vehicleEngineDamage: {
        folderName: "claim",
        catalogName: "vehicleEngineDamage"
    },
    vehicleFire: {
        folderName: "claim",
        catalogName: "vehicleFire"
    },
    vehicleMisfuelling: {
        folderName: "claim",
        catalogName: "vehicleMisfuelling"
    },
    vehicleInteriorDamage: {
        folderName: "claim",
        catalogName: "vehicleInteriorDamage"
    },
    vehicleLossOfKey: {
        folderName: "claim",
        catalogName: "vehicleLossOfKey"
    },
    lightTruck: {
        folderName: "product",
        catalogName: "lightTruck"
    },
    epaAndATractor: {
        folderName: "product",
        catalogName: "epaAndATractor"
    },
    mopedCar: {
        folderName: "product",
        catalogName: "mopedCar"
    },
    smallTractor: {
        folderName: "product",
        catalogName: "smallTractor"
    },
    car: {
        folderName: "product",
        catalogName: "car"
    },
    homeRental: {
        folderName: "product",
        catalogName: "homeRental"
    },
    homeBuilding: {
        folderName: "product",
        catalogName: "homeBuilding"
    },
    homeApartment: {
        folderName: "product",
        catalogName: "homeApartment"
    },
    homeVacation: {
        folderName: "product",
        catalogName: "homeVacation"
    },
    homeCondominium: {
        folderName: "product",
        catalogName: "homeCondominium"
    },
    homeAddOns: {
        folderName: "product",
        catalogName: "homeAddOns"
    },
    lightElectricalVehicle: {
        folderName: "product",
        catalogName: "lightElectricalVehicle"
    },
    accidentSpareTime: {
        folderName: "claim",
        catalogName: "accidentSpareTime"
    },
};
