import React from "react";
import {InputComponentBase} from "./InputComponentBase";
import {FormInputNumber} from "@folksam-digital/ui";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {injectIntl} from "react-intl";
import {InputMode, InputType} from "@folksam-digital/model";

export interface INumberInputMetadata {
    placeholder?: string;
    step?: number;
    min?: number;
    max?: number;
    defaultValue?: number;
    allowEmpty?: boolean;
    maxCharacters?: number;
    prefix?: string;
    suffix?: string;
    classNames?: string;
    help?: string;
    isDisabled?: boolean;
    roundToClosestStep?: boolean;
    type?: InputType,
    inputReadOnly?: boolean
}

class NumberInputInternal extends InputComponentBase<number, INumberInputMetadata, {}> {
    public render() {
        const {
            formData,
            name,
            disabled,
            intl,
            readonly
        } = this.props;

        const {
            min,
            max,
            maxCharacters,
            prefix,
            suffix,
            isDisabled,
            step,
            roundToClosestStep,
            allowEmpty = true,
            inputReadOnly
        } = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()}>
                <FormInputNumber
                    value={formData}
                    id={name}
                    min={min}
                    max={max || 99}
                    maxCharacters={maxCharacters || 3}
                    step={step}
                    readOnly={readonly}
                    prefix={prefix && intl.formatMessage({id: prefix})}
                    suffix={suffix && intl.formatMessage({id: suffix})}
                    disabled={disabled || isDisabled}
                    allowEmpty={allowEmpty}
                    invalid={this.isInvalid()}
                    roundToClosestStep={roundToClosestStep}
                    onChange={(event: any) => this.onChangeWithValidation(event.target.value)}
                    type={InputType.text}
                    inputMode={InputMode.numeric}
                    inputReadOnly={inputReadOnly}
                />
            </FormFieldLayout>
        );
    }
}

const NumberInput = injectIntl(NumberInputInternal);

export {NumberInput};
