import * as React from "react";
import {FormattedMessage} from "react-intl";
import {DescriptionListResponsive, Spacing, SummaryCard} from "@folksam-digital/ui";
import {Constants, GroupInsurance, OfferBase, PolicySearchResult, ShoppingCartContact} from "@folksam-digital/model";
import isEmpty from "lodash/isEmpty";
import {PersonProductList} from "../output/productList/PersonProductList";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IFailedToPurchaseProductListProps {
    data: GroupInsurance;
}

class FailedToPurchaseProductList extends React.Component<IFailedToPurchaseProductListProps> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const {data} = this.props;

        if (!this.displayFailedToPurchaseSection(data)) {
            return null;
        }

        return (
            <SummaryCard theme={Constants.Themes.LighterBlue} headerText={<FormattedMessage
                id={CmsHelper.withPrefix(this.context, "success.errorList.title")}/>}>
                <SummaryCard.Body>
                    <Spacing bottom={"md"} top={"sm"}>
                        <DescriptionListResponsive.Row>
                            <FormattedMarkdown
                                messageKey={CmsHelper.withPrefix(this.context, "success.errorList.description")}/>
                        </DescriptionListResponsive.Row>
                    </Spacing>
                    {this.renderPoliciesWithErrors(data.member)}
                    {this.renderPoliciesWithErrors(data.partner)}
                    {
                        data.children && data.children.map((childContact: ShoppingCartContact) => (
                            this.renderPoliciesWithErrors(childContact)
                        ))
                    }
                    <Spacing bottom={"sm"}/>
                </SummaryCard.Body>
            </SummaryCard>

        )
    }

    private displayFailedToPurchaseSection(data: GroupInsurance): boolean | undefined {
        return (data.member.offerList && this.hasPoliciesWithErrors(data.member.offerList)) || (data.partner && data.partner.offerList && this.hasPoliciesWithErrors(data.partner.offerList)) ||
            (data.children && data.children.some((child: ShoppingCartContact) => {
                return child.offerList && this.hasPoliciesWithErrors(child.offerList);
            }));
    }

    private hasPoliciesWithErrors(offerList: OfferBase[]): boolean {
        return offerList.some(offer => {
            return offer.policy && offer.policy.errors && offer.policy.errors.length > 0
        });
    }

    private filterSelectedAndInvalidOffers(offers: OfferBase[]) {
        return offers.filter((offer: OfferBase) => {
            return offer.selected && offer.policy && !isEmpty(offer.policy.errors);
        });
    }

    private renderPoliciesWithErrors(person: ShoppingCartContact | undefined) {
        if (!person || !person.contact?.ssn) {
            return null;
        }

        if (!person.offerList) {
            return null;
        }

        const filteredOfferList = this.filterSelectedAndInvalidOffers(person.offerList);

        return <PersonProductList filteredOfferList={filteredOfferList as PolicySearchResult[]} contact={person.contact}/>
    }
}

export default FailedToPurchaseProductList;
