import * as React from "react";
import {withRouter} from "react-router-dom";
import Mustache from "mustache";
import {FormattedMessage} from "react-intl";
import {
    BackPackIcon,
    CaravanIconSmall,
    CarIcon,
    CatIcon,
    DogIcon, ElectricScooterIcon,
    EpaTraktorIcon,
    HeavyMotorcycleSmall,
    HomeBuildingIcon,
    HomeVacationIcon,
    HouseIconCompact,
    LightMotorcycleIconCompact,
    MobileHomeIcon,
    MopedCarIcon,
    MopedIconCompact,
    SmallTractorIcon,
    SnowmobileAtvIcon,
    SummaryCard,
    TeddyBearIcon,
    TrailerIconCompact,
    TruckIcon,
    withViewContext
} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {PanelComponentBase} from "./PanelComponentBase";
import {FormContext, IFormContext} from "../FormContext";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {SummaryCardIconType} from "@folksam-digital/model";
import {RedirectControl} from "../controls/redirectControl";

interface IMetadata {
    image?: {
        src: string,
        alt: string,
    };
    coverageImgPath?: string;
    icon?: any;
    properties: [];
    redirectBtn?: {
        redirectText: string,
        redirectUrl: string,
        asBtn?: boolean
    };
    classNames?: string;
    disableSummaryCardImage?: boolean;
}

const IconComponentTypes = {
    [`${SummaryCardIconType.Cat}`]: CatIcon,
    [`${SummaryCardIconType.Dog}`]: DogIcon,
    [`${SummaryCardIconType.EpaAndATractor}`]: EpaTraktorIcon,
    [`${SummaryCardIconType.LightTruck}`]: TruckIcon,
    [`${SummaryCardIconType.MopedCar}`]: MopedCarIcon,
    [`${SummaryCardIconType.SmallTractor}`]: SmallTractorIcon,
    [`${SummaryCardIconType.Car}`]: CarIcon,
    [`${SummaryCardIconType.MemberChild}`]: TeddyBearIcon,
    [`${SummaryCardIconType.HomeStudent}`]: BackPackIcon,
    [`${SummaryCardIconType.HomeRental}`]: HouseIconCompact,
    [`${SummaryCardIconType.HomeBuilding}`]: HomeBuildingIcon,
    [`${SummaryCardIconType.HomeVacation}`]: HomeVacationIcon,
    [`${SummaryCardIconType.HomeCondominium}`]: HouseIconCompact,
    [`${SummaryCardIconType.Moped}`]: MopedIconCompact,
    [`${SummaryCardIconType.LightMotorcycle}`]: LightMotorcycleIconCompact,
    [`${SummaryCardIconType.Trailer}`]: TrailerIconCompact,
    [`${SummaryCardIconType.Caravan}`]: CaravanIconSmall,
    [`${SummaryCardIconType.HeavyMotorcycle}`]: HeavyMotorcycleSmall,
    [`${SummaryCardIconType.SnowmobileAtvIcon}`]: SnowmobileAtvIcon,
    [`${SummaryCardIconType.MobileHome}`]: MobileHomeIcon,
    [`${SummaryCardIconType.LightElectricalVehicle}`]: ElectricScooterIcon, // TODO: change to smaller icon when ready on folksam-ui
};

@(withRouter as any)
@(withViewContext as any)
export class SummaryCardWidget extends PanelComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        const {
            schema,
            viewContext,
            uiSchema
        } = this.props;
        const title = schema.title;

        const {
            coverageImgPath,
            icon,
            disableSummaryCardImage,
            redirectBtn
        } = this.metadata;

        let imgId = "";
        const IconComponent = IconComponentTypes[icon];

        if (coverageImgPath) {
            Mustache.parse(coverageImgPath);
            imgId = Mustache.render(coverageImgPath, this.context.data);
        }

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => (
                    <CmsContext.Consumer>
                        {(cmsContext: ICmsContext) => {
                            const imageAttribute = (imgId && imgId !== "") ?
                                CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, `coverageImage_${imgId}`), cmsContext.catalog!) :
                                CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, "bannerImage"), cmsContext.catalog!);
                            return <>
                                <SummaryCard customHeader={
                                    <SummaryCard.Header noPaddingSide={true} theme={5}>
                                        {coverageImgPath && !disableSummaryCardImage && !viewContext.isMobile &&
                                            <SummaryCard.Image {...imageAttribute} />}
                                        {IconComponent && <SummaryCard.HeaderIcon component={<IconComponent
                                            style={{marginRight: "10px"}} />} />}
                                        <SummaryCard.HeaderText>
                                            <FormattedMessage id={title} />
                                        </SummaryCard.HeaderText>
                                        <RedirectControl id={`${uiSchema.name}RedirectBtn`} context={context}
                                            redirectControl={redirectBtn} props={this.props} summaryCardRedirect={true} />
                                    </SummaryCard.Header>
                                }>
                                    <SummaryCard.Body>
                                        {this.props.properties?.map(p => p.content)}
                                    </SummaryCard.Body>
                                </SummaryCard>
                            </>
                        }}
                    </CmsContext.Consumer>
                )}
            </FormContext.Consumer>
        );
    }
}
