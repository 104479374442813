import * as React from "react";
import { apm } from "@elastic/apm-rum";
import {LocaleContext} from "./LocaleContext";
import {container} from "../inversify.config";
import {Types} from "../Types";
import {ILocaleConfig} from "../intl/ILocaleConfig";
import {IStorage} from "../services";
import {IntlProvider} from "react-intl";
import {Button} from "@folksam-digital/ui";

interface IState {
    locale: string
}

interface IProps {
    children?: React.ReactNode;
}

const LOCALE_KEY: string = "LOCALE";

export class LocaleProvider extends React.Component<IProps, IState> {
    private localeConfig: ILocaleConfig;
    private settingsStorage: IStorage;

    constructor(props: Readonly<{}>) {
        super(props);

        this.localeConfig = container.get<ILocaleConfig>(Types.LocaleConfig);
        this.settingsStorage = container.get<IStorage>(Types.SettingsStorage);

        this.state = {
            locale: this.settingsStorage.get<string>(LOCALE_KEY, this.localeConfig.defaultLocale)!
        }
    };

    /**
     * Switch locale to other
     * @param locale
     */
    private switchLocale = async (event: any, locale: string) => {
        this.settingsStorage.set(LOCALE_KEY, locale);
        this.setState({
            locale
        }, this.forceUpdate);
    };

    private onError(error: any) {
        apm.captureError(error)
    }

    public render(): any {
        return (
            <React.Fragment>
                {this.localeConfig.locales.length > 1 && (
                    <div style={{ textAlign: "right" }}>
                        {this.localeConfig.locales.map(locale => {
                            return (
                                <Button
                                    key={locale}
                                    type="button"
                                    asLink={true}
                                    onClick={(event: any) => this.switchLocale(event, locale)}
                                >
                                    {locale}
                                </Button>
                            );
                        })}
                    </div>
                )}
                <LocaleContext.Provider value={{ locale: this.state.locale }}>
                    <IntlProvider locale={this.state.locale} messages={{}} onError={this.onError}>
                        {this.props.children}
                    </IntlProvider>
                </LocaleContext.Provider>
            </React.Fragment>
        );
    }
}
