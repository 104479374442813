import {Breakpoints} from "@folksam-digital/model";

export const defaultBreakpoints = {
    toggleButton: {[Breakpoints.md]: 12},
    toggleButton3Options: {[Breakpoints.md]: 12},
    yesNoButton: {[Breakpoints.md]: 12},
    dropdown: {[Breakpoints.md]: 12},
    dateTime: {[Breakpoints.md]: 12},
    textArea: {[Breakpoints.md]: 12},
    googleSearch: {[Breakpoints.md]: 12},
    currencyInput: {[Breakpoints.md]: 12},
    emailInput: {[Breakpoints.md]: 12},
    phoneNumberInput: {[Breakpoints.md]: 12},
    paymentDetails: {[Breakpoints.md]: 12},
    registrationNumberInput: {[Breakpoints.md]: 12},
    ssnInput: {[Breakpoints.sm]: 12, [Breakpoints.md]: 12, [Breakpoints.lg]: 12},
    infoBox: {[Breakpoints.md]: 12},
    radioGroup: {[Breakpoints.md]: 12},
    textBox: {[Breakpoints.md]: 12},
    additionCard: {[Breakpoints.md]: 12},
    addButton: {[Breakpoints.md]: 12},
    dateTimeComponentTime: {[Breakpoints.md]: 6},
    dateTimeComponentDate: {[Breakpoints.md]: 6},
    additionalTimeCard: {[Breakpoints.md]: 12, [Breakpoints.lg]: 12},
    salaryCard: {[Breakpoints.md]: 12, [Breakpoints.lg]: 12},
};
