import React from "react";
import {RouteProps, useRouteMatch} from "react-router-dom";
import {ICmsCatalogs} from "../../../cms";
import {FeatureToggleUtils, JourneyHelper, UrlHelper} from "@folksam-digital/services";

export interface IToggleProps extends RouteProps {
    featureName?: string;
    journeyType?: string;
    journeyId?: keyof ICmsCatalogs;
}

interface IParams {
    journeyId: keyof ICmsCatalogs;
    slug?: string;
}

function withToggle(Component: React.ComponentType<IToggleProps>) {
    return function ToggleWrapper(props: IToggleProps) {
        const routeMatch = useRouteMatch<IParams>({
            path: `${UrlHelper.getBaseUrl()}${props.journeyType ? props.journeyType : ""}/:journeyId/:slug?`,
        });

        const featureName = !!props.featureName ? props.featureName : routeMatch?.params?.journeyId;
        if (featureName && FeatureToggleUtils.isFeatureEnabled(JourneyHelper.getUnobfuscatedJourneyId(featureName))) {
            return <Component journeyId={routeMatch?.params?.journeyId} {...props} />;
        }

        return null;
    }
}

export default withToggle;
