import React, {useCallback, useEffect, useState} from "react";
import {Constants} from "@folksam-digital/model";
import {FormattedMessage} from "react-intl";
import {ButtonResponsive, ConstrainWidth, Spacing, SpacingLine} from "@folksam-digital/ui";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {LinkButton} from "../../components/journey/form/input/LinkButton";

interface IChooseTimeToCallBackComponentProps {
    contactUsAltMessage?: string;
}

const loadScript = (callback?: () => void) => {
    const id = 'teliaScript';
    const existingScript = document.getElementById(id);

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = Constants.Links.callMeBackTeliaScriptUrl;
        script.id = id;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) {
                callback();
            }
        };
    }

    if (existingScript && callback) {
        callback();
    }
};

export const ChooseTimeToCallBackComponent = ({contactUsAltMessage}: IChooseTimeToCallBackComponentProps) => {
    const onClick = useCallback(() => {
        if (!window.humany) {
            return;
        }

        const widget = window.humany.widgets?.find("teckna-wcb-formular");

        if (!widget) {
            return;
        }

        if (widget.state === "activated") {
            widget.invoke("show");
            return;
        }

        widget.activate().then(() => {
            widget.invoke('attach', {
                withRenderState: 'open'
            });
        });
    }, []);

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        loadScript(() => {
            setLoaded(true);
        });
    }, []);

    return (
        <React.Fragment>
            <FormattedMarkdown
                messageKey={contactUsAltMessage ? contactUsAltMessage : CmsHelper.withGeneralPrefix("contactForm.contactUs")}/>
            <SpacingLine y={'3'}/>
            <Spacing type={"padding"} y={'5'}>
                <FormattedMessage id={CmsHelper.withGeneralPrefix("step.chooseTime.info")}/>
            </Spacing>
            <Spacing type={"padding"} y={'4'}>
                {loaded && <ButtonResponsive
                    full={true}
                    primary={false}
                    onClick={onClick}
                >
                    <FormattedMessage id={CmsHelper.withGeneralPrefix("step.chooseTime")}/>
                </ButtonResponsive>}
            </Spacing>
            <ConstrainWidth center={true}>
                <LinkButton url={Constants.Links.personalData}>
                    <FormattedMarkdown
                        messageKey={CmsHelper.withGeneralPrefix("step.personal")}/>
                </LinkButton>
            </ConstrainWidth>
        </React.Fragment>
    );
}
