import * as React from "react";
import {Spacing, Step} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";
import merge from "lodash/merge";

type TSpacingProps = 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';

export interface IStepMetadata {
    showBorder?: boolean;
    showBorderBottom?: boolean;
    marginTop?: boolean;
    marginBottom?: boolean;
    spacingTop?: TSpacingProps;
    spacingRight?: TSpacingProps;
    spacingBottom?: TSpacingProps;
    spacingLeft?: TSpacingProps;
    labelPanelName?: string;// set in LabelPanel component to avoid manual add in schema.
}

export class StepItemOutput extends PanelComponentBase<string, IStepMetadata, {}> {
    public render() {
        const {
            showBorder = false,
            showBorderBottom = false,
            marginTop = false,
            marginBottom = false,
            spacingTop,
            spacingRight,
            spacingBottom,
            spacingLeft,
            labelPanelName
        } = this.metadata;

        return (
            <>
                {marginTop && <Spacing type={"margin"} bottom={"3"}/>}
                <Step.Item border={showBorder} borderBottom={showBorderBottom}
                           spacingTop={spacingTop} spacingRight={spacingRight}
                           spacingBottom={spacingBottom} spacingLeft={spacingLeft}>
                    {this.props.properties?.map(p => {
                        if (labelPanelName) {
                            const componentMetadata = merge({}, p.content.props.uiSchema?.componentMetadata || {}, {labelPanelName: this.uiSchema.componentMetadata.labelPanelName});

                            return React.cloneElement(p.content, {
                                ...p.content.props,
                                uiSchema: {...p.content.props.uiSchema, componentMetadata}
                            });
                        }

                        return p.content;
                    })}
                </Step.Item>
                {marginBottom && <Spacing type={"margin"} bottom={"md"}/>}
            </>
        );
    }
}
