import * as React from "react";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {
}

export class QuestionnairePanel extends PanelComponentBase<string, IMetadata, {}> {
    public render() {
        return (
            <>{this.props.properties?.map(p => p.content)}</>
        )
    }
}
