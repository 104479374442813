import {JourneyBase, PolicyBase} from "@folksam-digital/model";
import {HomeTravelGolfSmallBoatCovers, HomeWithSmallBoatOptionsBase} from "@folksam-digital/model/lib/policy/IHomeBase";
import React from "react";
import {Template} from "../../../../components/journey/form/output/helpers";
import { SchemaHelper } from "../../../../Helpers/SchemaHelper";
import {RowScope} from "./ListRowItem";

export const HomeRowScope = ({prefix, withType, data, id}: {id?: string, prefix: string, withType?: boolean, data: JourneyBase<PolicyBase<HomeWithSmallBoatOptionsBase, HomeTravelGolfSmallBoatCovers>> }): React.ReactElement => {
    return (
        <RowScope
            contentMessage={
                <Template
                    template={SchemaHelper.definitionScopeHome({prefix, withType, targetEnv: data.targetEnv, insuranceType: data.policy.insuredObject?.insuranceType})}
                    data={data}
                />
            }
            data={data}
            id={id}
        />
    );
};
