import {Constants, IditRestException} from "@folksam-digital/model";
import {ICmsContext} from "../../../cms";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";

export interface IErrors {
    [key: string]: string;
}

export const handleIditValidationErrors = (validationErr: IditRestException, cmsContext: ICmsContext): any => {
    const errors: IErrors = {};

    switch (validationErr.type) {
        case Constants.IditErrorCodes.AccountNumberMaxLength:
        case Constants.IditErrorCodes.AccountNumberMinLength:
            errors.accountNumber = CmsHelper.withPrefix(cmsContext, "form.error.accountNumber.length");
            break;
        case Constants.IditErrorCodes.DirectDebitAccountValidation:
            errors.accountNumber = CmsHelper.withPrefix(cmsContext, "form.error.invalidBankAccountDetails");
            break;
        case Constants.IditErrorCodes.InvalidClearingNumber:
            errors.clearingNumber = CmsHelper.withPrefix(cmsContext, "form.error.invalidClearingNumber");
            break;
        case Constants.IditErrorCodes.BankAccountAlreadyExists:
            errors.bankAccountAlreadyExists = CmsHelper.withPrefix(cmsContext, "form.error.bankAccountAlreadyExists");
            break;
        default:
            errors.fatalError = "404";
    }

    return errors;
};
