import {BaseErrorMessage} from "./BaseErrorMessage";
import {Constants} from "@folksam-digital/model";

export class CustomerHasNoPolicy extends BaseErrorMessage {
    protected determineMessage() {
        switch (this.journeyId) {
            case Constants.Journey.DogClaim.Id:
                this.message = "dog.hasNoPolicy.description";
                break;
            case Constants.Journey.CatClaim.Id:
                this.message = "cat.hasNoPolicy.description";
                break;
        }
    }
}
