import * as React from "react";
import {PanelComponentBase} from "../../../journey/form/panel/PanelComponentBase";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import {withViewContext} from "@folksam-digital/ui";
import {withRouter} from "react-router";
import {IFormComponentProps} from "../../../journey/form/FormComponentBase";
import ShoppingCartProductList, { OfferGroupKeys } from "./ShoppingCartProductList";
import {Constants} from "@folksam-digital/model";
import {UrlHelper} from "@folksam-digital/services";
import {RedirectControl} from "../../../journey/form/controls/redirectControl";

class ShoppingCartSummary extends PanelComponentBase<any, any, IFormComponentProps<any, any>> {
    public render(): JSX.Element {
        return(
            <FormContext.Consumer>
                {
                    (context: IFormContext) => (<ShoppingCartProductList context={context} renderRedirectControl={this.renderRedirectControl(context)} />)
                }
            </FormContext.Consumer>
        );
    }

    private renderRedirectControl(context: IFormContext) {
        return (key: OfferGroupKeys) => (<RedirectControl id={`${Constants.Journey.ShoppingCart.Id}_${key}RedirectBtn`}
                                        context={context}
                                        redirectControl={{
                                            redirectUrl: UrlHelper.getGroupJourneyUrl(Constants.Journey.ShoppingCart.Id, this.getRedirectURl(key)),
                                            redirectText: "general.link.change"
                                        }}
                                        props={this.props}/>)
    }

    private getRedirectURl(key: OfferGroupKeys): string {
        switch (key) {
            case OfferGroupKeys.member:
                return Constants.Journey.ShoppingCart.Steps.insuranceForYou;
            case OfferGroupKeys.partner:
                return Constants.Journey.ShoppingCart.Steps.insuranceForYourPartner;
            case OfferGroupKeys.children:
                return Constants.Journey.ShoppingCart.Steps.insuranceForYourChildren;
            default:
                return "";
        }
    };
}

export default withViewContext(withRouter(ShoppingCartSummary));
