import * as React from "react";
import {P, defaultTheme as theme} from "@folksam-digital/ui";

interface IOptOutInContainerHeaderProps {
    text: string | React.ReactElement;
}

export const OptOutInContainerHeader: React.FC<IOptOutInContainerHeaderProps> = ({text}) => {
    return <P style={{marginBottom: theme.margin['3']}}>
        {text}
    </P>
};
