import {SatelliteEventTransactionTypes, SatelliteEvents} from "../new/BaseAnalytics";
import {
    BaseAnalytics,
    BaseMapping,
    IOnTriggerDataLayerEventParams
} from "./BaseAnalytics";

export interface OptInOptOutAMap extends BaseMapping {
    product_id: Array<number>;
    product_status: Array<string>;
    transaction_id: string;
    transaction_page_start?: string;
    transaction_page_complete?: string;
    age: number;
    groupid: string;
}

export class OptInOptOutAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.group.${params.journeyId!.toString()}`];
        const pageName: string = this.getPageName(params);
        const pageStart = params.messages[`general.adobe.group.pageStart`];
        const pageType = params.messages[`general.adobe.optInOptOut.pageType`];

        let productIds: Array<number> = [];
        let productStatus: Array<string> = [];

        if (params?.data?.optIn) {
            for (const product of params.data.optIn) {
                if (product.selected !== undefined) {
                    productIds = productIds.concat(product.productId);
                    productStatus = productStatus.concat((product.selected !== undefined && product.selected === true) ? "accept" : "deny");
                }
            }
        }

        if (params?.data?.optOut) {
            for (const product of params.data.optOut) {
                if (product.selected !== undefined) {
                    productIds = productIds.concat(product.productId);
                    productStatus = productStatus.concat((product.selected !== undefined && product.selected === true) ? "accept" : "deny");
                }
            }
        }

        let trackKey = `${SatelliteEventTransactionTypes.TransactionPage}_${params.event}`;
        const userId = await this.getUserId(params?.data?.member?.contact?.externalContactNumber);

        const dataLayerObj: OptInOptOutAMap = {
            age: params?.data?.member?.contact?.age,
            groupid: params?.data?.group?.id,
            customer_id: userId,
            pagename: `${pageStart}|${journeyName}|${pageName}`,
            sitesection1: `${pageStart}`,
            service_name: `${pageStart}`,
            sitesection2: `${pageStart}|${journeyName}`,
            sitesection3: `${pageStart}|${journeyName}|${pageName}`,
            sitesection4: `${pageStart}|${journeyName}|${pageName}|`,
            sitesection5: `${pageStart}|${journeyName}|${pageName}||`,
            pagetype: `${pageType}`,
            product_id: productIds,
            product_status: productStatus,
            transaction_id: this.getTransactionId(),
            transaction_page_start: this.checkMatch(trackKey, `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Start}`),
            transaction_page_complete: this.checkMatch(trackKey, `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Complete}`),
        };

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        this.setDataLayer(dataLayerObj, trackKey);
    }
}
