import {IQuestionSchemaOverride} from "../product/GeneratorConfig";

export const accidentAndIllness: IQuestionSchemaOverride = {
    1000001: {
        showBackgroundColor: false
    },
    1000002: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000003: {
        showBackgroundColor: false
    },
    1000004: {
        showBackgroundColor: false
    },
    1000005: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000006: {
        showBackgroundColor: false
    },
    1000007: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000008: {
        showBackgroundColor: false
    },
    1000009: {
        showBackgroundColor: false
    },
    1000010: {
        showBackgroundColor: false
    },
    1000011: {
        additionalHeaderTitle: true
    },
    1000012: {
        showBackgroundColor: false
    },
    1000019: {
        showBackgroundColor: false
    },
    1000020: {
        showBackgroundColor: false
    },
    1000021: {
        showBackgroundColor: false
    },
    1000022: {
        showBackgroundColor: false
    },
    1000023: {
        showBackgroundColor: false
    },
    1000024: {
        showBackgroundColor: false
    },
    1000025: {
        showBackgroundColor: false
    },
    1000026: {
        showBackgroundColor: false
    },
    1000027: {
        showBackgroundColor: false
    },
    1000028: {
        showBackgroundColor: false
    },
    1000029: {
        showBackgroundColor: false
    },
    1000030: {
        showBackgroundColor: false
    },
    1000031: {
        showBackgroundColor: false
    },
    1000032: {
        showBackgroundColor: false
    },
    1000035: {
        showBackgroundColor: false
    },
    1000037: {
        showBackgroundColor: false
    },
    1000038: {
        showBackgroundColor: false
    },
    1000039: {
        showBackgroundColor: false
    },
    1000040: {
        showBackgroundColor: false
    },
    1000563: {
        showBackgroundColor: false
    },
    1000564: {
        showBackgroundColor: false
    }
};
