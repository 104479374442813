import {FormattedMessage} from "react-intl";
import {withRouter} from "react-router-dom";
import {get, isEmpty} from "lodash";
import {
    DescriptionListResponsive, withViewContext,
    SummaryCard,
    Spacing
} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";
import {FormContext, IFormContext} from "../FormContext";
import {Template} from "../output/helpers";
import {DescriptionReveal} from "./DescriptionReveal";
import {RedirectControl} from "../controls/redirectControl";

interface IState {
    readMore?: boolean;
}

interface IMetadata {
    properties: [],
    redirectBtn?: {
        redirectText: string,
        redirectUrl: string,
        asBtn?: boolean
    };
    classNames?: any;
    headerKeyValuePath?: { [key: string]: string };
    titleAsTemplate?: boolean;
    rowLimit?: number;
    spacingTop?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    spacingBottom?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
}

class DescriptionListPanel extends PanelComponentBase<string, IMetadata, IState> {
    public static contextType = FormContext;
    context!: IFormContext;

    constructor(props: any, context?: any) {
        super(props, context);
        this.state = {readMore: false};

        this.readMoreToggle = this.readMoreToggle.bind(this);
    }

    private readMoreToggle() {
        this.setState({readMore: !this.state.readMore})
    }

    public render() {

        const title = this.schema.title;
        const {
            redirectBtn, classNames, headerKeyValuePath, titleAsTemplate, rowLimit,
            spacingTop,
            spacingBottom,
        } = this.metadata;
        const headerValues: { [key: string]: string } = {};

        if (!isEmpty(headerKeyValuePath)) {
            for (const [key, path] of Object.entries(headerKeyValuePath!)) {
                headerValues[key] = get(this.context.data, path, path);
            }
        }

        const {uiSchema} = this.props;

        const filteredProperties = this.props.properties!.filter((property: any) => !property?.hidden);
        const reveal = (rowLimit ? <DescriptionReveal
                rowLimit={rowLimit}
                properties={filteredProperties}
                readMore={this.state.readMore}
                readMoreToggle={this.readMoreToggle}
            />
            : filteredProperties?.map(p => p.content));

        const formattedTitle = <FormattedMessage id={title} values={headerValues}/>;
        const templateTitle = <Template template={title} data={this.context.data}/>;

        return (
            <Spacing type={"margin"} top={spacingTop} bottom={spacingBottom}>
                <DescriptionListResponsive>
                    <div className={classNames}>
                        {title !== undefined && title !== ""
                            ? <SummaryCard customHeader={
                                <SummaryCard.Header theme={5} noPaddingSide={true}>
                                    <SummaryCard.HeaderText>
                                        {titleAsTemplate ? templateTitle : formattedTitle}
                                    </SummaryCard.HeaderText>
                                    <RedirectControl id={`${uiSchema.name}RedirectBtn`} context={this.context} redirectControl={redirectBtn} props={this.props} />
                                </SummaryCard.Header>
                            }>
                                <SummaryCard.Body>
                                    {reveal}
                                </SummaryCard.Body>
                            </SummaryCard>
                            // no title used for prodcut review step together with SummaryCard
                            : reveal}
                    </div>
                </DescriptionListResponsive>
                {this.state.readMore && <div style={{paddingBottom: 44}}/>}
            </Spacing>
        );
    }
}

export const DescriptionListPanelWithRouter = withViewContext(withRouter(DescriptionListPanel));
