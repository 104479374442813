import {Constants} from "@folksam-digital/model";
import {bannerIconListConfig} from "./bannerIconListConfig";
import {IBannerIconListGenerator, IBannerIconNewListItem} from "./bannerIconListHelper";
import {AnalyticsHelper} from "../../../../../Helpers/analytic/AnalitycsHelper";

export const carIconListGenerator: IBannerIconListGenerator = (formData) => {

    const carCommonIconListItems: IBannerIconNewListItem[] = [
        bannerIconListConfig.environmentalChoice,
        bannerIconListConfig.topRated
    ];

    const carBrandIconList: IBannerIconNewListItem[] = [
        bannerIconListConfig.topRated,
        bannerIconListConfig.machineDamageInsurance,
        bannerIconListConfig.environmentalChoice
    ];

    if (formData?.policy?.insuredObject) {
        return formData?.policy?.insuredObject?.carInsuranceType === Constants.Car.InsuranceType.Brand ? carBrandIconList : carCommonIconListItems;
    }

    return carCommonIconListItems;

};

export const motorCommonIconListGenerator: IBannerIconListGenerator = (formData) => {
    return [
        bannerIconListConfig.customerSatisfaction,
        bannerIconListConfig.emergencyService,
    ];
};

export const homeLegacyIconListGenerator: IBannerIconListGenerator = (formData) => {
    // For Building Insurance buy flow legacy - A/B-test FD-10114
    if (AnalyticsHelper.isAdobeScriptEnabledForJourney(formData.journeyId, "variant")) {
        return [
            bannerIconListConfig.topRatedVariant,
            bannerIconListConfig.possibiltyOfCollectiveDiscountsVariant,
            bannerIconListConfig.safeHelpVariant
        ];
    }

    return [
        bannerIconListConfig.topRated,
        bannerIconListConfig.machineDamageInsurance,
        bannerIconListConfig.environmentalChoice,

    ];
};

export const homeVacationIconListGenerator: IBannerIconListGenerator = () => {
    return [
        bannerIconListConfig.safeHelp,
        bannerIconListConfig.environmentalChoice
        ]
    }
