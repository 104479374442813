import {default as React, useCallback} from "react";
import {FormattedMessage} from "react-intl";
import {Button, IconCloseV2} from "@folksam-digital/ui";

import {useModal} from "./hooks/useModal";
import {Modal} from "../../controls/remove/Modal";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";

interface IRemoveButtonProps {
    themeId?: number;
    onChange?: (param: any) => void;
    id?: string;
    style?: any;
    modalHeaderMessageId?: string;
}

export const RemoveButton = ({
                                 onChange,
                                 id,
                                 modalHeaderMessageId,
                                 style
                             }: React.PropsWithChildren<IRemoveButtonProps>): React.ReactElement => {
    const {open, handleOpen, handleClose} = useModal();

    const confirmAndClose = useCallback((e: React.SyntheticEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        handleClose(e);
        onChange && onChange(true);
    }, [onChange, handleClose]);

    return (
        <>
            <Button
                id={id}
                editButton
                fontSize={"small"}
                type="button"
                onClick={handleOpen}
                style={{
                    ...style
                }}
            >
                <IconCloseV2
                    style={{paddingRight: "4px", width: 16, height: 16}}
                />
                <FormattedMessage id={"general.remove"} />
            </Button>
            <Modal
                open={open}
                headerMessageId={modalHeaderMessageId || CmsHelper.withGeneralPrefix("remove.confirmation")}
                onSubmit={confirmAndClose}
                onClose={handleClose}
            />
        </>

    );
};
