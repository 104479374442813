import * as React from "react";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {
    Badge,
    defaultTheme as theme,
    DisplayIcon,
    Grid,
    IconCaretRightV2,
    IconWinner,
    QuestionSkeleton,
    SelectionCoverageCardV2,
    Spacing
} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CoverPlan, LegacyCoverPlan} from "@folksam-digital/model";
import {Markdown} from "../../../Markdown";
import {ModalIframe} from "../output";
import {CompactCoverListTableIcons} from "./helpers/compactCoverListTableIconMap";
import injectSheet from "react-jss";
import {Classes} from "jss";
import flowRight from "lodash/flowRight";

interface IMetadata {
    modal?: {
        title: string;
        url: string;
    };
    icon?: any;
    greyedText?: boolean;
    hideSubHeading?: boolean;
    separateCoverPlanHeadingsByInsuranceType?: boolean;
}

interface IPopOverConfig {
    isPopOverDisplayed: boolean;
    id?: string;
    text?: string;
}

const jssStyles = {
    linkIcon: {
        marginLeft: theme.margin['3'],
        color: 'inherit',
        '& svg': {
            top: '6px !important',// according to Figma overwrites icon baseline position bellow baseline
        }
    }
};

interface ISelectionCoverageCardsV2InternalProps<TValue, TMetadata> extends IInputComponentProps<TValue, TMetadata> {
    classes: Classes<keyof typeof jssStyles>;
}

class SelectionCoverageCardsV2Internal extends InputComponentBase<string, IMetadata, {}, ISelectionCoverageCardsV2InternalProps<string, IMetadata>> {
    public static contextType = FormContext;
    context!: IFormContext;

    constructor(props: any, context: any) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
    }

    public render() {
        if (this.context?.data?.policy?.premium?.coverPlans) {
            const coverPlans: LegacyCoverPlan[] | CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);

            return (
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) => (
                        <FormFieldGroupLayout {...this.getLayoutProps()} titleTheme={3}>
                            <Grid.Col>
                                {
                                    coverPlans.map((coverPlan: LegacyCoverPlan | CoverPlan) => {
                                        return coverPlan?.id && this.renderCoverPlan(coverPlan.id, coverPlan, cmsContext);
                                    })
                                }
                            </Grid.Col>
                        </FormFieldGroupLayout>
                    )}
                </CmsContext.Consumer>
            );
        } else {
            return <QuestionSkeleton/>;
        }
    }

    private getPopOverConfig({coverPlanId, cmsContext}: { coverPlanId: string, cmsContext: ICmsContext }): IPopOverConfig {
        const popOverConfig = {isPopOverDisplayed: false} as IPopOverConfig;
        const message = CmsHelper.withPrefix(cmsContext, `coverPlans.winner.popOver.${coverPlanId}`);
        const winnerPopOverText = this.props.intl.messages[message] as string;
        if (winnerPopOverText) {
            popOverConfig.isPopOverDisplayed = true;
            popOverConfig.id = coverPlanId;
            popOverConfig.text = winnerPopOverText;
        }

        return popOverConfig;
    }

    onChange(event: any) {
        const {disableButton} = this.context;

        if (!disableButton) {
            this.onChangeWithValidation(event.target.value)
        }
    }

    private renderCoverPlan(coverPlanId: string, coverPlan: LegacyCoverPlan | CoverPlan, cmsContext: ICmsContext) {
        const {formData, intl, classes} = this.props;

        const {modal, icon, greyedText} = this.metadata;
        const {data} = this.context;

        const modalTitle = modal && (<div style={{
            fontFamily: theme.fonts.mono,
            fontSize: theme.textSizes.sm
        }}>
            <FormattedMessage id={modal.title}/>
            <DisplayIcon baseline={true} icon={<IconCaretRightV2/>} className={classes.linkIcon}/>
        </div>);

        const popoverConfig = this.getPopOverConfig({coverPlanId, cmsContext});

        const coverPlanCount: number = Object.values(data.policy.premium.coverPlans).length;

        return (
            <Grid.Row style={{marginLeft: 0, marginRight: 0}}
                      lg={coverPlanCount === 2 ? 5 : Math.floor(12 / coverPlanCount)}
                      md={Math.floor(12 / coverPlanCount)} key={coverPlanId}>
                <>
                    <Spacing type={"margin"} top={"sm"}/>
                    <SelectionCoverageCardV2
                        id={coverPlanId}
                        icon={CompactCoverListTableIcons[icon]}
                        badge={
                            popoverConfig.isPopOverDisplayed && <Badge
                                bookmark
                                type="offer"
                                disableAnimation={true}
                                icon={<DisplayIcon medium icon={<IconWinner/>}/>}
                                text={popoverConfig.text}
                                halfWidth
                            />
                        }
                        heading={this.getHeading(coverPlanId, cmsContext)}
                        subHeading={this.getSubHeading(coverPlanId, cmsContext)}
                        price={intl.formatMessage({id: "general.addon.price.title"}, {monthlyPremium: intl.formatNumber(Math.round(coverPlan?.monthlyPremium || 0))})}
                        value={coverPlanId}
                        checked={formData === coverPlanId}
                        onChange={this.onChange}
                        disabled={this.context.data?.uiState?.disabledCoverPlanIds?.includes(coverPlanId)}
                        buttonText={{
                            unselected: <FormattedMessage
                                id={"general.package.unselected"}/>,
                            selected: <FormattedMessage
                                id={"general.package.selected"}/>
                        }}
                    >
                        <Markdown
                            customFont={theme.fonts.mono}
                            fontSize={theme.textSizes.sm}
                            greyedText={greyedText}
                            source={this.getCoverPlanCoverDescriptionList(coverPlanId, coverPlan, cmsContext)}/>
                        {modal && <Spacing type={'margin'} top={'6'}>
                            <ModalIframe url={modal.url} title={modalTitle || <></>}/>
                        </Spacing>}
                    </SelectionCoverageCardV2>
                </>
            </Grid.Row>
        )
    }

    private getHeading(coverPlanId: string, cmsContext: ICmsContext): string {
        const {intl} = this.props;
        const {separateCoverPlanHeadingsByInsuranceType} = this.metadata;

        if (separateCoverPlanHeadingsByInsuranceType) {
            return intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${this.context?.data?.policy?.insuredObject?.insuranceType}.${coverPlanId}.heading`)});
        } else {
            return intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.heading`)});
        }
    }

    private getSubHeading(coverPlanId: string, cmsContext: ICmsContext): string | undefined {
        const {intl} = this.props;
        const {hideSubHeading} = this.metadata;
        if (hideSubHeading) {
            return undefined
        }
        return intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.subHeading`)});
    }

    private getCoverPlanCoverDescriptionList(coverPlanId: string, coverPlan: LegacyCoverPlan | CoverPlan, cmsContext: ICmsContext): string {
        if (coverPlan?.included && this.props.intl.messages[`${CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.list.included`)}`]) {
            return this.props.intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.list.included`)});
        }

        return this.props.intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.list`)});
    }

}

const SelectionCoverageCardsV2 = flowRight(injectIntl, injectSheet(jssStyles, {inject: ["classes"]} as any))(SelectionCoverageCardsV2Internal);
export {SelectionCoverageCardsV2};
