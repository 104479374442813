import * as React from "react";
import { FormattedMessage } from "react-intl";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import { Button, Modal, defaultTheme } from "@folksam-digital/ui";
import { createUseStyles } from "react-jss";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";

const useStyles = createUseStyles(() => ({
    container: {
        textAlign: "center",
        marginBottom: defaultTheme.padding.sm
    }
}));

export const SigningProgressModal: React.FC<any> = ({ signingAlreadyInProgress, onClose, onSubmit, cmsContext }: any): React.ReactElement => {
    const classes = useStyles();

    return (
        <Modal
            isOpen={signingAlreadyInProgress}
            showCloseButton={true}
            onRequestClose={onClose}
        >
            <Modal.Header onModalClose={onClose}>
                <div className={classes.container}>
                    <FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, "form.modal.signingInProgress.title")}/>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.container}>
                    <Button onClick={onSubmit}>
                        <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "form.modal.tryAgain")}/>
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}
