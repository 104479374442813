import * as React from "react";
import {injectIntl} from "react-intl";
import {
    AddonAccordion as AddonAccordionFUI,
} from "@folksam-digital/ui";

import {InputComponentBase} from "./InputComponentBase";
import {IBreakPoint} from "../../layout/helpers";
import {Template} from "../output/helpers";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IMetadata {
    coverId: number;
    addOnId: string;
    prefix: string;
    breakpoints?: IBreakPoint;
    definitionPart1: string;
    definitionPart2: string;
}

class AddonAccordionBodyInternal extends InputComponentBase<boolean | string, IMetadata, {}> {

    public onModalOpen = () => {
        this.props.onChange(this.props.uiSchema.model ? true : "");
    };

    render() {
        const {intl} = this.props;

        const { definitionPart1, definitionPart2 } = this.metadata;

        return (
            <AddonAccordionFUI.Body
                valueText={<Template template={definitionPart1} data={this.context.data}/>}
                value={<Template template={definitionPart2} data={this.context.data}/>}
                onClick={this.onModalOpen}
                editButtonText={intl.formatMessage({id: CmsHelper.withGeneralPrefix(`accordionPanel.button.edit`)})}
            />
        );
    }

}

const AddonAccordionBody = injectIntl(AddonAccordionBodyInternal);
export { AddonAccordionBody };
