import React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import clone from "lodash/clone";
import {PolicySearchResult} from "@folksam-digital/model";
import {ICoverOptions} from "@folksam-digital/model/lib/policy/PolicySearchResult";
import {CmsContext, ICmsContext} from "../../../../cms";
import {List, AddonAccordion, Grid, Spacing} from "@folksam-digital/ui";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ISelectFieldConfig} from "../../../../../types/@folksam-digital/ui/lib/ProductCard";

export interface IProductSelectionCardProps {
    offer: PolicySearchResult,
    selectedCoverPlanId?: number,
    intl: IntlShape
    index: number,
    childIndex?: number,
    uniqueIndex: string,
    onChange: (offer: PolicySearchResult, index: number, childIndex?: number) => void
}

export class ProductSelectionCard extends React.Component<IProductSelectionCardProps> {
    public static contextType = CmsContext;

    constructor(props: IProductSelectionCardProps) {
        super(props);

        this.renderCoverPlanOption = this.renderCoverPlanOption.bind(this);
        this.onCoverPlanChange = this.onCoverPlanChange.bind(this);
        this.onProductCardChange = this.onProductCardChange.bind(this);
        this.getSelectFieldConfig = this.getSelectFieldConfig.bind(this);
        this.renderPriceNode = this.renderPriceNode.bind(this);
    }

    public render(): React.ReactNode {
        const {offer} = this.props;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    return(
                        <AddonAccordion
                            heading={offer.productTitle}
                            checked={!!offer.selected}
                            description={offer.assetDescription}
                            id={offer?.id}
                        >
                            <Grid>
                                <Grid.Col>
                                    <Grid.Row>{ this.renderCoverTextList(offer) }</Grid.Row>
                                </Grid.Col>
                            </Grid>
                            <AddonAccordion.Footer
                                price={this.renderPriceNode(offer)}
                                checkedToggle={this.onProductCardChange}
                                checked={offer.selected}
                                buttonText={{
                                evaluate: <FormattedMessage
                                    id={"general.button.product.evaluate.text"}/>,
                                add: <FormattedMessage
                                    id={"general.button.product.add.text"}/>,
                                added: <FormattedMessage
                                    id={"general.button.product.added.text"}/>,
                                disabled: <FormattedMessage
                                    id={"general.button.product.add.text"}/>,
                            }} />
                        </AddonAccordion>
                    )
                }}
            </CmsContext.Consumer>
        );
    }

    private getSelectFieldConfig(offer: PolicySearchResult): ISelectFieldConfig | null {
        if (!!offer?.coverOptions && !!offer?.productTitle) {
            const {selectedCoverPlanId} = this.props;

            const componentOptions = this.renderCoverPlanOption(offer?.coverOptions);
            const selectedCoverOption = selectedCoverPlanId ? componentOptions?.find(option => option.value === selectedCoverPlanId) : componentOptions[0];

            return {
                id: `covers-${offer.productId}`,
                value: `ProductCard-${this.props.uniqueIndex}`,
                label: <FormattedMessage id={`groupInsurance.common.policyList.item.productSelect.title`}/>,
                title: offer?.productTitle,
                selected: selectedCoverOption!,
                onChange: this.onCoverPlanChange,
                options: componentOptions
            }
        } else {
            return null;
        }
    }

    private renderPriceNode(offer: PolicySearchResult) {
        const {intl} = this.props;
        const monthlyPremium = intl.formatNumber(Math.round(offer?.monthlyPremium || 0));
        return <FormattedMessage id={CmsHelper.withGroupCommonPrefix(`price.monthly`)} values={{monthlyPremium}}/>
    };

    private onProductCardChange(event: any) {

        const modifiedOffer = clone(this.props.offer);
        modifiedOffer.selected = !modifiedOffer.selected;
        this.props.onChange(modifiedOffer, this.props.index, this.props?.childIndex);
    }

    private renderCoverTextList(offer: PolicySearchResult) {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Col xs>
                        <Spacing type={"padding"} left={"sm"} right={"sm"} bottom={"sm"}>
                            <List check={true} list={offer?.coverTextList}/>
                        </Spacing>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        )
    }

    private onCoverPlanChange(event: any) {
        const modifiedOffer = clone(this.props.offer);
        modifiedOffer.selectedCoverPlanId = event.value;
        this.props.onChange(modifiedOffer, this.props.index, this.props?.childIndex);
    }

    private renderCoverPlanOption(options: ICoverOptions[]) {
        const {intl} = this.props;
        return options.map(option => {
            let label = "";
            let value;
            if (option?.insuranceAmount && option?.numberOfUnits && option?.pbbUnitValue) {
                label = "insuranceAmount.unitsWithPbbUnitValue";
                value = {
                    amount: intl.formatNumber(option.insuranceAmount),
                    numberOfUnits: option.numberOfUnits
                }
            } else if (option?.insuranceAmount) {
                label = "insuranceAmount.simple";
                value = {
                    amount: intl.formatNumber(option.insuranceAmount)
                }
            } else if (option?.numberOfUnits) {
                label = "insuranceAmount.units";
                value = {
                    numberOfUnits: option.numberOfUnits
                }
            }
            return {
                label: <FormattedMessage id={CmsHelper.withGroupCommonPrefix(label)}
                                         values={value}/>,
                value: option.id
            };
        });
    }
}
