import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {IUpptecService} from "../../IUpptecService";
import {injectable} from "inversify";
import {
    UpptecClaimCreateRequest,
    UpptecClaimCreateWorkflowResponse, UpptecClaimRequestObj, UpptecClaimResponse, UpptecLookupWorkflowRequest,
    UpptecLookupWorkflowResponse,
    UpptecSuggestion
} from "@folksam-digital/model";
import {Workflow} from "./Workflow";
import {JourneyHelper} from "@folksam-digital/services";

@injectable()
export class UpptecServiceImpl extends WorkflowServiceBase implements IUpptecService {
    public async createClaim(data: UpptecClaimRequestObj, claimId: string): Promise<UpptecClaimResponse> {
        const upptecResponse = await super.execute<UpptecClaimCreateRequest, UpptecClaimCreateWorkflowResponse>(Workflow.forClaim(JourneyHelper.getObfuscatedJourneyId(claimId)).UpptecCreateClaim, {request: data});

        return upptecResponse.claimResponse!;
    }

    public async lookup(queryString: string, claimId: string): Promise<UpptecSuggestion[]> {
        const upptecResponse = await super.execute<UpptecLookupWorkflowRequest, UpptecLookupWorkflowResponse>(Workflow.forClaim(JourneyHelper.getObfuscatedJourneyId(claimId)).UpptecLookup, {queryString});
        return upptecResponse?.suggestions || [];
    }
}
