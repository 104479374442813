import * as React from "react";
import {FormInputRadioButton, QuestionSkeleton} from "@folksam-digital/ui";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {IFormComponentProps} from "../FormComponentBase";
import {withLocaleContext} from "../withLocaleContext";
import {ChoiceComponentBase, IChoiceComponentMetadataBase, IChoiceComponentStateBase} from "./ChoiceComponentBase";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {IBreakPoint} from "../../layout/helpers";
import castArray from "lodash/castArray";
import sanitizeField from "./helpers/sanitizeField";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import isObject from "lodash/isObject";
import {IOptionSelected} from "@folksam-digital/model/lib/utils/IOption";
import {IMetadata} from "../output/helpers";

export interface IRadioGroupMetadata extends IChoiceComponentMetadataBase {
    classNames?: string;
    breakpoints?: IBreakPoint;
    bordered?: boolean;
    checkDisabledForm?: boolean;
}

export interface IRadioGroupProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
    helpStyle?: object,
    titleTheme?: 1 | 2,
    messageValuesWithTranslations: IMetadata
}

export type IFormData = number|IOptionSelected;

class RadioGroupInternal extends ChoiceComponentBase<IFormData, IRadioGroupMetadata, IChoiceComponentStateBase, IRadioGroupProps<IFormData, IRadioGroupMetadata>> {
    constructor(props: IRadioGroupProps<IFormData, IRadioGroupMetadata>) {
        super(props);

        this.state = { };
    }

    public render() {
        const {
            name,
            formData
        } = this.props;

        const { breakpoints, bordered, checkDisabledForm } = this.metadata;
        const { values } = this.state;
        const {disableButton} = this.context;

        if(values) {
            return (
                <FormFieldGroupLayout {...this.getLayoutProps()} messageValuesWithTranslations={this.props.messageValuesWithTranslations} breakpoints={getBreakpoints(defaultBreakpoints.radioGroup, breakpoints)}>
                    {values.map((option) => (
                        <div key={option.id}>
                            <FormInputRadioButton
                                id={`${name}-${option.id}`}
                                name={name}
                                ariaLabelledBy={`${name}-${option.id}-label ${name}-label`}
                                label={option.title}
                                value={option.id}
                                onChange={() => this.onChange(option.id, option?.title)}
                                checked={formData && isObject(formData) ? formData?.value === option?.id : formData && formData?.toString() === option.id}
                                invalid={this.isInvalid()}
                                bordered={bordered}
                                tooltip={option.tooltip}
                                disabled={checkDisabledForm && disableButton}
                            />
                        </div>
                    ))}
                </FormFieldGroupLayout>
            );
        }
        else {
            return (
                <div>
                    <QuestionSkeleton />
                </div>
            );
        }
    }

    onChange(value: string | number, title?: string) {
        const types = castArray(this.schema.type);
        if (types.includes("string")) {
            this.onChangeWithValidation(sanitizeField(value.toString()));
        } else if (types.includes("object")) {
            this.onChangeWithValidation({label: title, value});
        } else {
            this.onChangeWithValidation(Number(value));
        }
    }
}

const RadioGroup = injectIntl(withLocaleContext<IRadioGroupProps<IFormData, IRadioGroupMetadata>>(RadioGroupInternal));
export {RadioGroup};
