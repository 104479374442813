import * as React from "react";
import {DescriptionList, DisplayIcon, Spacing} from "@folksam-digital/ui"
import {FormattedDate, FormattedMessage} from "react-intl";

interface ISuccessPageHeaderTableElementProps {
    icon: React.ReactElement,
    termId: string,
    text?: string,
    date?: Date | string
}

export class SuccessHeaderTableElement extends React.Component<ISuccessPageHeaderTableElementProps> {
    public render() {
        return (
            <DescriptionList.Row>
                <DescriptionList.Icon>
                    <Spacing left={'sm'}>
                        <DisplayIcon baseline large
                                     icon={this.props.icon}/>
                    </Spacing>
                </DescriptionList.Icon>
                <DescriptionList.Content>
                    <DescriptionList.Term>
                        <FormattedMessage id={this.props.termId}/>
                    </DescriptionList.Term>
                    {this.props.text && (<DescriptionList.Definition>
                        {this.props.text}
                    </DescriptionList.Definition>)}
                    {this.props.date && (<DescriptionList.Definition>
                        <FormattedDate value={this.props.date}/>
                    </DescriptionList.Definition>)}
                </DescriptionList.Content>
            </DescriptionList.Row>
        )
    }
}
