import * as React from "react";
import {FormattedMessage} from "react-intl";
import {createUseStyles} from "react-jss";
import classNames from "classnames";
import {B, Badge, defaultTheme, DescriptionListResponsive, IconPersonNew, DisplayIcon, DescriptionList} from "@folksam-digital/ui";
import {Contact, DeepPartial} from "@folksam-digital/model";
import {SsnFormatter} from "@folksam-digital/services";

interface IContactData extends Contact {
    rowNumber?: number;
}

interface IFetchedContactProps {
    key: string;
    contact: DeepPartial<IContactData>;
    removeButton: JSX.Element;
    title?: string;
}

const useStyles = createUseStyles(() => ({
    info: {
        backgroundColor: `${defaultTheme.colors.quanternary5} !important`,
    },
    primary: {
        backgroundColor: defaultTheme.colors.white,
        borderRadius: defaultTheme.borderRadius.xl,
        borderWidth: defaultTheme.borderWidths.default,
        borderColor: defaultTheme.colors.transparent,
        boxShadow: defaultTheme.shadows.xs
    },
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: defaultTheme.fonts.serif,
        paddingTop: (contact: DeepPartial<IContactData>) => contact?.firstName === "" && contact?.lastName === "" && defaultTheme.padding['4'],
        paddingRight: defaultTheme.padding['5'],
        paddingBottom: (contact: DeepPartial<IContactData>) => contact?.firstName === "" && contact?.lastName === "" && defaultTheme.padding['4'],
        paddingLeft: defaultTheme.padding['5'],
        borderBottom: 0
    },
    textBlock: {
        lineHeight: '30px',
    }
}));

export const FetchedContact = React.memo(({
                                              contact,
                                              removeButton
                                          }: IFetchedContactProps) => {
    let contactTitle: string | undefined = contact.firstName && contact.lastName && ` ${contact.firstName} ${contact.lastName} `;
    const companyTitle: string | undefined = contact.companyName && ` ${contact.companyName} `;
    const classes = useStyles({contact});
    const noFirstLastName = contact?.firstName === "" && contact?.lastName === "";
    const cn = classNames(classes.root, {
        [classes.info]: noFirstLastName,
        [classes.primary]: !noFirstLastName,
    });

    const formatSingleOrMultipleContactTitle = () => {
        if (contact?.rowNumber) {
            contactTitle = ` ${contact?.rowNumber}.${contactTitle}`;
        }
        const descriptionListTerm =
            <DescriptionList.Term>{SsnFormatter.formatWithoutDash(contact?.ssn)}</DescriptionList.Term>;
        return (
            <>
                {contactTitle ?
                    <>
                        <DescriptionList.Definition>{contactTitle}</DescriptionList.Definition>
                        {descriptionListTerm}
                    </>
                    : companyTitle ?
                        <>
                            <DescriptionList.Definition>{companyTitle}</DescriptionList.Definition>
                            {descriptionListTerm}
                        </> : descriptionListTerm}


            </>
        )
    };


    return (
        <div className={cn}>
            {
                noFirstLastName ?
                    <DescriptionListResponsive.Term>
                        <div style={{display: "flex"}}>
                            <div style={{height: "30px", alignSelf: "center"}}>
                                <Badge style={{alignSelf: "center", height: "fit-content"}} text={"i"} color={"blue1"}
                                       className={"informationBox-icon"}/>
                            </div>
                            <B style={{color: defaultTheme.colors.primary1}} className={classes.textBlock}>
                                <FormattedMessage
                                    id={"general.personSsnInput.protectedPerson.notification"}/>
                            </B>
                        </div>
                    </DescriptionListResponsive.Term>
                    :
                    <DescriptionList>
                        <DescriptionList.Row>
                            <DescriptionList.Icon>
                                <DisplayIcon baseline large icon={<IconPersonNew/>}/>
                            </DescriptionList.Icon>
                            <DescriptionList.Content>
                                {formatSingleOrMultipleContactTitle()}
                            </DescriptionList.Content>
                        </DescriptionList.Row>

                    </DescriptionList>
            }
            <DescriptionListResponsive.Definition>
                {removeButton}
            </DescriptionListResponsive.Definition>
        </div>
    );
});
