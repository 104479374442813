import * as React from "react";
import {memo, useContext} from "react";
import {FormattedMessage} from "react-intl";
import get from "lodash/get";
import merge from "lodash/merge";
import {Cover} from "@folksam-digital/model";
import {Grid, B} from "@folksam-digital/ui";
import {componentStyles} from "../../../../common/styles/componentStyles";
import {FormContext} from "../../FormContext";

export enum PriceBlockThemes {
    Grey,
    Transparent
}

interface IPriceBlockProps {
    addOnId: string;
    coverData: Cover;
    model?: string;
    prefix: string;
    recalculatedPrice?: boolean;
    theme?: PriceBlockThemes;
}

export const PriceBlock = memo((props: IPriceBlockProps) => {
    const contextData = useContext(FormContext);

    const { coverData, model, addOnId, recalculatedPrice, theme, prefix } = props;

    const monthlyPremium = Math.round(coverData.monthlyPremium!);
    const yearlyPremium = Math.round(coverData.yearlyPremium!);

    let values;
    if (model) {
        values = get(contextData.data, model);
    }

    const themeStyle = getThemeStyle(theme);
    const messageKey = recalculatedPrice ? `${prefix}.goodSupplementaryInsurance.${addOnId}.priceRecalculated` : `${prefix}.goodSupplementaryInsurance.${addOnId}.price`;

    return (
        <>
            <Grid.Row>
                <Grid.Col lg={12} md={9} sm={12}>
                    <div style={merge({}, componentStyles.priceBox.container, themeStyle)}>
                        <B>
                            <span style={componentStyles.priceBox.text}>
                                <FormattedMessage id={messageKey} values={{ ...values, monthlyPremium, yearlyPremium }}/>
                            </span>
                        </B>
                    </div>
                </Grid.Col>
            </Grid.Row>
        </>
    );
});

function getThemeStyle(theme?: PriceBlockThemes) {
    if (theme !== undefined) {
        if (theme === PriceBlockThemes.Transparent) {
            return componentStyles.priceBox.containerTransparent;
        } else if (theme === PriceBlockThemes.Grey) {
            return componentStyles.priceBox.containerGrey;
        }
    } else {
        return componentStyles.priceBox.containerTransparent;
    }
}
