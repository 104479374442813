import * as React from "react";
import {GroupOptInOptOut, PolicySearchResult} from "@folksam-digital/model/lib";
import {FormComponentBase, IFormComponentProps} from "../../FormComponentBase";
import {CmsContext, ICmsContext} from "../../../../../cms";
import {FormContext, IFormContext} from "../../FormContext";
import {isMemberPolicy, getMemberPolicies, getPartnerPolicies} from "../../../../optInOptOut/form/container/helpers";
import {UrlHelper} from "@folksam-digital/services";
import {Constants} from "@folksam-digital/model";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";
import {Slide} from "./Slide";
import {
    Carousel,
    IconHeart,
    UmbrellaIconRed,
} from "@folksam-digital/ui";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";
import {IMetadata} from "../../../../optInOptOut/form/container/OptOutContainer";



class OptInOptOutCarouselContainerInternal extends FormComponentBase <any, any, {}> {

    constructor(props: IFormComponentProps<any, IMetadata>, context: ICmsContext) {
        super(props, context);

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    private filterDuplicateMeberAndPartnerPolicies(allDeclinedPolicies: any) {
        const result = [];
        let member: number = 0;
        let partner: number = 0;

        for (const policy of allDeclinedPolicies) {
            if (isMemberPolicy(policy.associatedInsTypeId) && member < 1) {
                result.push(policy)
                member += 1;
            }
            if (!isMemberPolicy(policy.associatedInsTypeId) && partner < 1) {
                result.push(policy)
                partner += 1;
            }
        }
        return result;
    }


    public onClickHandler (policies:  {memberOptOutPolicies: PolicySearchResult[], partnerOptOutPolicies: PolicySearchResult[]}, currentSlide: 'member'|'partner') {
        const {history} = this.props;
        const { memberOptOutPolicies, partnerOptOutPolicies } = policies;

        if (currentSlide === 'member' && memberOptOutPolicies.length) {
             history.push(UrlHelper.getGroupJourneyUrl(Constants.Journey.OptInOptOut.Id, Constants.Journey.OptInOptOut.Steps.receivedOffersToMember));
        } else if (currentSlide === 'partner' && partnerOptOutPolicies.length) {
            history.push(UrlHelper.getGroupJourneyUrl(Constants.Journey.OptInOptOut.Id, Constants.Journey.OptInOptOut.Steps.receivedOffersToMember));
        } else {
            history.push(UrlHelper.getGroupJourneyUrl(Constants.Journey.OptInOptOut.Id, Constants.Journey.OptInOptOut.Steps.addThisAsNewMember));
        }
    }

    public render() {
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <FormContext.Consumer>
                        {(context: IFormContext) => {
                            const data: GroupOptInOptOut = context.data;

                            const {intl} = this.props;
                            const {slideText: {member, partner}} = this.metadata;

                            const optIn = data?.optIn || [];
                            const optOut = data?.optOut || [];

                            const allDeclinedPolicies = optIn.concat(optOut).filter(policy => policy.selected === false);
                            const uniquePolicies = this.filterDuplicateMeberAndPartnerPolicies(allDeclinedPolicies);

                            const memberOptOutPolicies: PolicySearchResult[] = getMemberPolicies(optOut).filter(policy => policy.selected === false);
                            const partnerOptOutPolicies: PolicySearchResult[] = getPartnerPolicies(optOut).filter(policy => policy.selected === false);

                            return (
                                <>
                                    {uniquePolicies?.length > 0 &&
                                        <Carousel numberOfItems={uniquePolicies?.length} gutter={10} peek={3} themeId={2}>
                                            {uniquePolicies.map((slide: any, index: number) =>
                                                <Carousel.Item
                                                    key={index}
                                                    themeId={2}
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => this.onClickHandler({memberOptOutPolicies, partnerOptOutPolicies}, isMemberPolicy(slide.associatedInsTypeId) ? 'member' : 'partner')}
                                                >
                                                    <Slide
                                                        term={isMemberPolicy(slide.associatedInsTypeId) ?
                                                              intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, member.term)})
                                                              :
                                                              intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, partner.term)})
                                                              }
                                                        definition={isMemberPolicy(slide.associatedInsTypeId) ?
                                                             intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, member.definition)})
                                                             :
                                                             intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, partner.definition)})
                                                             }
                                                        icon={isMemberPolicy(slide.associatedInsTypeId) ? <UmbrellaIconRed/> : <IconHeart fill={'#E6007E'} color={'#E6007E'}/>}
                                                    />
                                                </Carousel.Item>
                                            )}
                                        </Carousel>
                                    }
                                </>
                            )
                        }}
                    </FormContext.Consumer>
                )}
            </CmsContext.Consumer>
        );
    }
}

export const OptInOptOutCarousel = withRouter(injectIntl(OptInOptOutCarouselContainerInternal));


