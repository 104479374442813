import * as React from "react";

export const BankId = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 560 560"
        version="1.1"
        id="svg5384"
    >
            <metadata
                id="metadata5390" />
            <defs
                id="defs5388" />
            <path
                d="M 0,0 H 560 V 560 H 0 Z"
                id="path5366"
                fill="none"
                strokeWidth="0.78876239" />
            <circle
                cx="280"
                cy="280"
                id="ellipse5368"
                fill="#ffffff"
                stroke="#ff0000"
                strokeWidth="0"
                r="280" />
            <path
                d="m 227.37047,279.69507 13.17021,-83.01224 H 226.4725 c -6.5851,0 -15.06592,-3.69165 -17.56028,-10.4763 -0.79819,-2.29481 -2.69391,-10.17698 8.1815,-17.85961 3.89119,-2.6939 6.38555,-5.68713 6.88442,-7.98194 0.49888,-2.39459 -0.0998,-4.48985 -1.79593,-6.08624 -2.39459,-2.29481 -7.08398,-3.59187 -13.07044,-3.59187 -10.07721,0 -17.16118,5.78691 -17.8596,9.97743 -0.49887,3.093 1.89571,5.58736 3.99097,7.18375 6.28578,4.6894 7.7824,11.47405 3.8912,17.85961 -3.99097,6.5851 -12.67134,10.8754 -21.95035,10.97517 h -14.3675 c -1.1973,8.08172 -20.75306,132.00142 -22.24968,141.77931 h 77.92375 c 0.69842,-4.39007 4.29029,-27.83703 9.17923,-58.76707 z"
                id="path5370"
                fill="#479cbe"
                strokeWidth="0.99774319"
            />
            <path
                d="m 331.73441,124.04713 h -79.32058 l -10.57608,67.14811 h 13.46953 c 7.3833,0 14.3675,-3.39232 17.36073,-8.28126 0.99775,-1.59639 1.39684,-2.99324 1.39684,-4.2903 0,-2.79368 -1.89571,-4.88894 -3.79142,-6.28578 -5.18827,-3.8912 -6.28578,-7.98195 -6.28578,-10.8754 0,-0.59865 0,-1.09752 0.0998,-1.59639 1.09752,-7.08398 10.67585,-14.7666 23.34719,-14.7666 7.58285,0 13.36976,1.79594 16.86186,5.08849 3.09301,2.89345 4.2903,6.9842 3.39233,11.2745 -1.09752,5.08849 -6.18601,9.27901 -9.07946,11.37427 -7.68263,5.38781 -6.68488,10.07721 -6.18601,11.47405 1.59639,4.19052 7.68262,6.88442 12.37201,6.88442 h 20.45374 v 0.0998 c 27.93681,0.19955 42.90296,13.07044 38.21356,43.00274 -4.39007,27.83703 -25.74177,39.80995 -51.18422,40.0095 l -10.07721,64.25466 h 14.86638 c 62.75804,0 114.04204,-40.30883 124.11925,-103.96484 12.47179,-79.02126 -37.61492,-110.54995 -109.45243,-110.54995 z"
                id="path5372"
                fill="#00a5c3"
                strokeWidth="0.99774319"
            />
            <path
                d="m 331.73441,124.04713 h -79.32058 l -10.57608,67.14811 h 13.46953 c 7.3833,0 14.3675,-3.39232 17.36073,-8.28126 0.99775,-1.59639 1.39684,-2.99324 1.39684,-4.2903 0,-2.79368 -1.89571,-4.88894 -3.79142,-6.28578 -5.18827,-3.8912 -6.28578,-7.98195 -6.28578,-10.8754 0,-0.59865 0,-1.09752 0.0998,-1.59639 1.09752,-7.08398 10.67585,-14.7666 23.34719,-14.7666 7.58285,0 13.36976,1.79594 16.86186,5.08849 3.09301,2.89345 4.2903,6.9842 3.39233,11.2745 -1.09752,5.08849 -6.18601,9.27901 -9.07946,11.37427 -7.68263,5.38781 -6.68488,10.07721 -6.18601,11.47405 1.59639,4.19052 7.68262,6.88442 12.37201,6.88442 h 20.45374 v 0.0998 c 27.93681,0.19955 42.90296,13.07044 38.21356,43.00274 -4.39007,27.83703 -25.74177,39.80995 -51.18422,40.0095 l -10.07721,64.25466 h 14.86638 c 62.75804,0 114.04204,-40.30883 124.11925,-103.96484 12.47179,-79.02126 -37.61492,-110.54995 -109.45243,-110.54995 z"
                id="path5374"
                fill="#235971"
                strokeWidth="0.99774319"
            />
            <g
                id="g5378"
                transform="matrix(0.99774321,0,0,0.99774321,-13.983613,-142.94896)"
                fill="#235971">
                    <path
                        d="m 150.7,511.2 h 31.9 c 13.6,0 16.9,6.9 15.9,13.2 -0.8,5.1 -4.3,8.9 -10.3,11.4 7.6,2.9 10.6,7.4 9.5,14.5 -1.4,8.9 -9.1,15.5 -19.2,15.5 h -36.3 z m 21.1,22.6 c 6.2,0 9.1,-3.3 9.7,-7.2 0.6,-4.2 -1.3,-7.1 -7.5,-7.1 h -5.5 l -2.2,14.3 z m -3.4,23.6 c 6.4,0 10.1,-2.6 11,-7.9 0.7,-4.6 -1.9,-7.3 -8.1,-7.3 H 165 l -2.4,15.3 h 5.8 z m 74,8.8 c -8.3,0.6 -12.3,-0.3 -14.3,-3.9 -4.4,2.7 -9.3,4.1 -14.5,4.1 -9.4,0 -12.7,-4.9 -11.8,-10.3 0.4,-2.6 1.9,-5.1 4.3,-7.2 5.2,-4.5 18,-5.1 23,-8.5 0.4,-3.8 -1.1,-5.2 -5.8,-5.2 -5.5,0 -10.1,1.8 -18,7.2 l 1.9,-12.4 c 6.8,-4.9 13.4,-7.2 21,-7.2 9.7,0 18.3,4 16.7,14.6 l -1.9,12 c -0.7,4.2 -0.5,5.5 4.2,5.6 z M 228,547.4 c -4.4,2.8 -12.6,2.3 -13.5,8.1 -0.4,2.7 1.3,4.7 4,4.7 2.6,0 5.8,-1.1 8.4,-2.9 -0.2,-1 -0.1,-2 0.2,-3.9 z m 29.9,-23.9 h 16.6 l -0.9,5.5 c 5.3,-4.5 9.3,-6.2 14.5,-6.2 9.3,0 13.6,5.7 12.1,15 l -4.3,27.9 h -16.6 l 3.6,-23.1 c 0.7,-4.2 -0.6,-6.2 -3.8,-6.2 -2.6,0 -5,1.4 -7.3,4.5 l -3.8,24.7 h -16.6 z m 55.2,-12.3 h 16.6 l -4.2,26.8 15.9,-14.5 h 20.5 l -20.4,18 16.4,24.2 H 337 l -12.6,-19.5 h -0.2 l -3,19.5 h -16.6 z"
                        id="path5376"
                    />
            </g>
            <g
                id="g5382"
                transform="matrix(0.99774321,0,0,0.99774321,-13.983613,-142.94896)"
                fill="#479cbe">
                    <path
                        d="M 371.9,511.2 H 391 l -8.4,54.5 h -19.1 z m 28.4,0 h 27.3 c 21.1,0 27.2,15.3 25.2,28 -1.9,12.4 -11.7,26.5 -30.2,26.5 h -30.8 z m 17.7,41.5 c 9.3,0 14.4,-4.6 15.9,-14.3 1.1,-7.2 -1.1,-14.3 -11.4,-14.3 h -5.1 l -4.4,28.6 z"
                        id="path5380"
                    />
            </g>
    </svg>
);