import {
    GetGroupMemberPreferencesRequest,
    GetGroupMemberPreferencesResponse,
    GroupOptInOptOut,
    PolicyAndProposalSearchResponse,
    GetOfferedExistingPoliciesRequest,
    IDigitalGroupDetails,
    DigitalGetGroupDetailsResponse,
    DigitalGetGroupDetailsRequest
} from "@folksam-digital/model";
import {injectable} from "inversify";
import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";
import {IOptInOptOutService} from "../../IOptInOptOutService";

@injectable()
export class OptInOptOutService extends WorkflowServiceBase implements IOptInOptOutService {

    public async getOfferedPolicies(
        payload: GetOfferedExistingPoliciesRequest
    ): Promise<PolicyAndProposalSearchResponse> {
        const data = await super.execute<any, any>(
            Workflow.Group.OptInOptOutGetOfferedPolicies,
            payload
        );

        return data;
    }

    public async createProducts(payload: GroupOptInOptOut): Promise<GroupOptInOptOut | undefined> {
        const data = await super.execute<any, any>(
            Workflow.Group.OptInOptOutCreateProducts,
            payload
        ) as GroupOptInOptOut | undefined;

        return data;
    }

    public async getGroupMemberPreferences(payload: GetGroupMemberPreferencesRequest): Promise<GetGroupMemberPreferencesResponse> {
        const data = await super.execute<any, any>(
            Workflow.Group.GetGroupMemberPreferences,
            payload
        );

        return data;
    }

    public async premiumCalculate(payload: GroupOptInOptOut) {
        return await super.execute<GroupOptInOptOut, GroupOptInOptOut>(
            Workflow.Group.OptInOptOutPremiumCalculate,
            payload
        );
    }

    public async getGroupDetails(groupId: string, journeyId: string): Promise<IDigitalGroupDetails> {
        const groupData: DigitalGetGroupDetailsResponse = await super.execute<DigitalGetGroupDetailsRequest, DigitalGetGroupDetailsResponse>(
            Workflow.Group.GetAuthorizedGroupDetails,
            {groupId, journeyId}
        );

        return groupData.groupDetails;
    }
}
