import {phoneNumberFormatter} from "@folksam-digital/ui";

export class PhoneNumberFormatter {
    public static removeSpecialChars(phoneNumber: string): string {
        if (!phoneNumber) {
            return phoneNumber;
        }

        return phoneNumber.replace(/\D/g, "");
    }

    public static formatPhoneNumber(phoneNumber?: string): string {
        if (!phoneNumber) {
            return "";
        }

        return phoneNumberFormatter(phoneNumber);
    }
}
