import JourneyType from "@folksam-digital/model/lib/constants/JourneyType";
import {Constants} from "@folksam-digital/model";
import {IRoute} from "../../../IRoute";
import AccidentClaimSuccess from "./AccidentClaimSuccess";
import IllnessClaimSuccess from "./IllnessClaimSuccess";
import PregnancyComplicationsClaimSuccess from "./PregnancyComplicationsClaimSuccess";
import MobilePhoneClaimSuccess from "./MobilePhoneClaimSuccess";
import DelayedLuggageClaimSuccess from "./DelayedLuggageClaimSuccess";
import FireClaimSuccess from "./FireClaimSuccess";
import FireInVacationHomeClaimSuccess from "./FireInVacationHomeClaimSuccess";
import TheftClaimSuccess from "./TheftClaimSuccess";
import TheftInVacationHomeClaimSuccess from "./TheftInVacationHomeClaimSuccess";
import GlassClaimSuccess from "./GlassClaimSuccess";
import BurglaryClaimSuccess from "./BurglaryClaimSuccess";
import BurglaryInVacationHomeClaimSuccess from "./BurglaryInVacationHomeClaimSuccess";
import StormClaimSuccess from "./StormClaimSuccess";
import NatureClaimSuccess from "./NatureClaimSuccess";
import NatureInVacationHomeClaimSuccess from "./NatureInVacationHomeClaimSuccess";
import WaterClaimSuccess from "./WaterClaimSuccess";
import WaterInVacationHomeClaimSuccess from "./WaterInVacationHomeClaimSuccess";
import BicycleClaimSuccess from "./BicycleClaimSuccess";
import InstalledHeaterClaimSuccess from "./InstalledHeaterClaimSuccess";
import InstalledHeaterInVacationHomeClaimSuccess from "./InstalledHeaterInVacationHomeClaimSuccess";
import HouseholdAppliancesClaimSuccess from "./HouseholdAppliancesClaimSuccess";
import HouseholdAppliancesInVacationHomeClaimSuccess from "./HouseholdAppliancesInVacationHomeClaimSuccess";
import OtherDamageOrLossClaimSuccess from "./OtherDamageOrLossClaimSuccess";
import IncapacityClaimSuccess from "./IncapacityClaimSuccess";
import IllnessDuringTravelClaimSuccess from "./IllnessDuringTravelClaimSuccess";
import AccidentDuringTravelClaimSuccess from "./AccidentDuringTravelClaimSuccess";
import CancelledTravelClaimSuccess from "./CancelledTravelClaimSuccess";
import DelayedTravelerClaimSuccess from "./DelayedTravelerClaimSuccess";
import VehicleCollisionClaimSuccess from "./VehicleCollisionClaimSuccess";
import VehicleCollisionWithAnimalClaimSuccess from "./VehicleCollisionWithAnimalClaimSuccess";
import VehicleSingleAccidentClaimSuccess from "./VehicleSingleAccidentClaimSuccess";
import VehicleHitAndRunClaimSuccess from "./VehicleHitAndRunAccidentClaimSuccess";
import VehicleVandalismClaimSuccess from "./VehicleVandalismClaimSuccess";
import VehicleTheftOrBurglaryClaimSuccess from "./VehicleTheftOrBurglaryClaimSuccess";
import VehicleFireClaimSuccess from "./VehicleFireClaimSuccess";
import VehicleEngineDamageClaimSuccess from "./VehicleEngineDamageClaimSuccess";
import VehicleLossOfKeyClaimSuccess from "./VehicleLossOfKeyClaimSuccess";
import VehicleMisfuellingClaimSuccess from "./VehicleMisfuellingClaimSuccess";
import VehicleInteriorDamageClaimSuccess from "./VehicleInteriorDamageClaimSuccess";
import DogClaimSuccess from "./DogClaimSuccess";
import CatClaimSuccess from "./CatClaimSuccess";
import AccidentSpareTimeClaimSuccess from "./AccidentSpareTimeClaimSuccess";
import GlassInVacationHomeClaimSuccess from "./GlassInVacationHomeClaimSuccess";
import StormInVacationHomeClaimSuccess from "./StormInVacationHomeClaimSuccess";

const ClaimJourneySuccessRoutes: IRoute[] = [
    {
        path: `${JourneyType.ClaimProtected}/burglary`,
        component: BurglaryClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "burglary"
    },
    {
        path: `${JourneyType.ClaimProtected}/burglaryInVacationHome`,
        component: BurglaryInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "burglaryInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.PersonalAccident}`,
        component: AccidentClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "personalAccident"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.Illness}`,
        component: IllnessClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "illness"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.PregnancyComplications}`,
        component: PregnancyComplicationsClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "pregnancyComplications"
    },
    {
        path: `${JourneyType.ClaimProtected}/mobilePhone`,
        component: MobilePhoneClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "mobilePhone"
    },
    {
        path: `${JourneyType.ClaimProtected}/delayedLuggage`,
        component: DelayedLuggageClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "delayedLuggage"
    },
    {
        path: `${JourneyType.ClaimProtected}/fire`,
        component: FireClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "fire"
    },
    {
        path: `${JourneyType.ClaimProtected}/fireInVacationHome`,
        component: FireInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "fireInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/theft`,
        component: TheftClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "theft"
    },
    {
        path: `${JourneyType.ClaimProtected}/theftInVacationHome`,
        component: TheftInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "theftInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/glass`,
        component: GlassClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "glass"
    },
    {
        path: `${JourneyType.ClaimProtected}/glassInVacationHome`,
        component: GlassInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "glassInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/storm`,
        component: StormClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "storm"
    },
    {
        path: `${JourneyType.ClaimProtected}/stormInVacationHome`,
        component: StormInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "stormInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/nature`,
        component: NatureClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "nature"
    },
    {
        path: `${JourneyType.ClaimProtected}/natureInVacationHome`,
        component: NatureInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "natureInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/water`,
        component: WaterClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "water"
    },    
    {
        path: `${JourneyType.ClaimProtected}/waterInVacationHome`,
        component: WaterInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "waterInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/bicycle`,
        component: BicycleClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "bicycle"
    },
    {
        path: `${JourneyType.ClaimProtected}/installedHeater`,
        component: InstalledHeaterClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "installedHeater"
    },
    {
        path: `${JourneyType.ClaimProtected}/installedHeaterInVacationHome`,
        component: InstalledHeaterInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "installedHeaterInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/householdAppliances`,
        component: HouseholdAppliancesClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "householdAppliances"
    },
    {
        path: `${JourneyType.ClaimProtected}/householdAppliancesInVacationHome`,
        component: HouseholdAppliancesInVacationHomeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "householdAppliancesInVacationHome"
    },
    {
        path: `${JourneyType.ClaimProtected}/otherDamageOrLoss`,
        component: OtherDamageOrLossClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "otherDamageOrLoss"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.Incapacity}`,
        component: IncapacityClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "incapacity"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.IllnessDuringTravel}`,
        component: IllnessDuringTravelClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "illnessDuringTravel"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.AccidentDuringTravel}`,
        component: AccidentDuringTravelClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "accidentDuringTravel"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.CancelledTravel}`,
        component: CancelledTravelClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "cancelledTravel"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.ObfuscatedJourneyId.DelayedTraveler}`,
        component: DelayedTravelerClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "delayedTraveler"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleCollision.Id}`,
        component: VehicleCollisionClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleCollision"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleCollisionWithAnimal.Id}`,
        component: VehicleCollisionWithAnimalClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleCollisionWithAnimal"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleSingleAccident.Id}`,
        component: VehicleSingleAccidentClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleSingleAccident"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleHitAndRunAccident.Id}`,
        component: VehicleHitAndRunClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleHitAndRunAccident"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleVandalism.Id}`,
        component: VehicleVandalismClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleVandalism"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleTheftOrBurglary.Id}`,
        component: VehicleTheftOrBurglaryClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleTheftOrBurglary"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleFire.Id}`,
        component: VehicleFireClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleFire"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleEngineDamage.Id}`,
        component: VehicleEngineDamageClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleEngineDamage"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleLossOfKey.Id}`,
        component: VehicleLossOfKeyClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleLossOfKey"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleMisfuelling.Id}`,
        component: VehicleMisfuellingClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleMisfuelling"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.VehicleInteriorDamage.Id}`,
        component: VehicleInteriorDamageClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "vehicleInteriorDamage"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.DogClaim.Id}`,
        component: DogClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "dogClaim"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.CatClaim.Id}`,
        component: CatClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "catClaim"
    },
    {
        path: `${JourneyType.ClaimProtected}/${Constants.Journey.AccidentSpareTime.Id}`,
        component: AccidentSpareTimeClaimSuccess,
        journeyType: JourneyType.ClaimProtected,
        featureName: "accidentSpareTime"
    }
];

export default ClaimJourneySuccessRoutes;
