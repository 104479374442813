import {useViewContext} from "@folksam-digital/ui";

export interface IDeviceData {
    isMobileOrTablet: boolean;
    isChromeOnAppleDevice: boolean;
    isFirefoxOnAppleDevice: boolean;
    isOperaTouchOnAppleDevice: boolean;
}

const useDevice = (): IDeviceData => {
    const viewContext = useViewContext();

    const isMobileOrTablet = viewContext?.isMobile || viewContext?.isTablet;
    const isChromeOnAppleDevice = Boolean(navigator.userAgent.match(/CriOS/));
    const isFirefoxOnAppleDevice = Boolean(navigator.userAgent.match(/FxiOS/));
    const isOperaTouchOnAppleDevice = Boolean(navigator.userAgent.match(/OPT/));

    return {
        isMobileOrTablet,
        isChromeOnAppleDevice,
        isFirefoxOnAppleDevice,
        isOperaTouchOnAppleDevice,
    };
};

export default useDevice;
