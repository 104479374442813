import * as React from "react";
import {FlexRow, FormInputToggleButton} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {FormattedMessage} from "react-intl";
import {IBreakPoint} from "../../layout/helpers";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {FieldGroup} from "../../../formFieldLayout/FieldGroup";

interface IMetadata {
    classNames?: string;
    help?: string;
    breakpoints?: IBreakPoint;
    checkDisabledForm?: boolean;
}

export class YesNoButton extends InputComponentBase<boolean, IMetadata, {}> {
    public render() {
        const {
            name,
            formData
        } = this.props;

        const { breakpoints, checkDisabledForm } = this.metadata;
        const {disableButton} = this.context;
        const layoutProps = this.getLayoutProps();

        return (
            <FormFieldGroupLayout {...layoutProps} spaceBetween={false} breakpoints={getBreakpoints(defaultBreakpoints.yesNoButton, breakpoints)}>
                <div>
                    <FieldGroup spaceBetween={false} {...layoutProps}>
                        <FlexRow>
                            <FormInputToggleButton
                                inline={true}
                                key={`${name}-true`}
                                id={`${name}-true`}
                                ariaLabelledBy={`${name}-true-label ${name}-label`}
                                label={<FormattedMessage id={"general.yes"} />}
                                value={"true"}
                                name={name}
                                checked={formData === true}
                                invalid={this.isInvalid()}
                                onChange={() => this.onChangeWithValidation(true)}
                                disabled={checkDisabledForm && disableButton}
                                />
                            <FormInputToggleButton
                                inline={true}
                                key={`${name}-false`}
                                id={`${name}-false`}
                                ariaLabelledBy={`${name}-false-label ${name}-label`}
                                label={<FormattedMessage id={"general.no"} />}
                                value={"false"}
                                name={name}
                                checked={formData === false}
                                invalid={this.isInvalid()}
                                onChange={() => this.onChangeWithValidation(false)}
                                disabled={checkDisabledForm && disableButton}
                            />
                        </FlexRow>
                    </FieldGroup>
                </div>
            </FormFieldGroupLayout>
        )
    }
}
