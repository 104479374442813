import {IBannerIconBrandLogoGenerator} from "./bannerIconBrandLogoHelper";
import {Constants} from "@folksam-digital/model";
import {IntlShape} from "react-intl";
import {
    HyundaiIcon,
    SuzukiIcon,
    MazdaIcon,
    LararforsakringarIcon,
    HondaIcon,
    CoopIcon
} from "@folksam-digital/ui";
import {ReactNode} from "react";
import {iconLogoRenderer} from  "./bannerIconLogoRenderer";
import {FeatureToggleUtils} from "@folksam-digital/services";
import {findKey} from "lodash";

const iconsCollection: Record<string, ReactNode> = {
    [Constants.Car.CategoryType.Brand.HPB]: HondaIcon,
    [Constants.Car.CategoryType.Brand.HYP]: HyundaiIcon,
    [Constants.Car.CategoryType.Brand.SPB]: SuzukiIcon,
    [Constants.Car.CategoryType.Brand.MAZ]: MazdaIcon,
    [Constants.Car.CategoryType.Partner.LBL]: LararforsakringarIcon,
};

export const carBannerIconLogoGenerator: IBannerIconBrandLogoGenerator = (formData, cmsContext, intl: IntlShape) => {
    const { policy } = formData || {};

    const isPartnerInsurance = policy?.insuredObject?.carInsuranceType === Constants.Car.InsuranceType.Partner;
    const isBrandInsurance = policy?.insuredObject?.carInsuranceType === Constants.Car.InsuranceType.Brand;

    let constants;

    if (formData?.policy?.partner === Constants.Partners.Coop && FeatureToggleUtils.isFeatureEnabled("coop") && formData?.policy?.insuredObject?.carInsuranceType === Constants.Car.InsuranceType.Standard) {
        constants = {[`${Constants.Partners.Coop}`]: `${findKey(Constants.Partners, (partner) => partner === Constants.Partners.Coop)}`};

        return iconLogoRenderer({
            constants,
            iconsCollection: {},
            itemKey: formData?.policy?.partner,
            itemName: "brandName",
            IconComponent: CoopIcon,
            cmsContext,
            intl
        });
    }

    if(!isBrandInsurance && !isPartnerInsurance) {
        return;
    }

    constants = isBrandInsurance ? Constants.Car.CategoryType.Brand : Constants.Car.CategoryType.Partner;

    return iconLogoRenderer({
        constants,
        iconsCollection,
        itemKey: policy.insuredObject?.brand,
        itemName: 'brandName',
        cmsContext,
        intl
    });
};
