import {injectable} from "inversify";
import {REGEXP} from "@folksam-digital/model";
import {CmsHelper} from "../Helpers/cms/CmsHelper";
import {IValidation, ValidatorBase} from "./ValidatorBase";

export type TPossibleString = string | undefined;

@injectable()
export class DirectDebitValidator extends ValidatorBase {
    protected catalogParams = {folderName: "directDebit", catalogName: "directDebit"};

    protected readonly validations: {[key: string]: IValidation} = {
        "ssn": {
            "required": true
        },
        "phoneNumber": {
            "required": false,
            "regex": {
                "pattern": REGEXP.SWEDISH_OR_INTERNATIONAL_PHONE_NUMBER_OR_EMPTY,
                "errorMessage": CmsHelper.withPrefix(this.catalogParams, "form.error.pattern.phone"),
            }
        },
        "emailAddress": {
            "required": true,
            "regex": {
                "pattern": REGEXP.EMAIL_PATTERN,
                "errorMessage": CmsHelper.withPrefix(this.catalogParams, "form.error.pattern.email"),
            }
        },
        "clearingNumber": {
            "required": true,
            "regex": {
                "pattern": REGEXP.NUMERIC_WHITESPACE_ALLOWED,
                "errorMessage": CmsHelper.withGeneralPrefix("form.error.pattern.numeric"),
            }
        },
        "accountNumber": {
            "required": true,
            "regex": {
                "pattern": REGEXP.NUMERIC_WHITESPACE_ALLOWED,
                "errorMessage": CmsHelper.withGeneralPrefix("form.error.pattern.numeric"),
            }
        },
        "termsAndConditions": {"required": true}
    };
}
