import * as React from "react";
import {Modal, ButtonResponsive, Spacing, Headings, Grid, defaultTheme} from "@folksam-digital/ui";
import {InputComponentBase, IInputComponentProps} from "./InputComponentBase";
import {FormattedMessage} from "react-intl";
import FormattedMarkdown from "../../../FormattedMarkdown";

interface IMetadata {
    path: string;
    messageId: string;
    yesButtonTextId: string;
    noButtonTextId: string;
    descriptions: string[];
    classNames?: string;
    shouldReturnFocusAfterClose?: boolean;
    shouldCloseOnEsc?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    closeDialogueOnYesClick?: boolean;
    showCloseButton?: boolean;
}

export class ModalYesNoWidget extends InputComponentBase<any, IMetadata, any> {
    constructor(props: IInputComponentProps<any, IMetadata>) {
        super(props);
        this.handleOnToggle = this.handleOnToggle.bind(this);
    }

    public render() {
        const title = this.schema.title;
        const {
            noButtonTextId,
            yesButtonTextId,
            shouldReturnFocusAfterClose = true,
            shouldCloseOnEsc = true,
            shouldCloseOnOverlayClick = true,
            showCloseButton = false
        } = this.metadata;

        const modalDefaults = {
            role: 'dialog',
            shouldFocusAfterRender: true,
        };

        return (
            <Modal
                {...modalDefaults}
                id={this.props.name}
                isOpen={true}
                shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                shouldCloseOnEsc={shouldCloseOnEsc}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                onRequestClose={this.handleOnToggle}
                showCloseButton={showCloseButton}
                style={{
                    overlay: {
                        zIndex: "110000",
                    },
                }}
            >
                <Grid>
                    <Grid.Row>
                        <Headings.H3><FormattedMessage id={title} /></Headings.H3>
                    </Grid.Row>
                    <Grid.Row>
                        <Spacing bottom={6}>
                            <FormattedMarkdown messageKey={this.metadata.messageId} />
                        </Spacing>
                    </Grid.Row>
                    <Grid.Row center="xs">
                        <Grid.Col>
                            <Spacing type={"margin"} bottom={defaultTheme.margin.sm}>
                                <ButtonResponsive id={"YesModalBtn"} small={true} primary={true} onClick={this.onChange.bind(this, true)}>
                                    <FormattedMessage id={yesButtonTextId} />
                                </ButtonResponsive>
                            </Spacing>
                            <Spacing>
                                <ButtonResponsive id={"NoModalBtn"} outline={true} small={true} onClick={this.onChange.bind(this, false)}>
                                    <FormattedMessage id={noButtonTextId} />
                                </ButtonResponsive>
                            </Spacing>
                        </Grid.Col>
                    </Grid.Row>
                </Grid>
            </Modal>
        );
    }

    private handleOnToggle(event: any) {
        event?.preventDefault();
        const closeOnYesClick = this.metadata.closeDialogueOnYesClick !== undefined ? this.metadata.closeDialogueOnYesClick : true;
        this.props.onChange(closeOnYesClick);
    }

    onChange(value: boolean) {
        this.props.onChange(value);
    }
}
