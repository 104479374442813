import React, {useCallback, useEffect, useState} from "react";
import {ConfigHelper} from "@folksam-digital/services/lib";
import JourneyType from "@folksam-digital/model/lib/constants/JourneyType";
import {AnalyticScriptLoader} from "../adobe/AnalyticScriptLoader";
import {ICmsParamsService} from "../../services";
import {AnalyticsHelper} from "../../Helpers/analytic/AnalitycsHelper";
import {HelmetTags} from "react-helmet";
import {IScriptLoadedContext, ScriptLoadedContext} from "./ScriptLoadedContext";

const withDynamicAnalyticLoader = (WrappedComponent: React.ComponentType<any>, cmsParamsService: ICmsParamsService) => (props: any) => {
    const [src, setSrc] = useState<string>();
    const [helmetScriptTagAdded, setHelmetScriptTagAdded] = useState<boolean>(false);
    const [adobeScriptState, setAdobeScriptState] = useState<IScriptLoadedContext>({
        loading: true,
        loaded: false
    });
    const onScriptLoad = useCallback(() => {
        setAdobeScriptState({loading: false, loaded: true});
    }, []);
    const onScriptError = useCallback(() => {
        setAdobeScriptState({loading: false, loaded: false});
        console.error(`adobe script load error!`);
    }, []);
    const {journeyType, journeyId} = props;

    useEffect(() => {
        switch (journeyType) {
            case JourneyType.ClaimProtected:
            case JourneyType.ClaimUnprotected:
                setSrc(ConfigHelper.getValue("ADOBE_CLAIMS_DTM_URL"));
                break;
            case JourneyType.DirectDebit:
                setSrc(ConfigHelper.getValue("ADOBE_SERVICES_DTM_URL"));
                break;
            case JourneyType.Group:
            case JourneyType.Journey:
            case JourneyType.JourneyProtected: {
                try {
                    const params = cmsParamsService.getInitParams();
                    if (AnalyticsHelper.shouldLoadScriptForProduct(journeyId, params)) {
                        setSrc(ConfigHelper.getValue("ADOBE_PRODUCTS_DTM_URL"));
                    } else {
                        // If no src is set then journey fails to load unless we update adobeScriptState...
                        setAdobeScriptState({loading: false, loaded: false});
                    }
                } catch (e) {
                    console.log(e);
                }
                break;
            }
        }
    }, [journeyType, journeyId]);

    useEffect(() => {
        if (helmetScriptTagAdded) {
            const scriptTag = document.getElementById("adobeScriptTag");
            scriptTag?.addEventListener("load", onScriptLoad);
            scriptTag?.addEventListener("error", onScriptError);

            return () => {
                scriptTag?.removeEventListener("load", onScriptLoad);
                scriptTag?.removeEventListener("error", onScriptError);
            };
        }
    }, [helmetScriptTagAdded, onScriptLoad, onScriptError]);

    const onHelmetClientStateChange = useCallback((addedTags: HelmetTags) => {
        if (addedTags.scriptTags && addedTags.scriptTags[0]) {
            setHelmetScriptTagAdded(true);
        } else {
            setAdobeScriptState({loading: false, loaded: false});
        }
    }, [setHelmetScriptTagAdded, setAdobeScriptState]);

    return (
        <>
            {src && <AnalyticScriptLoader src={src}
                                          onChangeClientState={journeyType === JourneyType.Journey || journeyType === JourneyType.ClaimProtected ? onHelmetClientStateChange : undefined}
            />}
            <ScriptLoadedContext.Provider value={{loading: adobeScriptState.loading, loaded: adobeScriptState.loaded}}>
                <WrappedComponent
                    {...props}
                />
            </ScriptLoadedContext.Provider>
        </>
    );
};

export default withDynamicAnalyticLoader;
