import * as React from "react";
import {defaultTheme, IconCloseV2, Link} from "@folksam-digital/ui";
import {ButtonWithModal} from "../../form/input/actionInput/ButtonWithModal";
import {useStyles} from "./useStyles";

export const NavigationModalButton = (
    {
        onSubmit,
        modalHeaderMessage,
        modalContentMessage,
    }: any) => {
    const classes = useStyles();

    return (
        <ButtonWithModal
            onChange={onSubmit}
            triggerButton={
                <Link to={""} className={classes.root}>
                    <IconCloseV2 style={{paddingLeft: 5}}
                               color={defaultTheme.colors.primary2}
                               width={14}
                               height={14}/>
                </Link>
            }
            headerMessage={modalHeaderMessage}
            modalContentMessage={modalContentMessage}
            showCloseButton={false}
        />
    )
};
