import {IStorage} from "../IStorage";
import {injectable} from "inversify";

@injectable()
export class SessionStorage implements IStorage {
    public set(key: string, value: any): void {
        const strValue: string = JSON.stringify(value);
        sessionStorage.setItem(key, strValue);
    }

    public get<T>(key: string, defaultValue?: T | undefined): T | undefined {
        const strValue = sessionStorage.getItem(key);
        if(!strValue) {
            return defaultValue;
        }
        return JSON.parse(strValue) as T;
    }

    public has(key: string): boolean {
        return sessionStorage.getItem(key) != null;
    }

    public remove(key: string): void {
        sessionStorage.removeItem(key);
    }

    public clear(): void {
        sessionStorage.clear();
    }
}
