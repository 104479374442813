interface IAdditionalProperties {
    oldValue: string;
    oldSelectionStart: number | null;
    oldSelectionEnd: number | null
}

function filterValue(this: (HTMLInputElement | HTMLTextAreaElement) & IAdditionalProperties, inputFilter: any) {
    if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
    } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        if (this.oldSelectionStart !== null && this.oldSelectionEnd !== null) {
            this.setSelectionRange(this.oldSelectionStart ? this.oldSelectionStart : 0, this.oldSelectionEnd ? this.oldSelectionEnd : 0);
        }
    } else {
        this.value = "";
    }
}

export function setInputFilter(textBox: (HTMLInputElement | HTMLTextAreaElement) & IAdditionalProperties, inputFilter: (value: string) => boolean) {
    ["input"].forEach(function(event) {
        textBox.addEventListener(event, filterValue.bind(textBox, inputFilter));
    });
}

export function removeInputFilter(textBox: (HTMLInputElement | HTMLTextAreaElement)) {
    ["input"].forEach(function(event) {
        textBox.removeEventListener(event, filterValue);
    });
}
