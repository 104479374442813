import React from "react";
import {FormattedMessage, useIntl} from "react-intl";

interface IProps {
    value: number;
    suffixId: string;
}

export const FormattedPrice = ({value, suffixId}: IProps): React.ReactElement => {
    const intl = useIntl();

    return (<div>
        {intl.formatNumber(value)} <FormattedMessage id={suffixId}/>
    </div>);
};
