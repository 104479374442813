import * as React from "react";
import { PolicySearchResult } from "@folksam-digital/model";
import map from "lodash/map";

export interface IPolicyListGroupProps {
    listItemComponent: React.ComponentType<any>;
    policies?: PolicySearchResult[];
}

export default class PolicyListGroup extends React.Component<IPolicyListGroupProps> {
    public render() {
        const { listItemComponent: Component, policies } = this.props;

        return (
            <div>
                {map(policies, (policy, idx) => <Component key={idx} policy={policy} />)}
            </div>
        )
    }
};
