import React, {RefObject} from "react";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import memoize from "lodash/memoize";
import omit from "lodash/omit";
import Icons from "../../../icons";
import {defaultTheme, withViewContext} from "@folksam-digital/ui";
import {IFormComponentProps} from "../FormComponentBase";
import {IToothStatus} from "@folksam-digital/model/lib";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {renderToString} from "react-dom/server";
import {scroller} from "react-scroll/modules";
import {Modal} from "../controls/remove/Modal";
import {FormContext, IFormContext} from "../FormContext";
import {Actions} from "../panel/AccordionPanelConstants";
import { MemoizedFunction } from "lodash";
import ImageMapper, {IArea, IAreaMapping} from "../imageMapper/ImageMapper";

interface ITeethSelectionComponentState {
    localFormData: IToothStatus[];
    toothToRemove?: any;
    modalOpen?: boolean;
    isMobile: boolean;
}

interface ISvgViewBox {
    minX: number;
    minY: number;
    width: number;
    height: number;
}

export interface ITeethSelectionComponentMetadata {
    cardBaseName: string;
    modelPath: string;
    modalHeaderMessage?: string;
}

type FunctionType = (items: IToothStatus[]) => any;

class TeethSelectionComponentInternal extends InputComponentBase<IToothStatus[], ITeethSelectionComponentMetadata, ITeethSelectionComponentState> {
    ref: RefObject<any>;
    public static contextType = FormContext;
    context!: IFormContext;
    memoizeGetProcessedItems: FunctionType & MemoizedFunction;
    // Number of vertices for each tooth polygon
    private readonly NUM_POINTS: number = 50;
    private parser: DOMParser;
    private readonly path: string;

    constructor(props: IInputComponentProps<IToothStatus[], ITeethSelectionComponentMetadata>, context: IFormContext) {
        super(props, context);
        this.parser = new DOMParser();
        this.path = `expanded_${this.uiSchema.name}`;

        this.state = {
            localFormData: props.formData,
            modalOpen: false,
            isMobile: props.viewContext.isMobile
        };

        this.memoizeGetProcessedItems = memoize(this.getProcessedItems, (items: IToothStatus[]) =>
            JSON.stringify(items) // Use JSON.stringify to generate a unique cache key based on item contents
        );

        this.ref = React.createRef();
    }

    public componentDidMount(): void {
        scroller.register(this.props.name, this.ref.current);
        // Trigger initial dispatch if there are no teeth added yet to block user
        this.context.dispatchAccordionStatus({path: this.path, action: Actions.Open});
    }

    public componentWillUnmount(): void {
        scroller.unregister(this.props.name);
    }

    public render() {
        const { viewContext } = this.props;
        const { modalHeaderMessage } = this.metadata;
        const { teethLayoutMap, originalImgViewBox } = this.memoizeGetProcessedItems(this.state.localFormData);

        return (
            <div ref={this.ref} id={this.props.name}>
                <FormFieldLayout {...this.getLayoutProps()}>
                    <div className={"imgMapContainer"}>
                        <ImageMapper map={teethLayoutMap}
                                     onClick={this.onToothClick}
                                     fillColor={defaultTheme.colors.tertiary3}
                                     src={viewContext.isMobile ? Icons.TeethLayoutMobileUrl : Icons.TeethLayoutDesktopUrl}
                                     width={Number(defaultTheme.width.contentWrapperCompact.replace("px", ""))}
                                     imgWidth={originalImgViewBox.width}/>
                    </div>
                    <Modal onClose={this.onClose} open={this.state.modalOpen} onSubmit={this.onRemoveSubmit} headerMessageId={modalHeaderMessage} />
                </FormFieldLayout>
            </div>);
    }

    public onClose = () => {
        this.setState({modalOpen: false});
    };

    public onRemoveSubmit = () => {
        let dataToUpdate = {} as any;
        const toothId = this.state.toothToRemove.id;
        const formData = this.props.formData || [];

        if (!isEmpty(formData)) {
            const toothIndex = formData.findIndex(tooth => tooth.id === toothId);
            dataToUpdate = this.updateDamagedArea(toothIndex);

            this.setState({
                modalOpen: false,
            });
            this.context.dispatchAccordionStatus({path: `expanded_${this.metadata.cardBaseName}_${toothIndex}`, action: Actions.DeletePath});
            this.props.onChange(dataToUpdate.newData);

            return;
        }

        this.setState({
            modalOpen: false
        });
    };

    public componentDidUpdate(prevProps: Readonly<IFormComponentProps<IToothStatus[], {}>>, prevState: Readonly<ITeethSelectionComponentState>): void {

        if (prevProps.formData !== this.props.formData) {
            // Clear the memoized function cache before setting the new state
            this.memoizeGetProcessedItems.cache.clear?.();

            // Update the state with new items from props
            this.setState({ localFormData: this.props.formData });
        }

        if (prevProps.viewContext.isMobile !== this.props.viewContext.isMobile) {
            this.memoizeGetProcessedItems.cache.clear?.();

            this.setState({isMobile: this.props.viewContext.isMobile})
        }
    }

    getProcessedItems = (items: IToothStatus[]): any => {
        const viewBox: ISvgViewBox = this.extractImgViewBox();
        return {
            teethLayoutMap: this.extractLayoutMap(viewBox, items),
            originalImgViewBox: viewBox,
        }
    };

    private appendTeethArray(data: IToothStatus[], id: string) {
        data.push({
            id,
            isDamaged: true,
        });
    }

    private onToothClick = (area: IArea): void => {
        const newData: IToothStatus[] = this.props.formData || [];
        let dataToUpdate;

        const toothIndex = newData.findIndex((toothStatus: IToothStatus) => {
            return area.name === toothStatus.id;
        });

        if (toothIndex === -1) {
            dataToUpdate = this.addDamagedArea(area);
        } else if (newData[toothIndex].isDamaged && this.isToothInfoChanged(toothIndex)) {
            this.setState({toothToRemove: newData[toothIndex], modalOpen: true});

            return;
        } else {
            dataToUpdate = this.updateDamagedArea(toothIndex)

            if (!dataToUpdate.newData[toothIndex].isDamaged) {
                this.context.dispatchAccordionStatus({path: `expanded_${this.metadata.cardBaseName}_${toothIndex}`, action: Actions.DeletePath});
            }
        }

        this.onChangeWithValidation(dataToUpdate.newData);
    }

    private isToothInfoChanged(toothIndex: number) {
        const path = this.metadata.modelPath;
        let data = [] as any;
        let filteredTempTooth;

        if (path) {
            data = this.props.formData;
        }

        if (!isEmpty(data) && data.hasOwnProperty(toothIndex)) {
            const tempTooth = omit(data[toothIndex], ["id", "isDamaged"]);

            filteredTempTooth = filter(tempTooth, (value) => {
                return !isNil(value) && value !== 0;
            });
        }

        return !isEmpty(filteredTempTooth);
    }

    private addDamagedArea(area: IArea) {
        const newData: IToothStatus[] = this.props.formData || [];

        this.appendTeethArray(newData, area.name);

        return {newData};
    }

    private updateDamagedArea(index: number) {
        const newData: IToothStatus[] = this.props.formData || [];
        newData[index].isDamaged = !newData[index].isDamaged;

        return { newData };
    }

    private extractLayoutMap(viewBox: ISvgViewBox, formData: IToothStatus[] = []): IAreaMapping {
        const svgElement: SVGElement = this.getSvgElement();

        const teethLayoutMap: IAreaMapping = {
            name: svgElement.id,
            areas: []
        };

        const pathElements: SVGPathElement[] = Array.from(svgElement.children) as SVGPathElement[];
        pathElements.filter((pathEl: SVGPathElement) => pathEl.getAttribute("class") === "st1")
            .forEach((pathEl:SVGPathElement) => {
                const coords: number[] = [];
                for (let i: number = 0; i < this.NUM_POINTS; i++) {
                    const pointCoordinates: SVGPoint = pathEl.getPointAtLength(i * pathEl.getTotalLength() / (this.NUM_POINTS - 1));
                    coords.push(pointCoordinates.x - viewBox.minX, pointCoordinates.y - viewBox.minY)
                }

                const prefillPolygon: boolean = formData.some((toothStatus: IToothStatus) => toothStatus.id === pathEl.id && toothStatus.isDamaged);

                teethLayoutMap.areas.push({
                    name: pathEl.id,
                    shape: "poly",
                    coords: coords,
                    preFillColor: prefillPolygon ? defaultTheme.colors.tertiary2 : defaultTheme.colors.transparent,
                })
            });

        return teethLayoutMap;
    }

    private extractImgViewBox(): ISvgViewBox {
        const svgElement: SVGElement = this.getSvgElement();
        const svgViewBox: number[] = svgElement.getAttribute("viewBox")!.split(" ").map(x=>+x);

        return {
            minX: svgViewBox[0],
            minY: svgViewBox[1],
            width: svgViewBox[2],
            height: svgViewBox[3]
        } as ISvgViewBox;
    }

    private getSvgElement(): SVGElement {
        const { viewContext } = this.props;
        const svgString: string = viewContext.isMobile ? renderToString(<Icons.TeethLayoutMobile/>) : renderToString(<Icons.TeethLayoutDesktop/>);
        return this.parser.parseFromString(svgString, "image/svg+xml").firstElementChild! as SVGElement;
    }
}

const TeethSelectionComponent = withViewContext(TeethSelectionComponentInternal);
export { TeethSelectionComponent };
