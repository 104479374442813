import * as React from "react";
import {Modal, defaultTheme, IconContactPhone, Link} from "@folksam-digital/ui";
import {InputComponentBase, IInputComponentProps} from "./InputComponentBase";
import {FormattedMessage} from "react-intl";
import FormattedMarkdown from "../../../FormattedMarkdown";

interface IMetadata {
    path: string;
    messageId: string;
    messagePhoneNumber?: string;
    messageCallUsOn?: string;
    descriptions: string[];
    classNames?: string;
    shouldReturnFocusAfterClose?: boolean;
    shouldCloseOnEsc?: boolean;
    shouldCloseOnOverlayClick?: boolean;
    showCloseButton?: boolean;
}

export class ModalXPopupWidget extends InputComponentBase<any, IMetadata, any> {
    constructor(props: IInputComponentProps<any, IMetadata>) {
        super(props);
        this.handleOnToggle = this.handleOnToggle.bind(this);
    }

    public render() {
        const title = this.schema.title;
        const {
            shouldReturnFocusAfterClose = true,
            shouldCloseOnEsc = true,
            shouldCloseOnOverlayClick = true,
            showCloseButton = true
        } = this.metadata;

        const modalDefaults = {
            role: 'dialog',
            shouldFocusAfterRender: true,
        };

        return (
            <Modal
                {...modalDefaults}
                id={this.props.name}
                isOpen={true}
                shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                shouldCloseOnEsc={shouldCloseOnEsc}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                onRequestClose={this.handleOnToggle}
                showCloseButton={showCloseButton}
            >
                <Modal.Header showCloseButton={showCloseButton} onModalClose={this.handleOnToggle}>
                    <FormattedMessage id={title} />
                </Modal.Header>
                <Modal.Body>
                    <FormattedMarkdown messageKey={this.metadata.messageId}/>
                    <div style={{display: 'flex'}}>
                        <IconContactPhone style={{marginRight: "5px"}}/>
                        <FormattedMarkdown messageKey={this.metadata.messageCallUsOn!} />
                        <div style={{color: defaultTheme.colors.primary3, marginLeft: "15px"}}>
                            <FormattedMessage id={this.metadata.messagePhoneNumber!}>
                                {phoneNumber => (
                                    <Link to={`tel:${phoneNumber}`}>
                                        {phoneNumber}
                                    </Link>
                                )}
                            </FormattedMessage>
                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        );
    }

    private handleOnToggle(event: any) {
        event?.preventDefault();
        this.props.onChange(true);
    }

    onChange(value: boolean) {
        this.props.onChange(value);
    }
}
