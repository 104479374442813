import * as React from "react";
import {ILayoutComponentProps, LayoutComponentBase} from "./LayoutComponentBase";
import {B, Expandable, FlexRow, PriceBar} from "@folksam-digital/ui";
import {Cover, JourneyBase, PriceBarExcludedSteps, PriceBarFrequency} from "@folksam-digital/model";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import memoize from "lodash/memoize";
import FormattedMarkdown from "../../FormattedMarkdown";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import {CmsContext, ICmsContext} from "../../../cms";
import {PriceBarDiscounts} from "./priceBar";
import {Markdown} from "../../Markdown";
import isEmpty from "lodash/isEmpty";

interface IJourneyPriceBarLayoutProps extends ILayoutComponentProps {
    id: string;
    initialExpanded?: string[];
    collapse?: boolean;
    intl: IntlShape;
    type: string;
    currentStep: string;
    showPriceBar: boolean;
}

class JourneyPriceBarLayoutInternal extends LayoutComponentBase<IJourneyPriceBarLayoutProps, {}> {
    private renderMainProductType = memoize((coverPlan: string, cmsContext: ICmsContext) => {

        const intl = this.props.intl;
        // intl.messages has to be passed as any type to avoid error where types are incompatible.
        const defaultMessage = CmsHelper.getIdIfExists(intl.messages as any, CmsHelper.withPrefix(cmsContext, `coverPlanDefault`));
        const idMessage = CmsHelper.getIdIfExists(intl.messages as any, CmsHelper.withPrefix(cmsContext, `coverPlan.${coverPlan}`));
        if (idMessage || defaultMessage) {
            return <Markdown source={intl.formatMessage({
                id: !!idMessage ? idMessage : defaultMessage,
                defaultMessage: CmsHelper.withPrefix(cmsContext, `coverPlanDefault`)
            })}/>;
        }
    });

    private renderInsuranceType = memoize((insuranceType: number, cmsContext: ICmsContext) => {
        const intl = this.props.intl;
        const idMessage = CmsHelper.getIdIfExists(intl.messages as any, CmsHelper.withPrefix(cmsContext, `insuranceType.${insuranceType}`));

        return idMessage ? `${intl.formatMessage({
            id: idMessage,
        })}, ` : "";
    });

    private renderPriceMessage = memoize((price: number) => {
        const data = this.props.formData as JourneyBase<any>;
        let message: string;

        switch (data?.priceBar?.priceBarFrequency) {
            case PriceBarFrequency.Monthly:
                message = this.props.intl.formatMessage({id: "general.priceBar.totalPremium.monthly"}, {monthlyPremium: Math.round(price)});
                break;
            case PriceBarFrequency.Yearly:
                message = this.props.intl.formatMessage({id: "general.priceBar.totalPremium.yearly"}, {yearlyPremium: Math.round(price)});
                break;
            case PriceBarFrequency.Once:
                message = this.props.intl.formatMessage({id: "general.priceBar.totalPremium.once"}, {yearlyPremium: Math.round(price)});
                break;
            default: {
                message = this.props.intl.formatMessage({id: "general.priceBar.totalPremium.monthly"}, {monthlyPremium: Math.round(price)});
                break;
            }
        }

        return message;
    });

    public render() {
        const journey = this.props.formData as JourneyBase<any>;
        const intl = this.props.intl;
        const currentStep = this.props.currentStep;
        if (!journey?.priceBar || !journey?.policy?.premium || PriceBarExcludedSteps.includes(currentStep) || !this.props.showPriceBar || isEmpty(journey?.policy?.premium)) {
            return null;
        }

        const isCoversSelected = this.checkCoversSelected(journey);

        return (
            <Expandable
                id={this.props.id}
                initialExpanded={this.props.initialExpanded}
                collapse={this.props.collapse}
            >
                <Expandable.Section id={this.props.id}>
                    {({handleOpen, handleClose, expanded}:
                          { handleOpen: () => any, handleClose: () => any, expanded: boolean }) => (
                        <CmsContext.Consumer>
                            {(cmsContext: ICmsContext) => (
                                <PriceBar
                                    compact={true}
                                    isOpen={expanded}
                                    close={handleClose}
                                    open={handleOpen}
                                    price={this.renderPriceMessage(this.getPrice())}
                                    label={intl.formatMessage({id: "general.priceBar.label"})}
                                    toggleText={expanded ?
                                        intl.formatMessage({id: "general.priceBar.showLess"}) :
                                        intl.formatMessage({id: "general.priceBar.showMore"})}
                                >
                                    {
                                        !!journey.policy.premium.priceIncrease &&
                                        <FlexRow>
                                            <FlexRow className="text-wrap">
                                                <FormattedMessage
                                                    id={"general.priceBar.priceIncrease.label"}/>
                                            </FlexRow>
                                            <FlexRow className="price-wrap">
                                                <div
                                                    className="price">{this.renderPriceMessage(journey.policy.premium.priceIncrease)}</div>
                                            </FlexRow>
                                        </FlexRow>
                                    }
                                    {
                                        journey.policy.coverPlan &&
                                        <FlexRow>
                                            <FlexRow className="text-wrap">
                                                {this.renderInsuranceType(journey.policy.insuranceType, cmsContext)}
                                                {this.renderMainProductType(journey.policy.coverPlan, cmsContext)}
                                            </FlexRow>
                                            <FlexRow className="price-wrap">
                                                <div className="price">{this.renderPriceMessage(this.getCalculatedPackagePrice(this.getPrice()))}</div>
                                            </FlexRow>
                                        </FlexRow>
                                    }
                                    {
                                        journey?.policy?.coverPlanContent &&
                                        <FlexRow>
                                            <FlexRow className="text-wrap">
                                                {this.renderMainProductType(journey.policy.coverPlanContent, cmsContext)}
                                            </FlexRow>
                                            <FlexRow className="price-wrap">
                                               <div className="price"> {this.renderPriceMessage(this.getCalculatedPackagePrice(this.getPrice()))}</div>
                                            </FlexRow>
                                        </FlexRow>
                                    }
                                    {
                                        isCoversSelected &&
                                        <div>
                                            {
                                                journey.policy.coverPlan &&

                                                <FlexRow className="text-wrap">
                                                    <FormattedMessage
                                                        id={"general.priceBar.supplementary.covers.label"}/>
                                                </FlexRow>


                                            }
                                            {
                                                journey.policy.premium.covers.map((cover: Cover) => {
                                                    if (cover.selected && cover.monthlyPremium! > 0) {
                                                        return (
                                                            <FlexRow key={`table-row-index-${cover.id}`}>
                                                                <FlexRow className="text-wrap">
                                                                    <B>
                                                                        <FormattedMarkdown
                                                                        messageKey={`${CmsHelper.withPrefix(cmsContext, `cover.${cover.id}`)}`}
                                                                        messageValue={{
                                                                            insuranceAmount: cover.insuranceAmount,
                                                                            currency: this.props.intl.formatMessage({id: "general.monthlyPremium.suffix"})
                                                                        }}
                                                                        defaultMessageKey={cover.product!}
                                                                    />
                                                                    </B>

                                                                </FlexRow>
                                                                <FlexRow className="price-wrap">
                                                                    <div className="price">{this.renderSupplementaryCoverPrice(cover)}</div>
                                                                </FlexRow>
                                                            </FlexRow>
                                                        );
                                                    }
                                                    return "";
                                                })
                                            }
                                        </div>
                                    }
                                    <PriceBarDiscounts totalDiscount={journey.policy.premium.totalDiscount}
                                                       discounts={journey.policy.premium.discounts}/>
                                </PriceBar>
                            )}
                        </CmsContext.Consumer>
                    )}
                </Expandable.Section>
            </Expandable>
        );
    }

    private getPrice = () => {
        const data = this.props.formData as JourneyBase<any>;

        let price;
        switch (data?.priceBar?.priceBarFrequency) {
            case PriceBarFrequency.Monthly:
                price = data.policy.premium.monthlyPremium;
                break;
            case PriceBarFrequency.Yearly:
                price = data.policy.premium.yearlyPremium;
                break;
            case PriceBarFrequency.Once:
                price = data.policy.premium.yearlyPremium;
                break;
            default: {
                price = data.policy.premium.monthlyPremium;
                break;
            }
        }

        return price;
    };

    private getCalculatedPackagePrice(price: number): number {
        const data = this.props.formData as JourneyBase<any>;
        if (data.policy.covers && data.policy.premium.covers) {
            for (const cover of data.policy.premium.covers as Cover[]) {
                if (cover.selected === true) {
                    price -= Number(cover.monthlyPremium!);
                }
            }
        }
        if (data.policy.premium.priceIncrease) {
            price -= Number(data.policy.premium.priceIncrease);
        }

        return price;
    }

    private checkCoversSelected = memoize((data: JourneyBase<any>) => {
        if (data.policy.premium.covers && data.policy.premium.covers.length > 0) {
            for (const cover of data.policy.premium.covers as Cover[]) {
                if (cover.selected && cover.monthlyPremium! > 0) {
                    return true;
                }
            }
        }

        return false;
    });

    private renderSupplementaryCoverPrice = (cover: Cover): string => {
        const data = this.props.formData as JourneyBase<any>;
        let message: string;

        switch (data?.priceBar?.priceBarFrequency) {
            case PriceBarFrequency.Once:
                message = this.props.intl.formatMessage({id: "general.priceBar.premium.once"}, {yearlyPremium: Math.round(cover.yearlyPremium as number)});
                break;
            default: {
                message = this.props.intl.formatMessage({id: "general.priceBar.premium.monthly"}, {monthlyPremium: Math.round(cover.monthlyPremium as number)});
                break;
            }
        }

        return message;
    }
}

const JourneyPriceBarLayout = injectIntl<'intl', IJourneyPriceBarLayoutProps>(JourneyPriceBarLayoutInternal);
export {JourneyPriceBarLayout};
