import React from 'react';
import {Spinner, IconSearch, IconMapPin, defaultTheme as theme} from "@folksam-digital/ui";

interface IInputSuffixProps {
    loading: boolean;
    mobileView?: boolean;
    mobileViewOnIconClick?: () => void;
    isModalOpen?: boolean;
}

export const InputSuffix = ({loading, mobileView, mobileViewOnIconClick, isModalOpen}: IInputSuffixProps) => {
    let suffix;
    if (loading) {
        suffix = <Spinner/>
    } else if (mobileView && !isModalOpen) {
        suffix = <IconMapPin fill={theme.colors.primary2} width={18} height={18} onClick={mobileViewOnIconClick}/>
    } else if (mobileView && isModalOpen){
        suffix = null;
    } else {
        suffix = <IconSearch width={18} height={18}/>
    }

    return suffix;
};
