import * as React from "react";
import {InsuredPersonContact, PolicySearchResult} from "@folksam-digital/model/lib";
import {OptInOptOutProductRow} from "../output";
import {ContactHeader} from "../../../shoppingCart/form/output/productList/ContactHeader";


interface IOptInOptOutProductListBaseProps {
    policies: PolicySearchResult[];
    contact: InsuredPersonContact;
    customMessageFormatId?: string;
    type: "optIn" | "optOut";
    displayFreePeriod: boolean;
}

export default class OptInOptOutProductListBase extends React.Component<IOptInOptOutProductListBaseProps, {}> {

    public render() {
        const {policies, contact, customMessageFormatId, type, displayFreePeriod} = this.props;

        if (!policies || policies.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <ContactHeader fullName={`${contact?.firstName || ""} ${contact?.lastName || ""}`}
                               customMessageFormatId={customMessageFormatId}/>
                {
                    policies.map((policy, idx) => (
                        <OptInOptOutProductRow hideMonthlyPrice={type === "optOut"}
                                               displayFreePeriod={displayFreePeriod} key={idx} policy={policy}/>
                    ))
                }
            </React.Fragment>
        );
    }

}
