import * as React from "react";
import {
    B,
    Badge,
    defaultTheme as theme,
    DisplayIcon,
    IconCaretRightV2,
    P,
    QuestionsIcon,
    Spacing,
    withViewContext
} from "@folksam-digital/ui";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {Constants} from "@folksam-digital/model";

interface IProductContactUsSectionProps {
    altPhoneNumberMessageKey?: string;
    privacyMessageKey?: string;// has alternating version "general.claim.success.idit.privacy.text"
    viewContext?: any;
    displayContactUs?: boolean;
}

class ProductContactUsSectionInternal extends React.Component<IProductContactUsSectionProps & WrappedComponentProps> {
    public render(): React.ReactNode {
        const {altPhoneNumberMessageKey, viewContext, intl, displayContactUs = true, privacyMessageKey = "general.success.privacy.text"} = this.props;
        const phoneNumberMessageKey = altPhoneNumberMessageKey ? altPhoneNumberMessageKey : "general.success.contact.phone";

        return (
            <>
                {displayContactUs &&
                    <>
                        <div style={{ display: 'grid', justifyItems: 'center' }}>
                            <B><FormattedMessage id="general.success.contact.text" /></B>
                            <Spacing type="padding" top="3" />
                            <div style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "10px",
                                backgroundColor: theme.colors.white,
                                cursor: "pointer",
                                boxShadow: theme.shadows.xs
                            }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    ProductContactUsSectionInternal.handleCustomerServiceRedirect(intl.formatMessage({ id: phoneNumberMessageKey }), viewContext.isMobile);
                                }}>
                                <div style={{
                                    paddingLeft: '10px',
                                    paddingTop: `${theme.padding['3']}`,
                                    paddingBottom: `${theme.padding['3']}`,
                                }}>
                                    <Badge type='secondaryInfo'
                                        icon={<DisplayIcon large={true}
                                            icon={<QuestionsIcon
                                                style={{ marginBottom: 'unset' }} />} />}
                                        circle={true}
                                        style={{ width: '42px', height: '42px' }} />
                                </div>
                                <B style={{ paddingLeft: theme.padding['2'], flex: 'auto' }}>
                                    <FormattedMessage id="general.success.contact.phone.service.text" />
                                    {" "}
                                    <FormattedMessage id={phoneNumberMessageKey} />
                                </B>
                                <div style={{ paddingRight: '10px' }}>
                                    <DisplayIcon large={true}
                                        icon={<IconCaretRightV2 style={{ color: theme.colors.senary1 }} />} />
                                </div>
                            </div>
                        </div>
                        <Spacing type="padding" top="8" />
                    </>
                }
                <P style={{
                    fontFamily: theme.fonts.mono,
                    fontSize: theme.textSizes.sm,
                    letterSpacing: theme.tracking.tight,
                    lineHeight: theme.leading.tight
                }}><FormattedMarkdown messageKey={privacyMessageKey}/></P>
            </>
        );
    }

    private static handleCustomerServiceRedirect(phoneNumber: string, isMobile: boolean) {
        if (isMobile) {
            window.open(`tel:${phoneNumber}`, "_self");
        } else {
            window.open(Constants.Links.customerService, "_blank");
        }
    }
}

const ProductContactUsSection = withViewContext(injectIntl(ProductContactUsSectionInternal));
export {ProductContactUsSection};
