import * as React from "react";
import {defaultTheme, IconCloseV2} from "@folksam-digital/ui";

export const SeparatorIconClose = (props: any) => {
    return (
        <IconCloseV2
            style={{
                color: defaultTheme.colors.senary2,
                padding: 14,
                width: 14,
                height: 14,
                verticalAlign: "middle"
            }}
        />
    );
};
