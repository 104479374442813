export const Types = {
    CmsClient: Symbol.for("CmsClient"),
    FileUploadClient: Symbol.for("FileUploadClient"),
    DraftService: Symbol.for("DraftService"),
    SettingsStorage: Symbol.for("SettingsStorage"),
    SessionStorage: Symbol.for("SessionStorage"),
    LocaleConfig: Symbol.for("LocaleConfig"),
    UserService: Symbol.for("UserService"),
    PolicyService: Symbol.for("PolicyService"),
    DigitalPolicyService: Symbol.for("DigitalPolicyService"),
    ApplicationParamService: Symbol.for("ApplicationParamService"),
    ShoppingCartService: Symbol.for("ShoppingCartService"),
    OptInOptOutService: Symbol.for("OptInOptOutService"),
    ClassifierService: Symbol.for("ClassifierService"),
    SigningService: Symbol.for("SigningService"),
    DirectDebitSigningService: Symbol.for("DirectDebitSigningService"),
    SessionContextManager: Symbol.for("SessionContextManager"),
    ClaimService: Symbol.for("ClaimService"),
    AuthenticationService: Symbol.for("AuthenticationService"),
    UserClient: Symbol.for("UserClient"),
    HomeService: Symbol.for("HomeService"),
    CmsParamsService: Symbol.for("CmsParamsService"),
    DirectDebitValidator: Symbol.for("DirectDebitValidator"),
    DigitalClaimService: Symbol.for("DigitalClaimService"),
    UtilService: Symbol.for("UtilService"),
    IncomeInsuranceService: Symbol.for("IncomeInsuranceService"),
    ContentHeaderVehicleFieldValidator: Symbol.for("ContentHeaderVehicleFieldValidator"),
    SchemaService: Symbol.for("SchemaService"),
    ContentHeaderHomeFieldValidator: Symbol.for("ContentHeaderHomeFieldValidator"),
    SearchService: Symbol.for("SearchService"),
    UpptecService: Symbol.for("UpptecService"),
};
