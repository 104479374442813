import { IApplicationParamService, IParamValuesMap } from "../../IApplicationParamService";
import { WorkflowServiceBase } from "./WorkflowServiceBase";
import { Workflow } from "./Workflow";
import range from "lodash/range";

export class WorkflowApplicationParamService extends WorkflowServiceBase implements IApplicationParamService {
    private actionMap: {[key: string]: () => any } = {
        "noOfWeeksPregnant": this.calculateWeeksOfPregnancy.bind(this),
    };

    public async getApplicationParam(identifier: string): Promise<any> {
        const action = this.actionMap[identifier];

        if (action) {
            return action();
        } else {
            return this.loadFromWorkflow(identifier);
        }
    }

    private async loadFromWorkflow(identifier: string): Promise<any> {
        const data = await super.execute<any, any>(
            Workflow.Classifiers.ApplicationParams,
            { identifier: identifier }
        );

        return data.value;
    }

    private async calculateWeeksOfPregnancy(): Promise<IParamValuesMap[]> {
        const maxWeeks = await this.loadFromWorkflow("maxWeeksPregnant");

        return range(1, Number(maxWeeks) + 1)
            .map((id) => ({
                id,
                value: String(id)
            } as IParamValuesMap));
    }
}