import {
    Contact, ContactDirectDebitResponse, DirectDebit, JourneyBase, IsContactGroupMemberResponse,
    InsuredPersonContact, IditRestException
} from "@folksam-digital/model";
import {IMandateResponse, IUserService} from "../../IUserService";
import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";
import {JourneyHelper, SsnFormatter, OrgNumberFormatter} from "@folksam-digital/services";

export class UserService extends WorkflowServiceBase implements IUserService {
    public async getContactBySSN(identifier: string): Promise<Contact | undefined> {
        const data = await super.execute<any, any>(
            Workflow.Contact.GetBySSN,
            {
                identifier: SsnFormatter.isValidSsn(identifier) ? SsnFormatter.convertFormat(identifier, true) : OrgNumberFormatter.formatWithoutDash(identifier, true)
            }
        );

        return data.contact;
    }

    public async isContactGroupMember(identifier: string, groupId: string): Promise<boolean> {
        const data: IsContactGroupMemberResponse = await super.execute<any, IsContactGroupMemberResponse>(
            Workflow.Group.IsContactGroupMember,
            {
                identifier: SsnFormatter.convertFormat(identifier, true),
                groupId
            }
        );
        return data.isContactGroupMember;
    }

    public async getCustomerEligibilityFlag(product: string, input: any): Promise<JourneyBase<any> | undefined> {
        return await super.execute<any, any>(
            Workflow.forProduct(JourneyHelper.getObfuscatedJourneyId(product), input?.targetEnv).PerformUWChecks,
            input
        );
    }

    public async getContactDirectDebitDetails(identifier: string): Promise<ContactDirectDebitResponse> {
        const data = await super.execute<any, any>(
            Workflow.DirectDebit.GetDetailsBySSN,
            {
                identifier: SsnFormatter.convertFormat(identifier, true)
            }
        );
        return data.contactDirectDebitResponse as ContactDirectDebitResponse;
    }

    public async saveDirectDebitMandate(data: DirectDebit): Promise<boolean | IditRestException> {
        data.ssn = SsnFormatter.convertFormat(data.ssn, true);

        const responseData = await super.execute<any, any>(
            Workflow.DirectDebit.SaveMandate,
            {directDebitSetUpData: data}
        );

        if (responseData.error) {
            return responseData.error as IditRestException;
        }

        return responseData.mandateUpdated;
    }

    public async saveDummyDirectDebitMandate(data: DirectDebit): Promise<string | IditRestException> {
        data.ssn = SsnFormatter.convertFormat(data.ssn, true);

        const responseData = await super.execute<any, any>(
            Workflow.DirectDebit.CreateDummyMandate,
            {directDebitSetUpData: data}
        );

        if (responseData.error) {
            return responseData.error as IditRestException;
        }

        return responseData.dummyAccountExternalId;
    }

    public async validateDirectDebitMandate(data: DirectDebit): Promise<IMandateResponse> {
        data.ssn = SsnFormatter.convertFormat(data.ssn, true);

        const responseData = await super.execute<any, any>(
            Workflow.DirectDebit.ValidateMandate,
            {
                directDebitSetUpData: data
            }
        );

        if (responseData.error) {
            return {isValid: false, error: responseData.error as IditRestException};
        }

        return {isValid: true};
    }

    public async updateContact(data: Contact | DirectDebit | InsuredPersonContact): Promise<void> {
        data.ssn = SsnFormatter.convertFormat(data.ssn, true);

        await super.execute<any, any>(
            Workflow.Contact.UpdateContact,
            data
        );
    }
}
