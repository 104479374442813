import withToggle from "./withToggle";
import {ApmRoute, ApmRouteGroupWithInitialParams, ApmRouteWithInitialParams} from "../elasticApmRum/ApmRoute";

const FeatureRoute = withToggle(ApmRoute);

const FeatureRouteWithInitialParams = withToggle(ApmRouteWithInitialParams);

const FeatureRouteGroupWithInitialParams = withToggle(ApmRouteGroupWithInitialParams);

export { FeatureRoute, FeatureRouteWithInitialParams, FeatureRouteGroupWithInitialParams };
