import {useEffect} from "react";
import {Transaction} from "@elastic/apm-rum";

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * React guarantees the parent component effects are run after the child components effects
 * So once all the child components effects are run, we run the detectFinish logic
 * which ensures if the transaction can be completed or not.
 */
export function useTransactionEffect(effectParams: any[], transaction?: Transaction): void {
    useEffect(() => {
        if (transaction) {
            transaction.end();
        }

        return () => {
            if (transaction) {
                transaction.end();
            }
        }
    }, effectParams);
}
