const LC_REGEX = "^([a-z]{2})(-[A-Z]{2})*$";

export function parseLanguageCode(locale: string): string {
    const regex = new RegExp(LC_REGEX);

    const result = regex.exec(locale);

    if (result && result.length >= 1) {
        return result[1];
    }

    return locale;
}