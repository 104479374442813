import * as React from "react";
import { FormattedMessage } from "react-intl";
import { IconDownload, Link, ConstrainWidth } from "@folksam-digital/ui";
import { OutputComponentBase } from "./OutputComponentBase";

interface IMetadata {
    title: string;
    url?: string;
    classNames?: string;
    modelPath?: string;
}

export abstract class DownloadLinkOutputBase extends OutputComponentBase<void, IMetadata, {}> {
    public abstract render(): React.ReactNode

    protected renderLink(url = "") {

        const {
            title
        } = this.schema;

        const {
            classNames
        } = this.metadata;

        const downloadImageStyles = {
            fill: "#007BCF",
            width: "2rem",
            height: "2rem",
            marginRight: "0.4rem",
            verticalAlign: "middle"
        };

        return (
            <ConstrainWidth className={classNames}>
                <Link to={url}>
                    <IconDownload style={downloadImageStyles} />
                    <FormattedMessage id={title} />
                </Link>
            </ConstrainWidth>
        );
    }
}
