import {DropDownInternal, IDropDownInternalProps, IDropDownMetadata} from "./DropDown";
import {IOption, IOptionMultiSelected, IOptionSelected} from "@folksam-digital/model";
import castArray from "lodash/castArray";
import {isEmpty} from "lodash";
import {injectIntl} from "react-intl";
import {withLocaleContext} from "../withLocaleContext";
import {withFormContext} from "../withFormContext";
import {withViewContext} from "@folksam-digital/ui";

// currently used only in generator
class DropDownMultiSelectInternal extends DropDownInternal {
    constructor(props: IDropDownInternalProps<number | string | IOptionSelected[] | IOptionMultiSelected, IDropDownMetadata>) {
        super(props);
        this.isMulti = true;
    }

    // componentDidMount check if value in classifier override
    protected updateFormDataValue(classifierValues: IOption[]): void {
        if (this.props.formData) {
            const searchValue = this.props.formData as IOptionMultiSelected;
            let valueExists;
            if (castArray(this.schema.type).includes('object') && typeof searchValue === 'object') {
                const searchValues = Object.keys(searchValue) as string[];
                valueExists = classifierValues.some((value) => value.id === searchValues[0]);
            } else {
                super.updateFormDataValue(classifierValues)
            }

            if (!valueExists) {
                this.props.onChange(undefined);
            }
        }
    }

    protected get value() {
        const {formData} = this.props;
        const types = castArray(this.schema.type);
        if (types.includes('object') && !isEmpty(formData)) {
            return Object.values(formData) as IOptionSelected[];
        }

        return null;
    }

    protected onChange(event: any): void {
        const types = castArray(this.schema.type);

        if (types.includes("object") && Array.isArray(event)) {
            // isMulti intended to use only with {[key: string]: IOptionSelected} structure in Questionnaire
            const selectedObj = {} as IOptionMultiSelected;
            event.forEach((optionSelected: IOptionSelected) => {
                selectedObj[optionSelected.value!] = optionSelected;
            });
            this.onChangeWithValidation(selectedObj);
        } else {
            this.onChangeWithValidation(event);
        }
    }
}

const DropDownMultiSelect = injectIntl(withLocaleContext<IDropDownInternalProps<number | string | IOptionSelected[], any>>(withFormContext(withViewContext(DropDownMultiSelectInternal))));
export {DropDownMultiSelect};
