import React from "react";
import {RouteComponentProps} from "react-router";
import {UnregisterCallback} from "history";

function withHistoryBackConstraint<T extends RouteComponentProps<any>>(WrappedComponent: React.ComponentType<T>) {
    return class HistoryBackConstraint extends React.Component<T> {
        private readonly listenerUnregisterCallback: UnregisterCallback;

        constructor(props: T) {
            super(props);

            // Clone location object and push it to history
            props.history.push({
                pathname: props.location.pathname
            });

            // Move back to last cloned location object
            this.listenerUnregisterCallback = props.history.listen((newLocation, action) => {
                if (action !== "PUSH") {
                    props.history.go(1);
                }
            });
        }

        public componentWillUnmount() {
            this.listenerUnregisterCallback();
        }

        public render() {
            return <WrappedComponent {...(this.props as T)} />
        }
    }
}

export default withHistoryBackConstraint;
