import {DigitalApiServiceBase} from "./DigitalApiServiceBase";
import pick from "lodash/pick";
import {ISearchService} from "../../ISearchService";
import {injectable} from "inversify";

interface ISearchParams {
    query?: string;
    other?: any;
    path: string;
    method?: "post" | "get";
}

const validMethods = ["post", "get"];

@injectable()
export class SearchService extends DigitalApiServiceBase implements ISearchService {
    async search(data: ISearchParams): Promise<any> {
        try {
            const { path, method } = data;
            let requestMethod = "get";
            const http = super.getHttpClient(path);

            if (method && validMethods.includes(method.toLowerCase())) {
                requestMethod = method.toLowerCase();
            }

            const response = await http.request({
                url: path,
                method: requestMethod,
                data: pick(data, ["query", "other"])
            });

            return response.data;
        } catch (error) {
            return [];
        }

    }
}
