import * as React from "react";
import {createPortal} from "react-dom";

interface ICustomControlProps {
    position: google.maps.ControlPosition;
    children: React.ReactElement<any, any>;
    map?: google.maps.Map;
}

export const CustomControl: React.FC<ICustomControlProps> = ({map, children, position}) => {
    const [control, setControl] = React.useState<HTMLDivElement>();

    React.useEffect(() => {
        if (map && !map.controls[position].getLength() && !control) {
            const controlDiv = document.createElement("div");
            map.controls[position].push(controlDiv);
            setControl(controlDiv);
        }
        return () => {
            if (map && map.controls[position].getLength() > 0 && control) {
                map.controls[position].pop();
            }
        };
    }, [map, control, position]);

    if(control){
        return createPortal(
            <div>{children}</div>,
            control
        );
    } else {
        return null
    }
};
