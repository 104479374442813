import JourneySuccessBase, {IWithLocaleContextSuccessProps} from "./JourneySuccessBase";
import {
    CatJourney,
    Constants,
    pathof,
    PriceBarFrequency,
    yearMonthDayFormat
} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../../../cms";
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {FormattedMessage} from "react-intl";
import flowRight from "lodash/flowRight";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {withRouter} from "react-router";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {
    B,
    CatIcon,
    defaultTheme as theme,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import CatJourneyModel from "@folksam-digital/model/lib/model/CatJourneyModel";
import {getSuccessPageHeaderValues} from "../../../../Helpers/getSuccessPageHeaderValues";
import {MyPagesButton} from "../../../../components/journey/layout/Success";
import DirectDebitButton from "../../../../components/journey/layout/Success/DirectDebitButton";
import {withLocaleContext} from "../../../../components/journey/form/withLocaleContext";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {Template} from "../../../../components/journey/form/output/helpers";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {getPetSuccessHeaderMessageBoxValues} from "../../../../Helpers/getPetSuccessHeaderMessageBoxValues";
import {RowDiscounts, ListRowItem, RowPrice, RowAddon} from "../common/ListRowItem";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {FeatureToggleUtils} from "@folksam-digital/services";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";
import {CoopBannerWithLink} from "../common/CoopBannerWithLink";

class CatJourneySuccessInternal extends JourneySuccessBase<CatJourney, IWithLocaleContextSuccessProps> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.Cat.Id);
        this.triggerDataLayerEvent(Constants.Journey.Cat.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;
        const successMessageBoxValue = getPetSuccessHeaderMessageBoxValues<CatJourney>(data, Constants.Journey.Cat.Id, this.context)

        if (isEmpty(data)) {
            return <></>;
        }

        data.policy.startDate = new Date(data.policy?.startDate!).toLocaleString(this.props.localeContext.locale, yearMonthDayFormat.reactIntl) as any;

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.Cat.Id}
                    formData={{
                        petName: data?.policy?.insuredObject?.petName,
                    }}
                    backUrl={Constants.Journey.Cat.backUrl}
                    navButton={CloseLink}
                    translations={getSuccessPageHeaderValues<CatJourney>(data, this.context)}
                    doScroll={true}
                >
                    {successMessageBoxValue && <SuccessHeaderMessageBox
                        message={successMessageBoxValue}
                        formData={{
                            petName: data?.policy?.insuredObject?.petName,
                            startDate: data?.policy?.startDate,
                            email: data?.contact?.emailAddress
                        }}
                    />}
                    <SuccessHeaderTable
                        policyNumber={data?.policy?.policyNumber}
                        date={data?.policy?.startDate}
                        email={data?.contact?.emailAddress}
                    />
                    {this.isDirectDebitFormNeeded() &&
                    <DirectDebitButton handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>}
                    <Spacing type={"padding"} bottom={"4"}/>
                    <MyPagesButton/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            {FeatureToggleUtils.isFeatureEnabled("coop") && data?.policy?.partner === Constants.Partners.Coop &&
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <CoopBannerWithLink />
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>}
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <SummaryCard theme={this.summaryCardThemeID}>
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon large={true} component={<CatIcon
                                                        style={{width: 30, paddingRight: "1rem"}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage id='product.cat.success.summaryTable.header'/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList breakpoint={12}>
                                                        <ListRowItem
                                                            id='product.cat.success.insuranceAppliesTo.title'>
                                                            <B>
                                                                {data.policy?.insuredObject?.petName}
                                                            </B>
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            id='product.cat.success.scope.title'>
                                                            <FormattedMessage
                                                                id={`product.cat.sizeOfInsurance.package.${data.policy.coverPlan}.heading`}/>
                                                            <DescriptionList.Price
                                                                component={<Template
                                                                    template={`{{#extractCoverPlanPremium}} {{${pathof(CatJourneyModel.policy.coverPlan)}}} : {{${PriceBarFrequency.Monthly}}} {{/extractCoverPlanPremium}} {{#translate}} {{general.monthlyPremium.suffix}} {{/translate}}`}
                                                                    data={data}/>}
                                                            />

                                                        </ListRowItem>
                                                        <RowAddon
                                                            prefix={CmsHelper.getPrefix(this.context)}
                                                            data={data}
                                                        />
                                                        {
                                                            !isEmpty(data?.policy?.premium?.discounts) &&
                                                            <RowDiscounts premium={data.policy.premium!}
                                                                          tooltipId='product.cat.reviewAndSubmit.discountedDiscounts.tooltip'
                                                            />
                                                        }
                                                        <RowPrice premium={data.policy.premium!}/>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.black,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey='general.form.priceDisclaimer.helpText'/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid.Row>
                                <Grid.Col xl>
                                    {this.renderContactUsSection()}
                                </Grid.Col>
                            </Grid.Row>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const CatJourneySuccess = flowRight(
    withCmsProvider("cat"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint,
    withLocaleContext
)(CatJourneySuccessInternal);

export default CatJourneySuccess;
