import {Dispatch, SetStateAction, useEffect} from "react";
import {useHistory} from "react-router";
import {IDraftService, StorageKeys} from "../../services";
import {IJourneySchema} from "@folksam-digital/model";
import {UrlHelper} from "@folksam-digital/services";

export function useDraftData<T>(draftService: IDraftService, journeyId: string, schema: IJourneySchema, setData: Dispatch<SetStateAction<T | undefined>>): void {
    const history = useHistory();

    useEffect(() => {
        (async function () {
            const draftData = draftService.getDraft<T>();
            setData(draftData);

            if (!draftData) {
                draftService.updateDraft({
                    journeyId: journeyId,
                    backUrl: schema.backUrl
                }, StorageKeys.JOURNEY_ERROR_DATA_KEY);

                history.push(UrlHelper.getUrl(["journey", "error"]));
                return [];
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
