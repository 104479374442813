import React, {Component} from "react";
import isEqual from "react-fast-compare";
import {defaultTheme} from "@folksam-digital/ui";

// Define types for areas in the map
export interface IArea {
    name: string;
    coords: number[];
    href?: string;
    shape: string;
    preFillColor?: string;
    fillColor?: string;
    lineWidth?: number;
    strokeColor?: string;
    _id?: string;
}

// Define the shape of the map prop
export interface IAreaMapping {
    areas: IArea[];
    name: string;
}

// Define props for ImageMapper
interface ImageMapperProps {
    active?: boolean;
    fillColor?: string;
    height?: number;
    imgWidth?: number;
    lineWidth?: number;
    src: string;
    strokeColor?: string;
    width?: number;

    // Callback functions
    onClick?: (area: IArea, index: number, event: React.MouseEvent) => void;
    onMouseMove?: (area: IArea, index: number, event: React.MouseEvent) => void;
    onImageClick?: (event: React.MouseEvent) => void;
    onImageMouseMove?: (area: IArea) => void;
    onLoad?: () => void;
    onMouseEnter?: (area: IArea, index: number, event: React.MouseEvent) => void;
    onMouseLeave?: (area: IArea, index: number, event: React.MouseEvent) => void;

    map: IAreaMapping;
}

// Define state for ImageMapper
interface ImageMapperState {
    map: IAreaMapping;
}

const defaultProps = {
    fillColor: "rgba(255, 255, 255, 0.5)",
    lineWidth: 1,
    map: {
        areas: [],
        name: "image-map-" + Math.random(),
    },
    strokeColor: "rgba(0, 0, 0, 0.5)",
};

export default class ImageMapper extends Component<ImageMapperProps, ImageMapperState> {
    private canvas: HTMLCanvasElement | null = null;
    private ctx: CanvasRenderingContext2D | null = null;
    private container: HTMLDivElement | null = null;
    private img: HTMLImageElement | null = null;

    private styles: {
        container: React.CSSProperties;
        canvas: React.CSSProperties;
        img: React.CSSProperties;
        map?: React.CSSProperties;
    };

    private watchedProps: (keyof ImageMapperProps)[] = [
        "active",
        "fillColor",
        "height",
        "imgWidth",
        "lineWidth",
        "src",
        "strokeColor",
        "width",
    ];

    constructor(props: ImageMapperProps) {
        super(props);

        this.drawrect = this.drawrect.bind(this);
        this.drawcircle = this.drawcircle.bind(this);
        this.drawpoly = this.drawpoly.bind(this);
        this.initCanvas = this.initCanvas.bind(this);
        this.renderPrefilledIAreas = this.renderPrefilledIAreas.bind(this);

        const absPos = { position: "absolute", top: 0, left: 0 };
        this.styles = {
            container: { position: "relative" },
            canvas: { ...absPos, pointerEvents: "none", zIndex: 2 } as any,
            img: { ...absPos, zIndex: 1, userSelect: "none" } as any,
            map: props.onClick ? {cursor: "pointer"} : undefined,
        };

        const map = this.props.map || defaultProps.map; // Initialize state
        this.state = {
            map: { ...map },
        };

        this.imageClick = this.imageClick.bind(this);
        this.imageMouseMove = this.imageMouseMove.bind(this);
    }

    shouldComponentUpdate(nextProps: ImageMapperProps) {
        const propChanged = this.watchedProps.some(
            (prop) => this.props[prop] !== nextProps[prop]
        );
        const result = !isEqual(this.props.map, nextProps.map);

        return result || propChanged;
    }

    componentWillMount() {
        this.updateCacheMap();
    }

    updateCacheMap() {
        const map = this.props.map || defaultProps.map; // Initialize state

        this.setState(
            { map: JSON.parse(JSON.stringify(map)) },
            this.initCanvas
        );
    }

    componentDidUpdate() {
        this.updateCacheMap();
    }

    drawrect(coords: number[], fillColor?: string, lineWidth?: number, strokeColor?: string) {
        const [left, top, right, bot] = coords;
        if (this.ctx) {
            this.ctx.fillStyle = fillColor || this.props.fillColor || defaultProps.fillColor;
            this.ctx.lineWidth = lineWidth || defaultProps.lineWidth;
            this.ctx.strokeStyle = strokeColor || this.props.strokeColor || "";
            this.ctx.strokeRect(left, top, right - left, bot - top);
            this.ctx.fillRect(left, top, right - left, bot - top);
        }
    }

    drawcircle(coords: number[], fillColor?: string, lineWidth?: number, strokeColor?: string) {
        if (this.ctx) {
            this.ctx.fillStyle = fillColor || this.props.fillColor || "";
            this.ctx.beginPath();
            this.ctx.lineWidth = lineWidth || defaultProps.lineWidth;
            this.ctx.strokeStyle = strokeColor || this.props.strokeColor || "";
            this.ctx.arc(coords[0], coords[1], coords[2], 0, 2 * Math.PI);
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.fill();
        }
    }

    drawpoly(coords: number[], fillColor?: string, lineWidth?: number, strokeColor?: string) {
        const points = coords.reduce<number[][]>(
            (acc, v, i, arr) => (i % 2 ? acc : [...acc, arr.slice(i, i + 2)]),
            []
        );
        if (this.ctx) {
            this.ctx.fillStyle = fillColor || this.props.fillColor || "";
            this.ctx.beginPath();
            this.ctx.lineWidth = lineWidth || defaultProps.lineWidth;
            this.ctx.strokeStyle = strokeColor || this.props.strokeColor || "";
            this.ctx.moveTo(points[0][0], points[0][1]);
            points.forEach((p) => this.ctx?.lineTo(p[0], p[1]));
            this.ctx.closePath();
            this.ctx.stroke();
            this.ctx.fill();
        }
    }

    imageMouseMove(area: any) {
        if (this.props.onImageMouseMove) {
            this.props.onImageMouseMove(area);
        }
    }

    initCanvas() {
        if (this.img && this.canvas && this.container) {
            if (this.props.width) { this.img.width = this.props.width; }
            if (this.props.height) { this.img.height = this.props.height; }

            this.canvas.width = this.props.width || this.img.clientWidth;
            this.canvas.height = this.props.height || this.img.clientHeight;
            this.container.style.width = `${this.props.width || this.img.clientWidth}px`;
            this.container.style.height = `${this.props.height || this.img.clientHeight}px`;

            this.ctx = this.canvas.getContext("2d");
            this.ctx!.fillStyle = this.props.fillColor || "";

            if (this.props.onLoad) { this.props.onLoad(); }

            this.renderPrefilledIAreas();
        }
    }

    renderPrefilledIAreas() {
        this.state.map.areas.forEach((area) => {
            if (area.preFillColor && this.hasOwnProperty(`draw${area.shape}`)) {
                // @ts-ignore
                this[`draw${area.shape}`](
                    this.scaleCoords(area.coords),
                    area.preFillColor,
                    area.lineWidth,
                    area.strokeColor
                );
            }
        });
    }

    scaleCoords(coords: number[]) {
        const { imgWidth, width } = this.props;
        const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
        return coords.map((coord) => coord * scale);
    }

    hoverOn(area: any, index: any, event: any) {
        const shape = event.target.getAttribute("shape");

        if (this.hasOwnProperty("draw" + shape)) {
            // @ts-ignore
            this["draw" + shape](
                event.target.getAttribute("coords").split(","),
                area.fillColor,
                area.lineWidth || this.props.lineWidth,
                area.strokeColor || this.props.strokeColor
            );
        }

        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(area, index, event);
        }
    }

    hoverOff(area: any, index: any, event: any) {
        // Does not render "transparent" as a fillColor when fill color was selected before on hoverOn, using same color as panel background in this case.
        const prefillColor = area.preFillColor === "transparent" ? defaultTheme.colors.senary4 : area.preFillColor;
        // @ts-ignore
        this[`draw${area.shape}`](
            event.target.getAttribute("coords").split(","),
            prefillColor,
            area.lineWidth || this.props.lineWidth,
            area.strokeColor || this.props.strokeColor
        );


        if (this.props.active && this.ctx && this.canvas) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.renderPrefilledIAreas();
        }

        if (this.props.onMouseLeave) { this.props.onMouseLeave(area, index, event); }
    }

    imageClick(event: any) {
        if (this.props.onImageClick) {
            event.preventDefault();
            this.props.onImageClick(event);
        }
    }

    click(area: any, index: any, event: any) {
        if (this.props.onClick) {
            event.preventDefault();
            this.props.onClick(area, index, event);
        }
    }

    mouseMove(area: any, index: any, event: any) {
        if (this.props.onMouseMove) {
            this.props.onMouseMove(area, index, event);
        }
    }


    computeCenter(area: IArea) {
        if (!area) { return [0, 0]; }

        const scaledCoords = this.scaleCoords(area.coords);

        switch (area.shape) {
            case "circle":
                return [scaledCoords[0], scaledCoords[1]];
            case "rect":
                const [left, top, right, bot] = scaledCoords;
                return [(left + right) / 2, (top + bot) / 2];
            case "poly":
            default:
                const n = scaledCoords.length / 2;
                const { x: xResult, y: yResult } = scaledCoords.reduce(
                    ({ x, y }, val, idx) =>
                        idx % 2 === 0 ? { x: x + val / n, y } : { x, y: y + val / n },
                    { x: 0, y: 0 }
                );
                return [xResult, yResult];
        }
    }

    renderIAreas() {
        return this.state.map.areas.map((area, index) => {
            const scaledCoords = this.scaleCoords(area.coords);
            const center = this.computeCenter(area);
            const extendedIArea = { ...area, scaledCoords, center };
            return (
                <area
                    alt={area._id || index.toString()}
                    key={area._id || index}
                    shape={area.shape}
                    coords={scaledCoords.join(",")}
                    onMouseEnter={this.hoverOn.bind(this, extendedIArea, index)}
                    onMouseLeave={this.hoverOff.bind(this, extendedIArea, index)}
                    onMouseMove={this.mouseMove.bind(this, extendedIArea, index)}
                    onClick={this.click.bind(this, extendedIArea, index)}
                    href={area.href}
                />
            );
        });
    }

    render() {
        return (
            <div style={this.styles.container} ref={(node) => (this.container = node)}>
                <img
                    style={this.styles.img}
                    src={this.props.src}
                    useMap={`#${this.state.map.name}`}
                    alt=""
                    ref={(node) => (this.img = node)}
                    onLoad={this.initCanvas}
                    onClick={this.imageClick}
                    onMouseMove={this.imageMouseMove as any}
                />
                <canvas ref={(node) => (this.canvas = node)} style={this.styles.canvas} />
                <map name={this.state.map.name} style={this.styles.map}>
                    {this.renderIAreas()}
                </map>
            </div>
        );
    }
}
