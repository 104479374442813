import * as React from "react";
import {CmsCatalog} from "@folksam-digital/services";

export interface IMessages {
    [key: string]: string
}

export interface ICmsContext {
    catalogName: string,
    folderName: string,
    catalog: CmsCatalog
}

export const CmsContext = React.createContext<ICmsContext>({
    catalogName: "",
    folderName: "",
    catalog: {
        messages: {},
        images: {}
    }
});