import {BaseErrorMessage} from "../../product/model/BaseErrorMessage";

export class SupplementExistsError extends BaseErrorMessage {
    protected determineMessage() {
        this.message = "incomeInsurance.supplementExists.description";
    }

    public getMessage(): string {
        return this.message;
    }
}
