import {withRouter} from "react-router";
import {
    defaultTheme as theme,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard,
    TeddyBearIcon
} from "@folksam-digital/ui";
import * as React from "react";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormattedDate, FormattedMessage} from "react-intl";
import {Constants, MemberChildJourney} from "@folksam-digital/model";
import JourneySuccessBase from "./JourneySuccessBase";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import SuccessPageHeaderContent from "../../../../components/journey/layout/Success/SuccessPageHeaderContent";
import {FeatureToggleUtils} from "@folksam-digital/services";
import {ListRowItem, RowPrice} from "../common/ListRowItem";
import {BannerWithLink} from "../common/BannerWithLink";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

class MemberChildJourneySuccessInternal extends JourneySuccessBase<MemberChildJourney> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.MemberChild.Id);
        this.triggerDataLayerEvent(Constants.Journey.MemberChild.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.MemberChild.Id}
                    formData={{contact: data.contact}}
                    backUrl={Constants.Journey.MemberChild.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: "product.memberChild.success.banner.heading",
                        subheader: "product.memberChild.success.banner.subheading",
                        headerText: <FormattedMessage id={`product.memberChild.pageTitle.headerText`}/>
                    }}
                    doScroll={true}
                >
                    <SuccessPageHeaderContent formData={data}
                                              journeyId={Constants.Journey.MemberChild.Id}
                                              handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {FeatureToggleUtils.isFeatureEnabled(`${Constants.Journey.MemberChild.Id}-startSavingBanner`) && <Grid>
                                            <Grid.Row>
                                                <Grid.Col xl>
                                                    <Spacing type='padding' top={"8"}>
                                                        <BannerWithLink
                                                            titleId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.title")}
                                                            textId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.text")}
                                                            buttonTextId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.buttonText")}
                                                            linkId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.link")}
                                                        />
                                                    </Spacing>
                                                </Grid.Col>
                                            </Grid.Row>
                                        </Grid>}
                                        <Spacing type={"padding"} top={8}>
                                            <SummaryCard
                                                theme={this.summaryCardThemeID}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<TeddyBearIcon
                                                        style={{marginRight: '10px'}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id="product.memberChild.success.insurance.name"/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <Spacing bottom={4}>
                                                        <DescriptionList>
                                                            <ListRowItem id="general.review.scope.title">
                                                                <FormattedMessage id={`product.memberChild.coverPlan.${data.policy.coverPlan}`}/>
                                                            </ListRowItem>
                                                            <ListRowItem
                                                                id="general.appliesTo.title">
                                                                <span>{data.childContact.firstName} {data.childContact.lastName}</span>
                                                            </ListRowItem>
                                                            <ListRowItem
                                                                id="product.memberChild.success.insurance.member">
                                                                <span>{data.contact.firstName} {data.contact.lastName}</span>
                                                            </ListRowItem>
                                                            <ListRowItem
                                                                id="general.startDate.title">
                                                                <FormattedDate value={data.policy?.startDate}/>
                                                            </ListRowItem>
                                                            <ListRowItem
                                                                id="general.paymentMethod.title">
                                                                <FormattedMessage
                                                                    id="product.memberChild.success.insurance.paymentMethod"/>
                                                            </ListRowItem>
                                                            <RowPrice premium={data.policy.premium!}/>
                                                        </DescriptionList>
                                                    </Spacing>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>

                            <Spacing type={"padding"} top={"3"}>
                                <P style={{
                                    color: theme.colors.senary1,
                                    fontFamily: theme.fonts.mono,
                                    fontSize: theme.textSizes.sm,
                                    paddingBottom: theme.padding.md
                                }}>
                                    <FormattedMarkdown messageKey="general.form.priceDisclaimer.helpText"/>
                                </P>
                            </Spacing>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const MemberChildJourneySuccess = flowRight(
    withCmsProvider("memberChild"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint
)(MemberChildJourneySuccessInternal);

export default MemberChildJourneySuccess;
