import {IDraftService, StorageKeys} from "../IDraftService";
import {injectable} from "inversify";
import {IStorage} from "../IStorage";

@injectable()
export class DraftService implements IDraftService {
    private readonly storage: IStorage;

    constructor(storage: IStorage) {
        this.storage = storage;
    }

    public hasDraft(sessionKey: StorageKeys = StorageKeys.JOURNEY_FORM_DATA_KEY): boolean {
        return this.storage.has(sessionKey);
    }

    public getDraft<T>(sessionKey: StorageKeys = StorageKeys.JOURNEY_FORM_DATA_KEY): any {
        return this.storage.get<T>(sessionKey);
    }

    public updateDraft(data: any, sessionKey: StorageKeys = StorageKeys.JOURNEY_FORM_DATA_KEY): void {
        this.storage.set(sessionKey, data);
    }

    public removeDraft(sessionKey: StorageKeys = StorageKeys.JOURNEY_FORM_DATA_KEY): void {
        this.storage.remove(sessionKey);
    }
}
