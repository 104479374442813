import "core-js/stable";
import "events-polyfill";
import {apm} from "./rum";
import "./inversify.config";
import React from "react";
import { createRoot } from 'react-dom/client';
import "./styles/theme.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Support IE and safari and mobile phone browsers
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/sv";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/sv";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/sv";
import "@formatjs/intl-datetimeformat/polyfill";
import "@formatjs/intl-datetimeformat/locale-data/en";
import "@formatjs/intl-datetimeformat/locale-data/sv";
import "@formatjs/intl-datetimeformat/add-all-tz";

apm.setInitialPageLoadName("Portal");
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
