import {BaseErrorMessage} from "../../product/model/BaseErrorMessage";

export class CustomerTooOldError extends BaseErrorMessage {
    protected determineMessage() {
        this.message = "incomeInsurance.customerTooOld.description";
    }

    public getMessage(): string {
        return this.message;
    }
}
