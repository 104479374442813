import * as React from "react";
import flowRight from "lodash/flowRight";
import {ConstrainWidth, SpacingLine} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import {IErrorParams} from "../journeyErrorTypes";
import ErrorBase, {IErrorBaseState} from "../../ErrorBase";
import {
    IOnTriggerDataLayerEvent,
    SatelliteEvents,
    SatelliteEventTransactionTypes
} from "../../../analytics/new/BaseAnalytics";
import {CmsContext, ICmsContext} from "../../../cms";
import {StorageKeys} from "../../../services";
import withCmsProvider from "../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../components/general/withDataAnalytics";
import JourneyErrorPage from "../JourneyErrorPage";
import FormattedMarkdown from "../../../components/FormattedMarkdown";
import {IncomeInsuranceErrorMessageDelegator} from "./model/IncomeInsuranceErrorMessageDelegator";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import {getAnalytics, AnalyticsType} from "../../../analytics/AnalyticsSwitch";
import {LinkButton} from "../../../components/journey/form/input/LinkButton";

export class IncomeInsuranceErrorInternal<TProps extends IErrorParams, TState extends IErrorBaseState> extends ErrorBase<TProps & IOnTriggerDataLayerEvent, TState> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    public get data(): TProps {
        return this.draftService.getDraft<TProps>(StorageKeys.JOURNEY_ERROR_DATA_KEY) as TProps;
    }

    public get params(): TProps {
        return this.draftService.getDraft<TProps>(StorageKeys.PARAMS) as TProps;
    }

    public async componentDidMount() {
        const message = this.prepareErrorMessage();

        this.setState({message: message.getMessage()});

        if (this.data?.journeyId === Constants.Journey.IncomeAddon.Id) {
            // Trigger Analytics Error Lead Start
            this.props.onTriggerDataLayerEvent!({
                messages: this.context.catalog.messages,
                journeyId: this.data?.journeyId || "",
                currentStep: "error",
                transactionType: SatelliteEventTransactionTypes.Lead,
                event: SatelliteEvents.Start,
                errorMessage: message.getMessage()
            });
        }
    }

    private prepareErrorMessage(): IncomeInsuranceErrorMessageDelegator {
        return new IncomeInsuranceErrorMessageDelegator(this.data ? this.data : {});
    }

    public render(): React.ReactNode {
        const data = this.data;
        const params = this.params;

        return (
            <JourneyErrorPage params={{...data, ...params}}>
                {!!this.state.message &&
                    <React.Fragment>
                        <FormattedMarkdown messageKey={CmsHelper.withPrefix(this.context, this.state.message!)}/>
                        <SpacingLine y={'3'}/>
                        <ConstrainWidth center={true}>
                            <LinkButton url={Constants.Links.personalData}>
                                <FormattedMarkdown
                                    messageKey={CmsHelper.withGeneralPrefix("step.personal")}/>
                            </LinkButton>
                        </ConstrainWidth>
                    </React.Fragment>
                }
            </JourneyErrorPage>
        );
    }
}

const IncomeInsuranceError = flowRight(
    withCmsProvider("error"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product))
)(IncomeInsuranceErrorInternal);

export default IncomeInsuranceError;
