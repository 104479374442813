import * as React from "react";
import {useState} from "react";
import {FormattedMessage, FormattedNumber} from "react-intl";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {Constants, IJourneySchema, IncomeChangeJourney} from "@folksam-digital/model";
import {
    B,
    DateCalendarIcon,
    defaultTheme as theme,
    DescriptionList,
    DescriptionListResponsive,
    Grid,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import {CmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ProductContactUsSection} from "../../../../components/journey/layout/Success";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {IDraftService} from "../../../../services";
import {IRowDefinition, SummaryCardRowList} from "../../addOn/success/SummaryCardRowList";
import {useDraftData} from "../../../../util/hooks/useDraftData";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {useTriggerDataLayerEvent} from "../../../../util/hooks/useTriggerDataLayerEvent";
import {AnalyticsType, getAnalytics} from "../../../../analytics/AnalyticsSwitch";

const IncomeChangeSuccessView = (props: any) => {
    const draftService = container.get<IDraftService>(Types.DraftService);
    const schema = {} as IJourneySchema;
    const [data, setData] = useState<IncomeChangeJourney>();
    useDraftData<IncomeChangeJourney>(draftService, Constants.Journey.IncomeChange.Id, schema, setData);

    const cmsContext = React.useContext(CmsContext);
    const {onTriggerDataLayerEvent} = props;
    
    useTriggerDataLayerEvent(cmsContext, onTriggerDataLayerEvent, data, Constants.Journey.IncomeChange.Id);

    setJourneyCompleted(draftService, Constants.Journey.IncomeChange.Id);

    const summaryCardList = React.useMemo(() => {
        if (isEmpty(data)) {
            return [];
        }

        let totalInsuredAmount;
        if (data?.product === Constants.IncomeInsuranceType.SalaryCap) {
            totalInsuredAmount = {
                term: <FormattedMessage
                    id={"incomeInsurance.incomeChange.salaryCap.reviewAndSubmit.table.totalInsuredAmount"}/>,
                definition: <B><FormattedNumber value={Math.round(Number(data?.cover?.inputSetValue) || 0)}/>
                    &nbsp;<FormattedMessage id="incomeInsurance.incomeChange.price.suffix"/></B>,
            };
        }

        return [
            {
                term: <FormattedMessage id="incomeInsurance.incomeChange.reviewAndSubmit.appliesTo.title"/>,
                definition: <B><span>{data?.contact.firstName} {data?.contact.lastName}</span></B>,
            },
            {
                term: <FormattedMessage id={data?.product === Constants.IncomeInsuranceType.Time
                    ? "incomeInsurance.incomeChange.time.reviewAndSubmit.table.income"
                    : "incomeInsurance.incomeChange.salaryCap.success.table.income"}/>,
                definition: <B>{data?.product === Constants.IncomeInsuranceType.Time
                    ? (<FormattedMessage id={"incomeInsurance.incomeChange.time.success.incomeValue"}
                                         values={{incomeValue: data?.cover?.inputSetValue}}/>)
                    : (<FormattedNumber value={Math.round(Number(data?.cover?.inputValue!))}/>)}
                    &nbsp;
                    <FormattedMessage id="incomeInsurance.incomeChange.price.suffix"/></B>,
            },
            totalInsuredAmount,
            {
                definition: <>
                    <FormattedMessage id="general.monthlyPrice.title"/>
                    {data?.product === Constants.IncomeInsuranceType.Time
                        ? <DescriptionList.Price
                            component={<FormattedPrice
                                suffixId={"general.monthlyPremium.suffix"}
                                value={data?.cover?.monthlyPremium!}
                            />}
                            background
                        />
                        : <DescriptionList.Price
                            component={<FormattedPrice
                                suffixId={"general.monthlyPremium.suffix"}
                                value={Math.round(data?.cover?.monthlyPremium!)}
                            />}
                            background
                        />
                    }
                </>,
            }
        ].filter(value => value) as IRowDefinition[];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isEmpty(data)) {
        return <></>;
    }

    return (
        <FormContext.Provider value={{data} as IFormContext}>
            <ComponentBackgroundWrapper theme={2} noPaddingBottom={true}>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.IncomeChange.Id}
                    formData={{contact: data?.contact}}
                    backUrl={Constants.Journey.IncomeChange.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: CmsHelper.withPrefix(cmsContext, "success.banner.heading"),
                        subheader: CmsHelper.withPrefix(cmsContext, "success.banner.subheading"),
                        headerText: <FormattedMessage id={`${CmsHelper.withPrefix(cmsContext, "pageTitle.headerText")}`} />
                    }}
                    doScroll={false}
                    themeId={Constants.Themes.LighterBlue}
                >
                    <SuccessHeaderTable date={data?.startDate} dateTermId="incomeInsurance.incomeChange.success.dateInsuranceTakesEffect"/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} y={"md"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" y="md">
                                            <SummaryCard
                                                theme={Constants.Themes.LighterBlue as number}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<DateCalendarIcon
                                                        style={{width: 30, paddingRight: '1rem'}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id={data?.product === Constants.IncomeInsuranceType.Time
                                                                ? 'incomeInsurance.incomeChange.time.reviewAndSubmit.table.header'
                                                                : 'incomeInsurance.incomeChange.salaryCap.reviewAndSubmit.table.header'}
                                                        />
                                                    </SummaryCard.HeaderText>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionListResponsive breakpoint={12}>
                                                        <SummaryCardRowList list={summaryCardList}/>
                                                    </DescriptionListResponsive>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <ProductContactUsSection
                                            altPhoneNumberMessageKey={"incomeInsurance.incomeChange.success.contact.phone"}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </ComponentBackgroundWrapper>

        </FormContext.Provider>
    )
};

const IncomeChangeJourneySuccess = flowRight(
    withCmsProvider("incomeChange"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withHistoryBackConstraint
)(IncomeChangeSuccessView);

export default IncomeChangeJourneySuccess;
