import * as React from "react";
import {FormattedMessage, WrappedComponentProps} from "react-intl";
import {IconInfoV2} from "@folksam-digital/ui";
import {IChoiceComponentMetadataBase, IChoiceComponentStateBase} from "./ChoiceComponentBase";
import {IFormContext} from "../FormContext";
import {IFormComponentProps} from "../FormComponentBase";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CheckBox} from "./CheckBox";
import {InformationBoxContainer, ITheme} from "../containers/InformationBoxContainer";
import {InputComponentBase} from "./InputComponentBase";
import flowRight from "lodash/flowRight";
import injectSheet from "react-jss";
import classNames from "classnames";

const customLabelStyles = ["boldLabel", "regularLabel"] as const;
type customLabelStylesType = typeof customLabelStyles[number];

interface IMetadata extends IChoiceComponentMetadataBase {
    customLabelStyle?: customLabelStylesType;
    classNames?: string;
    colorTheme?: ITheme;
    titleMessageKey: string;
    markdownInfoMessageKey: string;
}

interface ICheckBoxInternalProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    classes?: any;
}

interface ICheckBoxState extends IChoiceComponentStateBase {
}

const cssStyles = {
    icon: {
        fill: "#007BCF",
        width: "24px",
        height: "24px",
        marginRight: "0.5rem",
        verticalAlign: "middle"
    },
    title: {
        fontFamily: "Sanchez",
        fontSize: "16px",
        lineHeight: "23.2px",
        "text-align": "left",
        fontWeight: 600,
        color: "#003E80",
        display: "flex",
        alignItems: "center",
        paddingBottom: "1rem"
    },
    body: {
        fontFamily: "Sanchez",
        fontSize: "16px",
        lineHeight: "23.2px",
        "text-align": "left",
        fontWeight: 500,
        color: "#22252B"
    },
    checkBox: {
        paddingTop: "28px",
        paddingBottom: "20px"
    }
};

class ImportantInformationCheckBoxInternal extends InputComponentBase<boolean, IMetadata, ICheckBoxState, ICheckBoxInternalProps<boolean, IMetadata>> {
    protected readonly infoBoxRef: React.RefObject<any>;

    constructor(props: ICheckBoxInternalProps<boolean, IMetadata>, context?: IFormContext) {
        super(props, context);
        this.infoBoxRef = React.createRef();
    }

    public render() {
        const {classes} = this.props;
        const {
            colorTheme = "importantInformation",
            markdownInfoMessageKey,
            titleMessageKey
        } = this.metadata;

        return (
            <InformationBoxContainer ref={this.infoBoxRef} classNames={this.metadata.classNames} theme={colorTheme}>
                <div className={classNames(classes.title)}>
                    <IconInfoV2 className={classNames(classes.icon)}/>
                    <FormattedMessage id={titleMessageKey}/>
                </div>
                <div className={classNames(classes.body)}>
                    <FormattedMarkdown messageKey={markdownInfoMessageKey}/>
                </div>
                <div className={classNames(classes.checkBox)}>
                    <CheckBox {...this.getLayoutProps()}/>
                </div>
            </InformationBoxContainer>
        );
    }
}

const ImportantInformationCheckBox = flowRight(injectSheet(cssStyles, {inject: ["classes"]} as any))(ImportantInformationCheckBoxInternal);
export {ImportantInformationCheckBox};
