import React, {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {Button, IconBankID, Spacing, DisplayIcon} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import DirectDebitButton from "../../../../components/journey/layout/Success/DirectDebitButton";
import {SuccessHeaderTable} from "./SuccessHeaderTable";

interface ISuccessPageHeaderContentProps {
    formData: any;
    handleDirectDebitRedirect: () => void;
    isContactLegalEntity?: boolean
    journeyId: string;
}

const SuccessPageHeaderContent: React.FC<ISuccessPageHeaderContentProps> = ({ formData, handleDirectDebitRedirect, journeyId, isContactLegalEntity }) => {
    const { policy: { policyNumber, startDate, proposalNumber }, contact: { emailAddress } } = formData || {};
    // SuccessPageHeaderContent only used for Product journeys.
    const myPagesUrl = Constants.Links.signInUrlProtectedProduct;

    const displayDirectDebitForm = useMemo(() => (!isContactLegalEntity && formData && formData.policy?.paymentDetails?.paymentMethod === Constants.PaymentMethod.DirectDebit && formData.policy.hasValidDirectDebitMandates), [formData, isContactLegalEntity]);

    const handleRedirect = useCallback((url: string) => {
        window.open(url, "_blank");
    }, []);

    const myPagesClickHandler = useCallback(() => {
        handleRedirect(myPagesUrl);
    }, [myPagesUrl, handleRedirect]);

    return (
        <>
            <SuccessHeaderTable
                policyNumber={policyNumber ? policyNumber : proposalNumber}
                date={startDate}
                email={emailAddress}
            />
            <Spacing type={"padding"} bottom={'4'}/>
            {displayDirectDebitForm && <DirectDebitButton handleDirectDebitRedirect={handleDirectDebitRedirect}/>}
            <Spacing type={"padding"} bottom={'4'}/>
            <Button full={true} onClick={myPagesClickHandler}>
                <DisplayIcon style={{marginRight: 8}} baseline={true} large={false} icon={<IconBankID/>}/>
                <FormattedMessage id={"general.success.bannerPanel.myPages.header"}/>
            </Button>

        </>
    );
};

export default SuccessPageHeaderContent;
