import * as React from "react";
import {Button, defaultTheme, IconCloseV2, IconEditV2, DisplayIcon} from "@folksam-digital/ui";

interface IRemoveButtonProps {
    onClick: (index: number) => void;
    uniqueKey: string;
    index?: number;
    id?: string;
    editIcon?: boolean;
}

const styles = {
    color: defaultTheme.colors.senary1,
    padding: 5,
    width: 20,
    height: 20,
    borderRadius: 20,
    pointerEvents: "all",
    cursor: "pointer",
    verticalAlign: "middle"
}

export class RemoveButton extends React.PureComponent<IRemoveButtonProps> {
    public render() {
        const { index = 0, editIcon } = this.props;
        return (
            <div style={{display: "flex", height: "100%"}}>
                <Button
                    id={this.props.id}
                    key={this.props.uniqueKey}
                    type="button"
                    asLink={true}
                    onClick={() => this.props.onClick(index)}
                >
                    {editIcon ? <DisplayIcon
                                        style={{...styles}}
                                        baseline={true}
                                        medium={true}
                                        icon={<IconEditV2/>}/> : <IconCloseV2 style={{...styles}}/> }
                </Button>
            </div>
        );
    }
}
