import {BaseErrorMessage} from "../../product/model/BaseErrorMessage";

export class StopCustomerError extends BaseErrorMessage {
    protected determineMessage() {
        this.message = "claim.stopCustomerError.description";
    }

    public getMessage(): string {
        return this.message;
    }
}
