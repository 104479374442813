import {BaseAnalytics, IOnTriggerDataLayerEventParams, JourneyType} from "./BaseAnalytics";

export interface DirectDebitMapping {
    applicationName: string;
    applicationStatus?: string;
}

export class DirectDebitAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const applicationName = params.messages["general.adobe.selfService.directDebit"];

        const datalayerObj: DirectDebitMapping = {
            applicationName
        };

        if (params.applicationStatus) {
            datalayerObj.applicationStatus = params.messages[`general.adobe.applicationStatus.${params.applicationStatus}`]
        }

        const trackKey = this.getTrackingKey(`${params.transactionType}${params.event}`);

        this.setDataLayer(datalayerObj, trackKey, params, JourneyType.SelfService);
    }
}
