import * as React from "react";
import {IBannerIconBrandLogoRenderer} from "./bannerIconBrandLogoHelper";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";
import {Markdown} from "../../../../Markdown";

export const iconLogoRenderer: IBannerIconBrandLogoRenderer = ({constants, iconsCollection, itemKey, itemName, cmsContext, intl, IconComponent}) => {
    if (!constants[itemKey] && !iconsCollection[itemKey] && !IconComponent) {
        return undefined;
    }

    const key = constants[itemKey] ? constants[itemKey] : itemKey;
    const { Icon, name, messageId, valueKey } = {
        name: key,
        Icon: IconComponent ? IconComponent as React.ElementType : iconsCollection[key] as React.ElementType,
        messageId: 'bannerLogo.inCollaborationWith',
        valueKey: itemName,
    };

    return {
        icon: <Icon/>,
        content: <Markdown source={intl.formatMessage({ id: CmsHelper.withPrefix(cmsContext, messageId) }, { [valueKey]: name })} />
    };
};
