import * as React from "react";
import {useCallback, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {NavigationModalButton} from "../common/modalButton";
import {logoHref} from "../common/params";
import {clearSessionStorage} from "../common/clearSessionStorage";
import {UrlHelper} from "@folksam-digital/services";

interface IProps {
    backUrl?: string;
    stepBack?: boolean;
    dynamicBackUrl?: string;
}

const CancelButtonInternal = (
    {
        backUrl,
        dynamicBackUrl,
        ...props
    }: IProps) => {
    const onReturn = useCallback(() => {
        clearSessionStorage();
        window.open(UrlHelper.getUrlWithoutSecureSubDomain(window.location.origin, dynamicBackUrl || backUrl || logoHref), "_self");
    }, [backUrl, dynamicBackUrl]);

    const messages = useMemo(() => ({
        modalHeaderMessage: "general.cancel.confirmation.heading",
        modalContentMessage: "general.cancel.confirmation.body"
    }), []);

    return (
        <NavigationModalButton
            onSubmit={onReturn}
            linkMessageId="general.navigate.cancel"
            modalHeaderMessage={
                <FormattedMessage
                    id={messages.modalHeaderMessage}
                />
            }
            modalContentMessage={
                <FormattedMessage
                    id={messages.modalContentMessage}
                />
            }
            {...props}
        />
    );
};

export const CancelModalButton = React.memo(CancelButtonInternal);
