import React from 'react';
import {Discount} from "@folksam-digital/model";
import {FormattedMessage} from "react-intl";
import {B, FlexRow} from '@folksam-digital/ui';
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";

interface IPriceBarDiscountProps {
    totalDiscount?: number;
    discounts?: Discount[];
}

export const PriceBarDiscounts = ({totalDiscount, discounts}: IPriceBarDiscountProps) =>
    (totalDiscount && Math.abs(totalDiscount) > 0) ?
        <>
            <div key="discount">
                <div className="text-wrap">
                    <FormattedMessage id="general.priceBar.premium.discount"/>
                </div>
                <FlexRow>
                    <FlexRow className="text-wrap">
                        {(!!discounts && discounts.length > 0) &&
                            discounts.map((discount: Discount) => {
                                return (
                                    <B key={`table-row-index-${discount.id}`}>{discount.reason}</B>
                                );
                            })
                        }
                    </FlexRow>
                    <FlexRow className="price-wrap discounts">
                        {(!!discounts && discounts.length > 0) ? <></> :
                            <div className="price">
                                <FormattedPrice value={totalDiscount} suffixId={"general.yearlyPremium.suffix"}/>
                            </div>
                        }

                        {(!!discounts && discounts.length > 0) &&
                            discounts.map((discount: Discount) => {
                                return (
                                    <div key={`table-row-index-${discount.id}`} className="price">
                                        <FormattedPrice value={discount.amount!} suffixId={"general.yearlyPremium.suffix"}/>
                                    </div>
                                );
                            })
                        }
                    </FlexRow>
                </FlexRow>
            </div>
        </> : <></>;
