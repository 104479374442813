import * as React from "react";

interface IInfoWindowProps extends google.maps.InfoWindowOptions {
    setInfoContent: (content?: string) => void;
    map?: google.maps.Map;
}


export const InfoWindow: React.FC<IInfoWindowProps> = ({map, content, setInfoContent}) => {
    const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();

    React.useEffect(() => {
        if (!infoWindow) {
            setInfoWindow(new google.maps.InfoWindow());
        }

        // remove info window from map on unmount
        return () => {
            if (infoWindow) {
                infoWindow.close();
            }
        };
    }, [infoWindow]);

    React.useEffect(() => {
        if (map && infoWindow && content) {
            infoWindow.setContent(content);
            infoWindow.setPosition(map.getCenter());
            infoWindow.open(map)
        } else if (map && infoWindow && !content) {
            infoWindow.close();
        }
    }, [map, content, infoWindow]);

    React.useEffect(() => {
        if (infoWindow) {
            infoWindow.addListener("closeclick", setInfoContent);
        }

        return () => {
            if (infoWindow) {
                ["closeclick"].forEach((eventName) => {
                    google.maps.event.clearListeners(infoWindow, eventName)
                });
            }
        };

    }, [infoWindow, setInfoContent]);

    return null;
};
