import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useIntl } from 'react-intl';
import { startsWith } from 'lodash';
import {FormPhoneInput, CountryData} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import {countries, guessSelectedCountry} from "../journey/form/input/PhoneNumberHelpers";
import {PhoneNumberFormatter} from "../../Helpers/PhoneNumberFormatter";

interface IPhoneNumberInputProps {
    formData: string;
    value?: string;
    onChange: (event: any) => void;
    onBlur?: (event: any) => void;
    disabled?: boolean;
    id: string;
    invalid?: boolean;
}

interface IPhoneNumberCountryData {
    dialCode: string;
}

interface IPhoneNumberConfig {
    enableAreaCodes: boolean;
    enableTerritories: boolean;
    regions: string;
    onlyCountries: string[];
    preferredCountries: string[];
    excludeCountries: string[];
    preserveOrder: string[];
    masks: { [key: string]: string };
    priority: number | null;
    areaCodes: number | null;
    localization: { [key: string]: any };
    prefix: string;
    alwaysDefaultMask: boolean;
}

const useOnce = (initializer: (props: any) => any, props: any): any => {
    const ref = useRef();

    if (!ref.current) {
        ref.current = initializer(props);
    }

    return ref.current;
};

const wrapInFakeEvent = (value: any, name: string, event: any) => ({
    target: {
        value,
        name
    },
    originalEvent: event,
    isFakeEvent: true,
    preventDefault: () => {
        console.warn(
            'This is a fake event that mimics the structure of the React SyntheticEvent but it does not implement its methods.'
        );
    },
    stopPropagation: () => {
        console.warn(
            'This is a fake event that mimics the structure of the React SyntheticEvent but it does not implement its methods.'
        );
    }
});

const phoneConfig: IPhoneNumberConfig = {
    enableAreaCodes: false,
    enableTerritories: false,
    regions: '',
    onlyCountries: Object.keys(countries),
    preferredCountries: [],
    excludeCountries: [],
    preserveOrder: [],
    masks: {se: '...-... .. ..'},
    priority: null,
    areaCodes: null,
    localization: {},
    prefix: '+',
    alwaysDefaultMask: false
};

const loadCountriesData = (configData: IPhoneNumberConfig) => {
    return new CountryData(configData);
}

const returnConfig = (configData: IPhoneNumberConfig) => {
    return configData;
}

const PhoneNumberInputInternal: React.FC<IPhoneNumberInputProps> = React.forwardRef((props, ref) => {
    const intl = useIntl();
    const { onBlur, id, value, onChange } = props;
    const inputRef = useRef<{ numberInputRef: any }>({ numberInputRef: null });
    const config = useOnce(returnConfig, phoneConfig);
    const { onlyCountries } = useOnce(loadCountriesData, config);
    const [country] = useState(Constants.Country.Sweden.alpha2Code);
    const [countryGuess, setCountryGuess] = useState<any>();

    useEffect(() => {
        if (value) {
            const foundCountryGuess = guessSelectedCountry(value.substring(0, 6), country, onlyCountries) || 0;
            setCountryGuess(foundCountryGuess);
        } else {
            setCountryGuess(undefined)
        }
    }, [value, setCountryGuess, country, onlyCountries]);

    const onFormatNumber = (text: string | undefined, countryObj: any) => {
        let caretPosition;
        if (countryObj.countryCode === '46' && text) {
            const firstChar = text.charAt(0);
            if (firstChar !== '0') {
                text = '0' + text;
                if (inputRef.current && inputRef.current.numberInputRef) {
                    caretPosition = inputRef.current.numberInputRef.selectionStart;
                    caretPosition++;
                }
            }
        }
        return { formattedNumber: text, caretPosition };
    };

    const getNumberWithoutDialCode = (numberValue?: string) => {
        if (!numberValue) {
            return numberValue;
        }
        const countryGuessInternal = guessSelectedCountry(numberValue.substring(0, 6), country, onlyCountries) || 0;
        if (countryGuessInternal.dialCode && startsWith(numberValue, countryGuessInternal.dialCode)) {
            return numberValue.substring(countryGuessInternal.dialCode.length);
        }
        return numberValue;
    };

    const onChangeInternal = useCallback((inputValue: string, data: IPhoneNumberCountryData, event: React.FocusEvent<any>, formattedValue: any) => {
        const formattedInputValue = PhoneNumberFormatter.removeSpecialChars(inputValue);
        let resultValue;
        if (formattedInputValue.length > 0) {
            resultValue = data.dialCode + formattedInputValue;
        } else {
            resultValue = undefined;
        }

        if (event?.type !== "change") {
            resultValue = undefined;
        }

        let fakeEvent = event;
        if (!event.target) {
            fakeEvent = wrapInFakeEvent(resultValue, id, event) as any;
        }
        fakeEvent.target.value = resultValue;
        fakeEvent.target.id = id;

        onChange(fakeEvent);
    }, [onChange, id]);

    const onBlurInternal = useCallback((event: any, countryData: IPhoneNumberCountryData) => {
        event.target.id = id;
        if (onBlur && typeof onBlur === "function") {
            onBlur(event);
        }
    }, [onBlur, id]);

    const { disabled, invalid, formData } = props;
    const onlyNumberPart = getNumberWithoutDialCode(formData);

    return (
        <FormPhoneInput value={onlyNumberPart}
                        placeholder={intl.formatMessage({ id: "general.phoneNumber.placeholder" })}
                        onChange={onChangeInternal}
                        onBlur={onBlurInternal}
                        ref={inputRef}
                        localization={countries}
                        disableCountryCode={true}
                        formatNumber={onFormatNumber}
                        country={countryGuess ? countryGuess.iso2 : country}
                        onlyCountries={Object.values(Constants.Country).map(countryValue => countryValue.alpha2Code)}
                        invalid={invalid}
                        jumpCursorToEnd={false}
                        masks={{ se: '...-... .. ..' }}
                        disabled={disabled}
        />
    );
});

const PhoneNumberInput = PhoneNumberInputInternal;

export { PhoneNumberInput };
