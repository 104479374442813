import * as React from 'react';
import {ILocaleContext, LocaleContext} from "../../../intl/LocaleContext";


export function withLocaleContext<P extends {localeContext?: ILocaleContext}>(Component: React.ComponentType<P>) {
    return function LocaleComponent(props: Pick<P, Exclude<keyof P, keyof 'localeContext'>>) {
        return (
            <LocaleContext.Consumer>
                {(context: any) => <Component {...props as P} localeContext={{...context}} />}
            </LocaleContext.Consumer>
        )
    }
}
