import * as React from "react";
import {Spacing} from "@folksam-digital/ui";
import map from "lodash/map";
import {IOptCardPolicy, IOptCardProps} from "../input/OptCard";

export interface IOptCardGroupListProps {
    header: JSX.Element | JSX.Element[];
    listItemComponent: React.ComponentType<IOptCardProps>;
    policies: IOptCardPolicy[];
    defaultValue: boolean | "";
    group: string;
    onChange: (value: IOptCardPolicy, index: number) => void;
    getError: (idx: number) => string | undefined;
}

export default class OptCardGroupList extends React.Component<IOptCardGroupListProps> {
    public render() {
        const { listItemComponent: Component, header, defaultValue, onChange, getError, group } = this.props;

        return (
            <div>
                {!!header && <Spacing top={'sm'} bottom={'sm'}>{header}</Spacing>}
                {map(this.props.policies, (policy, idx) =>
                    <Component key={idx}
                               defaultValue={defaultValue}
                               group={group}
                               index={policy.index}
                               error={getError(policy.index)}
                               onChange={onChange}
                               formData={policy} />)}
            </div>
        )
    }
};
