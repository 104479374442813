type PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;

export default function extractRecordValues(fields: PlaceDetailsRequest['fields'], record: any, fieldsToOmit?: string[]): string {
    if (!fields) {
        return '';
    }

    return fields
        .reduce((arr: any[], field: string) => {
            if (fieldsToOmit && fieldsToOmit.includes(field)) {
                arr.push(undefined);
            } else {
                arr.push(record[field]);
            }
            return arr;
        }, [])
        .filter((value: any) => !!value)
        .join(', ');
}
