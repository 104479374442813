import {Constants} from "@folksam-digital/model";
import {ICmsContext} from "../../../../../cms";
import {carBannerIconLogoGenerator} from "./carBannerIconLogoGenerator";
import {genericUnionBannerIconLogoGenerator} from "./bannerIconUnionLogoGenerator";
import {ReactNode} from "react";
import {IntlShape} from "react-intl";
import {coopBannerIconLogoGenerator} from "./coopBannerIconLogoGenerator";

export interface IBannerIconNewCollaboration {
    icon: ReactNode;
    content: ReactNode;
}

interface ILogoSettings {
    constants: { [key: string]: string },
    iconsCollection: Record<string, ReactNode>,
    itemKey: any,
    itemName: string,
    cmsContext: ICmsContext,
    intl: IntlShape,
    IconComponent?: ReactNode;
}

export type IBannerIconBrandLogoRenderer = (arg: ILogoSettings) => IBannerIconNewCollaboration | void;

export type IBannerIconBrandLogoGenerator = (formData: Record<string, any>, cmsContext: ICmsContext, intl: IntlShape) => IBannerIconNewCollaboration | void;

interface IBannerIconBrandLogoMap {
    [key: string]: IBannerIconBrandLogoGenerator;
}

export const bannerIconBrandLogoHelper: IBannerIconBrandLogoMap = {
    [Constants.Journey.Car.Id]: carBannerIconLogoGenerator,
    [Constants.Journey.MemberChild.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeRental.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeStudent.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeBuilding.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeApartment.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeVacation.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.ShoppingCart.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.OptInOptOut.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.HomeCondominium.Id]: genericUnionBannerIconLogoGenerator,
    [Constants.Journey.Cat.Id]: coopBannerIconLogoGenerator,
    [Constants.Journey.Dog.Id]: coopBannerIconLogoGenerator,
    [Constants.Journey.IndividualChild.Id]: coopBannerIconLogoGenerator,
};
