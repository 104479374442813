import * as React from "react";
import flowRight from "lodash/flowRight";
import {CmsContext, ICmsContext} from "../../cms";
import ErrorBase, {IErrorBaseState} from "../ErrorBase";
import ErrorPageView from "./ErrorPageView";
import {DirectDebit} from "@folksam-digital/model/lib";
import withDataAnalytics from "../../components/general/withDataAnalytics";
import withCmsProvider from "../../components/general/withCmsProvider";
import {StorageKeys} from "../../services";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {Constants} from "@folksam-digital/model";
import {AnalyticsType, getAnalytics} from "../../analytics/AnalyticsSwitch";
import {IOnTriggerDataLayerEvent, SatelliteEvents, SatelliteEventTransactionTypes} from "../../analytics/new/BaseAnalytics";
import {IErrorParams} from "../Journey/journeyErrorTypes";

class ErrorPageInternal<TProps extends IErrorParams & {
        errorType?: any
    }, TState extends IErrorBaseState> extends ErrorBase<TProps & IOnTriggerDataLayerEvent, TState> {
    
    public static contextType = CmsContext;
    context!: ICmsContext;

    private readonly customErrorMessages: { [key: string]: string } = {
        [`${Constants.Signing.statusCodes.internal.fault.mandateAlreadySigned}`]: "contactForm.mandateAlreadyExistsError.description"
    };

    public async componentDidMount() {
        const data = this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);

        // Trigger Analytics Error Lead Start
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            journeyId: "directDebit",
            currentStep: "error",
            data: this,
            transactionType: SatelliteEventTransactionTypes.Lead,
            event: SatelliteEvents.Start,
            errorMessage: this.customErrorMessages[`${data?.failureReason}`] ?
                          CmsHelper.withPrefix(this.context, this.customErrorMessages[`${data?.failureReason}`]) :
                          CmsHelper.withPrefix(this.context, "contactForm.description")
        });
    }

    public render(): React.ReactNode {
        const data = this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);

        return (
            <ErrorPageView
                formData={data}
                description={this.customErrorMessages[`${data?.failureReason}`] ?
                    CmsHelper.withPrefix(this.context, this.customErrorMessages[`${data?.failureReason}`]) :
                    CmsHelper.withPrefix(this.context, "contactForm.description")}
                headerMessages={{
                    header: CmsHelper.withPrefix(this.context, "banner.heading"),
                    headerText: CmsHelper.withPrefix(this.context, "title.message"),
                }}
            />
        );
    }
}

const ErrorPage = flowRight(
    withCmsProvider("directDebitError"),
    withDataAnalytics(getAnalytics(AnalyticsType.DirectDebit)),
)(ErrorPageInternal);

export default ErrorPage;
