import {Helmet, HelmetTags} from "react-helmet";
import React from "react";

export const AnalyticScriptLoader = ({src, onChangeClientState}: { src: string, onChangeClientState?: (addedTags: HelmetTags) => void}) => {
    return (
        <Helmet onChangeClientState={onChangeClientState}>
            <script src={src} async={true} id={"adobeScriptTag"}/>
        </Helmet>
    )
};
