import * as React from "react";
import { FormInputTextarea } from "@folksam-digital/ui";
import { InputComponentBase } from "./InputComponentBase";
import { IBreakPoint } from "../../layout/helpers";
import { FormFieldLayout } from "../../../FormFieldLayout";
import { injectIntl } from "react-intl";
import sanitizeField from "./helpers/sanitizeField";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";

interface IMetadata {
    placeholder?: string;
    help?: string;
    classNames?: string;
    breakpoints?: IBreakPoint;
    lineCount?: number;
}

class TextareaInternal extends InputComponentBase<string, IMetadata, {}> {
    public render() {
        const {
            formData,
            name,
            onChange,
            disabled,
            readonly,
            autofocus,
            intl
        } = this.props;

        const maxLength = this.schema.maxLength;

        const {
            placeholder,
            breakpoints,
            lineCount,
        } = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()} breakpoints={getBreakpoints(defaultBreakpoints.textArea, breakpoints)}>
                <FormInputTextarea
                    id={name}
                    name={name}
                    autoFocus={autofocus}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    maxLength={maxLength || 1000}
                    counter={maxLength! > 0 ? true : false}
                    value={formData || ""}
                    disabled={disabled}
                    readOnly={readonly}
                    invalid={this.isInvalid()}
                    lineCount={lineCount}
                    onChange={(event: any) => onChange(sanitizeField(event.target.value))}
                    onBlur={(event: any) => this.onBlurWithValidation(event, this.props.formData)}
                />
            </FormFieldLayout>
        );
    }

}

const Textarea = injectIntl(TextareaInternal);
export {Textarea};
