import {IIncomeInsuranceService} from "../../IIncomeInsuranceService";
import {IWorkflowServiceConfig, WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";
import {IncomeInsuranceJourneyBase} from "@folksam-digital/model";
import {SsnFormatter} from "@folksam-digital/services";
import {IStorage} from "../../IStorage";
import {ISessionContextManager} from "../../ISessionContextManager";
import {IDigitalPolicyService} from "../../IDigitalPolicyService";

export class IncomeInsuranceService extends WorkflowServiceBase implements IIncomeInsuranceService {
    private readonly policyService: IDigitalPolicyService;

    public constructor(config: IWorkflowServiceConfig, sessionStorage: IStorage, sessionContextManager: ISessionContextManager, policyService: IDigitalPolicyService) {
        super(config, sessionStorage, sessionContextManager);

        this.policyService = policyService;
    }

    public async getProductData(data: IncomeInsuranceJourneyBase): Promise<IncomeInsuranceJourneyBase> {
        return await this.execute(Workflow.IncomeInsurance.GetProductData, data);
    }

    public async premiumCalculate(data: IncomeInsuranceJourneyBase): Promise<IncomeInsuranceJourneyBase> {
        return await this.execute(Workflow.IncomeInsurance.PremiumCalculate, data);
    }

    public async createAddon(product: string, data: IncomeInsuranceJourneyBase, locale: string): Promise<IncomeInsuranceJourneyBase> {
        return this.policyService.createAddon(product, data, locale);
    }

    public async updateAddon(data: IncomeInsuranceJourneyBase): Promise<IncomeInsuranceJourneyBase> {
        data.contact.ssnShortFormat = SsnFormatter.formatWithoutDash(data.contact.ssn, false);
        return await this.execute(Workflow.IncomeInsurance.UpdateAddon, data);
    }
}
