import { default as React, useCallback } from "react";

import { useModal } from "./hooks/useModal";
import { Modal } from "../../controls/remove/Modal";

interface IButtonWithModalProps {
    themeId?: number;
    onChange?: (param: any) => void;
    id?: string;
    style?: any;
    triggerButton: React.ReactElement;
    headerMessage?: React.ReactElement;
    modalContentMessage?: React.ReactElement;
    showCloseButton?: boolean;
}

const ButtonWithModalInternal = (
    {
        onChange,
        triggerButton,
        headerMessage,
        modalContentMessage,
        showCloseButton
    }: React.PropsWithChildren<IButtonWithModalProps>
): React.ReactElement => {
    const { open, handleOpen, handleClose } = useModal();

    const confirmAndClose = useCallback((e: React.SyntheticEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        handleClose(e);
        onChange && onChange(true);
    }, [onChange, handleClose]);

    const button = React.cloneElement(triggerButton, { onClick: handleOpen });

    return (
        <>
            {button}
            <Modal
                onClose={handleClose}
                onSubmit={confirmAndClose}
                open={open} headerMessage={headerMessage}
                modalContentMessage={modalContentMessage}
                showCloseButton={showCloseButton} />
        </>

    );
};

export const ButtonWithModal = React.memo(ButtonWithModalInternal);
