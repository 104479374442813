import {IncomeInsuranceJourneyBase, JourneyBase} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IDigitalPolicyService} from "../../IDigitalPolicyService";
import {SharedServiceBase} from "../SharedServiceBase";
import {AxiosInstance} from "axios";
import {FilterRequestPayload} from "@folksam-digital/services";

@injectable()
export class DigitalPolicyService extends SharedServiceBase implements IDigitalPolicyService {

    public async createPolicy(product: string, data: JourneyBase<any>, locale: string): Promise<JourneyBase<any>> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);

        const path: string = `policy/${product}/create`;

        const http = this.getHttpClient(path);
        const response = await http.post(path, {locale, data}, {headers: this.getContext()});
        this.updateContext(response);

        return response.data;
    }

    public async createAddon(product: string, data: IncomeInsuranceJourneyBase, locale: string): Promise<IncomeInsuranceJourneyBase> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);

        const path: string = `incomeInsurance/protected/${product}/create`;

        const http = this.getHttpClient(path);
        const response = await http.post(path, {locale, data}, {headers: this.getContext()});
        this.updateContext(response);

        return response.data;
    }

    private getHttpClient(path: string): AxiosInstance {
        if (path.indexOf("/protected/") > -1) {
            return this.create({
                baseURL: this.config.baseProtectedUrl || this.config.baseUrl
            });
        } else {
            return this.create({
                baseURL: this.config.baseUrl
            });
        }
    }
}
