import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import {Animate, ConstrainWidth, ExtendedDescription, FormError, FormNote, Grid, Spacing} from "@folksam-digital/ui";
import {IBreakPoint} from "./journey/layout/helpers";
import {withFormContext} from "./journey/form/withFormContext";
import {IFormContext} from "./journey/form/FormContext";
import FormattedMarkdown from "./FormattedMarkdown";
import {FormattedMessage} from "react-intl";
import {IMetadata} from "./journey/form/output/helpers";
import * as JourneyOutputComponentBaseFunctions
    from "./journey/form/output/helpers/JourneyOutputComponentBaseFunctions";
import {HelpPosition, MarkdownListTypes} from "@folksam-digital/model";
import {Label} from "./formFieldLayout/Label";
import {LabelHelpText} from "./formFieldLayout/LabelHelpText";
import {LabelModal} from "./formFieldLayout/LabelModal";
import {Tooltip} from "./formFieldLayout/Tooltip";

interface IExpandableHelp {
    title?: string;
    content: string;
}

interface IDescription {
    content: string;
    listType?: MarkdownListTypes;
}

export interface IFormFieldLayoutProps {
    id: string;
    title?: React.ReactNode | string;
    titleTheme?: number;
    subtitle?: React.ReactNode | string;
    help?: React.ReactNode | string;
    helpPosition?: HelpPosition;
    helpStyle?: object;
    checkBoxMargin?: boolean;
    expandableHelp?: IExpandableHelp;
    description?: IDescription;
    error?: any;
    className?: string;
    classNames?: string;
    style?: object;
    required?: boolean;
    children?: React.ReactElement | React.ReactElement[];
    breakpoints?: IBreakPoint;
    url?: string;
    modalTitle?: string;
    formContext?: IFormContext;
    messageValues?: IMetadata;
    messageValuesWithTranslations?: IMetadata;
    attrSequence?: number;
    tooltip?: string;
    defaultTranslation?: string;
    translateTitle?: boolean;
    invalid?: boolean;
}

export class Layout extends React.Component<IFormFieldLayoutProps> {
    private readonly containerRef: React.RefObject<any>;

    constructor(props: IFormFieldLayoutProps) {
        super(props);

        this.containerRef = React.createRef();

        this.scrollToThisElementCallback = this.scrollToThisElementCallback.bind(this);
    }

    public render() {
        const {
            id,
            title,
            titleTheme,
            subtitle,
            help,
            helpPosition = HelpPosition.Top,
            helpStyle,
            checkBoxMargin,
            expandableHelp,
            description,
            error,
            className,
            classNames,
            style,
            required,
            children,
            breakpoints,
            url,
            modalTitle,
            tooltip,
            defaultTranslation,
            translateTitle,
            invalid
        } = this.props;

        return (<div ref={this.containerRef} className={`${className || ""} ${classNames || ""}`} style={style}>
            <ConstrainWidth>
                {(title || defaultTranslation) && <Label
                    id={`${id}-label`}
                    htmlFor={id}
                    theme={titleTheme}
                    required={required}
                    tooltip={<Tooltip tooltip={tooltip}/>}
                    invalid={invalid}
                >
                    {this.renderTranslatedField(title, defaultTranslation, translateTitle)}
                    {subtitle && this.renderTranslatedField(subtitle)}
                </Label>}
                {help && helpPosition === HelpPosition.Top &&
                <LabelHelpText style={helpStyle} checkBoxMargin={checkBoxMargin}>{this.renderHelp(help)}</LabelHelpText>}
                {description && this.renderDescription(description)}
                {expandableHelp ? <FormNote>{this.renderExpandableHelp(expandableHelp)}</FormNote> : null}
                <LabelModal url={url} modalTitle={modalTitle}/>
            </ConstrainWidth>

            {breakpoints ?
                this.renderInGrid(children, breakpoints) :
                children
            }
            <ConstrainWidth>
                {help && helpPosition === HelpPosition.Bottom &&
                <LabelHelpText checkBoxMargin={checkBoxMargin}>{this.renderHelp(help)}</LabelHelpText>}
            </ConstrainWidth>
            {this.renderError(error)}
        </div>);
    }

    public async componentDidUpdate(prevProps: any): Promise<void> {
        const {formContext, attrSequence = 0} = this.props;
        if (formContext) {
            if (this.props.error && prevProps.error === undefined) {
                formContext.pushToValidationScrollQueue(attrSequence, this.scrollToThisElementCallback);
            }

            if (prevProps.error && this.props.error === undefined) {
                formContext.removeFromValidationScrollQueue(attrSequence);
            }
        }
    }

    componentDidMount() {
        const {formContext, attrSequence = 0, error} = this.props;
        if (formContext && error) {
            formContext.pushToValidationScrollQueue(attrSequence, this.scrollToThisElementCallback);
        }
    }

    public async componentWillUnmount(): Promise<void> {
        const {formContext, attrSequence = 0, error} = this.props;
        if (formContext && error) {
            formContext.removeFromValidationScrollQueue(attrSequence);
        }
    }

    private renderError(error?: string) {
        return error ?
            (
                <Animate.Drop>
                    <ConstrainWidth>
                        <FormError>
                            <FormattedMessage id={error} values={this.getMessageValuesFromContext()}/>
                        </FormError>
                    </ConstrainWidth>
                </Animate.Drop>
            )
            : null;
    }

    private renderInGrid(children: React.ReactNode, breakpoints: IBreakPoint): React.ReactNode {
        return (
            <Grid.Row>
                <Grid.Col {...breakpoints}>
                    {children}
                </Grid.Col>
            </Grid.Row>
        );
    }

    private renderDescription(params: IDescription): React.ReactNode {
        return (
            <Spacing type={"margin"} bottom={"sm"}>
                <FormattedMarkdown messageKey={params.content} listType={params.listType}/>
            </Spacing>
        );
    }

    private getMessageValuesFromContext(): JourneyOutputComponentBaseFunctions.IMetadata | undefined {
        let values;
        if (this.props.messageValuesWithTranslations) {
            values = this.props.messageValuesWithTranslations;
        }
        else if (this.props.messageValues && this.props.formContext) {
            values = JourneyOutputComponentBaseFunctions.getMessageValues(this.props.messageValues, this.props.formContext!);
        }
        return values;
    }

    private renderHelp(field: React.ReactNode | string): React.ReactNode {
        return typeof field === "string" ?
            <FormattedMarkdown messageKey={field} messageValue={this.getMessageValuesFromContext()}/> : field;
    }

    private renderExpandableHelp(params: IExpandableHelp): React.ReactNode {
        return (
            <>
                <span>{params.title}</span>
                <ExtendedDescription
                    id={this.props.id}
                    linkText={{
                        open: <FormattedMessage id={"general.readMore"}/>,
                        close: <FormattedMessage id={"general.readLess"}/>
                    }}
                    isExpandable={true}
                >
                    <FormattedMarkdown messageKey={params.content}/>
                </ExtendedDescription>
            </>
        );
    }

    private scrollToThisElementCallback() {
        scrollIntoView(this.containerRef.current, {behavior: "smooth", block: "start"});
    }

    public renderTranslatedField(field: React.ReactNode | string, defaultTranslation?: string, translate?: boolean): React.ReactNode | string {
        if (translate === false) {
            return defaultTranslation;
        }

        return typeof field === "string" && field?.length > 0 ?
            <FormattedMessage id={field} defaultMessage={defaultTranslation}
                              values={this.getMessageValuesFromContext()}/> : field;
    }
}

const FormFieldLayout = withFormContext(Layout);
export {FormFieldLayout};
