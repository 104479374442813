import { FormComponentBase, IFormComponentProps} from "./FormComponentBase";

export interface IProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata> {

}

export abstract class FieldTemplate<TValue, TMetadata, TState>
    extends FormComponentBase<TValue, TMetadata, TState> {

    public render() {
        return(
            this.props.children
        );
    }
}
