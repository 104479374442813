import {ProgressBarInfinite} from "@folksam-digital/ui";
import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {container} from "../inversify.config";
import {IAuthenticationService, IDraftService, StorageKeys} from "../services";
import {Types} from "../Types";
import {AuthenticationContext} from "./AuthenticationContext";
import {IErrorParams} from "../Routers/Journey/journeyErrorTypes";
import {UrlHelper} from "@folksam-digital/services";

interface IState {
    loading: boolean,
    authenticated?: boolean
}

interface IProps extends RouteComponentProps<any> {
    children?: React.ReactNode;
    journeyId?: string;
}

class AuthenticationProviderInternal extends React.Component<IProps, IState> {
    private readonly authenticationService: IAuthenticationService;
    private readonly draftService: IDraftService;

    constructor(props: Readonly<IProps>) {
        super(props);
        this.authenticationService = container.get<IAuthenticationService>(Types.AuthenticationService);
        this.draftService = container.get<IDraftService>(Types.DraftService);

        this.state = {
            authenticated: false,
            loading: true
        };
    }

    /**
     * Component did mount - trigger authentication
     */
    public async componentDidMount() {
        try {
            const authenticated = await this.authenticationService.isAuthenticated();
            if(authenticated) {
                this.setState({
                    authenticated: true,
                    loading: false
                });
            }
            else {
                this.onUnauthenticated();
            }
        }
        catch (e) {
            console.error(e);
            this.onUnauthenticated();
        }
    }

    /**
     * Renders component or unauthorized message
     */
    public render(): React.ReactNode {
        if(this.state.loading) {
            return <ProgressBarInfinite duration={20}/>;
        }
        else if(this.state.authenticated) {
            return (
                <AuthenticationContext.Provider value={{ isAuthenticated: true, getAuthenticatedUser: this.authenticationService.getAuthenticatedUser.bind(this.authenticationService) }}>
                    {this.props.children}
                </AuthenticationContext.Provider>
            );
        }

        return <></>;
    }

    private onUnauthenticated() {
        const data: IErrorParams = this.draftService.getDraft<IErrorParams>(StorageKeys.JOURNEY_ERROR_DATA_KEY);
        this.draftService.updateDraft(
            {
                journeyId: this.props.match.params?.journeyId ? this.props.match.params?.journeyId : this.props.journeyId,
                tryAgainUrl: data?.tryAgainUrl ? data.tryAgainUrl : this.props.match.url.substring(1)
            },
            StorageKeys.JOURNEY_ERROR_DATA_KEY
        );

        this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
    }
}

const AuthenticationProvider = withRouter(AuthenticationProviderInternal);
export { AuthenticationProvider };
