import * as React from "react";
import {PolicySearchResult} from "@folksam-digital/model/lib";
import {Spacing, CardNew, SpacingLine, DynamicList} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

export interface IPolicyListRowProps extends WrappedComponentProps {
    policy: PolicySearchResult;
}

class PolicyListRow extends React.Component<IPolicyListRowProps, {}> {

    public render() {
        const {policy} = this.props;

        return (
            <Spacing bottom={"sm"}>
                <CardNew>
                    <CardNew.Section column>
                        <CardNew.Typography element={'h4'} variation={'primary'}>
                            {policy.productTitle}
                        </CardNew.Typography>
                    </CardNew.Section>
                    <SpacingLine />
                    <CardNew.Section column>
                        <CardNew.Typography>
                            {policy.productTextList?.join(" ")}
                        </CardNew.Typography>
                        <Spacing type={'margin'} top={'sm'}>
                            <CardNew.Typography>
                                {this.renderCoverTextList(policy.coverTextList)}
                            </CardNew.Typography>
                        </Spacing>
                    </CardNew.Section>
                    <SpacingLine />
                    <CardNew.Section column>
                        <CardNew.Typography element={'span'} variation={'secondary'}>
                            <FormattedMessage id={'groupInsurance.shoppingCart.policyList.remuneration.compensationLabel'} />
                        </CardNew.Typography>
                        <CardNew.Typography element={'h4'} variation={'primary'}>
                            {this.renderInsuranceAmount()}
                        </CardNew.Typography>
                    </CardNew.Section>
                    <SpacingLine />
                    <CardNew.Section>
                        <CardNew.Typography element={'h4'} variation={'accent'}>
                            { this.renderMonthlyPremium() }
                        </CardNew.Typography>
                    </CardNew.Section>
                </CardNew>
            </Spacing>
        );
    }

    private renderCoverTextList(list?: string[]) {

        if(!list) {
            return;
        }

        const preparedList = list.map(listItem => <>{listItem}</>);

        return(
            <DynamicList
                list={preparedList}
            />
        );
    }

    private renderInsuranceAmount() {
        const {policy, intl} = this.props;

        if (policy.insuranceAmount && policy.insuranceAmount > 0) {
            return (
                policy.mainCoverNumberOfUnits
                    ?
                    <FormattedMarkdown
                        messageKey={"groupInsurance.shoppingCart.policyList.remuneration.withAmount"}
                        messageValue={{
                            value: intl.formatNumber(policy.insuranceAmount),
                            numberOfUnits: Number(policy.mainCoverNumberOfUnits)
                        }}
                    />
                    :
                    policy.insuranceAmount && policy.insuranceAmount > 0 &&
                    <FormattedMarkdown
                        messageKey={"groupInsurance.shoppingCart.policyList.remuneration.withoutAmount"}
                        messageValue={{ value: intl.formatNumber(policy.insuranceAmount) }}
                    />
            );
        } else {
            return (<></>);
        }
    }

    private renderMonthlyPremium() {

        const {policy, intl} = this.props;

        if(policy.monthlyPremium) {
            return `${Math.round(policy.monthlyPremium)} ${intl.formatMessage({ id: "general.monthlyPremium.suffix" })}`
        }
    }
}

export default injectIntl(PolicyListRow);
