import { FormComponentBase, IFormComponentProps } from "../FormComponentBase";

export interface IOutputComponentProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata> {

}

export abstract class OutputComponentBase<TValue, TMetadata, TState, TProps extends IFormComponentProps<TValue, TMetadata> = IFormComponentProps<TValue, TMetadata>>
    extends FormComponentBase<TValue, TMetadata, TState, TProps> {

}
