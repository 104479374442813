import {IInputComponentProps, InputComponentBase} from "../../input/InputComponentBase";
import {FormContext} from "../../FormContext";
import {DescriptionListResponsive, FlexRow, FormInput, FormInputCheckbox, Grid, Spacing} from "@folksam-digital/ui";
import {FormFieldLayout} from "../../../../FormFieldLayout";
import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import {AddButton} from "../../input";
import {RemoveButton} from "../personSsnInput/RemoveButton";
import get from "lodash/get";
import {withFormContext} from "../../withFormContext";
import sanitizeField from "../../input/helpers/sanitizeField";
import flowRight from "lodash/flowRight";
import {injectIntl} from "react-intl";

interface IVehicleRegistrationNumberMetadata {
    checkBoxTitle: string;
    limit: number;
}

class VehicleRegistrationNumberInputMultipleContainerInternal extends InputComponentBase<any, any, any> {
    public static contextType = FormContext;

    constructor(props: IInputComponentProps<any, IVehicleRegistrationNumberMetadata>) {
        super(props);

        this.onNumberChange = this.onNumberChange.bind(this);
        this.addRegistrationNumber = this.addRegistrationNumber.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.resetAllRegistrationNumbers = this.resetAllRegistrationNumbers.bind(this);
        this.addRegistrationFieldShow = this.addRegistrationFieldShow.bind(this);

        this.state = {
            registrationNumberFieldShow: true,
            registrationNumberInputValue: ""
        };
    }

    public render() {
        const {name, required, intl} = this.props;
        const {checkBoxTitle} = this.metadata;

        let title;
        if (checkBoxTitle) {
            title = intl.formatMessage({id: checkBoxTitle});
        }

        return (
            <Grid.Row>
                <Grid.Col md={12}>
                    <FormFieldLayout
                        title={this.schema.title}
                        required={required}
                        help={this.metadata.help}
                        {...this.getLayoutProps()}
                    >
                        <Grid.Col md={12}>
                            {(this.props.formData?.registrationNumbers?.length === 0 || !this.props.formData?.registrationNumbers) &&
                                <FormInputCheckbox
                                    key={"registrationNumberNotKnown"}
                                    id={"registrationNumberNotKnownCheckBox"}
                                    label={title}
                                    name={name}
                                    checked={this.props.formData.registrationNotKnown}
                                    onChange={(event: any) => this.resetAllRegistrationNumbers(event)}
                                />
                            }
                        </Grid.Col>
                        <Grid.Col md={12}>
                            <Spacing type={"margin"} top={"sm"}>
                                {this.renderAddedNumbers()}
                            </Spacing>
                        </Grid.Col>
                        <Grid.Col md={12}>
                            <Spacing type={"margin"} top={"sm"}>
                                {(this.props.formData?.registrationNumbers?.length === 0 || this.state.registrationNumberFieldShow) &&
                                    <FormInput
                                        value={this.state.registrationNumberInputValue}
                                        id={"registrationNumberInputField"}
                                        disabled={this.props.formData.registrationNotKnown}
                                        required={!this.props.formData.registrationNotKnown}
                                        onChange={this.onNumberChange}
                                        onBlur={this.addRegistrationNumber}
                                    />}
                            </Spacing>
                        </Grid.Col>

                    </FormFieldLayout>
                    <Grid.Col md={12}>
                        <DescriptionListResponsive.Row key={`addRegistrationNumberButton`}>
                            <DescriptionListResponsive.Term>
                                {this.renderAddRegistrationNumberButton()}
                            </DescriptionListResponsive.Term>
                        </DescriptionListResponsive.Row>
                    </Grid.Col>
                </Grid.Col>
            </Grid.Row>
        );
    }

    private renderAddedNumbers(): React.ReactElement {
        if (!this.props.formData?.registrationNumbers) {
            return <></>;
        }

        return this.props.formData.registrationNumbers.map((registrationNumber: string, index: number) => {
            return (
                <React.Fragment key={`registrationNumber_${index}`}>
                    <Spacing type={"margin"} top={"sm"}>
                        <FlexRow style={{alignItems: 'center'}}>
                            <FormInput
                                disabled={true}
                                value={registrationNumber}
                                id={`registrationNumber_${index}`}
                            />
                            {this.renderRemoveButton(index)}
                        </FlexRow>
                    </Spacing>
                </React.Fragment>

            );
        });
    }


    private renderAddRegistrationNumberButton(): JSX.Element {
        const uiSchema = cloneDeep(this.props.uiSchema);
        const componentMetadata = {...omit(uiSchema.componentMetadata, ["help"])};
        uiSchema.componentMetadata = componentMetadata;

        const isButtonDisabled = this.props.formData.registrationNotKnown || this.isLimitReached() || this.props.formData?.registrationNumbers?.length === 0 || (!this.state.registrationNumberInputValue && this.state.registrationNumberFieldShow);

        return (
            <AddButton
                {...this.props}
                name={this.props.name + "Submit"}
                schema={{...this.props.schema, title: "claim.vehicleCollision.addVehicleRegistrationNumber.title"}}
                uiSchema={uiSchema}
                onClick={this.addRegistrationFieldShow}
                disabled={isButtonDisabled}
            />
        );
    };

    private addRegistrationFieldShow(): void {
        this.setState({registrationNumberFieldShow: true, registrationNumberInputValue: ""})
    }

    private renderRemoveButton(index: number): JSX.Element {
        return (
            <RemoveButton
                onClick={this.onRemove}
                uniqueKey={`removeRegistrationNumber_${index}`}
                index={index}
            />
        );
    }

    private async onNumberChange(event: any): Promise<void> {
        const value = !event.target.value ? "" : sanitizeField(event.target.value);
        const pattern = /^[a-zA-Z0-9]{0,10}$/;

        if (value.match(pattern)) {
            await this.setState({
                registrationNumberInputValue: value,
            });
        }
    }

    private onRemove(index?: number): void {
        const values = this.props.formData;
        values.registrationNumbers.splice(index, 1);

        this.setState(
            {registrationNumberInputValue: ""},
            () => this.onChangeWithValidation(values)
        );
    }

    private resetAllRegistrationNumbers(event: any): void {
        const newData = {registrationNumbers: [], registrationNotKnown: !this.props.formData?.registrationNotKnown};
        this.setState(
            {
                registrationNumberInputValue: ""
            },
            () => this.onChangeWithValidation(newData)
        );
    }

    private async addRegistrationNumber(): Promise<void> {
        const registrationNumber = this.state.registrationNumberInputValue.trim().toUpperCase();
        if (registrationNumber.length < 1) {
            return;
        }
        this.setState({
            registrationNumberInputValue: "",
        });

        const data = cloneDeep(this.props.formData?.registrationNumbers ? this.props.formData : {
            registrationNumbers: [],
            registrationNotKnown: false
        });
        data.registrationNumbers.push(registrationNumber);
        this.onChangeWithValidation(data);
        this.setState({registrationNumberFieldShow: false})
    }

    private getLimit(): number {
        if (this.metadata.limit) {
            return this.metadata.limit;
        } else if (this.metadata.limitPath) {
            return get(this.context.data, this.metadata.limitPath);
        } else {
            return 0;
        }
    }

    private isLimitReached(): boolean {
        return !!this.props.formData && this.props.formData?.registrationNumbers?.length >= this.getLimit();
    }
}

const VehicleRegistrationNumberInputMultipleContainer = flowRight(withFormContext, injectIntl)(VehicleRegistrationNumberInputMultipleContainerInternal);
export default VehicleRegistrationNumberInputMultipleContainer;
