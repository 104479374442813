import {IRoute} from "../../../IRoute";
import {Constants} from "@folksam-digital/model";

import AccidentJourneySuccess from "./AccidentJourneySuccess";
import AccidentAndIllnessJourneySuccess from "./AccidentAndIllnessJourneySuccess";
import IndividualChildJourneySuccess from "./IndividualChildJourneySuccess";
import MemberChildJourneySuccess from "./MemberChildJourneySuccess";
import PregnancyJourneySuccess from "./PregnancyJourneySuccess";
import RunningRaceJourneySuccess from "./RunningRaceJourneySuccess";
import SportsTravelJourneySuccess from "./SportsTravelJourneySuccess";
import TrailerJourneySuccess from "./TrailerJourneySuccess";
import CaravanJourneySuccess from "./CaravanJourneySuccess";
import HeavyMotorcycleJourneySuccess from "./HeavyMotorcycleJourneySuccess";
import LightMotorcycleJourneySuccess from "./LightMotorcycleJourneySuccess";
import MopedJourneySuccess from "./MopedJourneySuccess";
import SnowmobileAtvJourneySuccess from "./SnowmobileAtvJourneySuccess";
import MobileHomeJourneySuccess from "./MobileHomeJourneySuccess";
import CatJourneySuccess from "./CatJourneySuccess";
import DogJourneySuccess from "./DogJourneySuccess";
import LightTruckJourneySuccess from "./LightTruckJourneySuccess";
import EpaAndATractorJourneySuccess from "./EpaAndATractorJourneySuccess";
import MopedCarJourneySuccess from "./MopedCarJourneySuccess";
import SmallTractorJourneySuccess from "./SmallTractorJourneySuccess";
import CarJourneySuccess from "./CarJourneySuccess";
import HomeStudentJourneySuccess from "./HomeStudentJourneySuccess";
import HomeRentalJourneySuccess from "./HomeRentalJourneySuccess";
import HomeApartmentJourneySuccess from "./HomeApartmentJourneySuccess";
import HomeBuildingJourneySuccess from "./HomeBuildingJourneySuccess";
import HomeVacationJourneySuccess from "./HomeVacationJourneySuccess";
import HomeCondominiumJourneySuccess from "./HomeCondominiumJourneySuccess";
import LightElectricalVehicleJourneySuccess from "./LightElectricalVehicleSuccess";

const JourneySuccessRoutes: IRoute[] = [
    {path: "accident", component: AccidentJourneySuccess},
    {path: "accidentAndIllness", component: AccidentAndIllnessJourneySuccess},
    {path: "individualChild", component: IndividualChildJourneySuccess},
    {path: Constants.ObfuscatedJourneyId.Pregnancy, component: PregnancyJourneySuccess},
    {path: "memberChild", component: MemberChildJourneySuccess},
    {path: "runningRace", component: RunningRaceJourneySuccess},
    {path: "sportsTravel", component: SportsTravelJourneySuccess},
    {path: "trailer", component: TrailerJourneySuccess},
    {path: "caravan", component: CaravanJourneySuccess},
    {path: "heavyMotorcycle", component: HeavyMotorcycleJourneySuccess},
    {path: "lightMotorcycle", component: LightMotorcycleJourneySuccess},
    {path: "moped", component: MopedJourneySuccess},
    {path: "snowmobileAtv", component: SnowmobileAtvJourneySuccess},
    {path: "mobileHome", component: MobileHomeJourneySuccess},
    {path: "cat", component: CatJourneySuccess},
    {path: "dog", component: DogJourneySuccess},
    {path: Constants.Journey.LightTruck.Id, component: LightTruckJourneySuccess},
    {path: Constants.Journey.EpaAndATractor.Id, component: EpaAndATractorJourneySuccess},
    {path: Constants.Journey.MopedCar.Id, component: MopedCarJourneySuccess},
    {path: Constants.Journey.SmallTractor.Id, component: SmallTractorJourneySuccess},
    {path: Constants.Journey.Car.Id, component: CarJourneySuccess},
    {path: Constants.Journey.HomeStudent.Id, component: HomeStudentJourneySuccess},
    {path: Constants.Journey.HomeRental.Id, component: HomeRentalJourneySuccess},
    {path: Constants.Journey.HomeApartment.Id, component: HomeApartmentJourneySuccess},
    {path: Constants.Journey.HomeBuilding.Id, component: HomeBuildingJourneySuccess},
    {path: Constants.Journey.HomeVacation.Id, component: HomeVacationJourneySuccess},
    {path: Constants.Journey.HomeCondominium.Id, component: HomeCondominiumJourneySuccess},
    {path: Constants.Journey.LightElectricalVehicle.Id, component: LightElectricalVehicleJourneySuccess},

];

export default JourneySuccessRoutes;
