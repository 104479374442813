import * as React from "react";
import { OutputComponentBase } from "./OutputComponentBase";
import { ModalIframe } from "./ModalIframe"
import {FormattedMessage} from "react-intl";

export interface IModalProps {
    url: string;
    title: string;
    classNames?: string;
    customStyles?: React.CSSProperties;
    isBold?: boolean;
}

export class ModalIframeLayout extends OutputComponentBase<any, IModalProps, {}> {
    public render() {
        const title = this.schema.title;
        const url = this.metadata.url;
        const classNames = this.metadata?.classNames;
        const customStyles = this.metadata?.customStyles;
        const isBold = this.metadata?.isBold;

        return (
            <ModalIframe title={<FormattedMessage id={title}/>} url={url} classNames={classNames} customStyles={customStyles} isBold={isBold} />
        );
    }
}
