import {ButtonResponsive, Form, Grid, MaxWidthWrapper, Spacing} from "@folksam-digital/ui";
import * as React from "react";
import {FormFieldController} from "../../../../Routers/DirectDebit/FormFieldController";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {FormContext, IFormContext} from "../../form/FormContext";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import merge from "lodash/merge";
import sanitizeField from "../../form/input/helpers/sanitizeField";
import {container} from "../../../../inversify.config";
import {ContentHeaderVehicleFieldValidator} from "../../../../model";
import {Types} from "../../../../Types";
import {IError} from "../../../../model/ValidatorBase";
import isEmpty from "lodash/isEmpty";
import {SsnFormatter, UrlHelper} from "@folksam-digital/services";
import {InputType, JourneyBase} from "@folksam-digital/model";

interface IContentHeaderVehicleFieldsProps extends WrappedComponentProps {
    closeModal: () => void;
}

interface IContentHeaderVehicleFieldsState {
    data: {
        ssn: string;
        registrationNumber: string;
    };
    errors: { [key: string]: any };
}

class ContentHeaderVehicleFieldsInternal extends React.Component<IContentHeaderVehicleFieldsProps, IContentHeaderVehicleFieldsState> {
    public static contextType = FormContext;
    private validator: ContentHeaderVehicleFieldValidator;
    public context!: IFormContext;

    public constructor(props: IContentHeaderVehicleFieldsProps, context: IFormContext) {
        super(props, context);

        this.state = {
            data: {
                registrationNumber: context.data.policy.insuredObject?.registrationNumber!,
                ssn: context.data.contact.ssn
            },
            errors: {}
        };

        this.validator = container.get<ContentHeaderVehicleFieldValidator>(Types.ContentHeaderVehicleFieldValidator);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    public componentDidMount() {
        const errors = this.validator.validate(this.state.data);
        this.setState({errors});
    }

    private handleOnChange(event: any) {
        const {id, value} = event.target;
        const data = merge(this.state.data, {[id]: sanitizeField(value)});
        this.setState({data});
    }

    private handleOnBlur(event: any) {
        const {id} = event.target;
        let fieldValue = this.state.data[id as keyof IContentHeaderVehicleFieldsState["data"]];

        if (typeof fieldValue === "string") {
            fieldValue = sanitizeField(fieldValue.trim());
        }

        const data = merge(this.state.data, {[id]: fieldValue});
        const fieldError = this.validator.validateField(id, fieldValue);
        const errors = this.mergeErrors(fieldError, id);

        this.setState({data, errors});
    }

    private async handleOnSubmit(event: any) {
        event.preventDefault();

        const currentData: JourneyBase<any> = this.context.data;
        this.setState({errors: {}});

        const errors = this.validator.validate(this.state.data);
        const {data} = this.state;
        const currentSsn = SsnFormatter.convertFormat(currentData.contact.ssn, true)

        if (isEmpty(errors.ssn)) {
            data.ssn = this.validator.removeDashFromSsn(data.ssn)!;
        }

        if (!isEmpty(errors)) {
            this.setState({errors});
            return;
        }

        data.ssn = SsnFormatter.convertFormat(data.ssn, true);
        if (currentData?.policy?.insuredObject?.registrationNumber !== data.registrationNumber || data.ssn !== currentSsn) {
            this.context.restartJourneyWithNewData({
                ssn: data.ssn,
                regnr: data.registrationNumber
            }, UrlHelper.getPurchaseJourneyUrl(this.context.journeyId));
        }

        this.props.closeModal();
    }

    public render() {
        const {intl} = this.props;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Form onSubmit={this.handleOnSubmit}>
                        <MaxWidthWrapper compact={true}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xs>
                                        <Spacing type={"margin"} bottom={"sm"}>
                                            <FormFieldLayout
                                                required={true}
                                                title={<FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "contentHeader.regNr.title")}/>}
                                                id={"contentHeader-registrationNumber-field"}
                                                error={this.state.errors["registrationNumber"]}
                                                invalid={!!this.state.errors["registrationNumber"]}
                                            >
                                                <FormFieldController
                                                    id={"registrationNumber"}
                                                    value={this.state.data.registrationNumber}
                                                    invalid={!!this.state.errors["registrationNumber"]}
                                                    isReadOnly={false}
                                                    onChange={this.handleOnChange}
                                                    onBlur={this.handleOnBlur}
                                                />
                                            </FormFieldLayout>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col xs>
                                        <Spacing type={"margin"} bottom={"sm"}>
                                            <FormFieldLayout
                                                required={true}
                                                title={<FormattedMessage
                                                    id={CmsHelper.withPrefix(cmsContext, "contentHeader.ssn.title")}/>}
                                                id={"contentHeader-ssn-field"}
                                                error={this.state.errors["ssn"]}
                                                invalid={!!this.state.errors["ssn"]}
                                            >
                                                <FormFieldController
                                                    id={"ssn"}
                                                    value={this.state.data.ssn}
                                                    invalid={!!this.state.errors["ssn"]}
                                                    isReadOnly={false}
                                                    placeholder={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "contentHeader.ssn.placeholder")})}
                                                    onChange={this.handleOnChange}
                                                    onBlur={this.handleOnBlur}
                                                    type={InputType.tel}
                                                />
                                            </FormFieldLayout>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row style={{marginBottom: 25}}>
                                    <Grid.Col xs>
                                        <ButtonResponsive type='submit' full>
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "contentHeader.submit")}/>
                                        </ButtonResponsive>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </MaxWidthWrapper>
                    </Form>)
                }
            </CmsContext.Consumer>);
    }

    private mergeErrors(fieldError: IError, fieldId: string): IError {
        let errors = this.state.errors || {};

        if (isEmpty(fieldError)) {
            Object.keys(errors).forEach(key => {
                if (key === fieldId) {
                    delete errors[key];
                    return;
                }
            });
        } else {
            errors = merge({}, errors, fieldError);
        }

        return errors;
    }

}

const ContentHeaderVehicleFields = injectIntl(ContentHeaderVehicleFieldsInternal);

export {ContentHeaderVehicleFields};
