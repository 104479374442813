import {defaultTheme as theme, Spacing, SummaryCard} from "@folksam-digital/ui";
import * as React from "react";
import FormattedMarkdown from "../../../FormattedMarkdown";

interface ISuccessHeaderMessageBoxProps {
    message: string,
    formData?: any,
    skipSpacingTop?: boolean
}

export class SuccessHeaderMessageBox extends React.Component<ISuccessHeaderMessageBoxProps> {
    public render() {

        return (
            <>
            {!this.props.skipSpacingTop && <Spacing bottom={'sm'}/>}
                <SummaryCard>
                    <div style={{
                        padding: theme.padding.sm,
                        fontFamily: theme.fonts.mono,
                        fontSize: theme.textSizes.sm,
                        fontWeight: theme.fontWeights.regular,
                        color: theme.colors.black,
                        textAlign: 'start',
                        letterSpacing: theme.tracking.tight,
                        lineHeight: theme.leading.tight
                    }}>
                        <FormattedMarkdown messageKey={this.props.message} messageValue={this.props.formData}/>
                    </div>

                </SummaryCard>
                <Spacing bottom={'sm'}/>
            </>
        )
    }
}
