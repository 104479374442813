import {injectable} from "inversify";
import {ISessionContextManager} from "../ISessionContextManager";
import {UuidGenerator} from "../../Helpers/UuidGenerator";
import {ISessionContext} from "../ISessionContextManager";
import {IStorage} from "../IStorage";

export const SESSION_CONTEXT_KEY = "SESSION_CONTEXT";

@injectable()
export class SessionContextManager implements ISessionContextManager{
    private readonly sessionStorage: IStorage;

    constructor(sessionStorage: IStorage) {
        this.sessionStorage = sessionStorage;
    }

    public getSessionContext(): ISessionContext | undefined {
        if(this.sessionStorage.get(SESSION_CONTEXT_KEY)) {
            return this.sessionStorage.get<ISessionContext>(SESSION_CONTEXT_KEY);
        }
        else {
            const context: ISessionContext = {
                transactionId: UuidGenerator.generate()
            };

            this.sessionStorage.set(SESSION_CONTEXT_KEY, context);
            return context;
        }
    }
}
