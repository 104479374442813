import {createUseStyles} from "react-jss";
import {defaultTheme} from "@folksam-digital/ui";

export const useStyles = createUseStyles({
    root: {
        textDecoration: "none",
        color: "inherit",
        padding: "5px",
        '&:hover': {
            "& svg": {
                fill: defaultTheme.colors.linkHover
            }
        }
    }
});
