import {BankId} from "./BankId";
import TeethLayoutDesktopUrl, {ReactComponent as TeethLayoutDesktop} from "./teeth-desktop.svg";
import TeethLayoutMobileUrl, {ReactComponent as TeethLayoutMobile} from "./teeth-mobile.svg";

const Icons = {
    BankId,
    TeethLayoutDesktopUrl,
    TeethLayoutDesktop,
    TeethLayoutMobileUrl,
    TeethLayoutMobile
};

export default Icons;
