import * as React from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import omit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import {SsnFormatter} from "@folksam-digital/services";
import {FormattedMessage} from "react-intl";
import {withFormContext} from "../../withFormContext";
import {
    ButtonResponsive,
    defaultTheme,
    Grid,
    MaxWidthWrapper,
    Spacing,
    Spinner
} from "@folksam-digital/ui";
import {Contact} from "@folksam-digital/model";
import {FormFieldLayout} from "../../../../FormFieldLayout";
import PersonSsnInput from "../../input/PersonSsnInput";
import {ContainerBase, IPersonSsnInputMetadata} from "./ContainerBase";
import {FormContext} from "../../FormContext";
import {IInputComponentProps} from "../../input/InputComponentBase";
import {RemoveButton} from "./RemoveButton";
import {FetchedContact} from "./FetchedContact";
import {getBreakpoints} from "../../input/helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "../../input/helpers/breakpoints/defaultBreakpoints";
import {schemaTitleStyle} from "../../../../common/styles/componentStyles";
import {CmsContext, ICmsContext} from "../../../../../cms";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";

class PersonSsnInputMultipleContainer extends ContainerBase {
    public static contextType = FormContext;

    constructor(props: IInputComponentProps<any, IPersonSsnInputMetadata>) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onFindPersonClick = this.onFindPersonClick.bind(this);
        this.onLoadContactData = this.onLoadContactData.bind(this);
        this.onRemove = this.onRemove.bind(this);

        this.state = {
            ssnInputValue: "",
            error: "",
            isLoading: false
        };
    }

    public render() {
        const {name, required} = this.props;

        const schemaTitle = <div style={{display: 'inline-flex', flexWrap: 'wrap'}}>
            <FormattedMessage id={this.schema?.title}/>
            <div style={{...schemaTitleStyle}}>
                <FormattedMessage id={"general.form.personSsnNumber.placeholder"}/>
            </div>
        </div>

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Grid.Row>
                        <Grid.Col md={12}>
                            <FormFieldLayout
                                id={name}
                                title={!this.props?.formData?.length && schemaTitle}
                                required={required}
                                error={this.getFieldError(this.state.error)}
                                help={this.metadata.help}
                                invalid={this.isInvalid() || !!this.getFieldError(this.state.error)}
                                breakpoints={getBreakpoints(defaultBreakpoints.ssnInput)}
                            >
                                {this.renderFetchedPersons()}
                                {!this.isLimitReached() ?
                                    <PersonSsnInput
                                        {...this.props}
                                        value={this.state.ssnInputValue}
                                        error={this.state.error}
                                        onInputChange={this.onInputChange}
                                    />
                                    :
                                    <></>
                                }
                            </FormFieldLayout>
                            <MaxWidthWrapper key={`fetchedPersonCountAddButton`}>
                                <Spacing type={'padding'} top={2} x={2}>
                                    {this.renderPersonCount(cmsContext)}
                                </Spacing>
                                {this.props?.formData?.length > 0 ? this.renderAddMorePersonsButton(cmsContext) : this.renderFindPersonButton()}
                            </MaxWidthWrapper>
                        </Grid.Col>
                    </Grid.Row>
                )}
            </CmsContext.Consumer>
        );
    }

    private renderFetchedPersons(): React.ReactElement {
        if (!this.props.formData) {
            return <></>;
        }

        return this.props.formData.map((contact: Contact, index: number) => {
            return (
                <React.Fragment key={`fetchedPersonKey_${index}`}>
                    <FetchedContact
                        key={`fetchedPersonKey_${index}`}
                        contact={{
                            rowNumber: index + 1,
                            firstName: contact.firstName,
                            lastName: contact.lastName,
                            ssn: SsnFormatter.formatWithDash(contact.ssn) || ""
                        }}
                        removeButton={this.renderRemoveButton(index)}
                    />
                    <Spacing type={"margin"} bottom={"sm"} key={`spacingFetchedPerson_${index}`}/>
                </React.Fragment>
            );
        });
    }

    private renderFindPersonButton(): JSX.Element {
        return (
            <Spacing type={"margin"} top={"sm"}>
                <ButtonResponsive
                    id={`${this.props.name}Submit`}
                    type="button"
                    onClick={this.onFindPersonClick}
                    disabled={this.state.isLoading}
                    isBusy={this.state.isLoading}
                    full
                    outline
                >
                    <FormattedMessage id={"general.personSsnInput.findPerson"}/>
                </ButtonResponsive>
            </Spacing>
        );
    };

    private renderAddMorePersonsButton(cmsContext: ICmsContext): JSX.Element {
        if (this.isLimitReached()) {
            return <></>;
        }

        if (this.state.isLoading) {
            return <Spinner/>;
        }

        const uiSchema = cloneDeep(this.props.uiSchema);
        uiSchema.componentMetadata = {...omit(uiSchema.componentMetadata, ["help"])};

        return (
            <>
                <Spacing type={"margin"} top={"sm"}>
                    <ButtonResponsive
                        {...this.props}
                        id={`${this.props.name}Submit`}
                        type="button"
                        onClick={this.onFindPersonClick}
                        disabled={!isEmpty(this.state.error)}
                        isBusy={this.state.isLoading}
                        full
                        outline
                    >
                        <FormattedMessage id={`${CmsHelper.getPrefix(cmsContext)}.personSsnInput.multiple.addPerson`}/>
                    </ButtonResponsive>
                </Spacing>
            </>

        );
    };

    private renderPersonCount(cmsContext: ICmsContext): JSX.Element {
        if (!this.metadata.limit && !this.metadata.limitPath) {
            return <></>;
        }

        const currentCount = this.props.formData ? this.props.formData.length : 0;
        const limit = this.getPersonLimit();

        return (
            <span style={{color: defaultTheme.colors.senary2}}>
                <FormattedMessage
                    id={`${CmsHelper.getPrefix(cmsContext)}.personSsnInput.multiple.limit`}
                    values={{
                        current: currentCount,
                        limit: limit
                    }}
                />
            </span>
        );
    }

    private renderRemoveButton(index: number): JSX.Element {
        return (
            <RemoveButton
                onClick={this.onRemove}
                uniqueKey={`removeFetchedPerson_${index}`}
                index={index}
                editIcon
            />
        );
    }

    private async onInputChange(ssnInputValue: string): Promise<void> {
        await this.setState({ssnInputValue});

        if (!isEmpty(this.state.error)) {
            const error = this.validateField();
            this.setState({error});
        }
    }

    private onRemove(index?: number): void {
        const values = this.props.formData;
        values.splice(index, 1);

        this.setState(
            {ssnInputValue: ""},
            () => this.onChangeWithValidation(values)
        );
    }

    private async onFindPersonClick(): Promise<void> {
        const error = this.validateField();

        if (!isEmpty(error)) {
            this.setState({error});
            return;
        }
        // respect empty value for optional field
        if (!this.props.required && !this.state.ssnInputValue) {
            return;
        }
        this.setState({isLoading: true});

        const contact = await this.onLoadContactData();
        if (contact && contact.ssn) {
            this.setState({
                ssnInputValue: "",
                error: "",
                isLoading: false
            });

            const data = cloneDeep(this.props.formData ? this.props.formData : []);
            data.push(contact);

            if (data.length === this.getPersonLimit()) {
                this.onChangeWithValidation(data);
            } else {
                this.props.onChange(data);
            }
        } else {
            this.setState({
                error: "general.personSsnInput.error.notFound",
                isLoading: false
            });
        }
    }

    private getPersonLimit(): number {
        if (this.metadata.limit) {
            return this.metadata.limit;
        } else if (this.metadata.limitPath) {
            return get(this.context.data, this.metadata.limitPath);
        } else {
            return 0;
        }
    }

    private isLimitReached(): boolean {
        return !!this.props.formData && this.props.formData.length >= this.getPersonLimit();
    }
}

export default withFormContext<IInputComponentProps<any, IPersonSsnInputMetadata>>(PersonSsnInputMultipleContainer);
