import {
    BaseAnalytics,
    IOnTriggerDataLayerEventParams,
    SatelliteEventTransactionTypes, SatelliteEvents, JourneyType,
} from "./BaseAnalytics";

export interface OptInOptOutAMap
{
    product_id: Array<number>;
    product_status: Array<string>;
    transaction_id: string;
    transaction_page_start?: string;
    transaction_page_complete?: string;
    groupid: string;
    serviceName: string;
}

export class OptInOptOutAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const pageStart = params.messages[`general.adobe.group.pageStart`];

        let productIds: Array<number> = [];
        let productStatus: Array<string> = [];

        if (params?.data?.optIn) {
            for (const product of params.data.optIn) {
                if (product.selected !== undefined) {
                    productIds = productIds.concat(product.productId);
                    productStatus = productStatus.concat((product.selected !== undefined && product.selected === true) ? "accept" : "deny");
                }
            }
        }

        if (params?.data?.optOut) {
            for (const product of params.data.optOut) {
                if (product.selected !== undefined) {
                    productIds = productIds.concat(product.productId);
                    productStatus = productStatus.concat((product.selected !== undefined && product.selected === true) ? "accept" : "deny");
                }
            }
        }

        const trackKey = `${SatelliteEventTransactionTypes.TransactionPage}_${params.event}`;

        const dataLayerObj: OptInOptOutAMap = {
            groupid: params?.data?.group?.id,
            serviceName: `${pageStart}`,
            product_id: productIds,
            product_status: productStatus,
            transaction_id: this.getTransactionId(),
            transaction_page_start: this.checkMatch(trackKey, `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Start}`),
            transaction_page_complete: this.checkMatch(trackKey, `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Complete}`),
        };

        this.setDataLayer(dataLayerObj, trackKey, params, JourneyType.Product);
    }
}
