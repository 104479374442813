import {withRouter} from "react-router";
import {
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    PacifierIcon,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import * as React from "react";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormattedMessage} from "react-intl";
import {Constants, PregnancyJourney} from "@folksam-digital/model";
import JourneySuccessBase from "./JourneySuccessBase";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import DirectDebitButton from "../../../../components/journey/layout/Success/DirectDebitButton";
import {MyPagesButton} from "../../../../components/journey/layout/Success";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import {ListRowItem} from "../common/ListRowItem";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

class PregnancyJourneySuccessInternal extends JourneySuccessBase<PregnancyJourney> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.Pregnancy.Id);
        this.triggerDataLayerEvent(Constants.Journey.Pregnancy.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.Pregnancy.Id}
                    formData={{contact: data.contact}}
                    backUrl={Constants.Journey.Pregnancy.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: "product.pregnancy.success.banner.heading",
                        subheader: "product.pregnancy.success.banner.subheading",
                        headerText: <FormattedMessage id={`product.pregnancy.pageTitle.headerText`}/>
                    }}
                    doScroll={true}
                >
                    <SuccessHeaderTable
                        policyNumber={data.policy.policyNumber}
                        textDate={data.policy.coverPlan === Constants.CoverPlan.Pregnancy.Extended ?
                            "product.pregnancy.success.insurance.startDate.definition.extended" : "product.pregnancy.success.insurance.startDate.definition.basic"
                        }
                        email={data.contact.emailAddress}
                    />
                    {this.isDirectDebitFormNeeded() &&
                    <DirectDebitButton handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>}
                    <Spacing type={"padding"} bottom={'4'}/>
                    <MyPagesButton/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" top={"8"}/>
                                        <SummaryCard
                                            theme={this.summaryCardThemeID}
                                        >
                                            <SummaryCard.Header>
                                                <SummaryCard.HeaderIcon iconLeft component={<PacifierIcon
                                                    style={{width: 40, paddingRight: '1rem'}}/>}/>
                                                <SummaryCard.HeaderText>
                                                    <FormattedMessage
                                                        id="product.pregnancy.success.insurance.name"/>
                                                </SummaryCard.HeaderText>
                                                <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                            </SummaryCard.Header>
                                            <SummaryCard.Body>
                                                <DescriptionList>
                                                    <ListRowItem
                                                        id="general.review.scope.title">
                                                        <FormattedMessage
                                                            id={`product.pregnancy.coverPlan.${data.policy.coverPlan}`}/>
                                                    </ListRowItem>

                                                    <ListRowItem
                                                        id="general.appliesTo.title">
                                                        <>{data.contact.firstName} {data.contact.lastName}</>
                                                    </ListRowItem>

                                                    <ListRowItem>
                                                        {data.policy.coverPlan === Constants.CoverPlan.Pregnancy.Extended &&
                                                        <FormattedMessage
                                                            id="product.pregnancy.success.insurance.price.extended"/>
                                                        }
                                                        {data.policy.coverPlan === Constants.CoverPlan.Pregnancy.Basic &&
                                                        <FormattedMessage
                                                            id="product.pregnancy.success.insurance.price.basic"/>
                                                        }
                                                        <DescriptionList.Price
                                                            background
                                                            component={<FormattedPrice
                                                                value={data.policy?.premium?.yearlyPremium!}
                                                                suffixId={"general.price.suffix"}/>}
                                                        />
                                                    </ListRowItem>
                                                </DescriptionList>
                                            </SummaryCard.Body>
                                        </SummaryCard>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const PregnancyJourneySuccess = flowRight(
    withCmsProvider("pregnancy"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint
)(PregnancyJourneySuccessInternal);

export default PregnancyJourneySuccess;
