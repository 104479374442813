import {NoCoverPlanAvailableError} from "./NoCoverPlanAvailableError";
import {JourneyErrorType} from "./journeyErrorType";
import {BaseErrorMessage} from "./BaseErrorMessage";
import {DefaultJourneyError} from "./DefaultJourneyError";
import {InsufficientVehicleInformationError} from "./InsufficientVehicleInformationError";
import {UserTooYoungError} from "./UserTooYoungError";
import {IncorrectRegistrationNumberType} from "./IncorrectRegistrationNumberType";
import {UserAlreadyHasInsuranceOnVehicleError} from "./UserAlreadyHasInsuranceOnVehicleError";
import {UnsuitableVehicleTypeForProduct} from "./UnsuitableVehicleTypeForProduct";
import {UnsuitableSpecificVehicleTypeForProduct} from "./UnsuitableSpecificVehicleTypeForProduct";
import {MopedCarInsteadOfMoped} from "./MopedCarInsteadOfMoped";
import {MopedInsteadOfMopedCar} from "./MopedInsteadOfMopedCar";
import {HomeBuildingsTooManyError} from "./HomeBuildingsTooManyError";

export class JourneyErrorMessageDelegator {
    private readonly errorType?: JourneyErrorType;
    private readonly journeyId?: string;
    private error: BaseErrorMessage;

    constructor({errorType, journeyId}: {errorType?: JourneyErrorType, journeyId?: string}) {
        this.errorType = errorType;
        this.journeyId = journeyId;

        this.determineMessage();
    }

    private determineMessage(): void {
        switch (this.errorType) {
            case JourneyErrorType.noCoverPlanAvailable:
                this.error = new NoCoverPlanAvailableError(this.journeyId);
                break;
            case JourneyErrorType.insufficientInformationAboutVehicle:
                this.error = new InsufficientVehicleInformationError(this.journeyId);
                break;
            case JourneyErrorType.userTooYoung:
                this.error = new UserTooYoungError(this.journeyId);
                break;
            case JourneyErrorType.incorrectRegistrationNumberType:
                this.error = new IncorrectRegistrationNumberType(this.journeyId);
                break;
            case JourneyErrorType.userAlreadyHasInsuranceOnVehicle:
                this.error = new UserAlreadyHasInsuranceOnVehicleError(this.journeyId);
                break;
            case JourneyErrorType.unsuitableVehicleTypeForProduct:
                this.error = new UnsuitableVehicleTypeForProduct(this.journeyId);
                break;
            case JourneyErrorType.unsuitableSpecificVehicleTypeForProduct:
                this.error = new UnsuitableSpecificVehicleTypeForProduct(this.journeyId);
                break;
            case JourneyErrorType.mopedCarInsteadOfMoped:
                this.error = new MopedCarInsteadOfMoped(this.journeyId);
                break;
            case JourneyErrorType.mopedInsteadOfMopedCar:
                this.error = new MopedInsteadOfMopedCar(this.journeyId);
                break;
            case JourneyErrorType.homeBuildingTooManyBuildings:
                this.error = new HomeBuildingsTooManyError(this.journeyId);
                break;
            default:
                this.error = new DefaultJourneyError(this.journeyId);
        }
    }

    public getMessage(): string {
        return this.error.getMessage();
    }
}
