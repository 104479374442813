import * as React from "react";
import {FormattedMessage} from "react-intl";

import { Button, Modal as ModalComponent, defaultTheme } from '@folksam-digital/ui';

const modalFooterStyles = {
    display: 'flex',
    justifyContent: 'space-between',
};

const buttonStyle = {
    width: '48%',
};

const modalContentStyle = {
    marginBottom: defaultTheme.margin['4'],
    fontSize: defaultTheme.textSizes['7']
};

const modalDefaults = {
    role: 'dialog',
    shouldCloseOnEsc: true,
    shouldFocusAfterRender: true,
    shouldReturnFocusAfterClose: true,
    shouldCloseOnOverlayClick: true,
    htmlOpenClassName: 'ReactModal__Html--open'
};

interface ModalProps {
    onClose: any;
    onSubmit: any;
    open?: boolean;
    headerMessageId?: string;
    headerMessage?: React.ReactElement;
    modalContentMessage?: React.ReactElement;
    showCloseButton?: boolean;
}

export const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, open, headerMessageId, headerMessage, modalContentMessage, showCloseButton= true }) => {

    return (
        <ModalComponent {...modalDefaults} showCloseButton={showCloseButton} isOpen={open} onRequestClose={onClose}>
            <ModalComponent.Header onModalClose={onClose} showCloseButton={showCloseButton}>
                {headerMessage ? headerMessage : <FormattedMessage id={headerMessageId || "general.remove.confirmation"}/>}
            </ModalComponent.Header>
            <ModalComponent.Body>
                {
                    modalContentMessage && <div
                        style={modalContentStyle}
                    >{
                        modalContentMessage
                    }</div>
                }
                <div style={modalFooterStyles}>
                    <Button id="#confirm" onClick={onSubmit} style={buttonStyle}>
                        <FormattedMessage id={"general.yes"} />
                    </Button>
                    <Button id="#cancel" onClick={onClose} outline={true} style={buttonStyle}>
                        <FormattedMessage id={"general.no"} />
                    </Button>
                </div>
            </ModalComponent.Body>
        </ModalComponent>
    );
};
