import {Group, PolicySearchResult} from "@folksam-digital/model";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import {
    DescriptionListResponsive, withViewContext
} from "@folksam-digital/ui";
import * as React from "react";
import {IFormComponentProps} from "../../../journey/form/FormComponentBase";
import {withRouter} from "react-router";
import {PanelComponentBase} from "../../../journey/form/panel/PanelComponentBase";
import {CmsContext} from "../../../../cms";
import {RedirectControl} from "../../../journey/form/controls/redirectControl";
import OptInOptOutProductList from "./OptInOptOutProductList";

interface IMetadata {
    group: Group
    policies: PolicySearchResult[];
    redirectBtn?: {
        redirectText: string,
        redirectUrl: string
    };
}

interface IOptInOptOutSummaryProps extends IFormComponentProps<any, IMetadata> {
    properties: [{
        content: React.ReactNode[]
    }];
}

class OptInOptOutSummary extends PanelComponentBase<any, IMetadata, {}, IOptInOptOutSummaryProps> {
    public static contextType = CmsContext;

    public render() {
        const {redirectBtn} = this.metadata;

        return (<FormContext.Consumer>
            {(context: IFormContext) => (
                <OptInOptOutProductList data={context.data}
                                        summaryCardChildren={this.props.properties.map(p => p.content)}
                                        summaryRedirectControl={redirectBtn && <DescriptionListResponsive.Header>
                                            <RedirectControl id={`${this.props.uiSchema.name}RedirectBtn`}
                                                             context={context}
                                                             redirectControl={redirectBtn} props={this.props}/>
                                        </DescriptionListResponsive.Header>}
                />
            )}
        </FormContext.Consumer>);
    }
}

export default withViewContext(withRouter(OptInOptOutSummary));
