import * as React from "react";
import {defaultTheme, IconCloseV2, Link} from "@folksam-digital/ui";
import {useStyles} from "./useStyles";
import {logoHref} from "./params";
import {clearSessionStorage} from "./clearSessionStorage";
import {UrlHelper} from "@folksam-digital/services";

export const CloseLink = (
    {
        backUrl,
    }: any) => {
    const classes = useStyles();

    return (
        <Link to={UrlHelper.getUrlWithoutSecureSubDomain(window.location.origin, backUrl || logoHref)}
              className={classes.root}
              target={"_self"}
              onClick={clearSessionStorage}
        >
            <IconCloseV2 style={{paddingLeft: 5}}
                       color={defaultTheme.colors.primary2}
                       width={14}
                       height={14}/>
        </Link>
    );
};
