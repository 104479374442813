import {FormComponentBase} from "../../FormComponentBase";
import { DescriptionListResponsive, B } from "@folksam-digital/ui";

import * as React from "react";
import {Template} from "../../output/helpers";
import {FormContext, IFormContext} from "../../FormContext";
import {RemoveButton} from "./RemoveButton";
import {Constants} from "@folksam-digital/model";
import get from "lodash/get";

interface IHeaderPanelInputMetadata {
    path: string;
    definition: string;
    showRemoveButton?: boolean;
    showClearButtonWhenFieldWithValue?: string;
    pathFilter: string;
    defaultValue?: any;
    index: number;
    theme?: number;
    round?: boolean;
}

export class HeaderPanelInput extends FormComponentBase<any, IHeaderPanelInputMetadata, any> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        const { definition, showRemoveButton, showClearButtonWhenFieldWithValue, theme = Constants.Themes.LighterBlue , round} = this.metadata;
        let removeButton;
        if (showRemoveButton || (showClearButtonWhenFieldWithValue && get(this.context.data, showClearButtonWhenFieldWithValue))) {
            removeButton = <RemoveButton id={this.props.name + "_Btn"} themeId={theme} onChange={this.onChange}/>
        }

        return (
            <DescriptionListResponsive themeId={theme} id={this.props.name}>
                <DescriptionListResponsive.Header round={round}>
                    <B>
                        <Template template={definition} data={this.context.data}/>
                    </B>
                    {removeButton}
                </DescriptionListResponsive.Header>
            </DescriptionListResponsive>
        );
    }

    private onChange = () => {
        const isOnlyCard = this.metadata.pathFilter && get(this.context.data, this.metadata.pathFilter)?.length === 1;
        if (isOnlyCard && this.metadata.showClearButtonWhenFieldWithValue && get(this.context.data, this.metadata.showClearButtonWhenFieldWithValue)) {
            this.context.addToReset({
                pathFilter: this.metadata.pathFilter,
                index: this.metadata.index,
                defaultValue: this.metadata.defaultValue
            });
            return;
        }

        this.props.onChange({pathFilter: this.metadata.pathFilter, index: this.metadata.index});
    };
}
