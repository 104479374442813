import React, {useContext, useEffect, useState} from 'react';
import {loadGoogleMaps} from "../../../Helpers/googleMaps/loadGoogleMaps";
import {parseLanguageCode} from "../../../Helpers/locale/Locale";
import { LocaleContext } from "../../../intl/LocaleContext";

const withGoogleMaps = (WrappedComponent: React.ComponentType<any>) => ({ ...props }) => {
    const localeContext = useContext(LocaleContext);
    const [googleMapsReady, setGoogleMapReady] = useState(false);

    useEffect(() => {
        const languageCode = parseLanguageCode(localeContext.locale);
        loadGoogleMaps(languageCode, (isReady) => {
            setGoogleMapReady(isReady);
        });
    }, [localeContext.locale]);

    return (
        <>{googleMapsReady ? <WrappedComponent
            {...props}
        /> : null}</>
    );
};

export default withGoogleMaps;
