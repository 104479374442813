import {IUserClient} from "@folksam-digital/services";
import {DigitalApiServiceBase} from "./DigitalApiServiceBase";

export class ApiUserClient extends DigitalApiServiceBase implements IUserClient {

    public async getHash(input: string): Promise<string> {
        const path = `/user/hash/${input}`;

        const http = super.getHttpClient(path);
        const response = await http.get(path);

        return response.data;
    }
}
