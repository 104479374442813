import {CmsCatalog} from "@folksam-digital/services";
import {ICmsContext} from "../../cms/CmsContext";
import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';

export interface ImageObject {
    src: string;
    alt: string;
    height?: string;
}

interface IWithPrefixOrParams {
    cmsContext: IWithPrefixParams;
    id: string;
    prefix?: string;
}

type IWithPrefixParams = Pick<ICmsContext, "catalogName" | "folderName">;

export class CmsHelper {
    public static withPrefixOrId(params: IWithPrefixOrParams) {
        const prefixCalc: string = params.prefix === "" ? params.prefix : `${params.cmsContext.folderName}.${params.cmsContext.catalogName}.`;

        return `${prefixCalc}${params.id}`;
    }

    public static withPrefix(params: IWithPrefixParams, id: string) {
        return `${params.folderName}.${params.catalogName}.${id}`;
    }

    public static getIdIfExists(messages: Record<string, string> | Record<string, MessageFormatElement[]>, path: string): string {
        return messages[path] ? path : "";
    }

    public static withGeneralAndCommonPrefix(id: string) {
        return `general.common.${id}`;
    }

    public static withGroupCommonPrefix(id: string) {
        return `groupInsurance.common.${id}`;
    }

    public static withGeneralPrefix(id: string) {
        return `general.${id}`;
    }

    public static getPrefix(params: IWithPrefixParams) {
        return `${params.folderName}.${params.catalogName}`;
    }

    public static getImageAttributes(imageName: string = "", catalog: CmsCatalog): ImageObject | undefined {
        if(imageName in catalog.images) {
            return {
                src: catalog.images[imageName].absoluteUrl!,
                alt: catalog.images[imageName].altText!
            }
        }

        return {
            src: "",
            alt: ""
        };
    }
}
