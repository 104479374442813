import * as React from "react";

import CurrencyInput from 'react-currency-input-field';
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {injectIntl} from "react-intl";
import {FormFieldLayout} from "../../../FormFieldLayout";
import sanitizeField from "./helpers/sanitizeField";
import {IBreakPoint} from "../../layout/helpers";
import { CurrencyFieldAdapter } from "../adapter/CurrencyFieldAdapter";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {InputMode, InputType} from "@folksam-digital/model";

interface IMetadata {
    placeholder?: string;
    type?: InputType;
    inputMode?: InputMode;
    prefix?: string;
    suffix?: string;
    classNames?: string;
    help?: string;
    breakpoints?: IBreakPoint;
    isDisabled?: boolean;
    allowDecimals: boolean;
    labelPanelName?:string;
}

class CurrencyInputFieldInternal extends InputComponentBase<string, IMetadata, {}> {
    ref: React.RefObject<any>;

    constructor(props: IInputComponentProps<string, IMetadata>) {
        super(props);
        this.handleOnBlurCallbacks = this.handleOnBlurCallbacks.bind(this);

        this.onChange = this.onChange.bind(this);
        this.ref = React.createRef();
    }

    onChange(value?: string, name?: string) {
        this.props.onChange(value !== undefined ? sanitizeField(value) : value);
    }

    private handleOnBlurCallbacks(event: any) {
        if (this.props.onBlur && typeof this.props.onBlur === "function") {
            this.props.onBlur(event);
        }

        this.onBlurWithValidation(event, this.props.formData);
    }

    render() {
        const {
            formData,
            name,
            disabled,
            readonly,
            autofocus,
            intl,
        } = this.props;

        const {
            placeholder,
            suffix,
            prefix,
            breakpoints,
            isDisabled,
            allowDecimals = false,
            labelPanelName
        } = this.metadata;

        const maxLength = this.schema.maxLength;
        return (
            <FormFieldLayout {...this.getLayoutProps()} breakpoints={getBreakpoints(defaultBreakpoints.currencyInput, breakpoints)}>
                <CurrencyInput
                    ariaLabelledby={labelPanelName}
                    id={name}
                    name={name}
                    customInput={CurrencyFieldAdapter}
                    allowDecimals={allowDecimals}
                    forwardmetadata={
                        {
                            placeholder: placeholder && intl.formatMessage({id: placeholder}),
                            disabled: isDisabled,
                            breakpoints,
                            suffix: suffix && intl.formatMessage({id: suffix}),
                            prefix: prefix && intl.formatMessage({id: prefix})
                        }
                    }
                    maxLength={maxLength || 9}
                    value={formData}
                    forwardprops={{
                        disabled,
                        readOnly: readonly,
                        autoFocus: autofocus,
                        invalid: this.isInvalid()
                    }}
                    onBlur={this.handleOnBlurCallbacks}
                    onValueChange={this.onChange}
                    disableAbbreviations={true}
                />
            </FormFieldLayout>
        );
    }
}

const CurrencyInputField = injectIntl(CurrencyInputFieldInternal);

export { CurrencyInputField };
