import * as React from "react";
import flowRight from "lodash/flowRight";
import debounce from "lodash/debounce";
import get from "lodash/get";
import isObject from "lodash/isObject";
import {injectIntl} from "react-intl";
import {
    AutoComplete,
    withViewContext,
    P,
    defaultTheme,
} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import { FormFieldLayout } from "../../../FormFieldLayout";
import sanitizeField from "./helpers/sanitizeField";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import { ISearchService } from "../../../../services";
import { SuggestionsRenderer } from "./googleAutocomplete/SuggestionsRenderer";
import { InputRenderer } from "./searchAutoComplete/InputRenderer";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

interface ISearchInputState {
    isLoading: boolean;
    autocompleteOK: string | null;
    suggestions?: any[];
    focus?: boolean;
}

export interface ISearchInputAutoCompleteMeta {
    breakpoints?: any;
    debounce?: number;
    path: string;
    method: "get" | "post";
    placeholder?: string;
    helpText?: string;
    help?: string;
    maxLength?: string | number;
    fields?: string[];
    suggestionFields?: string[];
    map?: {[key: string]: string};
    additionalQueryValue?: string;
    resultListLocation?: string;
    capitalize?: boolean;
    freeTextField?: string;
}

class SearchInputInternal extends InputComponentBase<string, ISearchInputAutoCompleteMeta, ISearchInputState, IInputComponentProps<any, any>> {
    public ref: React.RefObject<HTMLInputElement>;
    public divRef: React.RefObject<HTMLDivElement>;
    protected searchService: ISearchService;

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            isLoading: false,
            autocompleteOK: null,
            suggestions: [],
            focus: false,
        };
        this.ref = React.createRef();
        this.divRef = React.createRef();

        this.searchService = container.get<ISearchService>(Types.SearchService);
        this.search = debounce(this.search.bind(this), this.metadata.debounce || 300);
        this.changeValue = this.changeValue.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        const dataToChange = {isLoading: false, focus: true, suggestions: this.state.suggestions};

        if (this.metadata.resultListLocation) {
            const suggestions = get(this.context.data, this.metadata.resultListLocation, []);

            if (this.state.suggestions?.length === 0 || !isEqual(suggestions, this.state.suggestions)) {
                dataToChange.suggestions = suggestions;
            }
        }

        this.setState(dataToChange);
    }

    onBlur() {
        const changes: Partial<Pick<ISearchInputState, "focus" | "suggestions">> = {focus: false};
        const isSelected = isObject(this.props.formData) && !isEmpty(this.props.formData);

        if (isSelected) {
            changes.suggestions = [];
        }

        this.setState(changes);
    }

    search(value: string) {
        this.setState({isLoading: false});
        if (value.length > 0) {
            this.fetchSuggestions(value);
        }
    }

    changeValue = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = sanitizeField(event.target.value);

        this.search(value);

        if (this.metadata.freeTextField && value) {
            const result = {
                [this.metadata.freeTextField]: value
            } as {[key: string]: string};

            this.props.onChange(result);
        } else {
            this.props.onChange(value);
        }
    };

    async fetchSuggestions(value: string) {
        try {
            this.setState({isLoading: true});
            const other = get(this.context.data, this.metadata.additionalQueryValue || "");
            const results = await this.searchService.search({query: value, other, path: this.metadata.path, method: this.metadata.method});

            this.setState({isLoading: false, suggestions: results });
        } catch (e) {
            this.setState({isLoading: false});
        }
    }

    handleBlur = (event: React.FocusEvent<any>) => {
        this.onChangeWithValidation(this.props.formData);
    };

    handleResultSelect = (record: any) => {
        const map = this.metadata.map;

        if (!map || !record) {
            this.setState({suggestions: []});
            return this.onChangeWithValidation(record);
        }

        const result = {} as {[key: string]: string};
        for (const [key, value] of Object.entries(map)) {
            result[key] = record[value];
        }

        this.onChangeWithValidation(result);
        this.setState({suggestions: []});
    };

    getFormDataValue(formData: any) {
        if (typeof formData === "string") {
            return formData;
        } else if (typeof formData === "object") {
            if (Array.isArray(this.metadata?.fields) && this.metadata?.fields.length > 1) {
                return `${formData?.label}, ${formData?.value}`;
            } else {
                return formData?.label;
            }
        }

        return undefined;
    }

    public render() {
        const {formData, name, intl} = this.props;
        const {placeholder, helpText, help, suggestionFields, maxLength} = this.metadata;
        const value = this.getFormDataValue(formData);
        const isSelected = isObject(formData) && !isEmpty(formData);

        return (
            <FormFieldLayout {...this.getLayoutProps()}
                             title={this.schema.title}
                             help={help}
            >
                {helpText ? <P style={{color: defaultTheme.colors.senary1, fontSize: 14, fontWeight: 400}}><FormattedMarkdown messageKey={helpText}/></P> : <></> }
                <AutoComplete
                    ref={this.ref}
                    loading={this.state.isLoading}
                    onSuggestionSelect={this.handleResultSelect}
                    suggestions={this.state.suggestions}
                    renderSuggestions={SuggestionsRenderer}
                    suggestionFields={suggestionFields}
                    suggestionProps={{ capitalize: this.metadata.capitalize }}
                    onChange={this.changeValue}
                    renderInput={InputRenderer}
                    value={value}
                    open={this.state.suggestions && this.state.suggestions.length > 0 && this.state.focus}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    inputProps={{
                        maxLength,
                        selected: isSelected,
                        onFocus: this.onFocus,
                        capitalize: this.metadata.capitalize
                    }}
                    onBlur={this.onBlur}
                    idPrefix={name}
                    invalid={this.isInvalid()}
                />
                <div ref={this.divRef}/>
            </FormFieldLayout>
        );
    }
}

const SearchInput = flowRight(injectIntl, withViewContext)(SearchInputInternal);

export { SearchInput };
