import React, {useCallback, useContext} from "react";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import {FormattedMessage, useIntl} from "react-intl";
import {SsnFormatter} from "@folksam-digital/services";
import {PhoneNumberFormatter} from "../../Helpers/PhoneNumberFormatter";
import {
    ButtonResponsive,
    ConstrainWidth,
    EnvelopeIcon,
    Form,
    FormInputCheckbox,
    Grid,
    IconContactPhone,
    IconDownload,
    Link,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    useViewContext
} from "@folksam-digital/ui";
import {TooltipOutput} from "../../components/journey/form/output/TooltipOutput";
import {FormFieldLayout} from "../../components/FormFieldLayout";
import {FormFieldController} from "./FormFieldController";
import {Constants, DirectDebit, HelpPosition, InputMode, InputType} from "@folksam-digital/model";
import {CmsContext} from "../../cms";
import Icons from "../../components/icons";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {LinkButton} from "../../components/journey/form/input/LinkButton";
import omit from 'lodash/omit';

interface IRenderFormField {
    layoutProps: {
        name: keyof DirectDebit;
        [key: string]: any;
    };
    inputProps: any;
    prefilled?: boolean;
    name?: keyof DirectDebit;
    formatter?: any;
    placeholder?: string;
    required?: boolean;
    title?: any;
}

export const SetUpFormView = (
    {
        data,
        errors,
        onSubmit,
        handleOnChange,
        handleOnBlur,
        handleTermsAndConditions,
        prefilled,
        isEmptyPrefilledField,
        initialData,
        disableFormSubmit
    }: any) => {
    const renderFormField = ({layoutProps, inputProps}: IRenderFormField): React.ReactElement => {
        return (
            <FormFieldLayout
                {...layoutProps}
                id={layoutProps.name}
                error={errors[layoutProps.name]}
                invalid={!!errors[layoutProps.name]}
            >
                <FormFieldController
                    id={layoutProps.name}
                    value={data[layoutProps.name]}
                    invalid={!!errors[layoutProps.name]}
                    isReadOnly={!!inputProps.prefilled}
                    readOnlyFormatter={inputProps.formatter}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    {...omit(inputProps, ['prefilled', 'formatter'])}
                />
            </FormFieldLayout>
        );
    };

    const shouldRenderTextField = (name: keyof DirectDebit): boolean => {
        const shouldRender = prefilled && initialData && initialData[name] && !isEmptyPrefilledField(name);

        return shouldRender as boolean;
    };

    const onClearingNumberChange = useCallback((event: any) => {
        const {value} = event.target;
        event.target.value = value.replace(/\D/g, "");
        handleOnChange(event);
    }, [handleOnChange]);

    const downloadImageStyles = {
        fill: "#007BCF",
        width: "2rem",
        height: "2rem",
        marginRight: "0.4rem",
        verticalAlign: "middle"
    };
    const intl = useIntl();

    const viewContext = useViewContext();
    const cmsContext = useContext(CmsContext);
    const ssnPlaceholder = intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "form.placeholder.ssn")});
    const emailPlaceholder = intl.formatMessage({id: CmsHelper.withGeneralPrefix("form.emailAddress.placeholder")});
    const clearingNumberPlaceholder = intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "form.placeholder.clearingNumber")});
    const phoneNumberPlaceholder = intl.formatMessage({id: CmsHelper.withGeneralPrefix("phoneNumber.placeholder")});

    return (
        <Form onSubmit={onSubmit} style={{backgroundColor: "#f4f8fa", marginBottom: "-14px"}}>
            <MaxWidthWrapper compact={true} theme={1}>
                <Step.SectionBody>
                    <Spacing type={"margin"} top={"sm"} bottom={"lg"}>
                        <P>
                            <FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, "form.description")}/>
                        </P>
                    </Spacing>
                    <Grid>
                        <Grid.Row>
                            <Grid.Col xl>
                                <Spacing type={"margin"} bottom={"sm"}>
                                    {renderFormField({
                                        layoutProps: {
                                            name: "ssn",
                                            title: <FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "form.ssn")}/>,
                                            required: true
                                        },
                                        inputProps: {
                                            readOnlyFormatter: SsnFormatter.formatWithDash,
                                            prefilled: prefilled,
                                            placeholder: ssnPlaceholder,
                                            type: InputType.tel
                                        }
                                    })
                                    }
                                </Spacing>
                            </Grid.Col>
                        </Grid.Row>
                        {prefilled && (data["firstName"] || data["lastName"]) && <>
                            <Grid.Row>
                                <Grid.Col xl>
                                    <Spacing type={"margin"} bottom={"sm"}>
                                        {renderFormField({
                                            layoutProps: {
                                                name: "firstName",
                                                title: <FormattedMessage
                                                    id={CmsHelper.withPrefix(cmsContext, "form.firstName")}/>,
                                                required: true
                                            },
                                            inputProps: {
                                                prefilled: prefilled,
                                                maxLength: 100
                                            }
                                        })
                                        }
                                    </Spacing>
                                </Grid.Col>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Col xl>
                                    <Spacing type={"margin"} bottom={"sm"}>
                                        {renderFormField({
                                            layoutProps: {
                                                name: "lastName",
                                                title: <FormattedMessage
                                                    id={CmsHelper.withPrefix(cmsContext, "form.lastName")}/>,
                                                required: true
                                            },
                                            inputProps: {
                                                prefilled: prefilled,
                                                maxLength: 100
                                            }
                                        })
                                        }
                                    </Spacing>
                                </Grid.Col>
                            </Grid.Row>
                        </>}
                        <Grid.Row>
                            <Grid.Col xl>
                                <Spacing type={"margin"} bottom={"sm"}>
                                    {renderFormField({
                                        layoutProps: {
                                            name: "phoneNumber",
                                            title: <FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "form.phoneNumber")}/>,
                                            help: CmsHelper.withGeneralPrefix("phoneNumber.helpText"),
                                            helpPosition: HelpPosition.Top,
                                        },
                                        inputProps: {
                                            inputMode: InputMode.tel,
                                            formatter: PhoneNumberFormatter.formatPhoneNumber,
                                            placeholder: phoneNumberPlaceholder,
                                            prefilled: shouldRenderTextField("phoneNumber"),
                                            prefix: <IconContactPhone/>,
                                            maxLength: 10
                                        }
                                    })
                                    }
                                </Spacing>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xl>
                                <Spacing type={"margin"} bottom={"sm"}>
                                    {renderFormField({
                                        layoutProps: {
                                            name: "emailAddress",
                                            title: <FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "form.email")}/>,
                                            required: true
                                        },
                                        inputProps: {
                                            prefilled: prefilled,
                                            placeholder: emailPlaceholder,
                                            prefix: <EnvelopeIcon/>
                                        }
                                    })
                                    }
                                </Spacing>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xl>
                                {renderFormField({
                                    layoutProps: {
                                        name: "clearingNumber",
                                        title: <FormattedMessage
                                            id={CmsHelper.withPrefix(cmsContext, "form.clearingNumber")}/>,
                                        required: true,
                                        help: CmsHelper.withPrefix(cmsContext, "form.clearingNumber.help"),
                                        helpPosition: HelpPosition.Bottom
                                    },
                                    inputProps: {
                                        suffix: <TooltipOutput
                                        messageKey={CmsHelper.withPrefix(cmsContext, "form.clearingNumber.hint")} isInlineTooltip={false} />,
                                        placeholder: clearingNumberPlaceholder,
                                        onChange: onClearingNumberChange,
                                        inputMode: InputMode.numeric,
                                        maxLength: 4
                                    }
                                })}
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xl>
                                {viewContext.isMobile && <Spacing type={"margin"} bottom={"sm"}/>}
                                {renderFormField({
                                    layoutProps: {
                                        name: "accountNumber",
                                        required: true,
                                        title: <FormattedMessage
                                            id={CmsHelper.withPrefix(cmsContext, "form.accountNumber")}/>
                                    },
                                    inputProps: {
                                        type: InputMode.numeric,
                                        maxLength: 100
                                    }
                                })}
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xs>
                                <Spacing type={"margin"} bottom={"sm"}/>
                            </Grid.Col>
                        </Grid.Row>
                        {
                            errors?.bankAccountAlreadyExists &&
                            <Grid.Row>
                                <Grid.Col xs>
                                    <Spacing type="margin" bottom="lg">
                                        <FormFieldLayout
                                            id={"bankAccountAlreadyExists"}
                                            error={errors.bankAccountAlreadyExists}
                                        />
                                    </Spacing>
                                </Grid.Col>
                            </Grid.Row>
                        }
                    </Grid>
                </Step.SectionBody>
            </MaxWidthWrapper>
            <MaxWidthWrapper compact={true} theme={1}>
                <Step.Footer>
                    <Grid>
                        <Grid.Row style={{marginBottom: 25}}>
                            <Grid.Col xl>
                                <Link
                                    to={intl.formatMessage({id: "directDebit.directDebit.prePostPurchaseUrl"})}>
                                    <IconDownload style={downloadImageStyles}/>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(cmsContext, "form.termsAndConditions.link")}/>
                                </Link>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row style={{marginBottom: 25}}>
                            <Grid.Col xl>
                                <FormFieldLayout
                                    id={"termsAndConditions"}
                                    required={true}
                                    error={errors.termsAndConditions}
                                    invalid={!!errors.termsAndConditions}>
                                    <FormInputCheckbox
                                        required={true}
                                        id={"termsAndConditions"}
                                        label={
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "form.termsAndConditions")}/>
                                        }
                                        name={"termsAndConditions"}
                                        checked={data.termsAndConditions}
                                        onChange={handleTermsAndConditions}
                                        invalid={!!errors.termsAndConditions}
                                    />
                                </FormFieldLayout>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row style={{marginBottom: 25}}>
                            <Grid.Col xl>
                                <ButtonResponsive
                                    type="submit"
                                    full
                                    primary
                                    disabled={disableFormSubmit}
                                >
                                    <div style={{height: 20, width: 20, marginRight: 10}}>
                                        {Icons.BankId()}
                                    </div>
                                    <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "form.submit")}/>
                                </ButtonResponsive>
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xl>
                                <ConstrainWidth center={true}>
                                    <LinkButton url={Constants.Links.personalData}>
                                        <FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, "form.personalData.link")}/>
                                    </LinkButton>
                                </ConstrainWidth>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid>
                </Step.Footer>
            </MaxWidthWrapper>
        </Form>
    );
};
