import {injectIntl} from "react-intl";
import {FormContext} from "../../../../components/journey/form/FormContext";
import {BannerWithLinkComponent, IBannerWithLinkProps} from "./BannerWithLink";
import {Constants} from "@folksam-digital/model";

class CoopBannerWithLinkComponent extends BannerWithLinkComponent<IBannerWithLinkProps> {
    public static contextType = FormContext;

    constructor({
                    titleId = "general.success.coop.bannerWithLink.title",
                    textId = "general.success.coop.bannerWithLink.text",
                    linkId = Constants.Links.folksamCoopBannerLink,
                    buttonTextId = "general.success.coop.bannerWithLink.buttonText",
                    ...props
                }: IBannerWithLinkProps) {
        super({titleId, textId, linkId, buttonTextId, ...props});
    }
}


const CoopBannerWithLink = injectIntl(CoopBannerWithLinkComponent);
export {CoopBannerWithLink};
