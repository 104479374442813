import * as React from "react";
import {GroupInsurance, OfferBase, PolicySearchResult, ShoppingCartContact} from "@folksam-digital/model";
import {DescriptionListResponsive, P, Spacing, SummaryCard} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import merge from "lodash/merge";
import SelectedProductListTotals, {TotalTypes} from "./SelectedProductListTotals";
import {PersonProductList} from "../output/productList/PersonProductList";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IProposalProductListProps {
    data: GroupInsurance;
}

class ProposalProductList extends React.Component<IProposalProductListProps> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const {data} = this.props;

        if (!this.displayProposalSection(data)) {
            return null;
        }

        return (
            <Spacing type={"padding"} y={"md"}>
                <SummaryCard theme={3} headerText={<FormattedMessage
                    id={CmsHelper.withPrefix(this.context, "success.proposalList.title")}/>}>
                    <SummaryCard.Body>
                        <Spacing bottom={"md"} top={"sm"}>
                            <DescriptionListResponsive.Row>
                                <FormattedMarkdown
                                    messageKey={CmsHelper.withPrefix(this.context, "success.proposalList.description")}/>
                            </DescriptionListResponsive.Row>
                        </Spacing>
                        {this.renderProposalList(data.member)}
                        {this.renderProposalList(data.partner)}
                        {
                            data.children && data.children.map((childContact: ShoppingCartContact) => (
                                this.renderProposalList(childContact)
                            ))
                        }
                        <SelectedProductListTotals {...merge(this.state, {type: TotalTypes.Proposal}) as any}/>
                        <Spacing top={"3"} bottom={"md"}>
                            <P><FormattedMarkdown
                                messageKey={CmsHelper.withGeneralPrefix("form.priceDisclaimer.helpText")}/></P>
                        </Spacing>
                    </SummaryCard.Body>
                </SummaryCard>
            </Spacing>

        );
    }

    private displayProposalSection(data: GroupInsurance): boolean | undefined {
        return (data.member.offerList && this.hasProposals(data.member.offerList)) || (data.partner && data.partner.offerList && this.hasProposals(data.partner.offerList)) ||
            (data.children && data.children.some((child: ShoppingCartContact) => {
                return child.offerList && this.hasProposals(child.offerList);
            }));
    }

    private hasProposals(offerList: OfferBase[]): boolean {
        return offerList.some(offer => {
            return offer.policy && offer.policy.proposalNumber && !offer.policy.policyNumber;
        });
    }

    private filterPurchasedAndValidOffers(offerList: OfferBase[]) {
        return offerList.filter((offer: OfferBase) => {
            return offer.selected && offer.policy && offer.policy.proposalNumber && (!offer.policy.errors || offer.policy.errors.length === 0) && !offer.policy.policyNumber;
        })
    }

    private renderProposalList(person: ShoppingCartContact | undefined, showHeader = false) {
        if (!person?.contact?.ssn) {
            return null;
        }

        if (!person?.offerList) {
            return null;
        }

        const filteredOfferList = this.filterPurchasedAndValidOffers(person.offerList);
        return <PersonProductList filteredOfferList={filteredOfferList as PolicySearchResult[]} contact={person.contact}
                                  showHeader={showHeader}/>;
    }
}

export default ProposalProductList;
