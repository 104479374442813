import {Constants} from "@folksam-digital/model";
import {IntlShape} from "react-intl";
import {ICmsContext} from "../../../../../cms";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";
import {
    carIconListGenerator,
    homeLegacyIconListGenerator, homeVacationIconListGenerator,
    motorCommonIconListGenerator,
} from "./bannerIconListGenerators";
import {ReactNode} from "react";

export interface IBannerIconNewListItem {
    text: string;
    icon: ReactNode;
}

export type IBannerIconListGenerator = (formData: Record<string, any>) => IBannerIconNewListItem[];

interface IBannerIconList {
    [key: string]: IBannerIconListGenerator;
}

export const buildFormattedMessageList = (list: IBannerIconNewListItem[], cmsContext: ICmsContext, intl: IntlShape) => (
    list.map(({ text, icon }) => ({
        text: intl.formatMessage({ id: CmsHelper.withPrefix(cmsContext,`${text}`) }),
        icon: icon
    }))
);

export const bannerIconNewList: IBannerIconList = {
    [Constants.Journey.Car.Id]: carIconListGenerator,
    [Constants.Journey.SmallTractor.Id]: motorCommonIconListGenerator,
    [Constants.Journey.EpaAndATractor.Id]: motorCommonIconListGenerator,
    [Constants.Journey.MopedCar.Id]: motorCommonIconListGenerator,
    [Constants.Journey.HomeRental.Id]: homeLegacyIconListGenerator,
    [Constants.Journey.HomeBuilding.Id]: homeLegacyIconListGenerator,
    [Constants.Journey.HomeStudent.Id]: homeLegacyIconListGenerator,
    [Constants.Journey.HomeApartment.Id]: homeLegacyIconListGenerator,
    [Constants.Journey.HomeVacation.Id]: homeVacationIconListGenerator,
    [Constants.Journey.HomeCondominium.Id]: homeLegacyIconListGenerator,
};
