import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import get from "lodash/get";
import classNames from "classnames";
import {
    Button,
    defaultTheme,
    IconPlus,
    B,
    DisplayIcon,
} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "../InputComponentBase";
import injectSheet from "react-jss";
import {Classes} from "jss";
import flowRight from "lodash/flowRight";

interface IMetadata {
    coverId: number;
    monthlyPremiumPath: string;
    expanded?: boolean;
    disabled?: boolean;
}

const cssStyles = {
    disabled: {
        opacity: "50%"
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `20px 0px 8px`
    },
    button: {
        color: defaultTheme.colors.primary2,
        'border-color': defaultTheme.colors.primary2,
        '&[disabled]': {
            color: defaultTheme.colors.primary2,
            fill: defaultTheme.colors.primary2,
            'border-color': defaultTheme.colors.primary2,
        }
    }
}

interface IAddonCardModalAddButtonInternalProps<TValue, TMetadata> extends IInputComponentProps<TValue, TMetadata> {
    classes: Classes<keyof typeof cssStyles>;
}

export class AddonCardModalAddButtonInternal extends InputComponentBase<any, IMetadata, any, IAddonCardModalAddButtonInternalProps<any, IMetadata>> {
    onClick = () => {
        this.props.onChange('');
    }

    render() {
        const {classes, intl} = this.props;
        const {coverId, monthlyPremiumPath, disabled} = this.metadata;

        const styles = {
            div: {
                width: '100%',
                minHeight: '63px'
            },
            span: {
                color: defaultTheme.colors.primary1,
                fontWeight: defaultTheme.fontWeights.semiBold,
                fontSize: defaultTheme.textSizes.base,
                fontFamily: defaultTheme.fonts.serif
            },
            iconPlus: {
                paddingRight: defaultTheme.padding[2],
                height: 20,
                width: 20
            }
        };

        return (
            <div className={classNames(classes.buttonWrapper, {[classes.disabled]: disabled})}>
                        <span style={styles.span}>
                            {monthlyPremiumPath && <FormattedMessage
                                id={"general.addon.price.title"}
                                values={{monthlyPremium: intl.formatNumber(Math.round(get(this.context.data, monthlyPremiumPath, 0)))}}/>}
                        </span>
                <Button
                    isKeyboardAccessible={true}
                    id={`${coverId}ModalButton`}
                    primary
                    outline={true}
                    onClick={this.onClick}
                    disabled={disabled}
                    className={classes.button}
                >
                    <>
                        <DisplayIcon icon={<IconPlus style={styles.iconPlus}/>} />
                        <B><FormattedMessage id={"general.button.product.add.text"}/></B>
                    </>
                </Button>
            </div>
        )
    }
}

export const AddonCardModalAddButton = flowRight(injectIntl, injectSheet(cssStyles, {inject: ["classes"]} as any))(AddonCardModalAddButtonInternal as any);
