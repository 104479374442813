import {IMessages} from "../../cms";
import {ISessionContextManager, IStorage, StorageKeys} from "../../services";
import {container} from "../../inversify.config";
import {Types} from "../../Types";
import {IUserClient} from "@folksam-digital/services";
import {SatelliteEventTransactionTypes, SatelliteEvents} from "../new/BaseAnalytics";

export enum PageTypes {
    SPAPage = "SPA_page"
}

export interface BaseMapping {
    customer_id?: string;
    pagename: string;
    pagetype: string;
    service_name?: string;
    sitesection1: string;
    sitesection2: string;
    sitesection3: string | boolean;
    sitesection4: string | boolean;
    sitesection5?: string | boolean;
}

export interface IOnTriggerDataLayerEventParams {
    messages: IMessages;
    currentStep: string;
    currentStepIndex?: number;
    transactionType: SatelliteEventTransactionTypes;
    event: SatelliteEvents;
    data?: any;
    journeyId?: string;
    transactionId?: string;
    dataChanged?: boolean;
}

export type TOnTriggerDataLayerEventFunction = (params: IOnTriggerDataLayerEventParams) => void;

export interface IOnTriggerDataLayerEvent {
    onTriggerDataLayerEvent: TOnTriggerDataLayerEventFunction
}

export abstract class BaseAnalytics implements IOnTriggerDataLayerEvent {
    private readonly sessionContextManager: ISessionContextManager;
    private readonly userClient: IUserClient;
    protected readonly sessionStorage: IStorage;

    public abstract onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void>;

    constructor() {
        this.sessionContextManager = container.get<ISessionContextManager>(Types.SessionContextManager);
        this.userClient = container.get<IUserClient>(Types.UserClient);
        this.sessionStorage = container.get<IStorage>(Types.SessionStorage);
    }

    protected setDataLayer(mappingObject: BaseMapping, key: string) {
        // If flag already set and user is on success page return
        if (this.analyticsFlowCompleted && this.isFlowCompleted(key)) {
            return;
        }
        // Reset the flag when user not on success page anymore
        if (!this.isFlowCompleted(key) && this.analyticsFlowCompleted) {
            this.sessionStorage.set(StorageKeys.ANALYTICS_FLOW_COMPLETED, false);
        }

        window.datalayer = Object.assign(window.datalayer, mappingObject);
        if (window._satellite && window._satellite.track) {
            window._satellite.track(key);
            // Set flag when triggered on success page to prevent repeated triggers on reload
            if (this.isFlowCompleted(key)) {
                this.sessionStorage.set(StorageKeys.ANALYTICS_FLOW_COMPLETED, true);
            }
        }
    }

    protected checkMatch(value: string, compareKey: string): string | undefined {
        return (value === compareKey) ? compareKey : undefined;
    }

    protected getTransactionId(): string {
        const context = this.sessionContextManager.getSessionContext();
        return context && context.transactionId ? context.transactionId : "";
    }

    protected async getUserId(input?: string): Promise<string> {
        let userId = "";
        try {
            userId = await this.loadUserId(input);
        } catch (e) {
            console.warn("Could not load user id");
        }

        return userId;
    }

    protected isStepTypeError(stepType: string): boolean {
        return stepType === "error";
    }

    protected isStepTypeSuccess(stepType: string): boolean {
        return stepType === "success";
    }

    protected getPageName(params: IOnTriggerDataLayerEventParams): string {
        let pageName: string = "";
        if (params.currentStepIndex) {
            pageName = params.messages["general.adobe.step"] + (params.currentStepIndex + 1);
        }
        if (params.currentStepIndex === undefined) {
            pageName = "|" + params.messages[`general.adobe.${params.currentStep}`];
        }

        if (this.isStepTypeError(params.currentStep)) {
            pageName = "|" + params.messages["general.adobe.error"];
        }
        return pageName;
    }

    private async loadUserId(input?: string): Promise<string | never> {
        if (window.datalayer.hasOwnProperty("customer_id")) {
            return window.datalayer["customer_id"];
        } else if (input) {
            return await this.userClient.getHash(input);
        }

        return "";
    }

    private get analyticsFlowCompleted(): boolean | undefined {
        return this.sessionStorage.get<boolean>(StorageKeys.ANALYTICS_FLOW_COMPLETED, false);
    }

    private isFlowCompleted(key: string): boolean {
        switch (key) {
            case `${SatelliteEventTransactionTypes.Service}_${SatelliteEvents.Complete}`:
                return true;
            case `${SatelliteEventTransactionTypes.ClaimFlow}_${SatelliteEvents.Complete}`:
                return true;
            case `${SatelliteEventTransactionTypes.TransactionPage}_${SatelliteEvents.Complete}`:
                return true;
            default:
                return false;
        }
    }
}
