import {BaseErrorMessage} from "./BaseErrorMessage";

export class InsufficientVehicleInformationError extends BaseErrorMessage {
    protected determineMessage(): void {
        this.message = "contactForm.insufficientVehicleInformation.description";
    }

    public getMessage(): string {
        return this.message;
    }
}
