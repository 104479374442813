import React from "react";
import {TooltipOutput} from "../journey/form/output/TooltipOutput";

export const Tooltip = ({tooltip, style}: any) => {
    if (!tooltip) {
        return null;
    }

    return tooltip && <TooltipOutput messageKey={tooltip} style={style}/>;
};
