import * as React from "react";
import {CoverageCardSelector, Grid, IconInfo, QuestionSkeleton, withViewContext} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {FormContext, IFormContext} from "../FormContext";
import {IModalProps, ModalIframe} from "../output";
import {FormattedMessage, injectIntl} from "react-intl";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {CoverPlan} from "@folksam-digital/model";
import {CmsHelper, ImageObject} from "../../../../Helpers/cms/CmsHelper";
import {Markdown} from "../../../Markdown";
import {PbbHelper} from "@folksam-digital/services";

interface IMetadata {
    coverPlansToShow?: string[];
    modal: IModalProps;
    prefix: string;
    mainCoverId?: number;
}

interface IOption {
    title: string;
    value: string;
    image: ImageObject;
    price: string;
}

const iconStyle = {
    fill: "#007BCF",
    width: "1.2rem",
    height: "1.2rem",
    marginRight: "0.4rem",
    verticalAlign: "middle"
};

class PackageSelectorCardInternal extends InputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    render() {
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    this.context.data.policy?.premium && this.context.data.policy?.premium?.coverPlans ?
                        <FormFieldLayout {...this.getLayoutProps()}>
                            <Grid.Row>
                                <Grid.Col lg={4}>
                                    {this.renderCoverPlans(cmsContext)}
                                </Grid.Col>
                            </Grid.Row>
                        </FormFieldLayout>
                        :
                        <QuestionSkeleton/>
                )}
            </CmsContext.Consumer>
        );
    }

    private renderCoverPlans(cmsContext: ICmsContext) {
        const {modal, mainCoverId} = this.metadata;
        const {intl, formData} = this.props;

        const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
            this.onChangeWithValidation(e.target.value);
        };

        const coverPlans: CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);
        const options = coverPlans.map((item: CoverPlan): IOption => {
            const coverPlanDetails = PbbHelper.getCoverPlanDetails(this.context.data, item.id!, mainCoverId!);

            return {
                title: intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.selector.${item.id}.heading`)}, coverPlanDetails),
                value: item?.id || "",
                image: CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, `coverageImage_${item?.id}`), cmsContext.catalog!) || {
                    src: "",
                    alt: ""
                },
                price: intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.price`)}, coverPlanDetails)
            };
        });

        return (
            <CoverageCardSelector price={options.find(item => item.value === formData)?.price}>
                <CoverageCardSelector.Heading
                    heading={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.heading`)})}
                    image={options.find(item => item.value === formData)?.image}
                    selectorId={formData}
                    selectorTitle={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.select.title`)})}
                    options={options}
                    selected={formData}
                    onChange={onChange}
                />
                <Markdown
                    source={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.selector.insuranceInfoList`)})}/>
                <div className="spaceTop">
                    <ModalIframe url={modal.url} title={<span><IconInfo style={iconStyle}/><FormattedMessage
                        id={modal.title}/></span>}/>
                </div>
            </CoverageCardSelector>
        );
    }
}

const PackageSelectorCard = injectIntl(withViewContext(PackageSelectorCardInternal));
export {PackageSelectorCard};
