import * as React from "react";
import {RouteComponentProps} from "react-router";
import {IntlShape} from "react-intl";
import {IFormContext} from "./FormContext";
import {Validation} from "@folksam-digital/model";

export interface ISchema {
    default?: any,
    format?: string,
    maximum?: number,
    minLength?: number,
    maxLength?: number,
    minimum?: string,
    pattern?: string,
    title: string,
    type: string,
    description: string,
}

export interface IBaseMetadata {
    attrSequence?: number
}

export interface IUiSchema<TMetadata> {
    name: string;
    displayCondition?: string;
    classNames: string;
    errorComponent: string;
    errorMessages: {
        [key: string]: string;
    };
    errorSuppress?: boolean;
    help?: string;
    helpComponent?: string;
    helpSuppress?: boolean;
    componentMetadata: TMetadata & IBaseMetadata;
    titleComponent: string;
    validation: Validation;
    model: string;
}

export interface IIdSchema {
    $id: string
}

export interface IFormComponentProps<TValue, TMetadata> extends RouteComponentProps<any> {
    required: boolean;
    name: string;
    title: string;
    onChange: (value: TValue | undefined) => void;
    onBlurValueFormatter: (value: TValue | undefined) => string;
    formData: TValue;
    autofocus: boolean;
    disabled: boolean;
    rawErrors: string[];
    errorSchema: any;
    readonly: boolean;
    formContext?: IFormContext;
    schema: ISchema;
    uiSchema: IUiSchema<TMetadata>;
    idSchema: IIdSchema;
    properties?: {
        content: any
    }[];
    viewContext?: any;
    intl: IntlShape;
    onBlur?: (value?: TValue | undefined) => void;
    onFocus?: (event?: FocusEvent, idSchema?: any) => void;
    children?: React.ReactNode;
}

export abstract class FormComponentBase<TValue, TMetadata, TState,
    TContainerProps extends IFormComponentProps<TValue, TMetadata> = IFormComponentProps<TValue, TMetadata>>
    extends React.PureComponent<TContainerProps, TState> {

    get schema(): ISchema {
        return this.props.schema;
    }

    get uiSchema(): IUiSchema<TMetadata> {
        return this.props.uiSchema;
    }

    get metadata(): TMetadata & IBaseMetadata {
        return this.uiSchema.componentMetadata;
    }
}
