import {SigningService} from "./SigningService";
import {Constants} from "@folksam-digital/model";
import {sleep} from "../../../util/sleep";

export class DirectDebitSigningService extends SigningService {
    public async awaitSigningComplete(requestId: string, isSigningCancelled: () => boolean, onStatusUpdate?: (status?: string, qrData?: string) => void): Promise<boolean> {
        let steps = 0;
        while (steps < this.signingMaxWaitingSteps) {
            // check if due to user actions e.g. "navigating back in browser" signing should be cancelled
            if (isSigningCancelled()){
                if (onStatusUpdate) {
                    onStatusUpdate(undefined); // Completed - clear status
                }
                return false;
            }

            const signingStatus = await this.getSigningStatus(requestId);

            switch (signingStatus.action) {
                case Constants.Signing.actions.workflowSuccess:
                    if (onStatusUpdate) {
                        onStatusUpdate(signingStatus.status); // Completed - Update status
                    }
                    return true;
                case Constants.Signing.actions.success: // In case of direct debit after signing succeeds still have to wait for Workflow to complete
                    if (onStatusUpdate) {
                        onStatusUpdate(signingStatus.status); // Success - Update status
                    }
                    await sleep(this.signingWaitingStep); // Wait and re-load the signingStatus
                    steps++;
                    break;
                case Constants.Signing.actions.failure:
                    if (onStatusUpdate) {
                        // Completed - clear status
                        if (signingStatus.status === Constants.Signing.statusCodes.internal.fault.mandateAlreadySigned) {
                            onStatusUpdate(signingStatus.status)
                        } else {
                            onStatusUpdate(undefined);
                        }
                    }
                    return false;
                case Constants.Signing.actions.wait:
                    if (onStatusUpdate) {
                        onStatusUpdate(signingStatus.status, signingStatus.qrData); // Update status
                    }
                    await sleep(this.signingWaitingStep); // Wait and re-load the signingStatus
                    steps++;
                    break;
            }
        }

        return false;
    }
}
