import React from "react";
import { P } from "@folksam-digital/ui";
import { FormattedMessage } from "react-intl";

export interface IProductCardWidgetTitle {
    firstName: string,
    lastName: string,
    titleMessage: string,
    subtitleMessage?: string,
}

export class ProductCardWidgetTitle extends React.Component<IProductCardWidgetTitle> {

    public render() {
        const { firstName, lastName, titleMessage, subtitleMessage } = this.props;
        return (
            <>
                <h3>
                    <span>
                        <FormattedMessage
                            id={titleMessage}
                            values={{ fullName: `${firstName} ${lastName}` }}
                        />
                    </span>
                </h3>
                {subtitleMessage && <P><FormattedMessage id={subtitleMessage!}/></P>}
            </>
        );
    }
}
