import jexl from "jexl";

export const filterDataArray = async (data: any[], filterExpression: string): Promise<any[]> => {
    const asyncFilters = data.map(dataModel => evaluate(dataModel, filterExpression));

    return Promise.all(asyncFilters).then(values => values.filter((value?: any) => {
        return typeof value !== 'undefined';
    }));
};

export const evaluate = async (dataModel: any, filterExpression: string) => {
    try {
        const isValid = await jexl.eval(filterExpression, dataModel);
        if (isValid) {
            return Promise.resolve(dataModel);
        }
    } catch (e: any) {
        console.log(`filterExpression: ${filterExpression} Error: ${e.message}`);
    }
    return Promise.resolve();
};
