import React from "react";
import {Spinner, defaultTheme as theme, IconCheckMarkV2} from "@folksam-digital/ui";

export const InputSuffix = ({loading, selected}: any) => {
    let suffix = <></>;
    if (loading) {
        suffix = <Spinner/>
    } else if (selected) {
        suffix = <IconCheckMarkV2 color={theme.colors.primary2} width={18} height={18} />
    }

    return suffix;
};
