import {PolicySearchResult} from "@folksam-digital/model/lib";
import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {CmsContext} from "../../../../cms";
import {ConstrainWidth, DescriptionList} from "@folksam-digital/ui";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";

export interface IOptInOptOutProductRowProps extends WrappedComponentProps {
    policy: PolicySearchResult;
    hideMonthlyPrice?: boolean;
    declinedOffers?: boolean;
    showRemuneration?: boolean;
    displayFreePeriod: boolean;
}

interface IRenderTitleProps {
    remuneration?: number;
    productTitle?: string;
}

class OptInOptOutProductRow extends React.Component<IOptInOptOutProductRowProps> {

    public render() {
        const {policy, hideMonthlyPrice, displayFreePeriod} = this.props;
        return (
            <CmsContext.Consumer>
                {() => {
                    return (
                        <DescriptionList.Row balanced={true}>
                            {this.renderTitle({
                                remuneration: policy?.insuranceAmount,
                                productTitle: policy?.productTitle,
                            })}

                            {this.renderMonthlyPrice(hideMonthlyPrice, displayFreePeriod, policy)}
                        </DescriptionList.Row>
                    );
                }}
            </CmsContext.Consumer>
        );
    }


    private renderTitle({remuneration, productTitle}: IRenderTitleProps) {
        const {intl} = this.props;

        return this.props.declinedOffers ? (
            <DescriptionList.Content>
                <DescriptionList.Term> {productTitle}</DescriptionList.Term>
                <DescriptionList.Definition><FormattedMessage
                    id={CmsHelper.withGroupCommonPrefix("declineOffer.insuranceSelected.definition")}/></DescriptionList.Definition>
            </DescriptionList.Content>
        ) : (
            <DescriptionList.Content>
                <DescriptionList.Term>
                    <FormattedMessage id={CmsHelper.withGroupCommonPrefix("declineOffer.insuranceSelected.term")}/>
                </DescriptionList.Term>
                <DescriptionList.Definition>
                    {productTitle} {
                    (this.props.showRemuneration && remuneration && remuneration !== 0) ?
                        <FormattedMessage id={CmsHelper.withGroupCommonPrefix(`insuranceAmount.simple`)}
                                          values={{amount: intl.formatNumber(remuneration)}}/> : <></>
                }
                </DescriptionList.Definition>
            </DescriptionList.Content>
        );
    }

    private renderMonthlyPrice(hideMonthlyPrice: boolean | undefined, displayFreePeriod: boolean, policy: PolicySearchResult) {
        if (hideMonthlyPrice) {
            return null;
        }
        const {intl} = this.props;
        if (displayFreePeriod && policy.freePeriodMonths && policy.freePeriodMonths !== 0) {
            return (
                <DescriptionList.Definition>
                    <ConstrainWidth>
                        <FormattedMessage id={CmsHelper.withGroupCommonPrefix("price.monthly.freePeriod")} values={{
                            numberOfFreeMonths: policy.freePeriodMonths,
                            monthlyPremium: intl.formatNumber(Math.round(policy.monthlyPremium!))
                        }}/>
                    </ConstrainWidth>
                </DescriptionList.Definition>
            );
        }

        return (
            <DescriptionList.Price
                primary
                component={<FormattedPrice
                    suffixId={"general.monthlyPremium.suffix"}
                    value={Math.round(policy.monthlyPremium!)}
                />}
            />
        );
    }
}

export default injectIntl(OptInOptOutProductRow);
