import * as React from "react";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {
    unwrapContent?: boolean;
}

export class ChildrenFieldBody extends PanelComponentBase<string, IMetadata, {}> {
    public render() {
        const {unwrapContent} = this.metadata;
        return (
            unwrapContent ?
                <>{this.props.properties?.map(p => p.content)}</> :
                <div>
                    {this.props.properties?.map(p => p.content)}
                </div>
        );
    }
}
