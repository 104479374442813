import {AxiosRequestConfig} from "axios";
import {DigitalApiServiceBase} from "./DigitalApiServiceBase";
import {IFileUploadClient} from "@folksam-digital/services";

export class ApiFileUploadClient extends DigitalApiServiceBase implements IFileUploadClient {
    async uploadFile(data: FormData, options: AxiosRequestConfig): Promise<any> {
        try {
            const path = "/file/upload";
            const http = super.getHttpClient(path);
            const response = await http.post<any>(path, data, options);

            return response;
        } catch (e) {
            throw e;
        }
    }

    async removeFile(id: string, options: AxiosRequestConfig): Promise<any> {
        const path = `/file/delete`;
        const http = super.getHttpClient(path);
        const response = await http.delete<any>(path, {data: {id}, ...options} );

        return response;
    }
}
