import {IQuestionSchemaOverride} from "../product/GeneratorConfig";

export const individualChild: IQuestionSchemaOverride = {
    1000397: {
        showBackgroundColor: false
    },
    1000401: {
        showBackgroundColor: false
    },
    1000402: {
        showBackgroundColor: false
    },
    1000403: {
        showBackgroundColor: false
    },
    1000412: {
        showBackgroundColor: false
    },
    1000416: {
        showBackgroundColor: false
    },
    1000417: {
        showBackgroundColor: false
    },
    1000418: {
        showBackgroundColor: false
    },
    1000420: {
        showBackgroundColor: false
    },
    1000428: {
        showBackgroundColor: false
    },
    1000431: {
        showBackgroundColor: false
    },
    1000435: {
        showBackgroundColor: false
    },
    1000438: {
        showBackgroundColor: false
    },
    1000439: {
        showBackgroundColor: false
    },
    1000582: {
        showBackgroundColor: false
    },
    1100019: {
        showBackgroundColor: false
    }
};
