import React from "react";
import {startsWith} from "lodash";
import "@folksam-digital/react-phone-input-2/lib/style.css";
import {Constants} from "@folksam-digital/model";
import {FormPhoneInput, CountryData} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {injectIntl} from "react-intl";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {IBreakPoint} from "../../layout/helpers";
import {PhoneNumberFormatter} from "../../../../Helpers/PhoneNumberFormatter";
import {IPhoneNumberCountryData} from "../../../../../types/@folksam-digital/ui/lib/FormPhoneInput";
import {countries, guessSelectedCountry} from "./PhoneNumberHelpers";

interface IMetadata {
    defaultCountry?: string;
    breakpoints?: IBreakPoint;
    placeholder?: string;
    isDisabled?: boolean;
}

interface IPhoneNumberInputState {
    country?: string;
    value?: string;
    onlyCountries?: any;
    preferredCountries?: any;
    hiddenAreaCodes?: any;
    countryGuess: any;
    fieldNotEmptyOnBlur: any;
}

export class PhoneNumberInputInternal extends InputComponentBase<string, IMetadata, IPhoneNumberInputState, IInputComponentProps<string, IMetadata>> {
    inputRef: React.RefObject<{ numberInputRef: any }>;
    config: any;
    private fieldNotEmptyOnBlur: boolean;

    public constructor(props: IInputComponentProps<string, IMetadata>) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.inputRef = React.createRef();
        this.config = {
            enableAreaCodes: false,
            enableTerritories: false,
            regions: '',
            onlyCountries: Object.keys(countries),
            preferredCountries: [],
            excludeCountries: [],
            preserveOrder: [],
            masks: { se: '...-... .. ..' },
            priority: null,
            areaCodes: null,
            localization: {},
            prefix: '+',
            defaultMask: '... ... ... ... ..',
            alwaysDefaultMask: false
        };

        const { onlyCountries, preferredCountries, hiddenAreaCodes } = new CountryData(this.config);

        this.onFormatNumber = this.onFormatNumber.bind(this);

        this.state = {
            fieldNotEmptyOnBlur: props.formData?.trim().length > 0,
            countryGuess: undefined,
            country: Constants.Country.Sweden.alpha2Code,
            value: props.formData,
            onlyCountries,
            preferredCountries,
            hiddenAreaCodes,
        };
    }

    componentDidMount(): void {
        const fieldNotEmptyOnBlur = this.props.formData?.trim().length > 0;

        this.setState({fieldNotEmptyOnBlur});
    }

    private showCheckMarkCondition(isFieldEnabled?: boolean, isSuffixOrPrefix?: string) {
        return this.props.formData && isFieldEnabled && this.state.fieldNotEmptyOnBlur && !isSuffixOrPrefix && this.isValid()
    }

    static getDerivedStateFromProps(props: any, state: any) {
        if (state?.value) {
            const countryGuess = guessSelectedCountry(state?.value?.substring(0, 6), state.country, state.onlyCountries) || 0;
            return {countryGuess};
        }
        return {countryGuess: undefined};
    }

    onFormatNumber = (text: string | undefined, country: any) => {
        let caretPosition;
        if (country.countryCode === Constants.Country.Sweden.countryCallingCode.toString() && text) {
            const firstChar = text.charAt(0);
            if (firstChar !== '0') {
                text = '0' + text;
                if (this.inputRef && this.inputRef.current && this.inputRef.current.numberInputRef) {
                    caretPosition = this.inputRef.current.numberInputRef.selectionStart;
                    caretPosition++;
                }
            }
        }

        return { formattedNumber: text, caretPosition };
    }

    public getNumberWithoutDialCode(formData: string) {
        if (!formData) {
            return formData;
        }

        const countryGuess = guessSelectedCountry(this.props.formData.substring(0, 6), this.state.country, this.state.onlyCountries) || 0;

        if (countryGuess.dialCode && startsWith(formData, countryGuess.dialCode)) {
            return formData.substring(countryGuess.dialCode.length);
        }

        return formData;
    }

    public render(): React.ReactNode {
        const {formData, disabled, intl} = this.props;
        const onlyNumberPart = this.getNumberWithoutDialCode(formData);

        const {
            breakpoints,
            placeholder,
            isDisabled,
        } = this.metadata;
        const showCheckmark = this.showCheckMarkCondition(!disabled || !isDisabled);

        return (
            <FormFieldLayout {...this.getLayoutProps()}
                             breakpoints={getBreakpoints(defaultBreakpoints.phoneNumberInput, breakpoints)}>
                <FormPhoneInput value={onlyNumberPart}
                                placeholder={placeholder && intl.formatMessage({id: placeholder})}
                                onChange={this.onChange}
                                onBlur={this.onBlur}
                                ref={this.inputRef}
                                localization={countries}
                                disableCountryCode={true}
                                jumpCursorToEnd={false}
                                formatNumber={this.onFormatNumber}
                                country={this.state?.countryGuess?.iso2 || this.state.country}
                                onlyCountries={Object.values(Constants.Country).map(country => country.alpha2Code)}
                                invalid={this.isInvalid()}
                                showCheckMark={showCheckmark}
                                masks={{se: '...-... .. ..'}}
                                disabled={disabled}
                />
            </FormFieldLayout>
        );
    }

    private onChange(value: string, data: IPhoneNumberCountryData, event: React.FocusEvent<any>, formattedValue: any): void {
        const inputValue: string = PhoneNumberFormatter.removeSpecialChars(value);
        let resultValue;
        if (inputValue.length > 0) {
            resultValue = data.dialCode + inputValue;
        } else {
            resultValue = undefined;
        }

        if (event?.type === "change") {
            this.props.onChange(resultValue);
        } else {
            // Case for when user changes country code via dropdown
            resultValue = undefined;
            this.props.onChange(resultValue);
            this.onBlurWithValidation(event, resultValue);
        }
    }

    private onBlur(event: any, countryData: IPhoneNumberCountryData): void {
        if (this.props.onBlur && typeof this.props.onBlur === "function") {
            this.props.onBlur(event);
        }

        const inputValue = PhoneNumberFormatter.removeSpecialChars(event.target.value || "");
        let resultValue = inputValue;

        if (inputValue.length > 0) {
            resultValue = countryData.dialCode + inputValue;
        } else {
            resultValue = "";
        }

        const fieldNotEmptyOnBlur = !!resultValue && resultValue.length > 0;

        this.setState({fieldNotEmptyOnBlur})

        if (resultValue) {
            this.props.onChange(resultValue);
        }

        this.onBlurWithValidation(event, resultValue);
    }
}

const PhoneNumberInput = injectIntl(PhoneNumberInputInternal);

export {PhoneNumberInput};
