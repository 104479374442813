import {withRouter} from "react-router";
import * as React from "react";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {Constants, SportsTravelJourney} from "@folksam-digital/model";
import JourneySuccessBase, {IJourneySuccessBaseState, IWithLocaleContextSuccessProps} from "./JourneySuccessBase";
import {CmsContext, ICmsContext} from "../../../../cms";
import {
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {FormattedDate, FormattedMessage} from "react-intl";
import {withLocaleContext} from "../../../../components/journey/form/withLocaleContext";
import {IClassifier, IClassifierService} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import {ListRowItem} from "../common/ListRowItem";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";


interface ISportsTravelJourneySuccessState extends IJourneySuccessBaseState<SportsTravelJourney> {
    country: string | undefined;
}

class SportsTravelJourneySuccessInternal extends JourneySuccessBase<SportsTravelJourney, IWithLocaleContextSuccessProps, ISportsTravelJourneySuccessState> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    protected prefix = "product.sportsTravel";
    private readonly classifierService: IClassifierService;

    constructor(props: IWithLocaleContextSuccessProps, context: ICmsContext) {
        super(props, context);
        this.classifierService = container.get<IClassifierService>(Types.ClassifierService);
    }

    public async componentDidMount() {
        this.checkData(Constants.Journey.SportsTravel.Id);
        this.triggerDataLayerEvent(Constants.Journey.SportsTravel.Id);
        this.getCountry();
    }

    private async getCountry() {
        const countries: IClassifier[] = await this.classifierService.getClassifiers(Constants.ClassifierIdentifier.Countries, this.props.localeContext.locale, undefined, undefined, {
            id: [
                Constants.CountryId.Sweden
            ]
        });
        this.setState({country: countries.find(country => country.id === this.data.policy?.insuredObject?.destination)?.value})
    }

    private getFormattedDateString(date: Date | undefined) {
        return date?.toString().split('T')[0];
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        const invoiceEmailAddress = data.policy?.insuredObject?.contactPerson?.emailAddress;
        const formattedEndDate = this.getFormattedDateString(data.policy?.endDate);

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.SportsTravel.Id}
                    backUrl={Constants.Journey.SportsTravel.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: `${this.prefix}.success.banner.heading`,
                        subheader: `${this.prefix}.success.banner.subheading`,
                        headerText: <FormattedMessage id={`${this.prefix}.pageTitle.headerText`}/>
                    }}
                    doScroll={true}

                >
                    <SuccessHeaderMessageBox message={`${this.prefix}.success.description.information`}/>
                    <SuccessHeaderTable email={invoiceEmailAddress}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" top={"8"}>
                                            <SummaryCard
                                                theme={this.summaryCardThemeID}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id={`${this.prefix}.success.table.header.title`}/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList breakpoint={12}>
                                                        <ListRowItem
                                                            termBold
                                                            id={`${this.prefix}.otherInformation.groupName.title`}>
                                                            {data.policy?.insuredObject?.groupName}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            termBold
                                                            id={`${this.prefix}.aboutTrip.numberOfPeople.title`}>
                                                            {data.policy?.insuredObject?.numberOfPeople}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            termBold
                                                            id={`${this.prefix}.aboutTrip.typeOfTrip.title`}>
                                                            {data.policy?.insuredObject?.typeOfTrip}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            termBold
                                                            id={`${this.prefix}.aboutTrip.destination.title`}>
                                                            {this.state.country ? this.state.country : data.policy?.insuredObject?.destination}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            termBold
                                                            id={`${this.prefix}.reviewAndSubmit.aboutTrip.dateOfTravel`}>
                                                            <FormattedDate value={data.policy?.startDate}/>
                                                            &nbsp;
                                                            -
                                                            &nbsp;
                                                            <FormattedDate value={formattedEndDate}/>
                                                        </ListRowItem>
                                                        <ListRowItem>
                                                            <FormattedMessage
                                                                id={`${this.prefix}.reviewAndSubmit.premium.premium`}/>
                                                            <DescriptionList.Price
                                                                background
                                                                component={<FormattedPrice
                                                                    value={data.policy?.premium?.yearlyPremium!}
                                                                    suffixId={"general.yearlyPremium.suffix"}/>}/>
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            termBold
                                                            id={`general.policyNumber.title`}>
                                                            {data?.policy?.policyNumber}
                                                        </ListRowItem>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const SportsTravelJourneySuccess = flowRight(
    withCmsProvider("sportsTravel"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint,
    withLocaleContext
)(SportsTravelJourneySuccessInternal);

export default SportsTravelJourneySuccess;
