import {BaseErrorMessage} from "../../product/model/BaseErrorMessage";
import {IncomeInsuranceJourneyErrorType} from "./incomeInsuranceJourneyErrorType";
import {DefaultIncomeInsuranceError} from "./DefaultIncomeInsuranceError";
import {SupplementExistsError} from "./SupplementExistsError";
import {CustomerTooOldError} from "./CustomerTooOldError";

export class IncomeInsuranceErrorMessageDelegator {
    private readonly errorType?: IncomeInsuranceJourneyErrorType;
    private readonly journeyId?: string;
    private error: BaseErrorMessage;

    constructor({errorType, journeyId}: {errorType?: IncomeInsuranceJourneyErrorType, journeyId?: string}) {
        this.errorType = errorType;
        this.journeyId = journeyId;

        this.determineMessage();
    }

    private determineMessage(): void {
        switch (this.errorType) {
            case IncomeInsuranceJourneyErrorType.supplementExists:
                this.error = new SupplementExistsError(this.journeyId);
                break;
            case IncomeInsuranceJourneyErrorType.customerTooOld:
                this.error = new CustomerTooOldError(this.journeyId);
                break;
            default:
                this.error = new DefaultIncomeInsuranceError(this.journeyId);
        }
    }

    public getMessage(): string {
        return this.error.getMessage();
    }
}
