export function findKeyInObj(dataObj: any, key: string): boolean {
    let result = false;
    if (dataObj.hasOwnProperty(key)) {
        return true;
    }
    if (Array.isArray(dataObj)) {
        for (const obj of dataObj) {
            if (typeof obj === "object" || Array.isArray(obj)) {
                result = findKeyInObj(obj, key);
                if (result) {
                    break;
                }
            }
        }
    } else {
        for (const objProperty in dataObj) {
            if (typeof dataObj[objProperty] === "object" || Array.isArray(dataObj[objProperty])) {
                result = findKeyInObj(dataObj[objProperty], key);
                if (result) {
                    break;
                }
            }

        }
    }
    return result;
}
