import {Constants} from "@folksam-digital/model";
import {ContentHeaderVehicleFields} from "./ContentHeaderVehicleFields";
import {container} from "../../../../inversify.config";
import {ICmsParamsService, IInitParams} from "../../../../services";
import {Types} from "../../../../Types";
import {ContentHeaderHomeFields} from "./ContentHeaderHomeFields";

export class ContentHeaderFieldDelegator {
    public static getJourneyContentHeaderFieldsComponent(journeyId: string) {
        switch (journeyId) {
            case Constants.Journey.LightTruck.Id:
            case Constants.Journey.EpaAndATractor.Id:
            case Constants.Journey.MopedCar.Id:
            case Constants.Journey.SmallTractor.Id:
            case Constants.Journey.Car.Id:
            case Constants.Journey.LightElectricalVehicle.Id:
                return ContentHeaderVehicleFields;
            case Constants.Journey.HomeApartment.Id:
            case Constants.Journey.HomeRental.Id:
            case Constants.Journey.HomeStudent.Id:
            case Constants.Journey.HomeBuilding.Id:
            case Constants.Journey.HomeCondominium.Id:
                const cmsParamsService = container.get<ICmsParamsService>(Types.CmsParamsService);
                const param = cmsParamsService.getInitParams() as IInitParams | undefined;

                if (param?.workFlowEnv === Constants.TargetEnvironment.Idit){
                    return ContentHeaderHomeFields;
                }
                return undefined;
            default:
                return undefined;
        }
    }
}
