import * as React from "react";
import {Cover} from "@folksam-digital/model";
import {Grid, Spacing, ProductCard, defaultTheme, QuestionSkeleton} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../../cms";
import {FormattedMessage, IntlShape} from "react-intl";
import {Markdown} from "../../../../Markdown";
import {ModalIframe} from "../../output";
import {PriceBlock, PriceBlockThemes} from "./PriceBlock";
import {InsuranceAmountBlock} from "./InsuranceAmountBlock";
import {OverlayBlock} from "./OverlayBlock";
import {componentStyles} from "../../../../common/styles/componentStyles";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";

interface IAddOnCardViewProps {
    coverId: number;
    addOnId: string;
    intl: IntlShape;
    checked: boolean;
    disabled: boolean;
    isLoading: boolean;
    onCardChange?: (event: any) => void;
    selectionDisabled?: boolean;
    inactive?: boolean;
    modalProps?: any;
    label?: string;
    cover?: Cover;
    model?: string;
    pbbUnitValue?: number;
    showHighestCompensation?: boolean;
    priceBlockTheme?: PriceBlockThemes;
    recalculatedPrice?: boolean;
    prefix: string;
    children?: React.ReactNode;
}

export class AddOnCardView extends React.PureComponent<IAddOnCardViewProps> {

    public render(): React.ReactNode {

        const {
            coverId,
            addOnId,
            intl,
            checked,
            disabled,
            isLoading,
            onCardChange,
            selectionDisabled,
            modalProps,
            label,
            cover,
            model,
            inactive,
            showHighestCompensation,
            pbbUnitValue,
            priceBlockTheme,
            recalculatedPrice,
            prefix
        } = this.props;

        return (
            <Grid>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                    <Spacing type={"margin"} top={"md"} bottom={"sm"}>
                        {
                            !isLoading
                                ?
                                <div style={{position: "relative"}}>
                                    {inactive && <OverlayBlock />}
                                    <CmsContext.Consumer>
                                        {(cmsContext: ICmsContext) => {
                                            return (
                                                <ProductCard
                                                    id={`ProductCard-${coverId}`}
                                                    key={`ProductCard-${coverId}`}
                                                    image={CmsHelper.getImageAttributes(`${prefix}.badge-${addOnId}`, cmsContext.catalog!)}
                                                    disabled={disabled}
                                                    label={label}
                                                    title={intl.formatMessage({id: `${prefix}.goodSupplementaryInsurance.${addOnId}.title`})}
                                                    checked={checked}
                                                    onChange={onCardChange}
                                                    renderCheckbox={!selectionDisabled}
                                                >
                                                    <Spacing type={"margin"} top={"sm"} bottom={"sm"}>
                                                        <FormattedMessage id={`${prefix}.goodSupplementaryInsurance.${addOnId}.subtitle`}/>
                                                    </Spacing>
                                                    <div style={{paddingBottom: defaultTheme.padding.sm}}>
                                                        <Markdown
                                                            source={intl.formatMessage(
                                                                {id: `${prefix}.goodSupplementaryInsurance.${addOnId}.description`}
                                                            )}
                                                        />
                                                        {showHighestCompensation && cover &&
                                                        <div style={{paddingTop: defaultTheme.padding.sm}}>
                                                            <strong style={componentStyles.singleCoverTitle}>
                                                                <FormattedMessage id={`general.remuneration.withUnits`} values={{"amount": cover.insuranceAmount, "numberOfUnits": pbbUnitValue }} />
                                                            </strong>
                                                        </div>
                                                        }
                                                    </div>
                                                    {modalProps &&
                                                    <Spacing type={"margin"} bottom={"sm"}>
                                                        <ModalIframe url={modalProps.url} title={<FormattedMessage id={modalProps.title}/>}/>
                                                    </Spacing>
                                                    }
                                                    {this.props.children}
                                                    {cover?.numberOfUnits && <InsuranceAmountBlock coverData={cover} />}
                                                    {cover && <PriceBlock coverData={cover} prefix={prefix} addOnId={addOnId} model={model} theme={priceBlockTheme} recalculatedPrice={recalculatedPrice} />}
                                                </ProductCard>
                                            )
                                        }}
                                    </CmsContext.Consumer>
                                </div>
                                :
                                <QuestionSkeleton/>
                        }
                    </Spacing>
                </Grid.Col>
            </Grid>
        );
    }
}
