import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import { apm } from "@elastic/apm-rum";

export abstract class RestClientBase {
    protected create(config: AxiosRequestConfig): AxiosInstance {
        const instance = axios.create(config);

        if(window.ajax === undefined) {
            window.ajax = 0;
        }

        // Global request handler
        instance.interceptors.request.use((request) => {
            window.ajax++;
            console.debug(`Ajax started. Active calls: ${window.ajax}`);
            return request;
        });


        instance.interceptors.response.use(
            // Global response handler
            (response) => {
                window.ajax--;
                console.debug(`Ajax finished. Active calls: ${window.ajax}`);
                return response;
            },
            // Global error handler
            (error) => {
                if(!axios.isCancel(error)) {
                    const apmError = {
                        ...error,
                        message: `API Error. Request URL: ${error?.config?.url}. Error: ${error.message}`,
                        url: error?.config?.url
                    };

                    apm.captureError(apmError);
                }
                window.ajax--;

                throw error;
            }
        );

        return instance;
    }
}
