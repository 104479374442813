import * as React from "react";
import {container} from "../inversify.config";
import {Types} from "../Types";
import {ICmsContext} from "../cms";
import {IDraftService} from "../services";

export interface IErrorBaseProps {
}

export interface IErrorBaseState {
    urlParts: string[];
    message?: string;
}

export default abstract class ErrorBase<TProps extends IErrorBaseProps = IErrorBaseProps, TState extends IErrorBaseState = IErrorBaseState> extends React.Component<TProps, TState> {
    protected draftService: IDraftService;

    public constructor(props: any, context?: ICmsContext) {
        super(props, context);

        this.draftService = container.get<IDraftService>(Types.DraftService);

        this.state = {} as TState;
    }

    public abstract render(): React.ReactNode
}
