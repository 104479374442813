import {ApmBase} from "@elastic/apm-rum"
import React, {useState} from "react";
import hoistStatics from "hoist-non-react-statics"
import {useLocation} from "react-router";
import {useTransactionEffect} from "../hooks/useTransactionEffect";

/* eslint-disable react-hooks/exhaustive-deps */
function getWithTransaction(apm: ApmBase) {
    return function withTransaction<P>(name: string) {
        return (Component: React.ComponentType<P>) => {

            if (!apm.isActive()) {
                return Component;
            }

            if (!Component) {
                const loggingService = apm.serviceFactory.getService("LoggingService");
                loggingService.warn(
                    `${name} is not instrumented since component property is not provided`
                );
                return Component;
            }

            const AppComponent = function (props: P): React.ReactElement<any> {
                const location = useLocation();
                /**
                 * We start the transaction as soon as the ApmComponent gets rendered
                 * so that we can capture all the effects inside child components
                 *
                 * The reason why we have this transaction inside setState is that we don't
                 * want this piece of code to run on every render instead we want to
                 * start the transaction only on component mounting
                 */
                const [transaction] = useState(() => apm.startTransaction(location.pathname, "route-change", {
                    managed: true,
                    canReuse: true
                }));

                useTransactionEffect([], transaction);

                return <Component transaction={transaction} {...props} />;
            };

            AppComponent.displayName = `withTransaction(${Component.displayName || Component.name})`;
            AppComponent.WrappedComponent = Component;

            return hoistStatics(AppComponent, Component);
        }
    }
}

export {getWithTransaction};
