import * as React from "react";
import { injectIntl } from "react-intl";
import { withLocaleContext } from "../withLocaleContext";
import {CardNew} from "@folksam-digital/ui";
import {IFormData, IRadioGroupMetadata, IRadioGroupProps, RadioGroup} from "./RadioGroup";
import {defaultTheme} from "@folksam-digital/ui";
import set from "lodash/set";

interface IRadioGroupCardMetadata extends IRadioGroupMetadata {
    defaultTranslation: string;
    index: number
}

class RadioGroupCardInternal extends React.Component<IRadioGroupProps<IFormData, IRadioGroupCardMetadata>>{
    public render() {
            const {defaultTranslation, index} = this.props.uiSchema.componentMetadata;
            const helpStyle = {
                fontFamily: defaultTheme.fonts.serif,
                fontSize: defaultTheme.textSizes.base,
                color: defaultTheme.colors.black,
                fontWeight: defaultTheme.fontWeights.semiBold,
            }

            if (defaultTranslation) {
                set(this.props, "uiSchema.componentMetadata.help", defaultTranslation)
            }

            return (
                <CardNew>
                    <CardNew.Section column>
                        <RadioGroup {...this.props} messageValuesWithTranslations={{index: String(index + 1)}} titleTheme={2} helpStyle={helpStyle} />
                    </CardNew.Section>
                </CardNew>
            )

    }
}

const RadioGroupCard = injectIntl(withLocaleContext<IRadioGroupProps<IFormData, IRadioGroupCardMetadata>>(RadioGroupCardInternal));
export {RadioGroupCard};
