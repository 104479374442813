import * as React from "react";
import {ILayoutComponentProps, LayoutComponentBase} from "../LayoutComponentBase";
import {ButtonResponsive, IconStar, Step, withViewContext} from "@folksam-digital/ui";
import {FormContext, IFormContext} from "../../form/FormContext";
import {FormattedMessage} from "react-intl";

interface IStepFooterProps extends ILayoutComponentProps {
    onClickNext: any;
    nextStepButtonText: string;
    isLast?: boolean;
    disableButton?: boolean;
    submitNotice?: string;
}

class StepFooterLayout extends LayoutComponentBase<IStepFooterProps, {}> {
    public render() {

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => (
                    <React.Fragment>
                        {
                            !this.props.isLast &&
                            <Step.Footer noPaddingTop={true}>
                            {/* For Building Insurance buy flow legacy - A/B-test FD-10114 */}
                                {this.props.submitNotice &&
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        fontWeight: 600,
                                        marginBottom: "2.5rem"
                                    }}>
                                        <IconStar style={{marginRight: "0.5rem"}} />
                                        <FormattedMessage id={this.props.submitNotice} />
                                    </div>
                                }

                                <div className="step-footer-button">
                                    <ButtonResponsive
                                        full={true}
                                        type="submit"
                                        disabled={context.disableButton}
                                        onClick={this.props.onClickNext}
                                    >
                                        {this.props.nextStepButtonText}
                                    </ButtonResponsive>
                                </div>
                            </Step.Footer>
                        }
                    </React.Fragment>
                )}
            </FormContext.Consumer>
        );
    }
}

export default withViewContext(StepFooterLayout);
