import { ClaimAnalytics as NewClaimAnalytics} from "./new/ClaimAnalytics";
import { ProductAnalytics as NewProductAnalytics } from "./new/ProductAnalytics";
import { DirectDebitAnalytics as NewDirectDebitAnalytics } from "./new/DirectDebitAnalytics";
import { BasicProductAnalytics } from "./new/BasicAnalytics";
import { OptInOptOutAnalytics as NewOptInOptOutAnalytics } from "./new/OptInOptOutAnalytics";
import { ShoppingCartAnalytics as NewShoppingCartAnalytics } from "./new/ShoppingCartAnalytics";
import { ClaimAnalytics as OldClaimAnalytics} from "./old/ClaimAnalytics";
import { DirectDebitAnalytics as OldDirectDebitAnalytics } from "./old/DirectDebitAnalytics";
import { IncomeAnalytics as OldIncomeAnalytics} from "./old/IncomeAnalytics";
import { JourneyAnalytics as OldProductAnalytics } from "./old/JourneyAnalytics";
import { OptInOptOutAnalytics as OldOptInOptOutAnalytics } from "./old/OptInOptOutAnalytics";
import { ShoppingCartAnalytics as OldShoppingCartAnalytics } from "./old/ShoppingCartAnalytics";
import { FeatureToggleUtils } from "@folksam-digital/services";
import { Logger } from "@folksam-digital/services";

export enum AnalyticsType{
    Claim,
    Product,
    DirectDebit,
    Income,
    OptInOptOut,
    ShoppingCart
}

export function getAnalytics(analyticsType: AnalyticsType){
    const logger = Logger.getLogger("AnalyticsSwitch");

    const useNewProductAnalytics = FeatureToggleUtils.isFeatureEnabled("useNewProductAnalytics");
    const useNewClaimAnalytics = FeatureToggleUtils.isFeatureEnabled("useNewClaimAnalytics");

    switch(analyticsType){
        case AnalyticsType.Claim:
            return useNewClaimAnalytics ? new NewClaimAnalytics() : new OldClaimAnalytics();
        case AnalyticsType.Product:
            return useNewProductAnalytics ? new NewProductAnalytics() : new OldProductAnalytics();
        case AnalyticsType.DirectDebit:
            return useNewProductAnalytics ? new NewDirectDebitAnalytics() : new OldDirectDebitAnalytics();
        case AnalyticsType.Income:
            return useNewProductAnalytics ? new BasicProductAnalytics() : new OldIncomeAnalytics();
        case AnalyticsType.OptInOptOut:
            return useNewProductAnalytics ? new NewOptInOptOutAnalytics() : new OldOptInOptOutAnalytics();
        case AnalyticsType.ShoppingCart:
            return useNewProductAnalytics ? new NewShoppingCartAnalytics() : new OldShoppingCartAnalytics();
        default:
            logger.error(`Could not get analytics with analyticsType ${analyticsType}`);
    }
}
