import * as React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import {FormFieldLayout} from "../../../../FormFieldLayout";
import {Grid, Spacing, ButtonResponsive} from "@folksam-digital/ui";
import {IBreakPoint} from "../../../layout/helpers";
import {IFormFieldGroupLayoutProps} from "../../../../FormFieldGroupLayout";
import {CurrencyFieldAdapter} from "../../adapter/CurrencyFieldAdapter";
import CurrencyInput from "react-currency-input-field";
import {Breakpoints} from "@folksam-digital/model";

interface ISeePriceView {
    name: string;
    intl: IntlShape;
    viewContext: any;
    layoutProps: IFormFieldGroupLayoutProps;
    value?: string;
    placeholder?: string;
    breakpoints?: IBreakPoint;
    isInvalid: boolean;
    isValidField: boolean;
    isDisabled: boolean;
    isLoading?: boolean;
    columnBreakpoints: {
        inputField: IBreakPoint,
        button: IBreakPoint
    };
    onInputChange: (value?: number) => void;
    onSeePriceClick: () => Promise<void>;
}

export class SeePriceView extends React.PureComponent<ISeePriceView> {
    public render() {
        const {
            name,
            intl,
            viewContext,
            placeholder,
            breakpoints,
            layoutProps,
            value,
            isLoading,
            isInvalid,
            isValidField,
            onSeePriceClick,
            columnBreakpoints,
            onInputChange,
            isDisabled
        } = this.props;

        return (
            <FormFieldLayout
                {...layoutProps}
                breakpoints={this.getBreakpoints(breakpoints)}
            >
                <Grid.Row>
                    <Grid.Col {...columnBreakpoints.inputField}>
                        <CurrencyInput
                            id={name}
                            name={name}
                            customInput={CurrencyFieldAdapter}
                            allowDecimals={false}
                            forwardmetadata={
                                {
                                    placeholder: placeholder && intl.formatMessage({id: placeholder}),
                                    isDisabled,
                                    breakpoints,
                                    suffix: <FormattedMessage id="general.price.suffix" />,
                                }
                            }
                            value={value}
                            forwardProps={{
                                isInvalid,
                                disabled: isDisabled,
                            }}
                            onValueChange={onInputChange}
                        />
                    </Grid.Col>
                    <Grid.Col {...columnBreakpoints.button}>
                        {viewContext.isMobile && <Spacing type="margin" top="sm" />}
                        <ButtonResponsive
                            id={`button${name}`}
                            type="button"
                            disabled={!isValidField || isLoading || isDisabled}
                            onClick={onSeePriceClick}
                            isBusy={isLoading}
                        >
                            <FormattedMessage id="general.seePrice" />
                        </ButtonResponsive>
                    </Grid.Col>
                </Grid.Row>
            </FormFieldLayout>
        );
    }

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        const defaultBreakpoints: IBreakPoint = {
            [Breakpoints.md]: 8
        };
        return {
            ...defaultBreakpoints,
            ...breakpoints
        };
    }
}
