import { GroupOptInOptOut, PolicySearchResult, Constants } from "@folksam-digital/model/lib";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

export interface ITotals {
    monthly: number,
    yearly: number,
    freePeriod: number,
    freePeriodPremium: number,
}

export function getAllPolicies(data: GroupOptInOptOut) {
    let policies: PolicySearchResult[] = [];
    if(data.optIn) {
        policies = policies.concat(data.optIn);
    }
    if(data.optOut) {
        policies = policies.concat(data.optOut);
    }
    return policies;
}
export function getAllOptOutPolicies(data: GroupOptInOptOut): PolicySearchResult[] {
    let optOutPolicies: PolicySearchResult[] = getAllPolicies(data);

    optOutPolicies = getOptOutPolicies(optOutPolicies);

    return optOutPolicies;
}

function getOptOutPolicies(policyList: PolicySearchResult[]): PolicySearchResult[] {
    return policyList.filter(policy => {
        return policy.selected === false;
    });
}

export function getMemberOptInOptOut(data: GroupOptInOptOut): PolicySearchResult[] {
    let memberOptInOptOut: PolicySearchResult[] = [];
    const memberOptIn = getMemberPolicies(getAllSuccessfulOptInPolicies(data));
    const memberOptOut = getMemberPolicies(getAllSuccessfulOptOutPolicies(data));
    memberOptInOptOut =  memberOptIn.concat(memberOptOut).sort((a, b) => Number(a.selected) - Number(b.selected));

    return memberOptInOptOut;
}

export function getPartnerOptInOptOut(data: GroupOptInOptOut): PolicySearchResult[] {
    let partnerOptInOptOut: PolicySearchResult[] = [];
    const partnerOptIn = getPartnerPolicies(getAllSuccessfulOptInPolicies(data));
    const partnerOptOut = getPartnerPolicies(getAllSuccessfulOptOutPolicies(data));
    partnerOptInOptOut = partnerOptIn.concat(partnerOptOut).sort((a, b) => Number(a.selected) - Number(b.selected));

    return partnerOptInOptOut;
}

export function getMemberPolicies(optOutPolicies: PolicySearchResult[]): PolicySearchResult[] {
    return optOutPolicies.filter(policy => {
        return isMemberPolicy(policy.associatedInsTypeId);
    });
}

export function getPartnerPolicies(optOutPolicies: PolicySearchResult[]): PolicySearchResult[] {
    return optOutPolicies.filter(policy => {
        return !isMemberPolicy(policy.associatedInsTypeId);
    });
}

export function getAllOptInPolicies(data: GroupOptInOptOut): PolicySearchResult[] {
    let optInPolicies: PolicySearchResult[] = getAllPolicies(data);
    optInPolicies = getOptInPolicies(optInPolicies);

    return optInPolicies;
}

function getOptInPolicies(policyList: PolicySearchResult[]): PolicySearchResult[] {
    return policyList.filter(policySearchResult => {
        return policySearchResult.selected;
    });
}

function getSuccessfulOptInOptOutPolicies(policyList: PolicySearchResult[]): PolicySearchResult[] {
    return policyList.filter(policySearchResult => {
        return policySearchResult.policy && isEmpty(policySearchResult.policy.errors) && policySearchResult.policyNumber;
    });
}

export function getAllSuccessfulOptInPolicies(data: GroupOptInOptOut): PolicySearchResult[] {
    let optInPolicies: PolicySearchResult[] = [];
    if (data.optIn) {
        optInPolicies = optInPolicies.concat(data.optIn);
    }
    optInPolicies = getSuccessfulOptInOptOutPolicies(optInPolicies);

    return optInPolicies;
}

export function getAllSuccessfulOptOutPolicies(data: GroupOptInOptOut): PolicySearchResult[] {
    let optOutPolicies: PolicySearchResult[] = [];
    if (data.optOut) {
        optOutPolicies = optOutPolicies.concat(data.optOut);
    }
    optOutPolicies = getSuccessfulOptInOptOutPolicies(optOutPolicies);

    return optOutPolicies;
}

function getFailedOptInPolicies(policyList: PolicySearchResult[]): PolicySearchResult[] {
    return policyList.filter(policySearchResult => {
        return policySearchResult.selected && policySearchResult.policy && !isEmpty(policySearchResult.policy.errors);
    });
}

export function getAllFailedOptInPolicies(data: GroupOptInOptOut): PolicySearchResult[] {
    let optInPolicies: PolicySearchResult[] = getAllPolicies(data);

    optInPolicies = getFailedOptInPolicies(optInPolicies);

    return optInPolicies;
}

export function getTotals(optInPolicies: PolicySearchResult[]): ITotals {
    const totals: ITotals = { monthly: 0, yearly: 0, freePeriodPremium: 0, freePeriod: 0 };
    optInPolicies && optInPolicies.forEach(policy => {
        if(policy.selected) {
            if (policy.freePeriodMonths && policy.freePeriodMonths !== 0 && (!totals.freePeriod || totals.freePeriod === 0 || totals.freePeriod > policy.freePeriodMonths)) {
                totals.freePeriod = policy.freePeriodMonths;
            }
            if ((!policy.freePeriodMonths || policy.freePeriodMonths === 0) && policy.monthlyPremium) {
                totals.freePeriodPremium += policy.monthlyPremium!;
            }

            totals.yearly += Math.round(policy.yearlyPremium!);
            totals.monthly += Math.round(policy.monthlyPremium!);
        }
    });
    return totals
}

export function isMemberPolicy(type?: null | number): boolean {
    return (!type || type === Constants.AssociatedInsuranceTypes.member)
}

export function isCoInsuredPolicy(type: PolicySearchResult["associatedInsTypeId"]): boolean {
    return (type === Constants.AssociatedInsuranceTypes.coInsured)
}

export function isChildCoInsuredPolicy(type?: PolicySearchResult["associatedInsTypeId"]): boolean {
    return (!type || type === Constants.AssociatedInsuranceTypes.childCoInsured)
}

export function isSeniorCoInsuredPolicy(type?: PolicySearchResult["associatedInsTypeId"]): boolean {
    return (!type || type === Constants.AssociatedInsuranceTypes.seniorCoInsured)
}


export function extractDates(policies: PolicySearchResult[], dateGetter: (policy: PolicySearchResult) => string) {
    return policies.map((policy) => {
        const date = dateGetter(policy);
        return new Date(date);
    });
}

export function getYoungestDate(dates: Date[]) {
    return dates.reduce(function (tempDate, oldestDate) {
        return tempDate < oldestDate ? tempDate : oldestDate;
    });
}

export function monthLater(month: number, date: Date): Date {
    const mDate = moment(date);
    mDate.add(month, "months");
    return mDate.toDate();
}
