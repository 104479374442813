import {memo, default as React} from "react";
import {FormattedMessage} from "react-intl";
import {Cover} from "@folksam-digital/model";
import {componentStyles} from "../../../../common/styles/componentStyles";

interface InsuranceAmountBlockProps {
    coverData: Cover;
}

export const InsuranceAmountBlock = memo((props: InsuranceAmountBlockProps) => {
    const coverData = props.coverData;

    return (
        <strong style={componentStyles.singleCoverTitle}>
            <FormattedMessage
                id={`general.remuneration.withUnits`}
                values={{amount: coverData.insuranceAmount, numberOfUnits: Math.round(Number(coverData.numberOfUnits))}}
            />
        </strong>
    );
});
