import * as React from "react";
import {FormattedMessage} from "react-intl";
import {ButtonResponsive, P, Spacing} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {Constants as LegacyConstants} from "@folksam-digital/legacy-model";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IWorkshopButtonProps {
    vehicleBrand?: string;
    claimNumber?: string;
}

export class WorkshopButton extends React.Component<IWorkshopButtonProps> {
    public render() {
        return (
            <Spacing top={this.props.claimNumber ? 'lg' : 'xs' }>
                <P>
                    <FormattedMarkdown messageKey={CmsHelper.withGeneralPrefix(`motor.claim.success.findWorkshop.description`)}/>
                </P>
                <ButtonResponsive
                    id={`workshopUrlBtn}`}
                    onClick={() => window.open(this.props.vehicleBrand === LegacyConstants.VehicleBrand.Volvo ? Constants.Links.vehicleWorkshopUrlVolvo : Constants.Links.vehicleWorkshopUrl)}
                    outline={true}
                >
                    <FormattedMessage id={CmsHelper.withGeneralPrefix(`motor.claim.success.findWorkshop.title`)}/>
                </ButtonResponsive>
            </Spacing>
        );
    }
}
