import * as React from "react";
import get from "lodash/get";
import {injectIntl} from "react-intl";
import {Grid} from "@folksam-digital/ui";
import {OutputComponentBase} from "./OutputComponentBase";
import {Markdown} from "../../../Markdown";
import {FormContext, IFormContext} from "../FormContext";
import {IBreakPoint} from "../../layout/helpers";
import {NumberRounder} from "../../../../Helpers/NumberRounder";
import {defaultBreakpoints} from "../input/helpers/breakpoints/defaultBreakpoints";
import {MarkdownListTypes} from "@folksam-digital/model";

interface IMetadata {
    classNames?: string;
    dataPath?: string;
    fullWidth?: boolean;
    style?: { [key: string]: string };
    breakpoints?: IBreakPoint;
    round?: string[];
    listType?: MarkdownListTypes;
}

class MarkdownOutputInternal extends OutputComponentBase<void, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        let formContext;
        if (this.metadata.dataPath) {
            formContext = get(this.context.data, this.metadata.dataPath);
        } else {
            formContext = this.context.data;
        }

        if (!formContext) {
            return null;
        }

        if (this.metadata.round && formContext) {
            formContext = NumberRounder.formatRound(this.metadata.round, formContext);
        }

        const text = this.props.intl.formatMessage({id: this.props.schema.title}, {...formContext});

        const {classNames, style} = this.metadata;

        let result = this.renderMarkdown(classNames, text, style);

        if (!this.metadata.fullWidth) {
            result = this.wrapMarkdown(result);
        }
        return result;
    }

    public renderMarkdown(classNames: string | undefined, text: string, style?: object): any {
        return <div className={classNames} style={style}>
            <Markdown listType={this.metadata.listType} source={text} {...this.metadata} />
        </div>;
    }

    private wrapMarkdown(body: any) {
        return (
            <Grid.Row>
                <Grid.Col {...this.getBreakpoints()}>
                    {body}
                </Grid.Col>
            </Grid.Row>
        );
    }

    private getBreakpoints(): IBreakPoint {
        return {
            ...defaultBreakpoints.textBox,
            ...this.metadata.breakpoints
        };
    }
}

const MarkdownOutput = injectIntl(MarkdownOutputInternal);
export {MarkdownOutput};
