import * as React from "react";
import { Step } from "@folksam-digital/ui";
import { IOutputComponentProps } from "./OutputComponentBase";
import {IFormContext} from "../FormContext";
import { injectIntl, WrappedComponentProps } from "react-intl";
import * as JourneyOutputComponentBaseFunctions from "./helpers/JourneyOutputComponentBaseFunctions";
import { IMetadata } from "./helpers";
import {withFormContext} from "../withFormContext";
import flowRight from "lodash/flowRight";

interface IProps extends IOutputComponentProps<void, IMetadata>, WrappedComponentProps {
    formContext: IFormContext;
}

class StepSectionHeaderInternal extends React.Component<IProps> {
    public render() {
        const {
            intl
        } = this.props;

        const { title } = this.props.schema;
        const values = JourneyOutputComponentBaseFunctions.getMessageValues(this.props.uiSchema.componentMetadata, this.props.formContext);
        const message: string = intl.formatMessage({ id: title }, values);
        return (
            <div id={this.props.uiSchema.name}>
                <Step.SectionHeader headerText={message} />
            </div>
        );
    }
}

const StepSectionHeader = flowRight(injectIntl, withFormContext)(StepSectionHeaderInternal);
export { StepSectionHeader };
