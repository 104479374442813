import * as React from "react";
import {RouteComponentProps} from "react-router";
import {Constants, DirectDebit, IJourneySchema, JourneyBase} from "@folksam-digital/model";
import {ICmsContext} from "../../../../cms";
import {
    IOnTriggerDataLayerEvent,
    SatelliteEvents,
    SatelliteEventTransactionTypes
} from "../../../../analytics/new/BaseAnalytics";
import {ProductContactUsSection} from "../../../../components/journey/layout/Success";
import {IDraftService, StorageKeys} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {UrlHelper} from "@folksam-digital/services";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";
import {defaultTheme as theme} from "@folksam-digital/ui";
export interface IJourneySuccessBaseProps extends RouteComponentProps<any, any, any>, IOnTriggerDataLayerEvent {
    children?: React.ReactNode;
}

export interface IJourneySuccessBaseState<TJourney extends JourneyBase<any>> {
    data: TJourney
}

export interface IWithLocaleContextSuccessProps extends IJourneySuccessBaseProps {
    localeContext: ILocaleContext;
}

export default abstract class JourneySuccessBase<TJourney extends JourneyBase<any>,
    TProps extends IJourneySuccessBaseProps = IJourneySuccessBaseProps,
    TState extends IJourneySuccessBaseState<TJourney> = IJourneySuccessBaseState<TJourney>> extends React.Component<TProps, TState> {
    protected readonly summaryCardThemeID: number = Constants.Themes.LightGreen;
    protected readonly summaryIconCheckMarkColor: string = theme.colors.secondarySuccess4;
    protected draftService: IDraftService;
    public context!: ICmsContext;

    constructor(props: TProps, context?: ICmsContext) {
        super(props, context);

        this.state = {
            data: {} as TJourney
        } as TState;

        this.draftService = container.get<IDraftService>(Types.DraftService);

        this.handleDirectDebitRedirect = this.handleDirectDebitRedirect.bind(this);
    }

    public get data(): TJourney {
        return this.draftService.getDraft<TJourney>();
    }

    public checkData(journeyId: string): void {

        if (!this.data) {
            const schema = {} as IJourneySchema;
            this.draftService.updateDraft({
                journeyId: journeyId,
                backUrl: schema?.backUrl
            }, StorageKeys.JOURNEY_ERROR_DATA_KEY);

            this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
            return;
        } else {
            setJourneyCompleted(this.draftService, journeyId);
        }
    }

    public abstract render(): React.ReactNode

    protected triggerDataLayerEvent(journeyId: string) {
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: this.data,
            journeyId: journeyId,
            currentStep: "success",
            transactionType: SatelliteEventTransactionTypes.TransactionPage,
            event: SatelliteEvents.Complete
        });
    }

    protected handleDirectDebitRedirect(): void {
        const journeyData: TJourney = this.data;
        const data: DirectDebit = {
            ssn: journeyData.contact.ssn,
            firstName: journeyData.contact.firstName,
            lastName: journeyData.contact.lastName,
            phoneNumber: journeyData.contact.phoneNumber,
            emailAddress: journeyData.contact.emailAddress!,
            stepBack: true
        };

        this.draftService.updateDraft(data, StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
        this.props.history.push(UrlHelper.getUrl(["direct-debit", "set-up"]));
    }

    protected isDirectDebitFormNeeded() {
        const data: TJourney = this.data;

        return data && data.policy.paymentDetails.paymentMethod === Constants.PaymentMethod.DirectDebit && data.policy.hasValidDirectDebitMandates;
    }

    protected renderContactUsSection() {
        return <ProductContactUsSection/>
    }
}
