import * as React from "react";
import Mustache from "mustache";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {injectIntl, IntlShape, WrappedComponentProps} from "react-intl";
import {ClaimBase, Cover, CoverPlan, DocumentUpload, IClassifierFilter, JourneyBase} from "@folksam-digital/model";
import {PbbHelper, SsnFormatter} from "@folksam-digital/services";
import {container} from "../../../../../inversify.config";
import {IClassifier, IClassifierService} from "../../../../../services";
import {Types} from "../../../../../Types";
import {parseLanguageCode} from "../../../../../Helpers/locale/Locale";
import {IOption, PriceBarFrequency} from "@folksam-digital/model/lib";

interface ITemplateProps extends WrappedComponentProps {
    template: string,
    data: any;
    style?: any;
}

interface ITemplateStateBase {
    [classifierId: string]: IClassifier[]
}

type TemplateFunction = (text: string, render: any) => any;

const valuesMatch = (keyValues: {[key: string]: any}, record: any[]) => {
    for (const key in keyValues) {
        const sourceValue = keyValues[key];
        const recordValue = get(record, key);

        if (sourceValue !== recordValue) {
            return false;
        }
    }

    return true;
};

export const parseQueryString = (query: string) => {
    if (!query) {
        return false;
    }
    const result = {} as any;
    const queryValues = query.split('&');

    for (const params of queryValues) {
        const p = params.split('=');
        if (!result[p[0]]) {
            result[p[0]] = [];
        }

        result[p[0]].push(decodeURIComponent(p[1]));
    }

    return result;
};

class TemplateInternal extends React.Component<ITemplateProps, ITemplateStateBase> {
    private readonly classifierService: IClassifierService;

    constructor(props: ITemplateProps) {
        super(props);
        this.classifierService = container.get<IClassifierService>(Types.ClassifierService);
        this.state = {};
    }

    public render() {
        const {
            template,
            data = [],
        } = this.props;

        const props = this.props;

        data["translate"] = this.translateMessage(props.intl);
        data["translateValue"] = this.translateMessageFromRenderedValue(props.intl);
        data["formatNumber"] = this.formatNumber(props.intl);
        data["formatRoundNumber"] = this.formatRoundNumber(props.intl);
        data["formatNumberAnnually"] = this.formatNumberAnnually(props.intl);
        data["formatDate"] = this.formatDate(props.intl);
        data["formatDateMonth"] = this.formatDateMonth(props.intl);
        data["formatDateYear"] = this.formatDateYear(props.intl);
        data["formatDateTime"] = this.formatDateTime(props.intl);
        data["formatSsn"] = this.formatSsn();
        data["formatBoolean"] = this.formatBoolean(props.intl);
        data["extractCoverPlanPremium"] = this.extractCoverPlanPremium(props.intl, props.data);
        data["extractCoverPlanAddition"] = this.extractCoverPlanAddition(props.intl, props.data);
        data["extractCoverInsuranceAmount"] = this.extractCoverInsuranceAmount(props.intl, props.data);
        data["extractCoverPlanInsuranceAmount"] = this.extractCoverPlanInsuranceAmount(props.intl, props.data);
        data["extractPbbUnits"] = this.extractPbbUnits(props.intl, props.data);
        data["extractPbbInsuranceAmount"] = this.extractPbbInsuranceAmount(props.intl, props.data);
        data["extractClassifierByIdValue"] = this.extractClassifierByIdValue(props.intl, props.data);
        data["extractValueFromClassifiers"] = this.extractValueFromClassifiers(props.intl, props.data);
        data["extractDeductible"] = this.extractDeductible(props.intl, props.data);
        data["categoriesCounter"] = this.categoriesCounter(props.intl, props.data);
        data["formatFileNames"] = this.formatFileNames(props.intl, props.data);
        data["displayIndex"] = this.displayIndex(props.intl, props.data);

        Mustache.parse(template);
        const rendered = Mustache.render(template, data);

        const regex = /extractClassifierByIdValue/g; // check mustache template function use
        const classifierRegex = /extractValueFromClassifiers/g; // check mustache template function use

        if (regex.test(template)) {
            return this.prepareClassifierText(rendered);
        }

        if (classifierRegex.test(template)) {
            return this.prepareClassifierTextWithFilter(rendered);
        }
        /* eslint-disable-next-line react/no-danger */
        return <span style={{...props.style, wordBreak: "break-word"}} dangerouslySetInnerHTML={{__html: rendered}}/>;
    }

    private displayIndex = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const [index, numberPath] = text.split(":");
            const maxRecords = get(data, numberPath.trim());
            if (maxRecords > 1) {
                const indexFormatted = parseInt(index.trim(), 10) + 1;
                return String(indexFormatted)
            }
            return "";
        }
    };

    private categoriesCounter = (intl: IntlShape, data: ClaimBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const [index, arrayPath, ...categoryIdValues] = text.split(":");
            const keyValues = {} as any;
            categoryIdValues.forEach((value: any, key: number) => {
                let path = categoryIdValues[key].trim();
                path = path.replace(/[{}]/gi, "").trim() as string;
                let relativePath = path.replace(arrayPath.trim(), "");
                relativePath = relativePath.replace(/^[.]*\d+[.]+/, "");

                keyValues[relativePath] = get(data, value.trim());
            });

            const records = get(data, arrayPath.trim(), {});
            let categoryItemCounter = 0;
            let categoryCounter = 0;

            for (const rowIndex in records) {
                if (records.hasOwnProperty(rowIndex)) {
                    const tempRecord = records[rowIndex as keyof typeof records];

                    const match = valuesMatch(keyValues, tempRecord);
                    if (Number(index.trim()) >= Number(rowIndex) && match) {
                        categoryItemCounter += 1;
                    }

                    if (match) {
                        categoryCounter += 1;
                    }
                }
            }

            return categoryCounter < 2 ? "" : categoryItemCounter;
        };
    };

    private prepareClassifierText(text: string) {
        const regexParser = /(\[[^\]]+])/g;

        text = text.trim();
        const matches = text.match(regexParser);

        let innerHtml = text;
        if (matches) {
            for (const matchIndex in matches) {
                const match = matches[matchIndex];
                const innerMatch = match.replace(/([[\]]*)/g, "");
                const [id, classifierId, languageCode] = innerMatch.split(",");

                // use rendered result, to get list
                if (!this.state[classifierId]) {
                    this.getClassifierValues(classifierId, languageCode);
                    break;
                }

                // re-render by id, when list available
                if (this.state[classifierId]) {
                    const index: number = this.state[classifierId].findIndex((x) => x.id?.toString() === id);
                    const {intl} = this.props;

                    let value = index !== -1 ? this.state[classifierId][index].value : id;
                    if (intl.messages[value]) {
                        value = intl.formatMessage({id: value});
                    }
                    if (Number(matchIndex) < matches.length - 1) {
                        value = `${value}, `;
                    }

                    innerHtml = innerHtml.replace(match, value);
                }
            }
        }

        return <span style={this.props.style} dangerouslySetInnerHTML={{__html: innerHtml}}/>;
    }

    private prepareClassifierTextWithFilter(text: string) {
        const regexParser = /(\[[^\]]+])/g;

        text = text.trim();
        const matches = text.match(regexParser);

        let innerHtml = text;
        if (matches) {
            for (const matchIndex in matches) {
                const match = matches[matchIndex];
                const innerMatch = match.replace(/([[\]]*)/g, "");
                const [defaultText, ignoreDefaultTextIds, blockListValues, id, classifierId, classifierFilter, languageCode] = innerMatch.split(":");
                const filterResult = parseQueryString(classifierFilter) as IClassifierFilter;
                // use rendered result, to get list
                if (!this.state[classifierId]) {
                    this.getClassifierValues(classifierId, languageCode, filterResult);
                    break;
                }

                // re-render by id, when list available
                if (this.state[classifierId]) {
                    const index: number = this.state[classifierId].findIndex((x) => x.id === Number(id));
                    const blockListValuesArray = blockListValues.split(",");
                    const defaultTextBlockIds = ignoreDefaultTextIds.split(",");

                    let value = index !== -1 ? this.state[classifierId][index].value : id;
                    if (this.props.intl.messages[value]) {
                        value = this.props.intl.formatMessage({id: value});
                    }
                    if (Number(matchIndex) < matches.length - 1) {
                        value = `${value}, `;
                    }

                    if (blockListValuesArray.indexOf(id) !== -1) {
                        value = defaultText;
                    } else if (defaultTextBlockIds.indexOf(id) === -1) {
                        value = `${defaultText} ${value.toLowerCase()}`;
                    }
                    innerHtml = innerHtml.replace(match, value);
                }
            }
        }

        return <span style={this.props.style} dangerouslySetInnerHTML={{__html: innerHtml}}/>;
    }

    private async getClassifierValues(classifierId: string, locale: string, classifierFilter?: IClassifierFilter, classifierIndex?: string): Promise<void> {
        const stateIndex = classifierIndex || classifierId;
        const languageCode = parseLanguageCode(locale);
        const classifiers: IClassifier[] = await this.classifierService.getClassifiers(classifierId, languageCode, classifierFilter);
        this.setState({[stateIndex]: classifiers});
    };

    private extractClassifierByIdValue = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const [id, classifierId] = render(text).split(":");
            return `[${id.trim()},${classifierId.trim()},${intl.locale}]`;
        };
    };

    private extractValueFromClassifiers = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const [defaultText, ignoreDefaultTextIds, blockListValues, value, classifierId, classifierFilter] = render(text).split(":");
            return `[${defaultText.trim()}:${ignoreDefaultTextIds.trim()}:${blockListValues.trim()}:${value.trim()}:${classifierId.trim()}:${classifierFilter.trim()}:${intl.locale}]`;
        };
    };

    private translateMessage = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let langKey = Mustache.parse(text)[1][1];

            if (!isEmpty(render(text).trim())) {
                langKey = `${langKey}.${render(text).trim()}`;
            }

            return intl.formatMessage({id: `${langKey}`});
        };
    };

    private translateMessageFromRenderedValue = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const [messageId, defaultMessageId] = render(text.trim()).split(":");

            if (!intl.messages[messageId?.trim()] && defaultMessageId) {
                return intl.formatMessage({id: defaultMessageId.trim()});
            }
            return intl.formatMessage({id: messageId.trim()});
        };
    };

    private formatBoolean = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            return intl.formatMessage({id: `general.${render(text).trim() === "true" ? "yes" : "no"}`});
        };
    };

    private formatNumber = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            return intl.formatNumber(render(text).trim(), {maximumFractionDigits: 2});
        };
    };

    private formatRoundNumber = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const roundedNumber: number = Math.round(Number(render(text).trim()));
            return intl.formatNumber(roundedNumber);
        };
    };

    private formatNumberAnnually = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            return intl.formatNumber(render(text).trim() * 12);
        };
    };

    private formatDate = (intl: IntlShape) => function() {
        return function(text: string, render: any) {
            return intl.formatDate(render(text).trim());
        };
    };

    private formatDateMonth = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const selectedDate: any = intl.formatDate(render(text).trim(), {year: "numeric", month: "long"});
            return selectedDate;
        };
    };
    private formatDateYear = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const selectedDate: any = intl.formatDate(render(text).trim(), {year: "numeric"});
            return selectedDate;
        };
    };

    private formatDateTime = (intl: IntlShape) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const selectedDate: any = intl.formatDate(render(text).trim(), {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
            return selectedDate;
        };
    };

    private extractCoverInsuranceAmount = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const id = Number(render(text.trim()));

            let value = 0;
            if (data?.policy?.premium?.covers && data.policy.premium.covers.length > 0) {
                for (const cover of data.policy.premium.covers as Cover[]) {
                    if (cover?.id === id) {
                        value = cover.insuranceAmount!;
                        break;
                    }
                }
            }

            return intl.formatNumber(value);
        };
    };

    private extractCoverPlanInsuranceAmount = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let [coverPlanId, coverId] = render(text.trim()).split(":");

            coverPlanId = String(coverPlanId.trim());
            coverId = Number(coverId.trim());

            let value = 0;
            if (data?.policy?.premium?.coverPlans) {
                const coverPlan = data.policy.premium.coverPlans.find((row: CoverPlan) => row.id === coverPlanId);
                for (const cover of coverPlan?.covers as Cover[]) {
                    if (cover?.id === coverId) {
                        value = cover.insuranceAmount!;
                        break;
                    }
                }
            }

            return intl.formatNumber(value);
        };
    };

    private extractCoverPlanPremium = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let [coverPlanId, paymentPeriod] = render(text.trim()).split(":");

            coverPlanId = String(coverPlanId.trim());
            paymentPeriod = Number(paymentPeriod.trim());

            let value: any = 0;
            if (data?.policy?.premium?.coverPlans) {
                const coverPlan = data.policy.premium.coverPlans.find((row: CoverPlan) => row.id === coverPlanId);

                switch (paymentPeriod) {
                    case PriceBarFrequency.Monthly:
                        value = coverPlan?.monthlyPremium;
                        break;
                    default:
                        value = coverPlan?.yearlyPremium;
                        break;
                }
            }

            return intl.formatNumber(Math.round(value));
        };
    };

    private extractCoverPlanAddition = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let [coverPlanId, paymentPeriod] = render(text.trim()).split(":");

            coverPlanId = String(coverPlanId.trim());
            paymentPeriod = Number(paymentPeriod.trim());

            let value: any = 0;
            if (data?.policy?.premium?.coverPlansAddition) {
                const coverPlan = data.policy.premium.coverPlansAddition.find((row: CoverPlan) => row.id === coverPlanId);

                switch (paymentPeriod) {
                    case PriceBarFrequency.Monthly:
                        value = coverPlan?.monthlyPremium;
                        break;
                    default:
                        value = coverPlan?.yearlyPremium;
                        break;
                }
            }

            return intl.formatNumber(Math.round(value));
        };
    };

    private extractPbbUnits = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let [coverPlanId, mainCoverId] = render(text.trim()).split(":");

            coverPlanId = String(coverPlanId.trim());
            mainCoverId = Number(mainCoverId.trim());

            const coverPlanDetails = PbbHelper.getCoverPlanDetails(data, coverPlanId, mainCoverId);

            return intl.formatNumber(coverPlanDetails.pbbUnits);
        };
    };

    private extractPbbInsuranceAmount = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            let [coverPlanId, mainCoverId] = render(text.trim()).split(":");

            coverPlanId = String(coverPlanId.trim());
            mainCoverId = Number(mainCoverId.trim());

            const coverPlanDetails = PbbHelper.getCoverPlanDetails(data, coverPlanId, mainCoverId);

            return intl.formatNumber(coverPlanDetails.pbbAmount);
        };
    };

    private formatSsn = () => function(): TemplateFunction {
        return function(text: string, render: any) {
            const ssn = render(text).trim();
            return SsnFormatter.formatWithDash(ssn);
        };
    };

    private formatFileNames = (intl: IntlShape, data: JourneyBase<any>) => function (): TemplateFunction {
        return function (text: string, render: any) {
            const files: DocumentUpload[] = get(data, render(text).trim());
            const fileNameStr: string = files?.reduce(
                (accumulator, currentFile, idx) =>
                    accumulator.concat(`${currentFile.fileName}${idx === files.length - 1 ? "" : "<br>"}`), "");

            return fileNameStr;
        };
    };

    private extractDeductible = (intl: IntlShape, data: JourneyBase<any>) => function(): TemplateFunction {
        return function(text: string, render: any) {
            const id = Number(render(text).trim());

            let value: any = 0;
            if (data?.policy?.premium?.deductibleOptions) {
                const deductible = data.policy.premium.deductibleOptions.find((row: IOption) => row.id === id);
                value = deductible?.title;
            }

            return value;
        };
    };
}

const Template = injectIntl(TemplateInternal);
export {Template};
