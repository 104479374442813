import * as React from "react";
import {injectIntl} from "react-intl";
import {FormComponentBase, IFormComponentProps} from "../../../journey/form/FormComponentBase";
import {PolicySearchResult} from "@folksam-digital/model";
import {Grid} from "@folksam-digital/ui";
import {OptCard} from "../input";
import {IOptCardPolicy} from "../input/OptCard";
import clone from "lodash/clone";
import isNil from "lodash/isNil";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import OptCardGroupList from "../output/OptCardGroupList";
import {groupPolicies} from "../../../../Helpers/optInOptOut/groupPolicies";
import FormattedMarkdown from "../../../FormattedMarkdown";
import * as OptInOptOutProductListBaseFunctions from "./helpers/OptInOptOutProductListBaseFunctions";
import {isMemberPolicy} from "./helpers";
import {OptCardHeaderMessage} from "../layout/OptCardHeaderMessage";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CmsContext, ICmsContext} from "../../../../cms";
import {OptOutInContainerFooter} from "./OptOutInContainerFooter";
import {OptOutInContainerHeader} from "./OptOutInContainerHeader";

interface IOptOutContainerProps extends IFormComponentProps<any, IMetadata> {
    index?: number;
}

export interface IMetadata {

}

interface IOptOutContainerState {
    startDate?: Date;
    freePeriodEndDate?: Date;
}

class OptOutContainer extends FormComponentBase<PolicySearchResult[], IMetadata, IOptOutContainerState, IOptOutContainerProps> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    constructor(props: IFormComponentProps<any, IMetadata>) {
        super(props);

        this.onCardChange = this.onCardChange.bind(this);
        this.getFieldError = this.getFieldError.bind(this);

        if (!isEmpty(this.props.formData)) {
            // @ToDo - completed, when existing in webservice freePeriodEndDate
            // const freePeriodEndDate = OptInOptOutProductListBaseFunctions.extractDates(this.props.formData, policy => policy.freePeriodEndDate!);
            const startDates = OptInOptOutProductListBaseFunctions.extractDates(this.props.formData, policy => policy.startDate!);
            const startDate = OptInOptOutProductListBaseFunctions.getYoungestDate(startDates);

            this.state = {
                startDate
            }
        }
    }

    private appendIndexToPolicies(policies: PolicySearchResult[]): IOptCardPolicy[] {
        return map(policies, (policy: PolicySearchResult, idx: number) => ({
            ...policy, index: idx
        }));
    }

    private shouldShowPbbMessage(policies: PolicySearchResult[]) {
        return find(policies, (policy: PolicySearchResult) => !isNil(policy.pbbUnitValue));
    }

    public render() {
        const formData = this.appendIndexToPolicies(this.props.formData);
        const pbbPolicy = this.shouldShowPbbMessage(formData);
        if (!this.state || !this.state.startDate || isEmpty(this.props.formData)) {
            return null;
        }

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => {
                    return (
                        <Grid>
                            <Grid.Col xs={12} md={12}>
                                <OptOutInContainerHeader text={<FormattedMarkdown
                                    messageKey={CmsHelper.withPrefix(this.context, "optOut.description")}
                                    messageValue={{
                                        union: context.data.group.name,
                                        // freePeriodEndDate: <FormattedDate value={this.state.freePeriodEndDate!} />
                                    }}
                                />}
                                />
                                {map(groupPolicies(formData), (policies: IOptCardPolicy[], group: any) => {
                                        return (
                                            <OptCardGroupList
                                                header={
                                                    this.getHeaderMessage(context, group)
                                                }
                                                defaultValue={true}
                                                key={`optOut-${group}`}
                                                group={group}
                                                getError={this.getFieldError}
                                                onChange={this.onCardChange}
                                                listItemComponent={OptCard}
                                                policies={policies}
                                            />
                                        );
                                    }
                                )}
                                <OptOutInContainerFooter pbbPolicy={pbbPolicy}/>
                            </Grid.Col>
                        </Grid>
                    )
                }}
            </FormContext.Consumer>
        )
    }

    private getFieldError(idx: number): string | undefined {
        if (this.props.errorSchema[idx] && this.props.errorSchema[idx]?.__errors?.length > 0) {
            return this.props.errorSchema[idx].__errors[0];
        }
    }

    private getHeaderMessage(context: IFormContext, group?: number) {
        if (!isMemberPolicy(Number(group))) {
            return <OptCardHeaderMessage key="listHeader"
                                         fullName={this.props.intl.formatMessage({id: CmsHelper.withPrefix(this.context, "optCard.yourPartner")})}/>;
        } else {
            const member = context.data.member;
            return <OptCardHeaderMessage key="listHeader"
                                         fullName={`${member.contact.firstName} ${member.contact.lastName}`}/>;
        }
    }

    private onCardChange(policy: IOptCardPolicy) {
        const optOutPolicies = clone(this.appendIndexToPolicies(this.props.formData));
        optOutPolicies[policy.index] = policy;
        this.props.onChange(optOutPolicies);
    }
}

export default injectIntl(OptOutContainer);
