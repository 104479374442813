import {PanelComponentBase} from "./PanelComponentBase";
import {FloatingPanelForMobile, withViewContext} from "@folksam-digital/ui";
import {IMetadata} from "../input";

class FloatingPanelComponent extends PanelComponentBase<string, IMetadata, {}> {
    render() {
        return (
            <FloatingPanelForMobile>
                {this.props.properties?.map(p => p.content)}
            </FloatingPanelForMobile>
        )
    }
}

export const FloatingPanel = withViewContext(FloatingPanelComponent);
