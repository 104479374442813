import * as React from "react";
import { Button } from "@folksam-digital/ui";

interface IProps {
    children: React.ReactNode;
    url: {link?: string, external?: boolean};
}

export const LinkButton = ({url, children}: IProps) => {
    return (
        <Button type="button"
             onClick={() => window.open(url?.link, "_blank")}
             asLink={true}>
            <div> {children} </div>
        </Button>
    );
};
