import {CmsCatalog, ICmsClient} from "@folksam-digital/services";
import {ILocaleConfig} from "../../../intl/ILocaleConfig";
import {IStorage} from "../../IStorage";
import {DigitalApiServiceBase, IDigitalApiServiceConfig} from "./DigitalApiServiceBase";

export class ApiCmsClient extends DigitalApiServiceBase implements ICmsClient {
    private static cache: {[key: string]: Promise<CmsCatalog | undefined>} = {};

    private localeConfig: ILocaleConfig;
    private settingsStorage: IStorage;

    constructor(config: IDigitalApiServiceConfig, localeConfig: ILocaleConfig, settingsStorage: IStorage) {
        super(config);

        this.localeConfig = localeConfig;
        this.settingsStorage = settingsStorage;
    }

    public async getCatalog(name: string, folder: string, locale?: string): Promise<CmsCatalog | undefined> {
        if (!locale) {
            locale = this.settingsStorage.get<string>("LOCALE", this.localeConfig.defaultLocale)!
        }

        const cacheKey = `${locale.toString()}/${folder}/${name}`;
        if(!ApiCmsClient.cache[cacheKey]) {
            ApiCmsClient.cache[cacheKey] = this.getCatalogInternal(name, folder, locale);
        }

        return ApiCmsClient.cache[cacheKey];
    }

    private async getCatalogInternal(name: string, folder: string, locale: string): Promise<CmsCatalog | undefined> {
        const path = `/cms/locales/${locale}/folders/${folder}/catalogs/${name}`;

        const http = super.getHttpClient(path);
        const response = await http.get<CmsCatalog>(path);

        return response.data;
    }
}
