import * as React from "react";
import {Grid, defaultTheme} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";
import {IBreakPoint} from "../../layout/helpers";
import {merge} from "lodash";

interface IMetadata {
    breakpoints?: IBreakPoint;
    classNames?: string;
    childrenMaxWidth?: number;
    positionItemsInRow?: boolean;
    rowGap?: string;
    labelPanelName?: string;
}

export class MultipleInputGrid extends PanelComponentBase<string, IMetadata, {}> {
    public render() {
        const {
            breakpoints,
            classNames,
            childrenMaxWidth,
            positionItemsInRow,
            rowGap,
            labelPanelName
        } = this.metadata;

        const paddings = positionItemsInRow && {
            paddingLeft: defaultTheme.padding['1'],
            paddingRight: defaultTheme.padding['1']
        }

        const columnStyle = childrenMaxWidth && {
            maxWidth: childrenMaxWidth
        };

        const rowStyle = rowGap && {
            rowGap: defaultTheme.margin[rowGap]
        }

        // for aria-labelledby
        const modifiedChildren = (children: {content: any}) =>{
            const componentMetadata = merge({}, children.content.props.uiSchema?.componentMetadata || {}, {labelPanelName: this.uiSchema.componentMetadata.labelPanelName});
            return React.cloneElement(children.content, {
                ...children.content.props,
                uiSchema: {...children.content.props.uiSchema, componentMetadata}
            });
        }

        return (
            <div className={classNames}>
                 <Grid>
                    <Grid.Row style={{...rowStyle}}>
                        {this.props.properties?.map((prop, key) =>
                            <Grid.Col key={key} style={{...columnStyle, ...paddings}} {...breakpoints}>
                                {labelPanelName ? modifiedChildren(prop): prop.content}
                            </Grid.Col>)
                        }
                    </Grid.Row>
                 </Grid>
            </div>
        );
    }
}
