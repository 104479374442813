import {BaseErrorMessage} from "./BaseErrorMessage";
import {Constants} from "@folksam-digital/model";

export class UnsuitableSpecificVehicleTypeForProduct extends BaseErrorMessage {
    protected determineMessage(): void {
        switch (this.journeyId) {
            case Constants.Journey.LightTruck.Id:
                this.message = "contactForm.incorrectVehicleCodeForProduct.description";
                break;
            case Constants.Journey.Car.Id:
                this.message = "contactForm.unsuitableVehicleTypeForProduct.car.description";
                break;
            default:
                this.message = "contactForm.unsuitableVehicleTypeForProduct.description";
        }
    }
}
