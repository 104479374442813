import * as React from "react";
import {FormattedMessage} from "react-intl";
import {OutputComponentBase} from "./OutputComponentBase";
import {
    DescriptionListResponsive,
    DescriptionList,
    defaultTheme as theme,
    Spacing,
    Grid,
    Step
} from "@folksam-digital/ui";
import {Template} from "./helpers";
import {FormContext, IFormContext} from "../FormContext";
import get from "lodash/get";
import {LegacyDiscount} from "@folksam-digital/model";
import {Label} from "../../../formFieldLayout/Label";
import {Tooltip} from "../../../formFieldLayout/Tooltip";
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";

interface IPrice {
    definition: string;
    definitionSub: string;
    background?: boolean;
}

interface IDiscounts {
    path: string;
    tooltip?: string;
    suffix?: string;
}

interface IMetadata {
    zebra?: boolean;
    balanced?: boolean;
    separated?: boolean;
    column?: boolean;
    themeId?: number;
    rowStyle?: any;
    compact?: boolean;
    term?: string;
    definitionTerm?: string;
    definition: string;
    price: IPrice;
    discounts?: IDiscounts;
    classNames?: string;
    odd?: boolean;
    directionRow?: boolean;
    spacingTop?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    spacingBottom?: 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';
    discountsInStep?: boolean;
}

interface IDefinitionStyle {
    justifyContent?: string;
    alignItems?: string;
}

export class DescriptionListRow extends OutputComponentBase<string, IMetadata, {}> {
    public render() {
        const {
            directionRow,
            zebra,
            balanced,
            separated,
            themeId,
            column,
            compact,
            odd,
            spacingTop,
            spacingBottom
        } = this.metadata;

        return (
            <DescriptionListResponsive.Row
                balanced={balanced}
                separated={separated}
                zebra={zebra}
                themeId={themeId}
                column={column}
                compact={compact}
                odd={odd}
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
            >
                <DescriptionList.Content
                    directionRow={directionRow}>{this.termAndDefinitionRenderer()}</DescriptionList.Content>
            </DescriptionListResponsive.Row>
        );
    }

    private termAndDefinitionRenderer = (): React.ReactElement => {
        const {term, definitionTerm, definition, rowStyle, price, discounts, discountsInStep} = this.metadata;
        const definitionStyle = {} as IDefinitionStyle;
        if (discounts && discounts?.tooltip) {
            definitionStyle.justifyContent = 'flex-start';
        } else if (price?.definitionSub) {
            definitionStyle.alignItems = 'flex-start';
        }

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => {
                    const TextBoldOrRegular = () => {
                        if (discountsInStep) {
                            return <div style={{fontWeight: theme.fontWeights.bold, display: "contents"}}><Template template={definition} data={context.data}/></div>
                        }
                        else if(discounts && !discountsInStep) {
                            return <div style={{fontWeight: theme.fontWeights.bold, fontSize: theme.textSizes.sm, display: "contents"}}><Template template={definition} data={context.data}/></div>;
                        }
                        return <Template template={definition} data={context.data}/>;
                    }
                    return (<>
                        {
                            term ?
                                (<DescriptionListResponsive.Term>
                                    <FormattedMessage id={term}/>
                                </DescriptionListResponsive.Term>)
                                : definitionTerm ?
                                    (<DescriptionListResponsive.Term>
                                        <Template style={rowStyle} template={definitionTerm} data={context.data}/>
                                    </DescriptionListResponsive.Term>)
                                    : null
                        }
                        <DescriptionListResponsive.Definition
                            style={definitionStyle}
                        >
                            {discounts && discounts?.tooltip ?
                                <Label
                                    required={true}
                                    tooltip={<Tooltip tooltip={discounts?.tooltip!}/>}
                                    optionalText={<FormattedMessage id={"general.optionalText"}/>}
                                >
                                    <TextBoldOrRegular/>
                                </Label>
                                : <TextBoldOrRegular/>
                            }

                            {price &&
                            <DescriptionList.Price
                                component={<Template template={price.definition} data={context.data}
                                                     style={{whiteSpace: "nowrap"}}/>}
                                componentSub={price.definitionSub ?
                                    <Template template={price.definitionSub} data={context.data}
                                              style={{whiteSpace: "nowrap"}}/>
                                    : undefined}
                                fontWeight={price.definitionSub ? theme.fontWeights.semiBold : undefined}
                                background={price.background}
                            />
                            }
                        </DescriptionListResponsive.Definition>
                        {discounts && <Spacing top={discountsInStep ? '1' : '2'}>
                            {discountsInStep && discounts && <Step.Item border={false} spacingTop={'0'} spacingBottom={'4'}/>}
                            <DescriptionListResponsive.Definition fontWeight={theme.fontWeights.medium}>
                                {this.mapDiscounts(get(context.data, discounts.path))}
                            </DescriptionListResponsive.Definition>
                        </Spacing>}
                    </>)
                    }
                }
            </FormContext.Consumer>
        );
    }

    private mapDiscounts(discounts?: LegacyDiscount[]): React.ReactNode {
        if (discounts && discounts.length > 0) {
            const {discountsInStep} = this.metadata;
            const discountData = discounts?.map((discount: LegacyDiscount) => {
                    return (<Grid.Row
                        style={discountsInStep ?
                            {justifyContent: "space-between", borderTop: "1px solid rgb(220, 227, 238)", padding: "12px 0"}
                            : {justifyContent: "space-between"}}
                        key={discount?.id}
                    >
                        <Grid.Col>{discount.reason}</Grid.Col>
                        <Grid.Col>
                            <DescriptionList.Price
                                component={<FormattedPrice value={discount.amount!} suffixId={"general.yearlyPremium.suffix"}/>}
                                fontWeight={discountsInStep ? theme.fontWeights.semiBold : theme.fontWeights.regular}
                            />
                        </Grid.Col>
                    </Grid.Row>)
                }
            );

            return (<Grid style={{width: "100%"}}>{discountData}</Grid>);
        }
    }
}
