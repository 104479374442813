import {Constants, DirectDebitSigningRequest, FetchSigningStatusOutput, SigningResponse} from "@folksam-digital/model";
import {sleep} from "../../../util/sleep";
import {ISigningService} from "../../ISigningService";
import {DigitalApiServiceBase} from "./DigitalApiServiceBase";

export class SigningService extends DigitalApiServiceBase implements ISigningService {
    protected signingWaitingStep = 1000; // 1 second
    protected signingMaxWaitingSteps = 90; // Max wait for 180 seconds. Normally status should be updated to EXPIRED_TRANSACTION in 180 seconds. In case it's not updated by the system, throw timeout in portal

    /**
     * Inits direct debit signing
     * @param data
     */
    public async initDirectDebitSigning(data: DirectDebitSigningRequest): Promise<SigningResponse> {
        const path = "/signing/directDebit";

        const http = super.getHttpClient(path);
        const response = await http.post<SigningResponse>(path, data);

        return response.data;
    }


    /**
     * Gets the signing status
     * @param requestId
     */
    public async getSigningStatus(requestId: string): Promise<FetchSigningStatusOutput> {
        const path = `/signing/status/${requestId}`;

        const http = super.getHttpClient(path);
        const response = await http.get<FetchSigningStatusOutput>(path, {
            headers: {
                'Pragma': 'no-cache'
            }
        });

        return response.data;
    }

    /**
     * Await until status is completed
     * @param requestId
     * @param isSigningCancelled
     * @param onStatusUpdate
     */
    public async awaitSigningComplete(requestId: string, isSigningCancelled: () => boolean, onStatusUpdate?: (status?: string) => void): Promise<boolean> {
        // Await for updates until Success of Failure is retrieved
        let steps = 0;
        while (steps < this.signingMaxWaitingSteps) {
            // check if due to user actions e.g. "navigating back in browser" signing should be cancelled
            if (isSigningCancelled()) {
                if (onStatusUpdate) {
                    onStatusUpdate(undefined); // Completed - clear status
                }
                return false;
            }

            const signingStatus = await this.getSigningStatus(requestId);

            switch (signingStatus.action) {
                case Constants.Signing.actions.success:
                    if (onStatusUpdate) {
                        onStatusUpdate(undefined); // Success - clear status
                    }
                    return true;
                case Constants.Signing.actions.failure:
                    if (onStatusUpdate) {
                        onStatusUpdate(undefined); // Completed - clear status
                    }
                    return false;
                case Constants.Signing.actions.wait:
                    if (onStatusUpdate) {
                        onStatusUpdate(signingStatus.status); // Update status
                    }
                    await sleep(this.signingWaitingStep); // Wait and re-load the signingStatus
                    steps++;
                    break;
            }
        }

        return false;
    }
}
