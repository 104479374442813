import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    AddonAccordion as AddonAccordionFUI,
} from "@folksam-digital/ui";
import get from "lodash/get";
import {InputComponentBase} from "./InputComponentBase";
import {IBreakPoint} from "../../layout/helpers";
import {CoverPlan} from "@folksam-digital/model";

interface IMetadata {
    coverId: number;
    addOnId: string;
    selectedCoverPlanId?: string; // used as flag to render from coverPlan object
    breakpoints?: IBreakPoint;
    unselectedTitle: string;
    selectedTitle: string;
    selectedPath: string;
    disabledMessage: string;
    monthlyPremiumPath: string;
    disabled: boolean;
    evaluate: boolean;
    showValue: boolean;
}

class AddonAccordionFooterInternal extends InputComponentBase<boolean | string, IMetadata, {}> {
    public onChange = () => {
        const value = this.props.uiSchema.model? this.props.formData : false
        this.props.onChange(!value);
    };

    private getPremiumByCoverPlanId(coverPlanId: string): number {
        return Math.round(this.context.data.policy?.premium?.coverPlans?.find(({ id }: CoverPlan) => id === coverPlanId)?.monthlyPremium || 0);
    };

    render() {
        const {selectedPath, selectedTitle, unselectedTitle, disabledMessage, disabled, evaluate, monthlyPremiumPath, selectedCoverPlanId} = this.metadata;
        const disabledForm = this.context.disableButton;
        const selected = get(this.context.data, selectedPath);
        const messagePremium: number = selectedCoverPlanId ? this.getPremiumByCoverPlanId(selectedCoverPlanId) : Math.round(get(this.context.data, monthlyPremiumPath, 0));
        const priceMessage = (!selectedTitle && !unselectedTitle) ? "" : <FormattedMessage
            id={selected ? selectedTitle : unselectedTitle}
            values={{monthlyPremium: Math.round(messagePremium)}}/>;

        return (
            <AddonAccordionFUI.Footer
                evaluate={evaluate}
                showValue={false}
                checked={selected}
                price={priceMessage}
                disabled={disabled || disabledForm}
                checkedToggle={this.onChange}
                evaluateToggle={this.onChange}
                onClick={this.onChange}
                buttonText={{
                    evaluate: <FormattedMessage
                        id={"general.button.product.evaluate.text"}/>,
                    add: <FormattedMessage
                        id={"general.button.product.add.text"}/>,
                    added: <FormattedMessage
                        id={"general.button.product.added.text"}/>,
                    disabled: <FormattedMessage
                        id={disabledMessage ? disabledMessage : "general.button.product.add.text"}/>,
                }}
            />
        );
    }

}

const AddonAccordionFooter = injectIntl(AddonAccordionFooterInternal);
export { AddonAccordionFooter };
