import * as React from "react";
import { FlexRow, defaultTheme } from "@folksam-digital/ui";
import { OutputComponentBase } from "../../../journey/form/output/OutputComponentBase";
import { FormattedMessage } from "react-intl";
import { get } from "lodash";
import { FormContext, IFormContext } from "../../../journey/form/FormContext";
import { Group } from "@folksam-digital/model/lib";
import { CmsContext, ICmsContext } from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IMetadata {
    groupPath: string
}

export default class GroupLogo extends OutputComponentBase<void, IMetadata, {}> {
    private readonly rowStyles = {marginTop: defaultTheme.margin.sm};

    public render() {
        const { groupPath } = this.metadata;
        const { title } = this.schema;

        return(
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <FormContext.Consumer>
                        {(context: IFormContext) => {
                            const group: Group = get(context.data, groupPath);

                            if (!group || !group.name || !group.id) {
                                return (<></>);
                            }

                            const image = CmsHelper.getImageAttributes(CmsHelper.withGroupCommonPrefix(`logo-${group.id}`), cmsContext.catalog!);

                            return(
                                <>
                                    <FlexRow style={this.rowStyles}>
                                        <FormattedMessage id={title} values={{groupName: group.name}}/>
                                    </FlexRow>
                                    {this.renderLogo(image)}
                                </>
                            );
                        }}
                    </FormContext.Consumer>
                )}
            </CmsContext.Consumer>
        );
    }

    private renderLogo(image: any) {
        if (image) {
            return (
                <FlexRow style={this.rowStyles}>
                    <img src={image.absoluteUrl} alt={image.altText} style={{maxHeight: 70}}/>
                </FlexRow>
            );
        }  else {
            return;
        }
    }
}
