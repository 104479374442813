import * as React from "react";
import {Constants} from "@folksam-digital/model";
import ClaimSuccessBase, {IClaimSuccessBaseProps} from "./ClaimSuccessBase";
import {VehicleSingleAccidentJourney} from "@folksam-digital/model/lib/journey/claim/VehicleSingleAccidentJourney";
import {IOnTriggerDataLayerEvent} from "../../../../analytics/new/BaseAnalytics";
import {CmsContext, ICmsContext} from "../../../../cms";
import isEmpty from "lodash/isEmpty";
import flowRight from "lodash/flowRight";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {withRouter} from "react-router";
import {Grid, MaxWidthWrapper, Step} from "@folksam-digital/ui";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMotorContentElements} from "../../../../components/journey/layout/Success";
import {FormattedMessage} from "react-intl";
import {AnalyticsType, getAnalytics} from "../../../../analytics/AnalyticsSwitch";

interface VehicleSingleAccidentClaimSuccessProps extends IClaimSuccessBaseProps, IOnTriggerDataLayerEvent {

}

class VehicleSingleAccidentClaimSuccessInternal extends ClaimSuccessBase<VehicleSingleAccidentJourney, VehicleSingleAccidentClaimSuccessProps> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    protected prefix: string = "claim.vehicleSingleAccident";

    constructor(props: VehicleSingleAccidentClaimSuccessProps, context: ICmsContext) {
        super(props, Constants.Journey.VehicleSingleAccident.Id, context);
    }

    public componentDidMount(): void {
        this.checkData(Constants.Journey.VehicleSingleAccident.Id);
        this.triggerDataLayerEvent(Constants.Journey.VehicleSingleAccident.Id);
    }

    public render() {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <FormContext.Provider value={{data: data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Claim}
                        journeyId={Constants.Journey.VehicleSingleAccident.Id}
                        formData={{contact: data.informant}}
                        backUrl={Constants.Journey.VehicleSingleAccident.backUrl}
                        navButton={CloseModalButton}
                        dynamicBackUrl={data?.dynamicBackUrl}
                        completionQueryString={data?.completionQueryString}
                        translations={{
                            header: "general.success.banner.heading",
                            subheader: data.claimNumber ? `${this.prefix}.success.banner.subheading`: `general.motor.claim.success.noPolicy.banner.subheading`,
                            headerText: <FormattedMessage id={`${this.prefix}.pageTitle.headerText`} />
                        }}
                        doScroll={true}
                    >
                        <SuccessHeaderMotorContentElements
                            claimNumber={data?.claimNumber}
                            emailAddress={this.getContact()?.emailAddress}
                            vehicleBrand={data?.vehicleBrand}
                        />
                    </SuccessHeaderLayout>
                    {/*Footer*/}
                    <ComponentBackgroundWrapper>
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            {this.renderContactUsSection()}
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        );
    }
}

const VehicleSingleAccidentClaimSuccess = flowRight(
    withCmsProvider("vehicleSingleAccident"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withRouter,
    withHistoryBackConstraint
)(VehicleSingleAccidentClaimSuccessInternal);

export default VehicleSingleAccidentClaimSuccess;
