import {Constants, JourneyBase} from "@folksam-digital/model";
import {ICmsContext} from "../cms";
import {CmsHelper} from "./cms/CmsHelper";

interface IConditionalSubheaders {
    invalidChipApplication: string,
    healthDeclaration: string,
    healthDeclarationFemale: string,
    outsideOfNordics: string,
    outsideOfNordicsFemale: string,
    combinedCase: string,
    combinedCaseFemale: string,
}

interface IGenderAndEligibility {
    isFemaleDog: boolean | undefined,
    notEligibleToBuy: boolean
}

const getCombinedCaseMessage = (
    {
        isFemaleDog,
        notEligibleToBuy
    }: IGenderAndEligibility,
    context: ICmsContext,
    conditionalSubheaderValues: IConditionalSubheaders
): string => {
    if (isFemaleDog) {
        return notEligibleToBuy ?
            conditionalSubheaderValues.combinedCaseFemale :
            CmsHelper.withPrefix(context, `success.banner.femalePetCondition.combinedCase.subheading`);
    } else {
        return notEligibleToBuy ?
            conditionalSubheaderValues.combinedCase :
            CmsHelper.withPrefix(context, `success.banner.combinedCase.subheading`);
    }
};

const getPetImportedMessage = (
    {
        isFemaleDog,
        notEligibleToBuy
    }: IGenderAndEligibility,
    context: ICmsContext,
    conditionalSubheaderValues: IConditionalSubheaders
): string => {
    if (isFemaleDog) {
        return notEligibleToBuy ?
            conditionalSubheaderValues.outsideOfNordics :
            CmsHelper.withPrefix(context, `success.banner.femalePetCondition.outsideNordic.subheading`);
    } else {
        return notEligibleToBuy ?
            conditionalSubheaderValues.outsideOfNordics :
            CmsHelper.withPrefix(context, `success.banner.outsideNordic.subheading`);
    }

};

const getHealthDeclarationMessage = (
    {
        isFemaleDog,
        notEligibleToBuy
    }: IGenderAndEligibility,
    context: ICmsContext,
    conditionalSubheaderValues: IConditionalSubheaders
): string => {
    if (isFemaleDog) {
        return notEligibleToBuy ?
            conditionalSubheaderValues.healthDeclarationFemale :
            CmsHelper.withPrefix(context, `success.banner.femalePetCondition.healthDeclaration.subheading`);
    } else {
        return notEligibleToBuy ?
            conditionalSubheaderValues.healthDeclaration :
            CmsHelper.withPrefix(context, `success.banner.healthDeclaration.subheading`);
    }
};

export const getPetSuccessHeaderMessageBoxValues = <TJourney extends JourneyBase<any>>(data: TJourney, journeyId: string, context: ICmsContext): string | undefined => {
    const eligibleToBuy = data?.policy?.isEligible;
    let isFemaleDog;

    if (journeyId === Constants.Journey.Dog.Id) {
        isFemaleDog = data?.policy?.insuredObject?.gender === Constants.Gender.Female;
    }

    const healthDeclarationCondition =
        (data?.policy?.insuredObject?.fullyHealthy === false) ||
        data?.policy?.insuredObject?.hasSigns === true ||
        data?.policy?.insuredObject?.sickOrInjured === true ||
        data?.policy?.insuredObject?.hadProcedures === true;
    const petImportedCondition = data?.policy?.insuredObject?.isImported === true;
    const notEligibleToBuy = !data?.policy?.isEligible && !(petImportedCondition || healthDeclarationCondition);

    const conditionalSubheaderValues: IConditionalSubheaders = {
        invalidChipApplication: CmsHelper.withPrefix(context, `success.banner.invalidRegistrationOrChipNumber.application.subheading`),
        healthDeclaration: CmsHelper.withPrefix(context, `success.banner.invalidRegistrationOrChipNumber.healthDeclaration.subheading`),
        healthDeclarationFemale: CmsHelper.withPrefix(context, `success.banner.femalePetCondition.invalidRegistrationOrChipNumber.healthDeclaration.subheading`),
        outsideOfNordics: CmsHelper.withPrefix(context, `success.banner.invalidRegistrationOrChipNumber.outsideNordic.subheading`),
        outsideOfNordicsFemale: CmsHelper.withPrefix(context, `success.banner.femalePetCondition.invalidRegistrationOrChipNumber.outsideNordic.subheading`),
        combinedCase: CmsHelper.withPrefix(context, `success.banner.invalidRegistrationOrChipNumber.combinedCase.subheading`),
        combinedCaseFemale: CmsHelper.withPrefix(context, `success.banner.femalePetCondition.invalidRegistrationOrChipNumber.combinedCase.subheading`),
    };

    if (eligibleToBuy) {
        return (isFemaleDog ? CmsHelper.withPrefix(context, `success.banner.femalePetCondition.subheading`) : undefined);
    }

    if (healthDeclarationCondition && petImportedCondition) {
        return getCombinedCaseMessage({isFemaleDog, notEligibleToBuy}, context, conditionalSubheaderValues);
    }

    if (petImportedCondition) {
        return getPetImportedMessage({isFemaleDog, notEligibleToBuy}, context, conditionalSubheaderValues);
    }

    if (healthDeclarationCondition) {
        return getHealthDeclarationMessage({isFemaleDog, notEligibleToBuy}, context, conditionalSubheaderValues);
    }

    if (notEligibleToBuy) {
        return isFemaleDog ? CmsHelper.withPrefix(context, `success.banner.femalePetCondition.application.subheading`) : CmsHelper.withPrefix(context, `success.banner.application.subheading`);
    }
};
