import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonResponsive } from "@folksam-digital/ui";
import { InputComponentBase } from "./InputComponentBase";
import Icons from "../../../icons";

interface IMetadata {
    classNames?: string;
    renderDirectDebitLogo?: boolean;
    type?: string;
    full?: boolean;
}

export class ButtonWidget extends InputComponentBase<void, IMetadata, {}> {
    public render() {
        const title = this.schema.title;
        const classNames = this.metadata.classNames ? this.metadata.classNames : "";
        const renderDirectDebitLogo = this.metadata.renderDirectDebitLogo;

        return (
            <div className={classNames}>
                <ButtonResponsive type={this.metadata.type} primary={true} full={this.metadata.full}>
                    {
                        renderDirectDebitLogo &&
                        <div style={{height: 20, width: 20, marginRight: 10}}>
                            {Icons.BankId()}
                        </div>
                    }
                    <FormattedMessage id={title} />
                </ButtonResponsive>
            </div>
        )
    }
}
