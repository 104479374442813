import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import memoize from "lodash/memoize";
import {Cover, CoverPlan, JourneyBase} from "@folksam-digital/model";
import {defaultTheme, FlexRow, FormInputToggleButton} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {FormContext, IFormContext} from "../FormContext";
import {IBreakPoint} from "../../layout/helpers";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {FieldGroup} from "../../../formFieldLayout/FieldGroup";

interface IMetadata {
    coverId: number;
    labelTemplate: string;
    help?: string;
    classNames?: string;
    breakpoints?: IBreakPoint;
}

class CoverSelectionYesNoButtonInternal extends InputComponentBase<boolean, IMetadata, {}> {

    public render() {
        const {
            name,
            formData
        } = this.props;

        const { coverId, breakpoints } = this.metadata;
        const layoutProps = this.getLayoutProps();

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => (
                    <FormFieldGroupLayout
                        {...layoutProps}
                        subtitle={this.createMonthlyPremiumSubtitle(context.data, coverId)}
                        spaceBetween={false}
                        breakpoints={getBreakpoints(defaultBreakpoints.toggleButton, breakpoints)}
                    >
                        <div>
                            <FieldGroup spaceBetween={false} {...layoutProps}>
                                <FlexRow>
                                    <FormInputToggleButton
                                        inline={true}
                                        key={`${name}-true`}
                                        id={`${name}-true`}
                                        ariaLabelledBy={`${name}-true-label ${name}-label`}
                                        label={<FormattedMessage id={"general.yes"} />}
                                        value={"true"}
                                        name={name}
                                        checked={formData === true}
                                        invalid={this.isInvalid()}
                                        onChange={(event: any) => this.onChangeWithValidation(true)}
                                    />
                                    <FormInputToggleButton
                                        inline={true}
                                        key={`${name}-false`}
                                        id={`${name}-false`}
                                        ariaLabelledBy={`${name}-false-label ${name}-label`}
                                        label={<FormattedMessage id={"general.no"} />}
                                        value={"false"}
                                        name={name}
                                        checked={formData === false}
                                        invalid={this.isInvalid()}
                                        onChange={(event: any) => this.onChangeWithValidation(false)}
                                    />
                                </FlexRow>
                            </FieldGroup>
                        </div>
                    </FormFieldGroupLayout>
                )}
            </FormContext.Consumer>
        )
    }

    private createMonthlyPremiumSubtitle = memoize((data: JourneyBase<any>, id: number): React.ReactNode | undefined => {
        if (data.policy?.premium?.coverPlans) {
            const covers = data.policy.premium.coverPlans.find((row: CoverPlan) => row.id === data.policy.coverPlan).covers as Cover[];

            for (const cover of covers) {
                if (cover?.id === id) {
                    return (
                        <span style={{fontWeight: defaultTheme.fontWeights.regular, marginLeft: 5}}>
                            <FormattedMessage id={this.metadata.labelTemplate} values={{ monthlyPremium: Math.round(cover?.monthlyPremium || 0) }} />
                        </span>
                    );
                }
            }
        }
    });
}

const CoverSelectionYesNoButton = injectIntl(CoverSelectionYesNoButtonInternal);
export {CoverSelectionYesNoButton};
