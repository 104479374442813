import React from "react";
import {BaseAnalytics as NewBaseAnalytics, IOnTriggerDataLayerEventParams as NewIOnTriggerDataLayerEventParams} from "../../analytics/new/BaseAnalytics";
import {BaseAnalytics as OldBaseAnalytics, IOnTriggerDataLayerEventParams as OldIOnTriggerDataLayerEventParams} from "../../analytics/old/BaseAnalytics";
import { FeatureToggleUtils } from "@folksam-digital/services";

const withDataAnalytics = (dataAnalytics: any) => {
    const useNewAnalytics = FeatureToggleUtils.isFeatureEnabled("useNewAnalytics");

    return useNewAnalytics ? newWithDataAnalytics(dataAnalytics) : oldWithDataAnalytics(dataAnalytics);
};

const newWithDataAnalytics = <P extends NewBaseAnalytics>(dataAnalytics: NewBaseAnalytics) => {
    const onTriggerDataLayerEvent = (params: NewIOnTriggerDataLayerEventParams) => {
        dataAnalytics.onTriggerDataLayerEvent(params);
    };

    return (WrappedComponent: React.ComponentType<P>) => (props: Pick<P, Exclude<keyof P, "onTriggerDataLayerEvent">>) => {
        return (
            <WrappedComponent
                {...props as P}
                onTriggerDataLayerEvent={onTriggerDataLayerEvent}
            />
        );
    }
}

const oldWithDataAnalytics = <P extends OldBaseAnalytics>(dataAnalytics: OldBaseAnalytics) => {
    const onTriggerDataLayerEvent = (params: OldIOnTriggerDataLayerEventParams) => {
        dataAnalytics.onTriggerDataLayerEvent(params);
    };

    return (WrappedComponent: React.ComponentType<P>) => (props: Pick<P, Exclude<keyof P, "onTriggerDataLayerEvent">>) => {
        return (
            <WrappedComponent
                {...props as P}
                onTriggerDataLayerEvent={onTriggerDataLayerEvent}
            />
        );
    }
}

export default withDataAnalytics;
