import * as React from "react";
import {Spacing} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {
    type: 'padding' | 'margin';
    top?: string;
    bottom?: string;
}

export class SpacingPanel extends PanelComponentBase<string, IMetadata, {}> {
    public render() {

        const {type, top, bottom} = this.metadata;

        return (
            <Spacing type={type} top={top} bottom={bottom}>
                {this.props.properties?.map(p => p.content)}
            </Spacing>
        );
    }
}
