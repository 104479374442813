import * as React from "react";
import Helmet from "react-helmet";
import { CmsContext, ICmsContext } from "../../cms/";

interface IDynamicProps {
    keyId: string,
}

export class DynamicTitle extends React.PureComponent<IDynamicProps, {}> {
    public render() {
        const {
            keyId
        } = this.props;
        
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Helmet>
                        <title>{cmsContext.catalog.messages[keyId]}</title>
                    </Helmet>
                )}
            </CmsContext.Consumer>
        )
    }
}
