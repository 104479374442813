import React from "react";
import {DescriptionList} from "@folksam-digital/ui";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {CmsContext, ICmsContext} from "../../../../cms";
import {PolicySearchResult} from "@folksam-digital/model";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";

interface IGetSelectedProductRowParams {
    monthlyPremium: number;
    remuneration: { type: "monthly" | "full", value: number };
}

interface IRemuneration {
    type: "monthly" | "full";
    value: number
}

export interface ISelectedProductRowProps extends WrappedComponentProps {
    offer: PolicySearchResult;
}

interface IRenderTitleProps {
    remuneration: IRemuneration;
    offer: PolicySearchResult;
    monthlyPremium: number;
}

class Row extends React.Component<ISelectedProductRowProps> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const {offer} = this.props;
        const {monthlyPremium, remuneration} = this.getSelectedProductRowParams(offer);

        return (
            <DescriptionList.Row balanced={true}>
                {this.renderTitle({remuneration, offer, monthlyPremium})}
            </DescriptionList.Row>
        );

    }

    private getSelectedProductRowParams(offer: PolicySearchResult): IGetSelectedProductRowParams {
        const remunerationType: "full" | "monthly" = !!offer.mainCoverNumberOfUnits ? "full" : "monthly";
        const remuneration = {
            type: remunerationType,
            value: offer.insuranceAmount || 0,
        };

        return {monthlyPremium: offer.monthlyPremium || 0, remuneration};
    }

    private renderTitle({remuneration, offer, monthlyPremium}: IRenderTitleProps) {

        // TODO: Revert and adjust the logic once IDIT implementation is finished/understood
        // const priceLabel = remuneration.type === "monthly" ?
        //     <FormattedMessage id={CmsHelper.withGroupCommonPrefix("insuranceAmount.monthly")}
        //                       values={{amount: intl.formatNumber(remuneration.value)}}/> :
        //     <FormattedMessage id={CmsHelper.withGroupCommonPrefix("insuranceAmount.simple")}
        //                       values={{amount: intl.formatNumber(remuneration.value)}}/>;

        return (
            <DescriptionList.Row separated={true}>
                <DescriptionList.Content>
                    {
                        offer.selected && <DescriptionList.Term>
                            <FormattedMessage id={"general.review.scope.title"}/>
                        </DescriptionList.Term>
                    }
                    <DescriptionList.Definition>
                        {offer.selected ?  offer.productTitle : <FormattedMessage id={CmsHelper.withPrefix(this.context, "success.summaryList.row.insuranceNotSelected.definition")}/>}
                        {offer.selected &&
                        <DescriptionList.Price
                            primary
                            component={<FormattedPrice
                                suffixId={"general.monthlyPremium.suffix"}
                                value={Math.round(offer.monthlyPremium!)}
                            />}
                        />}
                    </DescriptionList.Definition>
                </DescriptionList.Content>
            </DescriptionList.Row>

        );
    }
}

const SelectedProductRow = injectIntl(Row);
export {SelectedProductRow};
