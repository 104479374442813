import axios, {AxiosInstance, AxiosRequestConfig, CancelTokenSource} from "axios";
import {HttpMethod} from "@folksam-digital/model";
import {SharedServiceBase} from "../SharedServiceBase";

export interface Endpoint {
    method: HttpMethod,
    path: string
}

export interface IWorkflowServiceConfig {
    baseUrl: string,
    baseProtectedUrl: string,
}

export abstract class WorkflowServiceBase extends SharedServiceBase {
    private static cancelTokens: { [key: string]: CancelTokenSource } = {};

    /**
     * Execute request to workflow API
     * @param endpoint
     * @param data
     */
    public async execute<TInput, TOutput>(endpoint: Endpoint, data?: TInput): Promise<TOutput> {
        const request = this.prepareRequest(endpoint, data);

        const http = this.getHttpClient(endpoint);
        const response = await http.request<TOutput>(request);
        this.updateContext(response);

        return response.data;
    }

    /**
     * Execute request to workflow API and cancel previous request to same endpoint if such request exists
     * @param endpoint
     * @param data
     */
    protected async executeUnique<TInput, TOutput>(endpoint: Endpoint, data: TInput): Promise<TOutput | undefined> {
        const request = this.prepareRequest(endpoint, data);

        // Cancel previous request if exists
        if (WorkflowServiceBase.cancelTokens[endpoint.path]) {
            WorkflowServiceBase.cancelTokens[endpoint.path].cancel("Cancelling previous request due to other request received...");
        }

        // Prepare cancel token
        const cancelTokenSource = axios.CancelToken.source();
        request.cancelToken = cancelTokenSource.token;
        WorkflowServiceBase.cancelTokens[endpoint.path] = cancelTokenSource;

        try {
            const http = this.getHttpClient(endpoint);
            const response = await http.request<TOutput>(request);
            delete WorkflowServiceBase.cancelTokens[endpoint.path]; // Delete cancel token

            this.updateContext(response);
            return response.data;
        } catch (err) {
            if (axios.isCancel(err)) {
                window.ajax--;
                return undefined;
            } else {
                throw err;
            }
        }
    }

    private getHttpClient(endpoint: Endpoint): AxiosInstance {
        if (endpoint.path.indexOf("/protected/") > -1) {
            return this.create({
                baseURL: this.config.baseProtectedUrl || this.config.baseUrl
            });
        } else {
            return this.create({
                baseURL: this.config.baseUrl
            });
        }
    }

    /**
     * Prepare request to Workflow API based on endpoint definition and request data
     * @param endpoint
     * @param data
     */
    private prepareRequest<TInput>(endpoint: Endpoint, data: TInput): AxiosRequestConfig {
        const request: AxiosRequestConfig = {
            method: endpoint.method,
            url: endpoint.path,
            headers: this.getContext()
        };

        // Define request data - either set as Query params or Post params
        if (endpoint.method === HttpMethod.GET || endpoint.method === HttpMethod.DELETE) {
            request.params = data;
        } else {
            request.data = data;
        }

        return request;
    }
}
