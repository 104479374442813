import * as React from "react";
import {InputComponentBase} from "./InputComponentBase";
import {SlimCoverageCard, Grid, QuestionSkeleton, Spacing} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CoverPlan} from "@folksam-digital/model";
import {PbbHelper} from "@folksam-digital/services";
import {PackageSelectionCardIcons} from "./helpers/packageSelectionCardIcons";

interface IMetadata {
    mainCoverId?: number;
}

class SlimPackageSelectionCardsInternal extends InputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        if (this.context?.data?.policy?.premium?.coverPlans) {
            const coverPlans: CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);

            return (
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) => (
                        <FormFieldGroupLayout
                            id={this.props.name}
                            error={this.getError()}
                        >
                            <Grid.Row>
                                {
                                    coverPlans.map((coverPlan: CoverPlan) => {
                                        return coverPlan?.id && this.renderCoverPlan(coverPlan.id, cmsContext);
                                    })
                                }
                            </Grid.Row>
                        </FormFieldGroupLayout>
                    )}
                </CmsContext.Consumer>
            );
        } else {
            return <QuestionSkeleton/>;
        }
    }

    private renderCoverPlan(coverPlanId: string, cmsContext: ICmsContext) {
        const {
            formData,
            intl
        } = this.props;

        const { data: { journeyId } } = this.context;

        const { mainCoverId } = this.metadata;
        const coverPlanDetails = PbbHelper.getCoverPlanDetails(this.context.data, coverPlanId, mainCoverId!);
        const icon = this.getCoverPlanIcon(journeyId, coverPlanId);

        return (
            <Grid.Col xl key={coverPlanId}>
                <>
                    <Spacing type={"margin"} top={"md"}>
                        <React.Fragment />
                    </Spacing>
                    <SlimCoverageCard
                        id={coverPlanId}
                        heading={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.${coverPlanId}.heading`)}, coverPlanDetails)}
                        label={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.title`)}, coverPlanDetails)}
                        price={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `sizeOfInsurance.package.price`)}, coverPlanDetails)}
                        value={coverPlanId}
                        checked={formData === coverPlanId}
                        onChange={() => this.onChangeWithValidation(coverPlanId)}
                        icon={icon}
                        buttonText={{
                            unselected: <FormattedMessage
                                id={"general.package.unselected"}/>,
                            selected: <FormattedMessage
                                id={"general.package.selected"}/>
                        }}
                    />
                </>
            </Grid.Col>
        )
    }

    private getCoverPlanIcon(journeyId: string, coverPlanId: string) {
        if(PackageSelectionCardIcons[journeyId] && PackageSelectionCardIcons[journeyId][coverPlanId]) {
            return PackageSelectionCardIcons[journeyId][coverPlanId];
        }
    };
}

const SlimPackageSelectionCards = injectIntl(SlimPackageSelectionCardsInternal);
export { SlimPackageSelectionCards };
