import * as React from "react";
import {injectIntl} from "react-intl";
import moment from "moment";
import {DatepickerModal, FormInputDate} from "@folksam-digital/ui";
import {IInputComponentProps} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import sanitizeField from "./helpers/sanitizeField";
import {formattedPartsToDateLocale} from "../../../../Helpers/formattedPartsToDateLocale";
import isEmpty from "lodash/isEmpty";
import {DateTimeBase, IDateTimeComponentMetadataBase, IDateTimeComponentStateBase,} from "./DateTimeBase";
import isDate from "./helpers/isDate";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {yearMonthDayFormat} from "@folksam-digital/model";

interface IMetadata extends IDateTimeComponentMetadataBase {
    defaultDate?: string;
    ariaLabelledby?: string;
}

interface IState extends IDateTimeComponentStateBase {
}

export interface IFakeEvent extends React.FocusEvent<any> {
    originalEvent?: React.FocusEvent<any>;
    isFakeEvent?: boolean;
}

class DateComponentInternal extends DateTimeBase<string, IMetadata, IState> {

    constructor(props: IInputComponentProps<string, IMetadata>) {
        super(props);

        this.state = {
            input: "",
            date: undefined,
            disabled: false,
            minimalDate: undefined,
            maximalDate: undefined,
            isCalendarVisible: false,
            rectangleParams: {}
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.formData) {
            const date = moment.utc(this.props.formData, this.getDateFormat());
            this.setState({
                input: date.toString(),
                date: date.toDate()
            });
        }
        this.updateDateConfig();
    }

    public componentDidUpdate(prevProps: IInputComponentProps<string, IMetadata>, prevState: IState): void {
        if (this.props.formData && this.props.formData !== prevProps.formData) {
            const date = moment.utc(this.props.formData, this.getDateFormat());
            this.setState({
                input: date.toString(),
                date: date.toDate()
            });
        }
        this.updateCalendarRectangleParams(prevProps, prevState);
        this.updateDateConfig();
    }

    public render() {
        const {
            formData,
            intl,
            name,
            onFocus
        } = this.props;

        const {
            showActionTodayButton,
            showActionClearButton,
            showActionCloseButton,
            maxDetail,
            minDetail,
            defaultDate,
            placeholder,
            inputMode,
            ariaLabelledby,
        } = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()} breakpoints={getBreakpoints(defaultBreakpoints.dateTime, this.metadata.breakpoints)}>

                <FormInputDate
                    id={name}
                    value={isDate(this.state.date) ? this.formatDateObject(formData) : this.state.input}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    onInputFocus={(event: FocusEvent) => {
                        if (onFocus) {
                            onFocus(event, this.props?.idSchema?.$id);
                        }
                    }}
                    actionClearText={intl.formatMessage({id: "general.datePicker.actionClearText"})}
                    actionTodayText={intl.formatMessage({id: "general.datePicker.actionTodayText"})}
                    actionCloseText={intl.formatMessage({id: "general.datePicker.actionCloseText"})}
                    showActionTodayButton={showActionTodayButton}
                    showActionClearButton={showActionClearButton}
                    showActionCloseButton={showActionCloseButton}
                    disabled={this.state.disabled}
                    customDatepicker={<DatepickerModal {...this.state.rectangleParams} />}
                    isCalendarVisible={this.state.isCalendarVisible}
                    setIsCalendarVisible={this.setIsCalendarVisible}
                    format={this.getDateFormat()}
                    calendarProps={{
                        minDate: this.state.minimalDate,
                        maxDate: this.state.maximalDate,
                        maxDetail: maxDetail,
                        minDetail: minDetail,
                        locale: intl.locale,
                        defaultActiveStartDate: (defaultDate && new Date(defaultDate)) ?? this.getActiveStartDate()
                    }}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    ref={this.refObj}
                    invalid={this.isInvalid()}
                    autoComplete={"off"}
                    inputMode={inputMode}
                    ariaLabelledby={ariaLabelledby}
                />
            </FormFieldLayout>
        );
    }

    private handleOnChange(event: React.FocusEvent<any>) {
        const value = sanitizeField(event.target.value);

        this.setState({
            input: value,
            date: undefined
        });

        /* Event is triggered by selecting a date from calendar */
        if (event.nativeEvent === undefined) {
            this.handleDateSelect(event);
        }
    }

    private handleDateSelect(event: React.FocusEvent<any>) {
        const value = sanitizeField(event.target.value);
        const { date, dateIso } = this.convertDateValue(value);

        if (!moment(this.props.formData).isSame(dateIso)) {
            this.props.onChange(dateIso);
        }
        this.setState({date: date});
    }

    private convertDateValue(value: string) {
        const res = this.processDate(value);
        let date;
        let dateIso;

        if (res !== undefined) {
            date = res.toDate();
            // default format
            dateIso = res.toISOString(true);
        }

        // Change output format according to format.moment if necessary
        if (this.getDateFormat() && this.props.schema.pattern) {
            dateIso = moment.utc(date).format(this.getDateFormat());
        }

        return {date, dateIso}
    }

    private getDateValue(event: React.FocusEvent<any>) {
        let value = sanitizeField(event.target.value);

        if (this.getIntlFormat()) {
            const formattedParts = this.props.intl.formatters.getDateTimeFormat(this.props.intl.locale, this.getIntlFormat()).formatToParts();
            const format = formattedPartsToDateLocale(formattedParts);
            value = moment.utc(value, format).toISOString();
        }

        return this.convertDateValue(value);
    }

    private handleOnBlur(event: IFakeEvent | React.FocusEvent<any>) {
        if (!this.state.isCalendarVisible) {

            if (event?.persist && typeof event?.persist === "function" ) {
                event.persist();
            }

            const { date, dateIso } = this.getDateValue(event);
            if (!moment(this.props.formData).isSame(dateIso)) {
                this.context.triggerDataChanged();
                event.preventDefault();
                event.stopPropagation();
                this.onChangeWithValidation(dateIso);

            }
            this.setState({ date });

            // @ts-ignore-next-line
            const originalEvent: React.FocusEvent<any> = (event.isFakeEvent && event.originalEvent) ? event.originalEvent : event;

            setTimeout(() => {
                this.onBlurWithValidation(originalEvent, dateIso);
            }, 500)
        }
    }

    protected getDateFormat(): string {
        return this.metadata.format ? this.metadata.format.moment : yearMonthDayFormat.moment;
    }

    private getIntlFormat(): Intl.DateTimeFormatOptions {
        return this.metadata.format ? this.metadata.format.reactIntl : yearMonthDayFormat.reactIntl;
    }

    private processDate(formData: string): moment.Moment | undefined {
        if (!isEmpty(formData)) {
            const date = moment.utc(formData, this.getDateFormat());

            // Check the year to verify that it has four digits. Moment would add an
            // extra 0 to the year if it was bellow 1000 which would result in an date
            // that was valid in code but not valid in practice (i.e 202-01-01).
            // See FD-10166 for more information.
            const isValid = date.year() > 999 && moment.utc(date).isValid();

            if (!isValid) {
                if (this.state.minimalDate) {
                    return moment.utc(this.state.minimalDate, this.getDateFormat());
                } else if (this.state.maximalDate) {
                    return moment.utc(this.state.maximalDate, this.getDateFormat());
                } else {
                    return undefined;
                }
            } else {
                if (this.state.minimalDate && date.toDate() < moment.utc(this.state.minimalDate).toDate()) {
                    return moment.utc(this.state.minimalDate, this.getDateFormat());
                } else if (this.state.maximalDate && date.toDate() > moment.utc(this.state.maximalDate).toDate()) {
                    return moment.utc(this.state.maximalDate, this.getDateFormat());
                } else {
                    return date;
                }
            }
        }
        else {
            return undefined;
        }
    }

    private formatDateObject(formData: any): string | undefined {
        if (formData) {
            return this.props.intl.formatDate(formData.toLocaleString(), this.getIntlFormat());
        }
    }

}

const DateComponent = injectIntl(DateComponentInternal);
export {DateComponent};
