import * as React from "react";
import {AdditionCard as AdditionCardFUI} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {Cover, MarkdownListTypes} from "@folksam-digital/model";
import {Markdown} from "../../../Markdown";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {IBreakPoint} from "../../layout/helpers";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";

interface IMetadata {
    coverId: number;
    addOnId: string;
    prefix: string;
    breakpoints?: IBreakPoint;
}

class AdditionCardInternal extends InputComponentBase<Cover, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    constructor(props: any, context: IFormContext) {
        super(props, context);

        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const {intl, formData} = this.props;
        const {coverId, addOnId, breakpoints} = this.metadata;
        const cover = formData as Cover;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <FormFieldLayout {...this.getLayoutProps()} breakpoints={this.getBreakpoints(breakpoints)}>
                        <AdditionCardFUI
                            id={coverId}
                            value={coverId}
                            checked={!!(formData && formData?.selected)}
                            onChange={this.onChange}
                            heading={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `addition.${addOnId}.heading`)})}
                            expandText={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `addition.${addOnId}.expandText`)})}
                            buttonText={{
                                unselected: <FormattedMessage
                                    id={"general.package.unselected"}/>,
                                selected: <FormattedMessage
                                    id={"general.package.selected"}/>
                            }}
                            price={<FormattedMessage
                                id={`${CmsHelper.getPrefix(cmsContext)}.addition.${addOnId}.price`}
                                values={{monthlyPremium: cover?.monthlyPremium || 0}} />
                            }
                        >
                            <Markdown listType={MarkdownListTypes.BULLET}
                                      source={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `addition.${addOnId}.list`)})}/>
                        </AdditionCardFUI>
                    </FormFieldLayout>
                )}
            </CmsContext.Consumer>
        );
    }

    private async onChange(event: any): Promise<void> {
        const {disableButton} = this.context;
        if (!disableButton) {
            const newData = this.props.formData as Cover;
            newData.selected = !!event.target.checked;
            this.props.onChange(newData);
        }
    }

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        return {
            ...defaultBreakpoints.additionCard,
            ...breakpoints
        };
    }
}

const AdditionCard = injectIntl(AdditionCardInternal);
export {AdditionCard};
