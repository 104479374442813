
function makeConverter() {
    return function convert(format: string, part: any) {
        switch (part.type) {
            case "month":
                format += "MM";
                break;
            case "day":
                format += "DD";
                break;
            case "year":
                format += "YYYY";
                break;
            case "hour":
                format += "HH";
                break;
            case "minute":
                format += "mm";
                break;
            case "second":
                format += "ss";
                break;
            case "literal":
                format += part.value;
                break;
        }

        return format;
    }
}

export const formattedPartsToDateLocale = (parts: any[]) => {
    const format = "";
    return parts.reduce(makeConverter(), format)
};

