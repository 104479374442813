
import * as React from "react";
import {FormInputTextareaAutoresize, IconSearch} from "@folksam-digital/ui";
import {InputSuffix} from "./InputSuffix";
import {createUseStyles} from "react-jss";
import classNames from "classnames";

interface IInputRendererProps {
    className: string;
    id: string;
    selected: boolean;
    activeSuggestion: number | null | undefined;
    loading: boolean;
    autoComplete: string;
    value: string;
    onChange: (event: React.MouseEvent<HTMLElement>) => void;
    onBlur: (event: React.MouseEvent<HTMLElement>) => void;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    onFocus: (event: React.MouseEvent<HTMLElement>) => void;
    onKeyDown: () => void;
    required?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    placeholder?: string;
    capitalize?: boolean;
    maxLength?: string | number;
    ref?: React.RefObject<any>;
}

export const useStyles = createUseStyles({
    capitalize: {
        textTransform: "capitalize"
    }
});

export function InputRenderer({
    id,
    loading,
    autoComplete,
    value,
    selected,
    onChange,
    onBlur,
    required,
    disabled,
    invalid,
    placeholder,
    onFocus,
    ref,
    maxLength,
    capitalize
}: IInputRendererProps): React.ReactElement {
    const classes = useStyles();
    const cn = classNames({[classes.capitalize]: capitalize})

    return (
        <div className="custom-wrapper">
            <FormInputTextareaAutoresize
                id={id}
                className={cn}
                value={!!value ? value : ''}
                disabled={disabled}
                required={required}
                invalid={invalid}
                autoComplete={autoComplete}
                placeholder={placeholder}
                onChange={onChange}
                suffix={<InputSuffix loading={loading} selected={selected} />}
                prefix={<IconSearch width={18} height={18} />}
                onBlur={onBlur}
                onFocus={onFocus}
                name={id}
                minRows={1}
                maxLength={maxLength || 150}
            />
        </div>
    );
}
