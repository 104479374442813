import * as React from "react";
import {Spacing, defaultTheme, SummaryCard, DescriptionList, P} from "@folksam-digital/ui";
import {GroupOptInOptOut, InsuredPersonContact, PolicySearchResult} from "@folksam-digital/model/lib";
import {FormattedMessage} from "react-intl";
import * as OptInOptOutProductListBaseFunctions from "./helpers/OptInOptOutProductListBaseFunctions";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../../FormattedMarkdown";
import SuccessPageFailedProductList from "./SuccessPageFailedProductList";
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";
import {ListRowItem} from "../../../../Routers/Journey/product/common/ListRowItem";

interface OptInOptOutProductListProps {
    data: GroupOptInOptOut;
    summaryCardChildren?: any
    summaryRedirectControl?: any
}

export default class OptInOptOutProductList extends React.Component<OptInOptOutProductListProps, {}> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const {data, summaryCardChildren, summaryRedirectControl: RedirectControlComponent} = this.props;
        const contact = data.member.contact as InsuredPersonContact;
        let anyErrors: boolean = false;

        const allOptInOptOutPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getAllPolicies(data);
        const policies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getAllSuccessfulOptOutPolicies(data);
        const proposals: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getAllSuccessfulOptInPolicies(data);

        const memberOptInOptOutPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getMemberOptInOptOut(data);
        const partnerOptInOptOutPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getPartnerOptInOptOut(data);

        anyErrors = allOptInOptOutPolicies.some((policy) => policy.policy?.errors && policy.policy?.errors.length > 0);

        return (
            contact && ((policies && policies.length > 0) || (proposals && proposals.length > 0)) &&
            <>
                {memberOptInOptOutPolicies && memberOptInOptOutPolicies.length > 0 &&
                    <SummaryCard customHeader={
                        <SummaryCard.Header theme={5} noPaddingSide={true}>
                            <SummaryCard.HeaderText>
                                <FormattedMessage
                                    id={CmsHelper.withPrefix(this.context, "success.summaryList.insuranceForYou.title")} />
                            </SummaryCard.HeaderText>
                            {!!RedirectControlComponent && RedirectControlComponent}
                        </SummaryCard.Header>
                    }>
                        <SummaryCard.Body>
                            <DescriptionList>
                                <DescriptionList.Row separated={true}>
                                    <DescriptionList.Content>
                                        <DescriptionList.Term>
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(this.context, "success.summaryList.row.contact.title")} />
                                        </DescriptionList.Term>
                                        <DescriptionList.Definition>
                                            {`${data.member.contact.firstName} ${data.member.contact.lastName}`}
                                        </DescriptionList.Definition>
                                    </DescriptionList.Content>
                                </DescriptionList.Row>

                                {memberOptInOptOutPolicies.map((policy: PolicySearchResult, index: number) => {
                                    return (
                                        <DescriptionList.Row separated={true}
                                            key={`memberOptInOptOutPolicies_${index}`}>
                                            <DescriptionList.Content>
                                                <DescriptionList.Term>
                                                    {policy.selected ? <FormattedMessage
                                                        id={CmsHelper.withPrefix(this.context, "success.summaryList.row.insuranceSelected.term")} /> : policy.productTitle}
                                                </DescriptionList.Term>
                                                <DescriptionList.Definition>
                                                    {policy.selected ? policy.productTitle : <FormattedMessage
                                                        id={CmsHelper.withPrefix(this.context, "success.summaryList.row.insuranceNotSelected.definition")} />}
                                                    {policy.selected && <DescriptionList.Price
                                                        primary
                                                        component={<FormattedPrice
                                                            suffixId={"general.monthlyPremium.suffix"}
                                                            value={Math.round(policy.monthlyPremium!)}
                                                        />}
                                                    />}
                                                </DescriptionList.Definition>
                                            </DescriptionList.Content>
                                        </DescriptionList.Row>
                                    )
                                })}
                            </DescriptionList>
                        </SummaryCard.Body>
                    </SummaryCard>

                }

                {partnerOptInOptOutPolicies && partnerOptInOptOutPolicies.length > 0 &&
                    <Spacing type={"padding"} top={"sm"}>
                        <SummaryCard customHeader={
                            <SummaryCard.Header theme={5} noPaddingSide={true}>
                                <SummaryCard.HeaderText>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "success.summaryList.insuranceForPartner.title")} />
                                </SummaryCard.HeaderText>
                                {!!RedirectControlComponent && RedirectControlComponent}
                            </SummaryCard.Header>
                        }>
                            <SummaryCard.Body>
                                <DescriptionList>
                                    {data.partner && data.partner.contact &&
                                        <DescriptionList.Row separated={true}>
                                            <DescriptionList.Content>
                                                <DescriptionList.Term>
                                                    <FormattedMessage
                                                        id={CmsHelper.withPrefix(this.context, "success.summaryList.row.contact.title")} />
                                                </DescriptionList.Term>
                                                <DescriptionList.Definition>
                                                    {`${data.partner.contact.firstName} ${data.partner.contact.lastName}`}
                                                </DescriptionList.Definition>
                                            </DescriptionList.Content>
                                        </DescriptionList.Row>
                                    }

                                    {partnerOptInOptOutPolicies.map((policy: PolicySearchResult, index: number) => {
                                        return (
                                            <DescriptionList.Row separated={true}
                                                key={`partnerOptInOptOutPolicies_${index}`}>
                                                <DescriptionList.Content>
                                                    <DescriptionList.Term>
                                                        {policy.selected ? <FormattedMessage
                                                            id={CmsHelper.withPrefix(this.context, "success.summaryList.row.insuranceSelected.term")} /> : policy.productTitle}
                                                    </DescriptionList.Term>
                                                    <DescriptionList.Definition>
                                                        {policy.selected ? policy.productTitle : <FormattedMessage
                                                            id={CmsHelper.withPrefix(this.context, "success.summaryList.row.insuranceNotSelected.definition")} />}
                                                        {policy.selected && <DescriptionList.Price
                                                            primary
                                                            component={<FormattedPrice
                                                                suffixId={"general.monthlyPremium.suffix"}
                                                                value={Math.round(policy.monthlyPremium!)}
                                                            />}
                                                        />}
                                                    </DescriptionList.Definition>
                                                </DescriptionList.Content>
                                            </DescriptionList.Row>
                                        )
                                    })}
                                </DescriptionList>
                            </SummaryCard.Body>
                        </SummaryCard>
                    </Spacing>
                }
                {!data.uiState?.skipPaymentStep && !anyErrors &&
                    <Spacing type={"padding"} top={"sm"}>
                        <SummaryCard customHeader={
                            <SummaryCard.Header theme={5} noPaddingSide={true}>
                                <SummaryCard.HeaderText>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "success.summaryList.totals.title")} />
                                </SummaryCard.HeaderText>
                            </SummaryCard.Header>
                        }>
                            <SummaryCard.Body>
                                <DescriptionList>
                                    <ListRowItem>
                                        <FormattedMessage
                                            id={CmsHelper.withPrefix(this.context, "success.summaryList.totals.annualPrice")} />
                                        <DescriptionList.Price
                                            component={<FormattedPrice
                                                suffixId={"general.yearlyPremium.suffix"}
                                                value={data.premium?.yearlyPremium!}
                                            />}
                                        />
                                    </ListRowItem>
                                    <ListRowItem>
                                        <FormattedMessage
                                            id={CmsHelper.withPrefix(this.context, "success.selectedItems.monthly.title")} />
                                        <DescriptionList.Price
                                            background
                                            component={<FormattedPrice
                                                suffixId={"general.monthlyPremium.suffix"}
                                                value={data.premium?.monthlyPremium!}
                                            />}
                                        />

                                        {/* The information about free period will be stored under Group details for all policies together
                                        TODO: once we have freePolicyPeriod and discount, uncomment and adjust this section
                                        TODO: once adjusted, also check if discounts and free periods are calculated and applied to monthly and yearly premiums correctly
                                            <>
                                                <Spacing type={"padding"} bottom={4}/>
                                                <FormattedMessage id={CmsHelper.withPrefix(this.context, "success.summaryList.totals.freePeriod")} values={{endDate: freePeriod?.freePeriodEnds}}/>

                                                {freePeriod.policyNames && freePeriod.policyNames.length > 0 &&
                                                    freePeriod.policyNames.map(policyName => {
                                                        return (
                                                            <strong>{`${policyName}`}</strong>
                                                        );
                                                    })
                                                }
                                            </>
                                        */}
                                    </ListRowItem>
                                </DescriptionList>
                            </SummaryCard.Body>
                            {!!summaryCardChildren && <SummaryCard.Body>
                                {summaryCardChildren}
                            </SummaryCard.Body>}
                        </SummaryCard>
                    </Spacing>
                }

                {!data.uiState?.skipPaymentStep && anyErrors &&
                    <>
                        <Spacing type={"padding"} top={"sm"}>
                            <SummaryCard customHeader={
                                <SummaryCard.Header theme={5} noPaddingSide={true}>
                                    <SummaryCard.HeaderText>
                                        <FormattedMessage
                                            id={CmsHelper.withPrefix(this.context, "success.summaryList.totals.title")} />
                                    </SummaryCard.HeaderText>
                                </SummaryCard.Header>
                            }>
                                <SummaryCard.Body>
                                    <DescriptionList>
                                        <ListRowItem>
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(this.context, "success.summaryList.totals.annualPrice")} />
                                            <DescriptionList.Price
                                                component={<FormattedPrice
                                                    suffixId={"general.yearlyPremium.suffix"}
                                                    value={data.premium?.yearlyPremium!}
                                                />}
                                            />
                                        </ListRowItem>
                                        <ListRowItem>
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(this.context, "success.selectedItems.monthly.title")} />
                                            <DescriptionList.Price
                                                background
                                                component={<FormattedPrice
                                                    suffixId={"general.monthlyPremium.suffix"}
                                                    value={data.premium?.monthlyPremium!}
                                                />}
                                            />
                                        </ListRowItem>
                                    </DescriptionList>
                                </SummaryCard.Body>
                            </SummaryCard>
                        </Spacing>

                        <Spacing type={"padding"} top={"md"}>
                            <SuccessPageFailedProductList data={data} />
                        </Spacing>
                    </>
                }

                {!summaryCardChildren && !data.uiState?.skipPaymentStep &&
                    <Spacing top={"3"}>
                        <P style={{
                            color: defaultTheme.colors.senary2,
                            fontFamily: defaultTheme.fonts.mono,
                            fontSize: defaultTheme.textSizes.sm,
                        }}>
                            <FormattedMarkdown messageKey={"general.form.priceDisclaimer.helpText"} />
                        </P>
                    </Spacing>
                }

                {!summaryCardChildren &&
                    <Spacing type={"padding"} top={"sm"}>
                        <div style={{borderBottom: `1px solid ${defaultTheme.colors.senary3}`}} />
                    </Spacing>
                }
            </>
        )
    }
}
