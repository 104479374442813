import {IQuestionSchemaOverride} from "../product/GeneratorConfig";

export const shoppingCart: IQuestionSchemaOverride = {
    1000167: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000168: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000169: {
        showBackgroundColor: false
    },
    1000170: {
        showBackgroundColor: false
    },
    1000172: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000173: {
        showBackgroundColor: false
    },
    1000174: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000008: {
        showBackgroundColor: false
    },
    1000009: {
        showBackgroundColor: false
    },
    1000177: {
        showBackgroundColor: false,
        showBorder: false
    },
    1000178: {
        additionalHeaderTitle: true
    },
    1000183: {
        showBackgroundColor: false
    },
    1000184: {
        additionalHeaderTitle: true
    },
    1000193: {
        showBackgroundColor: false
    },
    1000194: {
        additionalHeaderTitle: true
    },
    1000201: {
        showBackgroundColor: false
    },
    1000202: {
        additionalHeaderTitle: true
    },
    1000209: {
        showBackgroundColor: false
    },
    1000210: {
        additionalHeaderTitle: true
    },
    1000217: {
        showBackgroundColor: false
    },
    1000218: {
        additionalHeaderTitle: true
    },
    1000225: {
        showBackgroundColor: false
    },
    1000226: {
        additionalHeaderTitle: true
    },
    1000233: {
        showBackgroundColor: false
    },
    1000234: {
        additionalHeaderTitle: true
    },
    1000241: {
        showBackgroundColor: false
    },
    1000242: {
        additionalHeaderTitle: true
    },
    1000249: {
        showBackgroundColor: false
    },
    1000250: {
        additionalHeaderTitle: true
    },
    1000257: {
        showBackgroundColor: false
    },
    1000258: {
        additionalHeaderTitle: true
    },
    1000265: {
        showBackgroundColor: false
    },
    1000266: {
        additionalHeaderTitle: true
    },
    1000273: {
        showBackgroundColor: false
    },
    1000274: {
        additionalHeaderTitle: true
    },
    1000281: {
        showBackgroundColor: false
    },
    1000282: {
        additionalHeaderTitle: true
    },
    1000289: {
        showBackgroundColor: false
    },
    1000290: {
        additionalHeaderTitle: true
    },
    1000297: {
        showBackgroundColor: false
    },
    1000298: {
        additionalHeaderTitle: true
    },
    1000307: {
        showBackgroundColor: false
    },
    1000308: {
        additionalHeaderTitle: true
    },
    1000316: {
        showBackgroundColor: false
    },
    1000317: {
        showBackgroundColor: false
    },
    1000318: {
        showBackgroundColor: false
    },
    1000319: {
        showBackgroundColor: false
    }
};
