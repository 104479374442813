import * as React from "react";
import {
    AddonAccordion as AddonAccordionFUI,
    Grid,
    Modal,
    FormInputSelect,
    Button,
    BannerSkeleton,
    defaultTheme,
    IconPlus,
    B,
    withViewContext,
    Badge,
    DisplayIcon,
    IconWinner
} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {Breakpoints, LifeInsuranceCover as Cover} from "@folksam-digital/model";
import {Markdown} from "../../../Markdown";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {IBreakPoint} from "../../layout/helpers";
import {IOption} from "@folksam-digital/model";
import sanitizeField from "./helpers/sanitizeField";
import isNil from "lodash/isNil";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

interface IPopOverConfig {
    isPopOverDisplayed: boolean;
    id?: string;
    text?: string;
}

interface ISelectedOption {
    label: string;
    value: string | number;
}

interface IState {
    showValue?: boolean;
    values?: IOption[];
    value?: ISelectedOption,
    expanded?: boolean;
}

interface IMetadata {
    coverId: number;
    coverIdPath: string;// added to use if coverId can change between type of journey, like HomeStudent(IHome, KHome). Only on Idit Schema.
    addOnId: string;
    prefix: string;
    breakpoints?: IBreakPoint;
    simple?: boolean; // to render without evaluate functionality
    withIcon?: boolean;
    expandableList?: any[];
    disabled?: boolean;
    disabledMessage?: string;
    expanded?: boolean;
    selectedTitle: string;
    selectedPath: string;
}

class AddonAccordionInternal extends InputComponentBase<Cover, IMetadata, IState> {
    public static contextType = FormContext;
    context!: IFormContext;

    constructor(props: any, context: IFormContext) {
        super(props, context);
        this.state = {
            showValue: false,
            expanded: false
        };

        this.checkedToggle = this.checkedToggle.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addValue = this.addValue.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onExpandedToggle = this.onExpandedToggle.bind(this);
        this.getCoverOptionByValue = this.getCoverOptionByValue.bind(this);
    }

    public componentDidMount(): void {
        const {coverOptions} = this.context.data.policy.premium;
        const {coverId, simple, expanded} = this.metadata;
        const {inputValue, preSelectedValue} = this.props.formData;

        if (!simple) {
            const currentOption = preSelectedValue ? this.getCoverOptionByValue(preSelectedValue) : this.getCoverOptionByValue(inputValue!);
            this.setState({
                expanded: expanded,
                values: coverOptions[coverId],
                showValue: !!inputValue,
                value: !isNil(inputValue) && currentOption ? {
                    label: currentOption.title,
                    value: (currentOption.id).toString()
                } : undefined
            });
        } else {
            this.setState({expanded: expanded});
        }
    }


    private getPopOverConfig(cmsContext: ICmsContext, addOnId: string): IPopOverConfig {
        const popOverConfig = {isPopOverDisplayed: false} as IPopOverConfig;
        const prefix = CmsHelper.getPrefix(cmsContext)

        const winnerPopOverText = this.props.intl.messages[`${prefix}.coverPlans.winner.popOver.${addOnId}`] as string;
        if (winnerPopOverText) {
            popOverConfig.isPopOverDisplayed = true;
            popOverConfig.id = addOnId;
            popOverConfig.text = winnerPopOverText;
        }

        return popOverConfig;
    }

    public render() {
        const {viewContext, intl, formData} = this.props;
        const isMobile = viewContext.isMobile;
        const {coverIdPath, coverId, addOnId, breakpoints, simple, withIcon, expandableList, disabledMessage, disabled, prefix} = this.metadata;
        const currentOption = formData?.inputValue && this.getCoverOptionByValue(formData?.inputValue);
        const showValue = !!formData?.inputValue;
        const disabledForm = this.context.disableButton;
        const coverIdCalc = coverIdPath ? get(this.context.data, coverIdPath, coverId) : coverId;

        let accordionCurrentOption;
        let value: any;

        if (!simple) {
            accordionCurrentOption = formData?.preSelectedValue ? this.getCoverOptionByValue(formData?.preSelectedValue) : this.getCoverOptionByValue(formData?.inputValue!);
            value = !isNil(formData?.inputValue) && accordionCurrentOption ? {
                label: accordionCurrentOption.title,
                value: (accordionCurrentOption.id).toString()
            } : undefined;
        }

        const messagePremium: number = (simple ? formData?.monthlyPremium : formData.previousMonthlyPremium ? formData?.previousMonthlyPremium : formData?.monthlyPremium) || 0;
        const priceMessage = <FormattedMessage
            id={`${!simple && !formData?.selected ? "general.addon.price.from.title" : "general.addon.price.title"}`}
            values={{monthlyPremium: intl.formatNumber(Math.round(messagePremium))}}/>;

        const styles = {
            div: {
                width: '100%',
                minHeight: '63px'
            },
            buttonWrapper: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 10px 15px'
            },
            span: {
                color: defaultTheme.colors.primary1,
                fontWeight: defaultTheme.fontWeights.semiBold,
                fontSize: defaultTheme.textSizes.base,
                fontFamily: defaultTheme.fonts.serif
            },
            iconPlus: {
                paddingRight: defaultTheme.padding[2],
                height: 20,
                width: 20
            }
        };

        const evaluate = !!simple ? false : formData.selected || !formData.isModalOpen;
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const popoverConfig = this.getPopOverConfig(cmsContext, addOnId);
                    return (
                    <FormFieldLayout {...this.getLayoutProps()} breakpoints={this.getBreakpoints(breakpoints)}>
                        <AddonAccordionFUI
                            id={coverIdCalc}
                            withIcon={withIcon}
                            badge={
                                popoverConfig.isPopOverDisplayed && <Badge
                                        bookmark
                                        type="offer"
                                        disableAnimation={true}
                                        icon={<DisplayIcon medium icon={<IconWinner />} />}
                                        text={popoverConfig.text}
                                        halfWidth
                                    />
                            }
                            heading={intl.formatMessage({
                                id: CmsHelper.withPrefixOrId({cmsContext, id: `${addOnId}.heading`, prefix})
                            })}
                            subheading={intl.formatMessage({
                                id: CmsHelper.withPrefixOrId({cmsContext, id: `${addOnId}.headingHelp`, prefix})
                            })}
                            description={!expandableList ?
                                <div style={{fontFamily: defaultTheme.fonts.mono}}>
                                    <FormattedMarkdown messageKey={CmsHelper.withPrefixOrId({
                                        cmsContext,
                                        id: `${addOnId}.headingHelpExpand`,
                                        prefix
                                    })}/>
                                </div>
                                : undefined }
                            expandableList={expandableList ? this.getExpandableList(expandableList) : undefined}
                            disabled={formData?.disabled || (disabled || disabledForm)}
                            expanded={this.state.expanded}
                            price={priceMessage}
                            buttonText={{
                                evaluate: <FormattedMessage
                                    id={"general.button.product.evaluate.text"}/>,
                                add: <FormattedMessage
                                    id={"general.button.product.add.text"}/>,
                                added: <FormattedMessage
                                    id={"general.button.product.added.text"}/>,
                                disabled: <FormattedMessage
                                    id={disabledMessage ? disabledMessage : formData && formData?.selected ? "general.button.product.added.text" : "general.button.product.add.text"}/>,
                            }}
                            evaluate={!!simple ? undefined : formData.selected || !formData.isModalOpen}
                            evaluateToggle={this.openModal}
                            showValue={showValue}
                            valueText={!simple && intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `cover.showValue.title`)})}
                            value={value?.label}
                            checked={formData && formData?.selected}
                            checkedToggle={this.checkedToggle}
                            onExpandedToggle={this.onExpandedToggle}
                        >
                            {!simple && <Modal
                                noOverFlowY={true}
                                showCloseButton={true}
                                shouldCloseOnOverlayClick={true}
                                isOpen={formData.isModalOpen}
                                onRequestClose={this.closeModal}
                            >
                                <Modal.Header onModalClose={this.closeModal} showCloseButton>
                                    {intl.formatMessage({
                                        id: CmsHelper.withPrefixOrId({
                                            cmsContext,
                                            id: `${addOnId}.modal.heading`,
                                            prefix
                                        })
                                    })}
                                </Modal.Header>
                                <Modal.Body>
                                    <Grid>
                                        <Grid.Row style={{paddingBottom: '10px'}}>
                                            <Grid.Col
                                                xs={true}>{intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `${addOnId}.modal.description`)})}</Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Col xs={true} style={{paddingBottom: '10px'}}>
                                                {<Markdown
                                                    source={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `cover.modal.inputSelect.title`)})}/>}
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row style={{paddingBottom: '18px'}}>
                                            <Grid.Col xs={true}>
                                                <FormInputSelect
                                                    position={isMobile && 'relative'}
                                                    id={`${coverIdCalc}Input`}
                                                    square={true}
                                                    selected={formData.inputValue ? {
                                                        label: currentOption.title,
                                                        value: (currentOption.id).toString()
                                                    } : undefined}
                                                    onChange={this.onInputChange}
                                                    full={true}
                                                    options={this.inputOptions}
                                                    placeholder={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `cover.modal.inputSelect.placeholder`)})}
                                                />
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Col xs={true}>
                                                {formData.inputValue && !formData.loading ?
                                                    <div style={styles.buttonWrapper}>
                                                        <span style={styles.span}>
                                                            <FormattedMessage
                                                                id={"general.addon.price.title"}
                                                                values={{monthlyPremium: intl.formatNumber(Math.round(this.props.formData?.monthlyPremium || 0))}}/>
                                                        </span>
                                                        <Button
                                                            as={'div'}
                                                            id={`${coverIdCalc}ModalButton`}
                                                            small
                                                            outline={true}
                                                            onClick={this.addValue}
                                                            checked={this.state.showValue}
                                                        >
                                                            <>
                                                                <IconPlus style={styles.iconPlus}/>
                                                                <B>{intl.formatMessage({id: CmsHelper.withGeneralPrefix(`button.product.add.text`)})}</B>
                                                            </>
                                                        </Button>
                                                    </div>
                                                    : !formData.inputValue ? <div style={styles.div}/>
                                                        : <div style={styles.div}><BannerSkeleton theme={3} sm/></div>
                                                }
                                            </Grid.Col>
                                        </Grid.Row>
                                    </Grid>
                                </Modal.Body>
                            </Modal>}
                            {evaluate && showValue && <AddonAccordionFUI.Body
                                disabled={formData?.disabled || disabled}
                                onClick={this.openModal}
                                valueText={!simple && intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `cover.showValue.title`)})}
                                value={value?.label}
                                editButtonText={intl.formatMessage({id: CmsHelper.withGeneralPrefix(`accordionPanel.button.edit`)})}
                            />}
                            <AddonAccordionFUI.Footer
                                showValue={showValue}
                                checked={formData.selected}
                                checkedToggle={this.checkedToggle}
                                price={priceMessage}
                                buttonText={{
                                    evaluate: <FormattedMessage
                                        id={"general.button.product.evaluate.text"}/>,
                                    add: <FormattedMessage
                                        id={"general.button.product.add.text"}/>,
                                    added: <FormattedMessage
                                        id={"general.button.product.added.text"}/>,
                                    disabled: <FormattedMessage
                                        id={disabledMessage ? disabledMessage : formData && formData?.selected ? "general.button.product.added.text" : "general.button.product.add.text"}/>,
                                }}
                                evaluate={!!simple ? undefined : formData.selected || !formData.isModalOpen}
                                evaluateToggle={this.openModal}
                            />
                        </AddonAccordionFUI>
                    </FormFieldLayout>
                )}}
            </CmsContext.Consumer>
        );
    }

    public onExpandedToggle() {
        this.setState({expanded: !this.state.expanded});
    }

    private onInputChange(event: any) {
        const {values} = this.state;
        const newData = cloneDeep(this.props.formData);

        if (values && event?.value && event?.value !== newData?.inputValue) {
            newData.loading = true;
        }

        if (event?.value !== newData?.inputValue) {
            newData.inputValue = event?.value && sanitizeField(event.value);
            this.props.onChange(newData);
        }
    }

    private get inputOptions() {
        if (this.state.values) {
            return this.state.values.map((value) => {
                return {
                    label: value.title,
                    value: (value?.id || "").toString()
                };
            });
        }
        return undefined;
    }

    private openModal() {
        const newData = cloneDeep(this.props.formData);
        newData.isModalOpen = true;
        newData.preSelectedValue = newData?.inputValue;
        newData.previousMonthlyPremium = newData?.monthlyPremium;

        this.props.onChange(newData);
    }

    private closeModal() {
        const newData = this.props.formData;
        newData.isModalOpen = false;
        newData.loading = false;
        newData.inputValue = !isNil(newData.preSelectedValue) ? newData.preSelectedValue : "";
        newData.preSelectedValue = "";
        newData.monthlyPremium = !isNil(newData.previousMonthlyPremium) ? newData.previousMonthlyPremium : newData.monthlyPremium;
        newData.previousMonthlyPremium = null;
        this.props.onChange(newData);
    }

    private addValue() {
        const newData = this.props.formData;
        newData.isModalOpen = false;
        newData.loading = false;
        newData.selected = true;
        newData.preSelectedValue = "";
        newData.previousMonthlyPremium = null;
        this.props.onChange(newData);
    }

    private async checkedToggle(): Promise<void> {
        const newData = this.props.formData;
        const {simple} = this.metadata;

        if (!simple) {
            newData.selected = false;
            newData.inputValue = "";
            newData.isModalOpen = false;
            newData.preSelectedValue = "";
            newData.previousMonthlyPremium = null;
        } else {
            newData.selected = !newData.selected;
        }
        this.props.onChange(newData);
    }

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        const defaultBreakpoints: IBreakPoint = {
            [Breakpoints.md]: 12,
            [Breakpoints.lg]: 12
        };
        return {
            ...defaultBreakpoints,
            ...breakpoints
        };
    }

    private getExpandableList(expandableList: any) {
        return expandableList.map((listItem: any) => {
            return {
                name: this.props.intl.formatMessage({id: listItem.title}),
                content: <Markdown source={this.props.intl.formatMessage({id: listItem.content})}/>
            };
        });
    }

    private getCoverOptionByValue(value: string | number) {
        const {coverOptions} = this.context.data?.policy?.premium;
        if (coverOptions) {
            const {coverId} = this.metadata;
            return coverOptions && coverOptions[coverId]?.find((option: IOption) => (option.id).toString() === value);
        }
    }
}

const AddonAccordion = injectIntl(withViewContext(AddonAccordionInternal));

export {AddonAccordion};
