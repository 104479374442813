import React from "react";
import {createUseStyles} from "react-jss";
import classNames from 'classnames';
import {Button, P, defaultTheme as theme} from "@folksam-digital/ui";
import {useIntl} from "react-intl";

const useStyles = createUseStyles(() => ({
    block: {
        position: "relative",
        backgroundColor: theme.colors.white
    },
    readMore: {
        position: "absolute",
        bottom: 0,
        height: 108,
        left: 0,
        width: theme.width.full,
        textAlign: "center",
        marginLeft: `-${theme.margin[5]}`,
        paddingLeft: theme.padding[5],
        paddingRight: theme.padding[5],
        backgroundImage: `-webkit-gradient(linear,left top,left bottom,color-stop(0, transparent),color-stop(1, ${theme.colors.senary4}))`,
        display: 'flex',
        flexDirection: "column",
        justifyContent: "flex-end"
    },
    readLess: {
        position: 'absolute',
        bottom: `-41px`
    },
    readLessContainer:{
        display: 'flex',
        justifyContent: 'center'
    },
    buttonContainer: {
        textAlign: "center",
        lineHeight: theme.leading.normal
    },
}));

const incrementToVisibleRowCount = ({rowLimit, properties}: any) => {
    if (properties.length < rowLimit) {
        return properties.length;
    }

    for (let i = 0; i < rowLimit; i++) {
        if (properties[i]?.content?.props?.uiSchema?.['ui:widget'] === "hidden") {
            if (rowLimit < properties.length) {
                rowLimit++;
            }
        }
    }

    return rowLimit;
};

export const DescriptionReveal = ({rowLimit = 3, properties, readMore, readMoreToggle, renderComponents = false}: any) => {
    const classes = useStyles();
    const intl = useIntl();

    const linkName = readMore ? intl.formatMessage({id: "general.readLess"}) : intl.formatMessage({id: "general.readMore"});
    const visibleRows = incrementToVisibleRowCount({rowLimit, properties});
    const items = properties.slice(0, visibleRows);
    const additionalItems = properties.slice(visibleRows, properties.length);
    const filteredAdditionalItems = additionalItems.filter((p: any) => p?.content?.props?.uiSchema?.['ui:widget'] !== "hidden");

    return (
        <div className={classes.block}>
            {items.map((p: any) => renderComponents ? p : p.content)}
            {readMore && filteredAdditionalItems.map((p: any) => renderComponents ? p : p.content)}
            {!readMore && filteredAdditionalItems.slice(0, 1).map((p: any) => renderComponents ? p : p.content)}
            {filteredAdditionalItems.length > 1 &&
            <section className={classNames({
                [classes.readLessContainer]: readMore
            })}>
                <div className={classNames({
                    [classes.readMore]: !readMore,
                    [classes.readLess]: readMore
                })}>
                    <div className={classes.buttonContainer}>
                        <Button asLink={true} type="button" onClick={readMoreToggle}>
                            <P>{linkName}</P>
                        </Button>
                    </div>
                </div>
            </section>
            }
        </div>
    );
};
