import React from "react";

// @ToDo - removed|renamed, when theme is finalized and used in place of defaultTheme or as defaultTheme
import {defaultTheme} from "@folksam-digital/ui";

interface IComponentBackgroundWrapperProps {
    children?: React.ReactElement | React.ReactElement[];
    theme?: number;
    noPaddingBottom?: boolean;
}

export class ComponentBackgroundWrapper extends React.Component<IComponentBackgroundWrapperProps> {
    public render() {
        return (
            <div
                style={ComponentBackgroundWrapper.getThemeStyle(this.props.theme || 0, this.props.noPaddingBottom)}>{this.props.children}</div>
        );
    }

    private static getThemeStyle(theme: number, noPaddingBottom?: boolean): null | any {
        const themes: { [key: number]: { [key: string]: string | null } } = {
            0: {background: null},
            1: {background: defaultTheme.colors.senary4},
            2: {background: defaultTheme.colors.white},
            3: {background: defaultTheme.colors.primary5}
        };

        // simplified in container to keep space for last step so that e.g. price bar do have a scroll have space in review page. Spacing shouldn't be included in every review page schema.
        return {backgroundColor: themes[theme].background, paddingBottom: noPaddingBottom ? null : '5rem'};
    }
}
