import isEmpty from "lodash/isEmpty";

export function isValue(value: string): boolean {
    return !isEmpty(value);
}

export function isValidFormat(value: string, pattern: string): boolean {
    const regex = new RegExp(pattern || "");
    return regex.test(value || "");
}

export function isValidMinMaxValue(value: string, minimum: number, maximum: number): boolean {
    return Number(value) >= Number(minimum) && Number(value) <= Number(maximum);
}

export function doValuesMatch(valueA: string, valueB: string) {
    return Number(valueA) === Number(valueB);
}
