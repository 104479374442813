import {useEffect} from "react";
import {SatelliteEvents, SatelliteEventTransactionTypes} from "../../analytics/new/BaseAnalytics";
import {ICmsContext} from "../../cms";

export function useTriggerDataLayerEvent(cmsContext: ICmsContext, onTriggerDataLayerEvent: (data: any) => void, formData: any, journeyId: any) {
    useEffect(() => {
        if (onTriggerDataLayerEvent && formData) {
            onTriggerDataLayerEvent({
                messages: cmsContext.catalog.messages,
                data: formData,
                journeyId: journeyId,
                currentStep: "success",
                transactionType: SatelliteEventTransactionTypes.TransactionPage,
                event: SatelliteEvents.Complete
            });
        }
    }, [formData, onTriggerDataLayerEvent, cmsContext.catalog.messages, journeyId]);
}
