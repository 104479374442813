import {AxiosInstance} from "axios";
import {RestClientBase} from "../RestClientBase";

export interface IDigitalApiServiceConfig {
    baseUrl: string;
    baseProtectedUrl: string;
}

export abstract class DigitalApiServiceBase extends RestClientBase {
    private readonly config: IDigitalApiServiceConfig;

    constructor(config: IDigitalApiServiceConfig) {
        super();

        this.config = config;
    }

    protected getHttpClient(path: string): AxiosInstance {
        if(path.indexOf("/protected/") > -1) {
            return super.create({
                baseURL: this.config.baseProtectedUrl || this.config.baseUrl
            });
        }
        else {
            return super.create({
                baseURL: this.config.baseUrl
            });
        }
    }
}
