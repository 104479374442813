import {IStorage} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";

const clearSessionStorage = (): void => {
    const sessionStorage: IStorage = container.get<IStorage>(Types.SessionStorage);
    sessionStorage.clear();
};

export {clearSessionStorage};
