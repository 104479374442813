import * as React from "react";
import {Button, Modal} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {FormattedMessage} from "react-intl";
import {MarkdownListTypes} from "@folksam-digital/model";

interface IProps {
    style?: React.CSSProperties;
    linkText: string;
    modalHeader?: string;
    modalContent?: string;
    listType?: MarkdownListTypes;
}

export const ModalLink: React.FC<IProps> = ({style, linkText, modalHeader, modalContent, listType}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleModalOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event?.preventDefault();
            event?.stopPropagation();
            setIsOpen(!isOpen);
        },
        [isOpen, setIsOpen]
    );

    const handleModalClose = React.useCallback(() => {
        setIsOpen(false);
    },[setIsOpen]);

    return (<>
        {linkText &&
        <Button style={{textAlign: "start", ...style}} type="button" asLink={true} onClick={handleModalOpen}>
            <FormattedMarkdown messageKey={linkText}/>
        </Button>}
        <Modal showCloseButton onRequestClose={handleModalClose} isOpen={isOpen}>
            {modalHeader && <Modal.Header onModalClose={handleModalClose}>
                <FormattedMessage id={modalHeader}/>
            </Modal.Header>}
            {modalContent && <Modal.Body>
                <FormattedMarkdown messageKey={modalContent} listType={listType}/>
            </Modal.Body>}
        </Modal>
    </>)
};
