/// <reference types="googlemaps" />

import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import { FormFieldLayout } from "../../../FormFieldLayout";
import {
    AutoComplete,
    IconCaretLeft,
    withViewContext,
    defaultTheme as theme,
    Modal,
    Grid,
    Button,
    P,
} from "@folksam-digital/ui";
import * as React from "react";
import { IAutocompletionRequestType } from "./helpers/autocompletionRequestBuilder";
import flowRight from "lodash/flowRight";
import withGoogleMaps from "../withGoogleMaps";
import {injectIntl} from "react-intl";
import sanitizeField from "./helpers/sanitizeField";
import {Map} from "./googleMaps/map";
import {Marker} from "./googleMaps/marker";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CustomControl} from "./googleMaps/customControl";
import {InfoWindow} from "./googleMaps/infoWindow";
import {TargetIcon} from "./googleMaps/targetIcon";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {
    fetchSuggestions,
    fetchSuggestionsCallback,
    getPlacePredictions,
    IFetchSuggestionsCallback, IFetchSuggestionsFn, IGetPlacePredictions,
    initializeService,
    retryInitialize,
    initializeServiceCallBack
} from "./googleAutocomplete/autocompleteHelper";
import debounce from "lodash/debounce";
import {IInputRendererFn, inputRenderer} from "./googleAutocomplete/inputRenderer";
import {SuggestionsRenderer} from "./googleAutocomplete/SuggestionsRenderer";
import {ISetPlace, ISetPlaceValueCallback, setPlace, setPlaceValueCallback, renewSessionToken, valuePlaceCallBack, IValuePlaceCallBack} from "./googlePlaces/placesHelper";

export interface IGooglePlacesAutoCompleteProps {
    children: React.ReactNode;
    autocompletionRequest: IAutocompletionRequestType;
    onSelect: (value: any) => {};
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    idPrefix: string;
}

export interface IGooglePlaceInputState {
    isLoading: boolean;
    autocompleteOK: string | null;
    sessionToken?: google.maps.places.AutocompleteSessionToken;
    suggestions?: google.maps.places.AutocompletePrediction[];
    geometry?: google.maps.places.PlaceGeometry | { location: google.maps.LatLng | google.maps.LatLngLiteral };
    isModalOpen?: boolean;
    withPlaces?: boolean;
    infoContent?: string;
}

export interface IGooglePlacesAutoCompleteMeta {
    autocompletionRequest: Omit<google.maps.places.AutocompletionRequest, "input">;
    breakpoints?: any;
    debounce?: number;
    placesRequest?: Omit<google.maps.places.PlaceDetailsRequest, "placeId">;
    placeholder?: string;
    helpText?: string;
    help?: string;
    googleMapOptions?: {
        defaultCenterLatLng: google.maps.LatLngLiteral;
        initialZoom: number;
        geocoderRequest: google.maps.GeocoderRequest;
        modal:{
            header: string,
            button: string
        }
    };
}

const defaultGeocoderRequest = {};

class GooglePlacesAutoCompleteInternal extends InputComponentBase<string, IGooglePlacesAutoCompleteMeta, IGooglePlaceInputState, IGooglePlacesAutoCompleteProps & IInputComponentProps<any, any>> {
    public ref: React.RefObject<HTMLInputElement>;
    public divRef: React.RefObject<HTMLDivElement>;
    private autocompleteService: google.maps.places.AutocompleteService;
    private placesService: google.maps.places.PlacesService;
    private geocoder: google.maps.Geocoder;
    private readonly retryInitialize: () => NodeJS.Timeout;
    private readonly initializeService: () => void;
    private readonly initializeServiceCallBack: () => void;
    private readonly valuePlaceCallBack: IValuePlaceCallBack;
    private readonly fetchSuggestions: IFetchSuggestionsFn;
    private readonly getPlacePredictions: IGetPlacePredictions;
    private readonly fetchSuggestionsCallback: IFetchSuggestionsCallback;
    private readonly inputRenderer: IInputRendererFn;
    private readonly setPlace: ISetPlace;
    private readonly setPlaceValueCallback: ISetPlaceValueCallback;
    private readonly renewSessionToken: () => void;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            autocompleteOK: null,
            suggestions: [],
        };
        this.ref = React.createRef();
        this.divRef = React.createRef();

        this.retryInitialize = retryInitialize.bind(this);
        this.initializeService = initializeService.bind(this);
        this.initializeServiceCallBack = initializeServiceCallBack.bind(this);
        this.valuePlaceCallBack = valuePlaceCallBack.bind(this);
        this.fetchSuggestions = debounce(fetchSuggestions.bind(this), this.metadata.debounce || 300);
        this.getPlacePredictions = getPlacePredictions.bind(this);
        this.fetchSuggestionsCallback = fetchSuggestionsCallback.bind(this);
        this.inputRenderer = inputRenderer.bind(this);
        this.setPlace = setPlace.bind(this);
        this.setPlaceValueCallback = setPlaceValueCallback.bind(this);
        this.renewSessionToken = renewSessionToken.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalCloseWithValidation = this.handleModalCloseWithValidation.bind(this);
        this.handleGoogleMapClick = this.handleGoogleMapClick.bind(this);
        this.geocodeRequest = this.geocodeRequest.bind(this);
        this.geocoderResultCallBack = this.geocoderResultCallBack.bind(this);
        this.handleInfoContent = this.handleInfoContent.bind(this);
        this.handleGoogleMapIconCallback = this.handleGoogleMapIconCallback.bind(this);
        this.handleLocateControlClick = this.handleLocateControlClick.bind(this);
        this.handleNavigatorLocationSuccessCallBack = this.handleNavigatorLocationSuccessCallBack.bind(this);
        this.handleNavigatorLocationErrorCallBack = this.handleNavigatorLocationErrorCallBack.bind(this);
    }

    componentDidMount() {
        this.initializeService();
    }

    changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({isLoading: false});

        if (event.target.value.length > 0) {
            this.fetchSuggestions(event.target.value);
        }

        this.props.onChange(sanitizeField(event.target.value));
    };

    handleBlur = (event: React.FocusEvent<any>) => {
        // @ToDo possible improvement, when googleMapOptions enabled
        // refactor and improve clarified and repeated map behavior on stst
        // 1) input value on blur for place_id isn't checked(improvement: is checked)
        // 2) marker stays on previous entered place_id coords(improvement: could be unset)
        this.onChangeWithValidation(event.target.value);
    };

    handleResultSelect = (record: google.maps.places.AutocompletePrediction, event: React.FocusEvent<Element>) => {
        if (this.metadata.googleMapOptions) {
            this.setState({withPlaces: false, infoContent: undefined}, () => this.setPlace(record));
        } else {
            this.setPlace(record)
        }

        this.onBlurWithValidation(event, this.props.formData);
    };

    private geocodeRequest(cords: google.maps.LatLng | google.maps.LatLngLiteral) {
        const geocodeRequest = this.metadata.googleMapOptions?.geocoderRequest || defaultGeocoderRequest as google.maps.GeocoderRequest;
        geocodeRequest.location = cords;
        this.geocoder.geocode(geocodeRequest, this.geocoderResultCallBack);
    }

    private handleGoogleMapClick(event: google.maps.MouseEvent) {
        if(event.latLng){
            this.geocodeRequest(event.latLng);
            this.setState({geometry: {location: event.latLng}, withPlaces: false, infoContent: undefined});
        }
    }

    geocoderResultCallBack(record: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
        if (status === window.google.maps.GeocoderStatus.OK) {
            const firstFoundPlace = record.find(result => result.formatted_address && result.place_id && result.geometry);
            if(firstFoundPlace){
                const value = sanitizeField(firstFoundPlace.formatted_address);
                this.onChangeWithValidation(value);
            }
        }
    }

    private getLocation(): undefined | google.maps.LatLng | google.maps.LatLngLiteral {
        const {geometry} = this.state;
        return geometry?.location
    }

    public render() {
        const {formData, name, viewContext, intl} = this.props;
        const {breakpoints, placeholder, googleMapOptions, helpText, help} = this.metadata;

        const autoCompleteWithSuggestions = (withHelpText: boolean) =>
            <FormFieldLayout {...this.getLayoutProps()}
                             breakpoints={getBreakpoints(defaultBreakpoints.googleSearch, breakpoints)}
                             title={(googleMapOptions && this.state.isModalOpen) ? undefined : this.props.schema.title}
                             help={(googleMapOptions && this.state.isModalOpen) ? undefined : help}
                             style={(googleMapOptions && viewContext.isMobile && this.state.isModalOpen) ? {marginLeft:21, marginRight:20} : undefined}
            >
            {helpText && withHelpText ? <P><FormattedMarkdown messageKey={helpText}/></P> : <></> }
            <AutoComplete
                ref={this.ref}
                loading={this.state.isLoading}
                onSuggestionSelect={this.handleResultSelect}
                suggestions={this.state.suggestions}
                renderSuggestions={SuggestionsRenderer}
                onChange={this.changeValue}
                renderInput={this.inputRenderer}
                value={formData}
                placeholder={placeholder && intl.formatMessage({id: placeholder})}
                idPrefix={name}
                invalid={this.isInvalid()}
            />
            <div ref={this.divRef}/>
        </FormFieldLayout>;
        const googleMapHeight = googleMapOptions && viewContext.isMobile ? "100%" : "550px";
        const googleMap = googleMapOptions
            ? <Map
                style={{flex: "1", height: googleMapHeight, width: "1050px"}}
                defaultCenterLatLng={this.state.withPlaces === undefined && this.state.geometry ? this.getLocation() : googleMapOptions?.defaultCenterLatLng}
                initialZoom={googleMapOptions?.initialZoom}
                fullscreenControl={!viewContext.isMobile}
                streetViewControl={false}
                withMount={this.state.withPlaces === undefined}
                withPlaces={this.state.withPlaces}
                withPlacesLatLng={this.state.withPlaces ? this.state.geometry && this.state.geometry.location : undefined}
                onClick={this.handleGoogleMapClick}
            >
                <Marker key={"marker"} position={this.getLocation()} visible={!!this.getLocation()}/>
                <CustomControl position={google.maps.ControlPosition.RIGHT_BOTTOM}>
                    <div onClick={this.handleLocateControlClick}
                         style={{
                             backgroundColor: theme.colors.white,
                             borderRadius: '50%',
                             marginRight: '10px',
                             width: '40px',
                             height: '42px',
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             cursor: 'pointer'
                         }}>
                        <TargetIcon/>
                    </div>
                </CustomControl>
                <InfoWindow content={this.state.infoContent} setInfoContent={this.handleInfoContent}/>
            </Map>
            : <></>;
        const googleMapLargeScreenWrapper = googleMapOptions
            && <Grid.Row style={{marginTop: 30, marginLeft: 1}}>
                {googleMap}
            </Grid.Row>;
        const googleMapMobileScreenWrapper = googleMapOptions
            && <Modal showCloseButton={false}
                      withSiteHeader={false}
                      fullScreen={true}
                      shouldCloseOnOverlayClick={false}
                      isOpen={!!this.state.isModalOpen}
                      style={{
                          content: {
                              maxWidth: "815px",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              padding: "0px"
                          },
                          overlay: {
                              zIndex: "110000",
                          },
                      }}
            >
                <>
                    <Grid.Row style={{cursor: "pointer"}}>
                        <Grid.Col onClick={this.handleModalClose}><IconCaretLeft style={{marginLeft: 21, marginTop: 23, marginBottom: 19}} fill={theme.colors.link} width={18} height={18}/></Grid.Col>
                        <Grid.Col style={{
                            marginTop: 19,
                            verticalAlign: 'center',
                            textAlign: 'center',
                            color: theme.colors.primary2,
                            fontWeight: theme.fontWeights.semiBold,
                            fontSize: theme.textSizes['5'],
                            flex: 1
                        }}>
                            {googleMapOptions?.modal && googleMapOptions.modal.header && intl.formatMessage({id: googleMapOptions.modal.header})}
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row style={{flex: 1, marginLeft:1, marginRight:1, marginBottom:14, marginTop: 4, borderTop: `solid 2px ${theme.colors.quinary1}`}}>
                        {googleMap}
                    </Grid.Row>
                    {autoCompleteWithSuggestions(false)}
                    <Grid.Row style={{marginLeft:21, marginRight:20, marginBottom: 46, marginTop: 7}}>
                        <Button
                            id={`modalButton`}
                            type="button"
                            disabled={this.props.disabled}
                            onClick={this.handleModalCloseWithValidation}
                            primary={true}
                            full={viewContext.isMobile}
                        >
                            {googleMapOptions?.modal && googleMapOptions.modal.header && intl.formatMessage({id: googleMapOptions.modal.button})}
                        </Button>
                    </Grid.Row>
                </>
            </Modal>;

        return (
            <>
                {autoCompleteWithSuggestions(true)}
                {googleMapOptions && !viewContext.isMobile && googleMapLargeScreenWrapper}
                {googleMapOptions && viewContext.isMobile && googleMapMobileScreenWrapper}
            </>
        );
    }

    private handleModalClose() {
        this.setState({ isModalOpen: false });
    }

    private handleModalCloseWithValidation(event: React.FocusEvent<Element>) {
        const {formData} = this.props;
        if (formData !== "") {
            this.setState({isModalOpen: false});
        }
        this.onBlurWithValidation(event, this.props.formData)
    }

    private handleGoogleMapIconCallback() {
        this.setState({
            isModalOpen: true,
            withPlaces: this.props.viewContext.isMobile && this.state.geometry ? true : this.state.withPlaces
        });
    }

    private handleLocateControlClick() {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.handleNavigatorLocationSuccessCallBack,
                () => {
                    this.handleNavigatorLocationErrorCallBack(true);
                });
        } else {
            this.handleNavigatorLocationErrorCallBack(false);
        }
    }

    private handleNavigatorLocationSuccessCallBack(position: GeolocationPosition) {
        const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        };

        this.geocodeRequest(pos);
        this.setState({geometry: {location: pos}, withPlaces: true, infoContent: undefined});
    }

    private handleNavigatorLocationErrorCallBack(hasGeolocation: boolean) {
        const {intl} = this.props;

        if (hasGeolocation) {
            this.handleInfoContent(intl.formatMessage({id: "general.googleMap.geoService.fail.error"}));
        } else {
            this.handleInfoContent(intl.formatMessage({id: "general.googleMap.geoService.notSupported.error"}));
        }
    }

    private handleInfoContent(content?: string) {
        this.setState({infoContent: content})
    }
}

const GooglePlacesAutoComplete = flowRight(withGoogleMaps, injectIntl, withViewContext)(GooglePlacesAutoCompleteInternal);

export { GooglePlacesAutoComplete };
