import {injectable} from "inversify";
import {IError} from "./ValidatorBase";
import {validateSsn} from "../Helpers/validation/isValidSsn";
import {TPossibleString} from "./DirectDebitValidator";
import forIn from "lodash/forIn";
import merge from "lodash/merge";
import get from "lodash/get";
import {IHomeData} from "../components/journey/layout/contentHeader/ContentHeaderHomeFields";
import {REGEXP} from "@folksam-digital/model";

interface IHomeErrorMsg {
    errorMessage: string;
    regex?: {
        pattern: string,
        errorMessage: string
    }
}

interface IHomeValidation {
    required?: IHomeErrorMsg;
    street_number?: IHomeErrorMsg;
    postal_code?: IHomeErrorMsg;
}

@injectable()
export class ContentHeaderHomeFieldValidator {
    protected readonly validations: { [key: string]: IHomeValidation } = {
        "ssn": {
            "required": {
                "errorMessage": "general.form.error.required",
            }
        },
        "addressSearchable": {
            "required": {
                "errorMessage": "general.form.error.required",
            }
        },
        "address_components": {
            "required": {
                "errorMessage": "general.form.address.error.notValid",
            },
            "street_number": {
                "errorMessage": "general.form.address.error.streetNumber",
            },
            "postal_code": {
                "errorMessage": "general.form.address.error.postalTown",
                "regex": {
                    "pattern": REGEXP.SWEDISH_ZIP_CODE,
                    "errorMessage": "general.form.error.zipCode.invalidCode",
                }
            },
        }
    };

    public validate(data: IHomeData): IError {
        let errors: { [key: string]: any } = {};

        forIn(this.validations, (value: IHomeValidation, key: string) => {
            const dataValue = get(data, key);
            errors = merge({}, errors, this.validateField(key, data, dataValue));
        });

        return errors;
    }

    public validateField(formField: string, data: IHomeData, fieldValue: any): IError {
        const errors: IError = {};
        const validation: IHomeValidation = this.validations[formField];

        if (!!validation?.required) {
            if (!fieldValue) {
                const {errorMessage} = validation.required;
                errors[formField] = errorMessage;
            }
        }

        if (fieldValue && formField === "addressSearchable" && data && !data.address_components) {
            const {errorMessage} = this.validations["address_components"].required!;
            errors[formField] = errorMessage;
        }

        if (fieldValue && formField === "addressSearchable" && data && data.address_components) {
            if (!data.address_components.find((el: any) => el.types.includes("street_number"))) {
                const {errorMessage} = this.validations["address_components"].street_number!;
                errors[formField] = errorMessage;
            } else if (!data.address_components.find((el: any) => el.types.includes("postal_code"))) {
                const {errorMessage} = this.validations["address_components"].postal_code!;
                errors[formField] = errorMessage;
            } else if (data.address_components.find((el: any) => el.types.includes("postal_code"))) {
                const {errorMessage, pattern} = this.validations["address_components"].postal_code!.regex!;
                // postalCode number range
                const value = data.address_components.find((el: any) => el.types.includes("postal_code"))!.long_name;
                if (!value.match(pattern) && (Number(value.replace(" ", "")) < 11115 || Number(value.replace(" ", "")) > 98499)) {
                    errors[formField] = errorMessage!;
                }
            }
        }

        if (formField === "ssn") {
            const ssnError = validateSsn(fieldValue as TPossibleString, "general.personSsnInput.error.format");
            if (ssnError) {
                errors[formField] = ssnError;
            }
        }

        return errors;
    }
}
