import * as React from "react";
import {DescriptionList} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import {SelectedProductRow} from "../SelectedProductRow";
import {PolicySearchResult} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../../../../cms";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";

interface IPolicyListGroupProps {
    offers: PolicySearchResult[];
}

export default class OfferList extends React.Component<IPolicyListGroupProps> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const {offers} = this.props;

        if (!offers || offers.length === 0) {
            return (
                <DescriptionList.Row separated={true}>
                    <DescriptionList.Content>
                        <DescriptionList.Term>
                            <FormattedMessage
                                id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.noItemsSelected")}/>
                        </DescriptionList.Term>
                    </DescriptionList.Content>

                </DescriptionList.Row>
            );
        }

        return (
            offers.map((offer, idx) => (
                <SelectedProductRow key={idx} offer={offer}/>
            ))
        );
    }
};
