import * as React from "react";
import {BannerPanel} from "../../../../components/journey/form";
import {ICmsContext} from "../../../../cms";
import {Constants, DirectDebit, GroupInsurance, GroupOptInOptOut} from "@folksam-digital/model/lib";
import {IDraftService, StorageKeys} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {Headings, Spacing, IconBankID, DisplayIcon, Button} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import {UrlHelper} from "@folksam-digital/services";

interface IDirectDebitButtonProps {
    cmsContext: ICmsContext;
    journeyData: GroupOptInOptOut | GroupInsurance;
    successfulPolicyCreation: boolean;
    history: any;
    displayBankIdIcon?: boolean;
    stepBack?: boolean;
    noBanner?: boolean;
    translations?: {
        directDebitButtonLabel?: string;
        directDebitButtonHeader?: string;
        directDebitButtonSubHeader?: string;
    };
}

export class DirectDebitButton extends React.Component<IDirectDebitButtonProps, {}> {
    private draftService: IDraftService;

    constructor(props: IDirectDebitButtonProps) {
        super(props);

        this.draftService = container.get<IDraftService>(Types.DraftService);

        this.handleDirectDebitRedirect = this.handleDirectDebitRedirect.bind(this);
    }

    public render() {
        const {cmsContext, journeyData, successfulPolicyCreation } = this.props;

        if (this.isDirectDebitFormNeeded(journeyData) && successfulPolicyCreation) {
            if (this.props.noBanner) {
                return (
                    <>
                        <Spacing type={'padding'} bottom={'4'}/>
                        <Headings.H4 style={this.props.displayBankIdIcon ? {textAlign: 'center'} : {}}>
                            <FormattedMessage id={this.props.translations?.directDebitButtonHeader ?? "general.success.bannerPanel.directDebit.header"}/>
                        </Headings.H4>
                        {!this.props.displayBankIdIcon &&
                            <Headings.H5><FormattedMessage id={this.props.translations?.directDebitButtonSubHeader ?? "general.success.bannerPanel.directDebit.subheader"}/></Headings.H5>
                        }
                        <Button full={true} primary={true} onClick={this.handleDirectDebitRedirect}>
                            {this.props.displayBankIdIcon &&
                                <DisplayIcon style={{marginRight: 8}} baseline={true} large={false} icon={<IconBankID/>}/>
                            }
                            <FormattedMessage id={this.props.translations?.directDebitButtonLabel ?? "general.success.bannerPanel.directDebit.button.title"}/>
                        </Button>
                    </>
                )
            }

            return (
                <React.Fragment>
                    <BannerPanel
                        theme={1}
                        button={{
                            primary: true,
                            title: `general.success.bannerPanel.directDebit.button.title`,
                            redirectCallback: this.handleDirectDebitRedirect,
                        }}
                        image={CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, "directDebitImage"), cmsContext.catalog!)}
                        translations={{
                            header: `general.success.bannerPanel.directDebit.header`,
                            subheader: `general.success.bannerPanel.directDebit.subheader`
                        }}
                    />
                    <br/>
                </React.Fragment>
            )
        }

        return null;
    }
    private handleDirectDebitRedirect(): void {
        const {journeyData} = this.props;
        const data: DirectDebit = {
            ssn : journeyData.member.contact.ssn,
            firstName: journeyData.member.contact.firstName,
            lastName: journeyData.member.contact.lastName,
            phoneNumber: journeyData.member.contact.phoneNumber,
            emailAddress: journeyData.member.contact.emailAddress!,
            stepBack: this.props.stepBack
        };

        this.draftService.updateDraft(data, StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
        this.props.history.push(UrlHelper.getUrl(["direct-debit", "set-up"]));
    }

    private isDirectDebitFormNeeded(journeyData: GroupOptInOptOut | GroupInsurance) {
        return journeyData && journeyData?.paymentDetails?.paymentMethod === Constants.PaymentMethod.DirectDebit && journeyData?.hasValidDirectDebitMandates;
    }
}
