import {
    GroupInsurance,
    GetGroupMemberPreferencesResponse,
    GetGroupMemberPreferencesRequest,
    GetOfferedExistingPoliciesRequest,
    GetOfferedExistingPoliciesResponse,
    IDigitalGroupDetails,
    DigitalGetGroupDetailsRequest,
    DigitalGetGroupDetailsResponse,
} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IShoppingCartService} from "../../IShoppingCartService";
import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";

@injectable()
export class ShoppingCartService extends WorkflowServiceBase implements IShoppingCartService {
    public async createProducts(payload: GroupInsurance): Promise<GroupInsurance> {
        const data = await super.execute<any, any>(
            Workflow.Group.ShoppingCartCreateProducts,
            payload
        );

        return data;
    }

    public async getOfferedPolicies(
        payload: GetOfferedExistingPoliciesRequest
    ): Promise<GetOfferedExistingPoliciesResponse> {
        const data = await super.execute<any, any>(
            Workflow.Group.ShoppingCartGetOfferedPolicies,
            payload
        );

        return data;
    }

    public async getGroupMemberPreferences(payload: GetGroupMemberPreferencesRequest): Promise<GetGroupMemberPreferencesResponse> {
        const data = await super.execute<any, any>(
            Workflow.Group.GetGroupMemberPreferences,
            payload
        );

        return data;
    }

    public async premiumCalculate(payload: GroupInsurance): Promise<GroupInsurance> {
        return await super.execute<GroupInsurance, GroupInsurance>(
            Workflow.Group.ShoppingCartPremiumCalculate,
            payload
        );
    }

    public async getGroupDetails(groupId: string, journeyId: string): Promise<IDigitalGroupDetails> {
        const groupData: DigitalGetGroupDetailsResponse = await super.execute<DigitalGetGroupDetailsRequest, DigitalGetGroupDetailsResponse>(
            Workflow.Group.GetAuthorizedGroupDetails,
            {groupId, journeyId}
        );

        return groupData.groupDetails;
    }
}
