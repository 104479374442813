import {ISchemaService} from "../../ISchemaService";
import {Children, IJourneySchema, SchemaDataType} from "@folksam-digital/model";
import {FilterRequestPayload} from "@folksam-digital/services";
import { SharedServiceBase } from "../SharedServiceBase";
import { AxiosInstance } from "axios";

export class SchemaService extends SharedServiceBase implements ISchemaService {
    public async getSchema(journeyId: string, data: SchemaDataType): Promise<IJourneySchema> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        const path = `/schema/${journeyId}`;

        const http = this.getHttpClient(path);
        const response = await http.post<IJourneySchema>(path, data, {headers: this.getContext()});

        return response.data;
    }

    public async generateChildSchema(fnName: string, index: number, journeyId: string): Promise<Children[][]> {
        const path = `schema/${journeyId}/generateChildSchema`;

        const http = this.getHttpClient(path);
        const response = await http.post<Children[][]>(path, {fnName, index});

        return response.data;
    }

    private getHttpClient(path: string): AxiosInstance {
        if(path.indexOf("/protected/") > -1) {
            return super.create({
                baseURL: this.config.baseProtectedUrl || this.config.baseUrl
            });
        }
        else {
            return super.create({
                baseURL: this.config.baseUrl
            });
        }
    }
}
