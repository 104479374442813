import React from "react";
import {JourneyBase} from "@folksam-digital/model";
import {getHeaderTextByJourneyId} from "../Routers/util/headerTextUtils";
import {ICmsContext} from "../cms";
import getHeaderByJourneyId from "../Routers/util/headerUtils";

interface ISuccessHeaderValues {
    header: string;
    subheader?: string;
    headerText: React.ReactNode | string;
}

export const getSuccessPageHeaderValues = <TJourney extends JourneyBase<any>>(data: TJourney, context: ICmsContext): ISuccessHeaderValues | undefined => {
    const {folderName, catalogName} = context;

    const messagePrefix = `${folderName}.${catalogName}`;
    const subheader = `${messagePrefix}.success.banner.subheading`;

    const headerText = getHeaderTextByJourneyId(catalogName, data, context);
    const header = getHeaderByJourneyId(catalogName, data);

    return {
        header: `${messagePrefix}.${header}`,
        subheader,
        headerText,
    } as ISuccessHeaderValues;
};
