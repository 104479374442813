import {BaseAnalytics, BaseMapping, IOnTriggerDataLayerEventParams} from "./BaseAnalytics";

export class DirectDebitAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.${params.journeyId!.toString()}`];
        const pageName: string = params.currentStep === "setUpForm" ? "" : this.getPageName(params);
        const pageStart = params.messages[`general.adobe.directDebit.pageStart`];

        const source: BaseMapping = {
            pagename: `${pageStart}|${journeyName}${pageName}`,
            pagetype: journeyName,
            service_name: journeyName,
            sitesection1: `${pageStart}|`,
            sitesection2: `${pageStart}|${journeyName}`,
            sitesection3: `${pageStart}|${journeyName}${pageName}|`,
            sitesection4: `${pageStart}|${journeyName}${pageName}||`,
            sitesection5: `${pageStart}|${journeyName}${pageName}|||`
        };

        this.setDataLayer(source, `${params.transactionType}_${params.event}`);
    }
}
