import {ListGroup, defaultTheme as theme, DisplayIcon} from "@folksam-digital/ui";
import * as React from "react";
import {InputComponentBase} from "./InputComponentBase";
import classNames from "classnames";
import injectSheet from "react-jss";

const styles = {
    icon: {
        '--bodyColor': '#009FE4',
    },
    selected: {
        '--bodyColor': '#7DC8F0',
    },
    skeleton: {
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.colors.quinary3,
        color: theme.colors.quinary3,
        borderRadius: 4,
        width: '100%'
    },
    skeletonPrice: {
        width: '80%',
        top: '5px'
    },
    wave: {
        background:
            'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%)',
        width: '70%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-100%',
        animation: '$wave 1.5s infinite linear',
        transform: 'skew(-20deg)'
    },
    '@keyframes wave': {
        '0%': {
            left: '-100%'
        },
        '100%': {
            left: '100%'
        }
    }
};

class ListGroupItemsInner extends InputComponentBase<string, any, {}, any> {
    public render() {
        const {items, selectHandler, selected, spacing, classes, icon: IconComponent, isUpdating} = this.props;
        const hasIconComponent = IconComponent !== undefined;
        return items.map((item: any, key: any) => {

            const isActive = key === selected && !isUpdating;
            const isDisabled = item.disabled && !isUpdating;

            return (
                <ListGroup.Item
                    action={true}
                    onClick={(event: any) => {
                        selectHandler(event, key)
                    }}
                    id={item.coverPlanId}
                    className={classNames({popover: !!item.popover})}
                    active={isActive}
                    spacing={spacing}
                    eventKey={key}
                    key={key}
                    disabled={isDisabled}
                >
                    {item.popover && item.popover}
                    <div style={{paddingBottom: 6}} className={classNames( {
                        [classes.skeleton]: isUpdating
                    })}>
                        {
                            hasIconComponent && <DisplayIcon style={{paddingRight: 5, paddingLeft: 5, marginRight: 9}} baseline={true}
                                                             large={true}
                                                             icon={<IconComponent className={classNames(classes.icon, {
                                                                 [classes.selected]: key === selected
                                                             })}/>}
                            />
                        }
                        <span style={{
                            fontSize: theme.textSizes.base,
                            fontWeight: theme.fontWeights.bold,
                            fontFamily: theme.fonts.serif
                        }}>{item.iconText}</span>
                        { isUpdating && <div className={classes.wave} /> }
                    </div>
                    <div className={classNames( {
                        [classes.skeleton]: isUpdating,
                        [classes.skeletonPrice]: isUpdating
                    })}>
                        {item.currencyText}
                        { isUpdating && <div className={classes.wave} /> }
                    </div>
                </ListGroup.Item>
            );
        });
    }
}

// @ts-ignore // TODO solve ts issue with injectSheet
const ListGroupItems = injectSheet(styles, {inject: ["classes"]} as any)(ListGroupItemsInner);
export {ListGroupItems};
