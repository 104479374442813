import "reflect-metadata";
import {Container} from "inversify";
import getDecorators from "inversify-inject-decorators";
import {Types} from "./Types";
import {ConfigHelper, ICmsClient, IFileUploadClient, IUserClient} from "@folksam-digital/services";
import * as services from "./services";
import {ICmsParamsService, IDraftService, IStorage} from "./services";
import {ILocaleConfig} from "./intl/ILocaleConfig";
import {ContentHeaderHomeFieldValidator, ContentHeaderVehicleFieldValidator, DirectDebitValidator} from "./model";

const container = new Container();

const workflowConfig: services.IWorkflowServiceConfig = {
    baseUrl: ConfigHelper.getValue("WORKFLOW_API_URL"),
    baseProtectedUrl: ConfigHelper.getValue("WORKFLOW_API_PROTECTED_URL")
};

const digitalApiConfig: services.IWorkflowServiceConfig = {
    baseUrl: ConfigHelper.getValue("DIGITAL_API_URL"),
    baseProtectedUrl: ConfigHelper.getValue("DIGITAL_API_PROTECTED_URL"),
};

// Register dependencies
container.bind<services.IStorage>(Types.SettingsStorage)
    .to(services.LocalStorage)
    .inSingletonScope();

// Register dependencies
container.bind<DirectDebitValidator>(Types.DirectDebitValidator)
    .to(DirectDebitValidator)
    .inSingletonScope();

container.bind<ContentHeaderVehicleFieldValidator>(Types.ContentHeaderVehicleFieldValidator)
    .to(ContentHeaderVehicleFieldValidator)
    .inSingletonScope();

container.bind<ContentHeaderHomeFieldValidator>(Types.ContentHeaderHomeFieldValidator)
    .to(ContentHeaderHomeFieldValidator)
    .inSingletonScope();

container.bind<services.IStorage>(Types.SessionStorage)
    .to(services.SessionStorage)
    .inSingletonScope();

container.bind<services.ISessionContextManager>(Types.SessionContextManager)
    .toConstantValue(
        new services.SessionContextManager(container.get<IStorage>(Types.SessionStorage))
    );

container.bind<ILocaleConfig>(Types.LocaleConfig)
    .toConstantValue({
        defaultLocale: ConfigHelper.getValue("DEFAULT_LOCALE", "sv-SE"),
        locales: ConfigHelper.getValue("LOCALES", "sv-SE").split("|")
    });

container.bind<IDraftService>(Types.DraftService)
    .toConstantValue(new services.DraftService(container.get<IStorage>(Types.SessionStorage)));

container.bind<services.IUserService>(Types.UserService)
    .toConstantValue(
        new services.UserService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager)
        )
    );

container.bind<services.ISchemaService>(Types.SchemaService).toConstantValue(new services.SchemaService(
        digitalApiConfig,
        container.get<IStorage>(Types.SessionStorage),
        container.get<services.ISessionContextManager>(Types.SessionContextManager)
    ));

container.bind<services.IDigitalPolicyService>(Types.DigitalPolicyService)
    .toConstantValue(
        new services.DigitalPolicyService(
            digitalApiConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<services.IPolicyService>(Types.PolicyService)
    .toConstantValue(
        new services.PolicyService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
            container.get<services.IDigitalPolicyService>(Types.DigitalPolicyService),
        )
    );

container.bind<services.IShoppingCartService>(Types.ShoppingCartService)
    .toConstantValue(
        new services.ShoppingCartService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<services.IApplicationParamService>(Types.ApplicationParamService)
    .toConstantValue(
        new services.WorkflowApplicationParamService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<services.IOptInOptOutService>(Types.OptInOptOutService)
    .toConstantValue(
        new services.OptInOptOutService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager)
        ),
    );

container.bind<services.IClassifierService>(Types.ClassifierService)
    .toConstantValue(
        new services.ClassifierService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
            container.get<services.IApplicationParamService>(Types.ApplicationParamService),
        )
    );

container.bind<services.ISearchService>(Types.SearchService)
    .toConstantValue(
        new services.SearchService(
            digitalApiConfig
        )
    );

container.bind<services.IAuthenticationService>(Types.AuthenticationService)
    .toConstantValue(
        new services.AuthenticationService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<services.IUtilService>(Types.UtilService)
    .toConstantValue(
        new services.UtilService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<ICmsClient>(Types.CmsClient)
    .toConstantValue(
        new services.ApiCmsClient(
            digitalApiConfig,
            container.get<ILocaleConfig>(Types.LocaleConfig),
            container.get<IStorage>(Types.SettingsStorage)
        )
    );

container.bind<IFileUploadClient>(Types.FileUploadClient)
    .toConstantValue(
        new services.ApiFileUploadClient(digitalApiConfig)
    );

container.bind<services.ISigningService>(Types.SigningService)
    .toConstantValue(
        new services.SigningService(digitalApiConfig)
    );

container.bind<services.ISigningService>(Types.DirectDebitSigningService)
    .toConstantValue(
        new services.DirectDebitSigningService(digitalApiConfig)
    );

container.bind<services.IDigitalClaimService>(Types.DigitalClaimService)
    .toConstantValue(
        new services.DigitalClaimService(
            digitalApiConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

container.bind<services.IClaimService>(Types.ClaimService)
    .toConstantValue(
        new services.ClaimService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
            container.get<services.DigitalClaimService>(Types.DigitalClaimService)
        )
    );

container.bind<IUserClient>(Types.UserClient)
    .toConstantValue(
        new services.ApiUserClient(digitalApiConfig)
    );

container.bind<services.IHomeService>(Types.HomeService)
    .toConstantValue(
        new services.HomeService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        ),
    );

container.bind<services.IIncomeInsuranceService>(Types.IncomeInsuranceService)
    .toConstantValue(
        new services.IncomeInsuranceService(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
            container.get<services.IDigitalPolicyService>(Types.DigitalPolicyService),
        )
    );

container.bind<ICmsParamsService>(Types.CmsParamsService)
    .toConstantValue(
        new services.CmsShimParamsService(container.get<IStorage>(Types.SessionStorage))
    );

container.bind<services.IUpptecService>(Types.UpptecService)
    .toConstantValue(
        new services.UpptecServiceImpl(
            workflowConfig,
            container.get<IStorage>(Types.SessionStorage),
            container.get<services.ISessionContextManager>(Types.SessionContextManager),
        )
    );

// Make additional decorators
const {
    lazyInject,
    lazyInjectNamed,
    lazyInjectTagged,
    lazyMultiInject,
} = getDecorators(container);

export {
    lazyInject,
    lazyInjectNamed,
    lazyInjectTagged,
    lazyMultiInject,
    container
};
