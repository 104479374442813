import {Constants, HttpMethod} from "@folksam-digital/model";
import {capitalize} from "lodash";

export const Workflow = {
    Authentication: {
        GetAuthenticatedUser: {path: "/protected/contact/authorized", method: HttpMethod.GET},
        IsAuthenticated: {path: "/protected/isAuthenticated", method: HttpMethod.GET},
    },
    Contact: {
        GetBySSN: {path: "/contact", method: HttpMethod.POST},
        UpdateContact: {path: "/protected/contact", method: HttpMethod.PATCH},
    },
    Classifiers: {
        ApplicationParams: {path: "/applicationParams", method: HttpMethod.GET},
        ReferenceTable: {path: "/referenceTable", method: HttpMethod.GET},
        PetBreedOptions: {path: "/breedTypes", method: HttpMethod.GET},
        HomeProductGroups: {path: "/homeProductGroups", method: HttpMethod.GET},
    },
    DirectDebit: {
        GetDetailsBySSN: {path: "/contact/directDebitDetails", method: HttpMethod.POST},
        SaveMandate: {path: "/contact/saveDirectDebitMandate", method: HttpMethod.POST},
        CreateDummyMandate: {path: "/contact/saveDummyDirectDebitMandate", method: HttpMethod.POST},
        ValidateMandate: {path: "contact/validateDirectDebitMandate", method: HttpMethod.POST},
    },
    Home: {
        GetBuildingDetails: {path: "/policy/buildingDetails", method: HttpMethod.POST},
    },
    IncomeInsurance: {
        GetProductData: {path: "/protected/incomeInsurance/getProduct", method: HttpMethod.POST},
        PremiumCalculate: {path: "/protected/incomeInsurance/premiumCalculate", method: HttpMethod.POST},
        UpdateAddon: {path: "/protected/incomeInsurance/update", method: HttpMethod.POST},
    },
    Utils: {
        GetSystemDate: {path: "/systemDate", method: HttpMethod.GET},
        GetLocalServerDate: {path: "/localServerDate", method: HttpMethod.GET},
    },
    forProduct: (product: string, targetEnv?: string) => {
        const envSuffix = targetEnv === Constants.TargetEnvironment.Idit ? capitalize(Constants.TargetEnvironment.Idit) : "";
        return {
            PremiumCalculate: {path: `/policy/${product}/premiumCalculate${envSuffix}`, method: HttpMethod.POST},
            PremiumCalculateByCoverPlans: {
                path: `/policy/${product}/premiumCalculateByCoverPlans${envSuffix}`,
                method: HttpMethod.POST
            },
            PerformUWChecks: {path: `/policy/${product}/performUWChecks${envSuffix}`, method: HttpMethod.POST},
            GetQuestionnaire: {path: `/policy/${product}/getQuestionnaire`, method: HttpMethod.POST},
        };
    },
    Group: {
        GetGroupPoliciesBySSN: {path: "/protected/contact/searchGroupPoliciesBySSN", method: HttpMethod.GET},
        IsContactGroupMember: {path: "/protected/contact/isContactGroupMember", method: HttpMethod.POST},
        OptInOptOutCreateProducts: {path: "/protected/policy/optInOptOut/create", method: HttpMethod.POST},
        OptInOptOutGetOfferedPolicies: {
            path: "/protected/policy/optInOptOut/getOfferedPolicies",
            method: HttpMethod.POST
        },
        OptInOptOutPremiumCalculate: {path: "/protected/policy/optInOptOut/premiumCalculate", method: HttpMethod.POST},
        GetGroupMemberPreferences: {path: "/protected/contact/getGroupMemberPreferences", method: HttpMethod.POST},
        ShoppingCartGetOfferedPolicies: {
            path: "/protected/policy/shoppingCart/getOfferedPolicies",
            method: HttpMethod.POST
        },
        ShoppingCartPremiumCalculate: {
            path: "/protected/policy/shoppingCart/premiumCalculate",
            method: HttpMethod.POST
        },
        ShoppingCartCreateProducts: {path: "/protected/policy/shoppingCart/create", method: HttpMethod.POST},
        GetGroupDetails: {path: "/contact/getGroupDetails", method: HttpMethod.POST},
        GetAuthorizedGroupDetails: {path: "/protected/contact/getGroupDetails", method: HttpMethod.POST},
    },
    Claim: {
        ContactHasValidPolicies: {path: "/protected/claim/contactHasValidPolicies", method: HttpMethod.POST}
    },
    forClaim: (claim: string) => {
        return {
            UpptecLookup: {path: `/protected/upptec/${claim}/lookup`, method: HttpMethod.POST},
            UpptecCreateClaim: {path: `/protected/upptec/${claim}/claim`, method: HttpMethod.POST},
            GetQuestionnaire: {path: `/protected/claim/${claim}/getLossEventQuestionnairePCOL`, method: HttpMethod.POST},
            GetQuestionnaireItems: {path: `/protected/claim/${claim}/getLossEventQuestionnaireItems`, method: HttpMethod.POST},
            getContactEngagement: {path: `/protected/${claim}/getCustomerEngagementData`, method: HttpMethod.GET},
        };
    }
};
