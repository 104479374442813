import {BaseAnalytics, IOnTriggerDataLayerEventParams, JourneyType, NoAddtionalMapping} from "./BaseAnalytics";

export class BasicProductAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        this.setDataLayer({} as NoAddtionalMapping, `${params.transactionType}_${params.event}`, params, JourneyType.Product);
    }
}

export class BasicClaimAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        this.setDataLayer({} as NoAddtionalMapping, `${params.transactionType}_${params.event}`, params, JourneyType.Claim);
    }
}