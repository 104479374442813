import { default as React, useCallback, useState } from "react";

export const useModal = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(
        (e: React.SyntheticEvent)  => {
            e?.preventDefault();
            e?.stopPropagation();
            setOpen(true);
        },
        []
    );

    const handleClose = useCallback(
        (e: React.SyntheticEvent) => {
            e?.preventDefault();
            e?.stopPropagation();
            setOpen(false);
        },
        []
    );

    return {open, handleOpen, handleClose}
};
