import {injectable} from "inversify";
import {WorkflowServiceBase} from "./WorkflowServiceBase";
import {IHomeService} from "../../IHomeService";
import {Workflow} from "./Workflow";
import {DeepPartial, HomeBuildingJourney, HomeCondominiumJourney, BuildingDetailsRequest} from "@folksam-digital/model";
import {SsnFormatter} from "@folksam-digital/services";

@injectable()
export class HomeService extends WorkflowServiceBase implements IHomeService {
    public async getBuildingDetails({identifier, ...data}: BuildingDetailsRequest): Promise<DeepPartial<HomeBuildingJourney | HomeCondominiumJourney> | undefined> {
        return await super.execute<BuildingDetailsRequest, DeepPartial<HomeBuildingJourney>>(Workflow.Home.GetBuildingDetails, {
            identifier: SsnFormatter.convertFormat(identifier, true),
            ...data
        });
    }

}
