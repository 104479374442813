import * as React from "react";
import {defaultTheme as theme, Tooltip} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import scrollIntoView from "scroll-into-view-if-needed";
import {injectIntl, WrappedComponentProps} from "react-intl";

interface IProps {
    messageKey: string;
    symbol?: string;
    direction?: Tooltip.left | Tooltip.right;
    isInlineTooltip?: boolean;
    style?: object;
}

class TooltipOutputInternal extends React.Component<IProps & WrappedComponentProps> {

    public render() {

        const { messageKey, symbol, direction, isInlineTooltip, style = {}} = this.props;

        return (
            this.props.intl.messages[messageKey] !== " " &&
            <Tooltip
                tabIndex={"-1"}
                style={{pointerEvents: "all", marginLeft: theme.margin["2"], display: "inline", ...style}}
                symbol={symbol !== undefined ? symbol : Tooltip.defaultSymbol}
                direction={direction !== undefined ? direction : Tooltip.right}
                onClick={(event: any) => this.scrollIntoViewHandler(event)}
                inlineTooltip={isInlineTooltip === false ? false : true}
            >
                <span className="icon-hint">
                    <FormattedMarkdown messageKey={messageKey} />
                </span>
            </Tooltip>
        );
    }

    private scrollIntoViewHandler = (event: any) => {
        event.preventDefault();
        scrollIntoView(event.target, {block: "center", behavior: "smooth"});
    }
}

const TooltipOutput = injectIntl(TooltipOutputInternal);
export {TooltipOutput};
