import {pathof} from "@folksam-digital/model";
import JourneyBaseModel from "@folksam-digital/model/lib/model/JourneyBaseModel";
import HomeWithSmallBoatJourneyModel from "@folksam-digital/model/lib/model/HomeWithSmallBoatJourneyModel";

export class SchemaHelper {
    public static definitionScopeHome({prefix, withType}: any): string {
        if (withType) {
            return `{{#translateValue}} ${prefix}.coverPlans.{{{${pathof(HomeWithSmallBoatJourneyModel.policy.insuredObject.insuranceType)}}}}.{{{${pathof(HomeWithSmallBoatJourneyModel.policy.coverPlan)}}}}.title {{/translateValue}}`;
        }

        return `{{#translateValue}} ${prefix}.coverPlans.{{{${pathof(JourneyBaseModel.policy.coverPlan)}}}}.title {{/translateValue}}`;
    }
}
