import { IRoute } from "../IRoute";
import SuccessPage from "./SuccessPage";
import ErrorPage from "./ErrorPage";
import SigningErrorPage from "./SigningErrorPage";
import SetUpForm from "./SetUpForm";

const DirectDebitRoutes: IRoute[] = [
    {path: "set-up", component: SetUpForm},
    {path: "success", component: SuccessPage},
    {path: "error", component: ErrorPage},
    {path: "signing-error", component: SigningErrorPage}

];

export default DirectDebitRoutes;
