import { DirectDebit } from "@folksam-digital/model";

export const createEmptyPrefilledFieldList = (data: DirectDebit): string[] => {
    const emptyFields: string[] = [];
    for (const [key, value] of Object.entries(data)) {
        if (value === undefined) {
            emptyFields.push(key);
        }
    }

    return emptyFields;
}
