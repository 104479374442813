import * as React from "react";
import get from "lodash/get";
import { PanelComponentBase } from "./PanelComponentBase";
import { Expandable, Accordion, Spacing } from "@folksam-digital/ui";
import { IExpandableParams } from "../../../../../types/@folksam-digital/ui/lib/Expandable";
import {FormContext, IFormContext} from "../FormContext";
import { WrappedComponentProps, injectIntl } from "react-intl";

interface IMetadata {
    properties: [];
    expandedPath?: string;
    dataPath?: string;
    labelTemplate?: string;
}

class ExpandablePanelInternal extends PanelComponentBase<void, IMetadata & WrappedComponentProps, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        return (
            <Spacing type={"margin"} top={"md"}>
                <Expandable initialExpanded={this.getInitialExpanded()}>
                    <Expandable.Section id={this.metadata.expandedPath && this.metadata.expandedPath}>
                        {({handleClick, expanded}: IExpandableParams) => {
                            return (
                                <Accordion>
                                    <Accordion.Header onClick={handleClick} expanded={expanded}>
                                        {this.renderTitle(this.context.data)}
                                    </Accordion.Header>
                                    <Accordion.Body
                                        expanded={expanded}
                                        padded={true}
                                        noBorder={true}
                                    >
                                        {this.props.properties?.map(p => p.content)}
                                    </Accordion.Body>
                                </Accordion>
                            )
                        }}
                    </Expandable.Section>
                </Expandable>
            </Spacing>
        )
    }

    private renderTitle(data: any): React.ReactNode {
        let title: string;
        if (this.metadata.labelTemplate && this.metadata.dataPath) {
            const params = get(data, this.metadata.dataPath);
            if (!params) {
                return null;
            }

            title = this.props.intl.formatMessage({id: this.metadata.labelTemplate}, {...params});
        } else {
            title = this.schema.title;
        }

        return (
                <span>{title}</span>
        );
    }

    private getInitialExpanded(): string[] {
        if (this.metadata.expandedPath) {
            return this.isExpandedOnError() ? [this.metadata.expandedPath] : [];
        }
        return [];
    }

    private isExpandedOnError(): boolean {
        if (this.metadata.expandedPath) {
            return get(this.context.data, this.metadata.expandedPath);
        }
        return false;
    }
}

const ExpandablePanel = injectIntl(ExpandablePanelInternal);
export {ExpandablePanel};
