import * as React from "react";
import {SpacingLine as SpacingLineFUI} from "@folksam-digital/ui";
import {OutputComponentBase} from "./OutputComponentBase";

interface IMetadata {

}

class SpacingLine extends OutputComponentBase<string, IMetadata, {}> {
    render() {
        return(
            <SpacingLineFUI />
        );
    }
}

export { SpacingLine }
