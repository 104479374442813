import {Constants, PolicySearchResult} from "@folksam-digital/model";
import {SatelliteEvents, SatelliteEventTransactionTypes} from "../new/BaseAnalytics";
import {BaseAnalytics, BaseMapping, IOnTriggerDataLayerEventParams} from "./BaseAnalytics";
import {IMessages} from "../../cms";
import {StorageKeys} from "../../services";

export interface ShoppingCartMap extends BaseMapping{
    product_id: Array<number | string> | string;
    product_units: Array<number>;
    product_price: Array<number>;
    revenue?: number;
    transaction_id: string;
    transaction_page_start?: string;
    transaction_page_complete?: string;
    age: number;
    groupid: string;
    union: string;
    agentId: string
    eventName: string;
    gender: string;
    pageurl?: string;
    payment_method: string;
    payment_period: string;
}

export class ShoppingCartAnalytics extends BaseAnalytics {
    protected getStepName(params: IOnTriggerDataLayerEventParams): string {// Added from new
        if (this.isStepTypeError(params.currentStep)) {
            return params.messages["general.adobe.error"];
        }

        if (this.isStepTypeSuccess(params.currentStep)) {
            return params.messages["general.adobe.success"];
        }

        return params.messages[`general.adobe.step.${params.currentStep}`];
    }

    private getPaymentMethod(messages: IMessages, data?: any): string {// Added from product analytics
        return messages[`general.policy.paymentDetails.paymentMethod.${data?.paymentDetails?.paymentMethod}`] ?
            messages[`general.policy.paymentDetails.paymentMethod.${data?.paymentDetails?.paymentMethod}`] : "";
    }

    private getPaymentFrequency(messages: IMessages, data?: any): string {// Added from product analytics
        return messages[`general.policy.paymentDetails.paymentFrequency.${data?.paymentDetails?.paymentFrequency}`] ?
            messages[`general.policy.paymentDetails.paymentFrequency.${data?.paymentDetails?.paymentFrequency}`] : "";
    }

    private getAgentId(params: IOnTriggerDataLayerEventParams): string {
        const sessionParams: any = this.sessionStorage.get(StorageKeys.PARAMS);
        return sessionParams?.agentId ? sessionParams.agentId : Constants.AgentId.Policy;
    }

    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.product.${params.journeyId!.toString()}`];
        const pageStart = params.messages[`general.adobe.application`];
        const pageType = params.messages[`general.adobe.product.pageType`];
        const stepName: string = this.getStepName(params);

        let productIds: Array<number | string> = [];
        let productUnits: Array<number> = [];
        let productPrices: Array<number> = [];

        if (params?.data?.member?.offers !== undefined) {
            const memberResult = this.getProductIds(params.data.member.offers);

            productIds = productIds.concat(memberResult.productIds);
            productUnits = productUnits.concat(memberResult.productUnits);
            productPrices = productPrices.concat(memberResult.productPrices);
        }

        if (params?.data?.partner !== undefined && params.data.partner.offers !== undefined) {
            const partnerResult = this.getProductIds(params.data.partner.offers);

            productIds = productIds.concat(partnerResult.productIds);
            productUnits = productUnits.concat(partnerResult.productUnits);
            productPrices = productPrices.concat(partnerResult.productPrices);
        }

        if (params?.data?.children !== undefined && params.data.children?.length > 0) {
            for (const child of params.data.children) {
                const childrenResult = this.getProductIds(child?.offers);

                productIds = productIds.concat(childrenResult.productIds);
                productUnits = productUnits.concat(childrenResult.productUnits);
                productPrices = productPrices.concat(childrenResult.productPrices);
            }
        }

        if(productIds.length === 0) {
            productIds = ['GMI-PRODUCTS-TO-CHOOSE'];
        }

        let trackKey = `${SatelliteEventTransactionTypes.TransactionPage}_${params.event}`;
        const dataLayerObj: Omit<ShoppingCartMap, "groupid"> = {
            age: params?.data?.member?.contact?.age,
            agentId: this.getAgentId(params),
            customer_id: await this.getUserId(params?.data?.member?.contact?.externalContactNumber),
            pagename: `${pageStart}|${pageType}|${journeyName}|${stepName}`,
            union: params?.data?.group?.id,
            pagetype: `${pageType}`,
            sitesection1: `${pageStart}`,
            sitesection2: `${pageStart}|${pageType}`,
            sitesection3: `${pageStart}|${pageType}|${journeyName}`,
            sitesection4: `${pageStart}|${pageType}|${journeyName}|${stepName}`,
            product_id: productIds,
            product_units: productUnits,
            product_price: productPrices,
            revenue: 0,
            transaction_id: this.getTransactionId(),
            eventName: trackKey,
            gender: params.data?.member?.contact?.gender ? params.messages[`general.adobe.gender.${params.data?.member?.contact?.gender}`] : "",
            pageurl: window.location.href.split('?')[0],
            payment_method: this.getPaymentMethod(params.messages, params.data),
            payment_period: this.getPaymentFrequency(params.messages, params.data),
        };

        if (Array.isArray(dataLayerObj.product_price)) {
            dataLayerObj.revenue = dataLayerObj.product_price.reduce((partialSum, a) => partialSum + a, 0);
        }

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        this.setDataLayer(dataLayerObj, trackKey);
    }

    private getProductIds(offers: PolicySearchResult[]): {
        productIds: number[];
        productUnits: number[];
        productPrices: number[];
    } {
        const productIds: Array<number> = [];
        const productUnits: Array<number> = [];
        const productPrices: Array<number> = [];

        for (const offer of offers) {
            if (offer.selected === true) {
                productIds.push(offer.productId!);
                productUnits.push(1);
                productPrices.push(offer.yearlyPremium!);
            }
        }

        return {
            productIds,
            productUnits,
            productPrices
        };
    }
}
