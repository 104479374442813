import * as React from "react";
import {PanelComponentBase} from "./PanelComponentBase";
import {Step} from "@folksam-digital/ui";

interface IMetadata {
    withBackground: boolean;
}

export class QuestionnairePlaceholder extends PanelComponentBase<string, IMetadata, {}, any> {
    public render() {
        const {withBackground} = this.metadata;

        return (
            <Step.SectionBody
                withBackground={withBackground}>{this.props.properties?.map((p: any) => p.content)}</Step.SectionBody>
        )
    }
}
