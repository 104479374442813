import * as React from "react";
import {container} from "../../../../inversify.config";
import {ISessionContextManager, IUpptecService} from "../../../../services";
import {Types} from "../../../../Types";
import flowRight from "lodash/flowRight";
import {injectIntl} from "react-intl";
import {AutoComplete, P, withViewContext} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {UpptecClaimResponse, UpptecSuggestion} from "@folksam-digital/model";
import {FormFieldLayout} from "../../../FormFieldLayout";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {SuggestionsRenderer} from "./googleAutocomplete/SuggestionsRenderer";
import isObject from "lodash/isObject";
import sanitizeField from "./helpers/sanitizeField";
import debounce from "lodash/debounce";
import {InputRenderer} from "./searchAutoComplete/InputRenderer";
import moment from "moment";
import {UuidGenerator} from "../../../../Helpers/UuidGenerator";
import get from "lodash/get";

export interface IUpptecSearchInputMetadata {
    breakpoints?: any;
    debounce?: number;
    placeholder?: string;
    helpText?: string;
    help?: string;
    suggestionFields?: string[];
    upptecResponsePath: string;
    purchaseDatePath?: string;
    brandPath: string;
    pricePath: string;
}

interface IUpptecSearchInputState {
    inputValue: string;
    isLoading: boolean;
    suggestions?: UpptecSuggestion[];
    touched?: boolean;
}

class UpptecSearchInputInternal extends InputComponentBase<UpptecClaimResponse | UpptecSuggestion, IUpptecSearchInputMetadata, IUpptecSearchInputState, IInputComponentProps<any, any>> {
    public ref: React.RefObject<HTMLInputElement>;
    public divRef: React.RefObject<HTMLDivElement>;
    protected readonly upptecService: IUpptecService;
    protected readonly sessionContextManager: ISessionContextManager;

    public constructor(props: any, context: any) {
        super(props, context);

        this.state = {
            isLoading: false,
            suggestions: [],
            inputValue: "",
            touched: true,
        };
        this.ref = React.createRef();
        this.divRef = React.createRef();

        this.upptecService = container.get<IUpptecService>(Types.UpptecService);
        this.sessionContextManager = container.get<ISessionContextManager>(Types.SessionContextManager);
        this.search = debounce(this.search.bind(this), this.metadata.debounce || 300);
        this.changeValue = this.changeValue.bind(this);
        this.handleResultSelect = this.handleResultSelect.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    public componentDidMount(): void {
        const formData: UpptecClaimResponse = this.props.formData;

        this.setState({
            inputValue: formData?.originalProduct?.brand && formData?.originalProduct?.name ? `${formData?.originalProduct?.brand} ${formData?.originalProduct?.name}` : ""
        });
    }

    private search(value: string): void {
        this.setState({isLoading: false});
        if (value.length > 0) {
            this.fetchSuggestions(value);
        }

        this.props.onChange(sanitizeField(value));
    }

    changeValue = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        this.search(event.target.value);
        this.setState({inputValue: event.target.value, touched: true});
    };

    onBlur = (event: React.FocusEvent) => {
        if (this.state.touched) {
            this.onBlurWithValidation(event, undefined);

            this.setState({touched: false});
        }
    }

    private async fetchSuggestions(value: string): Promise<void> {
        try {
            this.setState({isLoading: true});
            const results = await this.upptecService.lookup(value, this.context.journeyId);
            this.setState({isLoading: false, suggestions: results});
        } catch (e) {
            this.setState({isLoading: false});
        }
    }

    handleResultSelect = async (record: UpptecSuggestion): Promise<void> => {
        if (!record || !record?.brand || !record?.name || !record?.id) {
            return;
        }
        const {data} = this.context;
        const sessionContext = this.sessionContextManager.getSessionContext();
        const uuid: string = sessionContext && sessionContext.transactionId ? sessionContext.transactionId : UuidGenerator.generate();

        // Taken from path or from event date - 1 day to prevent createClaim WS error
        const purchaseDate = this.metadata.purchaseDatePath ? moment(get(data, this.metadata.purchaseDatePath)) : moment(data?.event?.date).subtract(1, "days");
        const upptecClaimData = await this.upptecService.createClaim({
            date_of_incident: moment(data?.event?.date).format("YYYY-MM-DD"),
            name: `${data?.claimant?.contact?.firstName} ${data?.claimant?.contact?.lastName}`,
            tracking_id: uuid,
            items: [
                {
                    product_id: record?.id,
                    tracking_id: `${record?.id}-${uuid}`,
                    date_of_purchase: purchaseDate.format("YYYY-MM-DD"),
                },
            ],
        }, this.context.journeyId);
        this.setState({inputValue: `${record?.brand} ${record?.name}`, touched: false});

        this.onChangeWithValidation(upptecClaimData);
    };

    public render() {
        const {name, intl} = this.props;
        const {placeholder, helpText, help, suggestionFields} = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()}
                             title={this.schema.title}
                             help={help}
            >
                {helpText ? <P><FormattedMarkdown messageKey={helpText}/></P> : <></>}
                <AutoComplete
                    ref={this.ref}
                    loading={this.state.isLoading}
                    onSuggestionSelect={this.handleResultSelect}
                    suggestions={this.state.suggestions}
                    renderSuggestions={SuggestionsRenderer}
                    suggestionFields={suggestionFields}
                    onChange={this.changeValue}
                    renderInput={InputRenderer}
                    selected={isObject(this.props.formData)}
                    value={this.state.inputValue}
                    placeholder={placeholder && intl.formatMessage({id: placeholder})}
                    idPrefix={name}
                    onBlur={this.onBlur}
                    invalid={this.isInvalid()}
                />
                <div ref={this.divRef}/>
            </FormFieldLayout>
        );
    }
}

const UpptecSearchInput = flowRight(injectIntl, withViewContext)(UpptecSearchInputInternal);

export {UpptecSearchInput};
