import * as React from "react";
import { FormInputCheckbox } from "@folksam-digital/ui";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ILocaleContext } from "../../../../intl/LocaleContext";
import { IFormComponentProps } from "../FormComponentBase";
import { withLocaleContext } from "../withLocaleContext";
import { ChoiceComponentBase, IChoiceComponentMetadataBase, IChoiceComponentStateBase } from "./ChoiceComponentBase";
import { FormFieldGroupLayout } from "../../../FormFieldGroupLayout";
import { QuestionSkeleton } from "@folksam-digital/ui";
import { IBreakPoint } from "../../layout/helpers";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {IOptionSelected} from "@folksam-digital/model/lib/utils/IOption";

interface IMetadata extends IChoiceComponentMetadataBase {
    classNames?: string;
    breakpoints?: IBreakPoint;
    bordered?: boolean;
    checkDisabledForm?: boolean;
}

interface ICheckBoxGroupProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
}

type IFormData = IOptionSelected[];


class CheckBoxGroupInternal extends ChoiceComponentBase<IFormData, IMetadata, IChoiceComponentStateBase, ICheckBoxGroupProps<IFormData, IMetadata>> {
    constructor(props: ICheckBoxGroupProps<IFormData, IMetadata>) {
        super(props);
        this.state = {};
    }

    public render() {
        const { formData } = this.props;

        const { breakpoints, bordered, checkDisabledForm } = this.metadata;
        const { values } = this.state;
        const {disableButton} = this.context;

        if(values) {
            return (
                <FormFieldGroupLayout {...this.getLayoutProps()} breakpoints={getBreakpoints(defaultBreakpoints.radioGroup, breakpoints)}>
                    {values.map((option) => (
                        <div key={option?.id}>
                            <FormInputCheckbox
                                id={option?.id}
                                label={option?.title}
                                value={option?.title}
                                name={option?.id}
                                checked={formData?.find((item: IOptionSelected) => item?.label === option?.id)}
                                invalid={this.isInvalid()}
                                onChange={(event: any) => this.onChange(event, option?.id)}
                                disabled={(checkDisabledForm && disableButton) || option?.disabled}
                                bordered={bordered}
                            />
                        </div>
                    ))}
                </FormFieldGroupLayout>
            );
        }
        else {
            return (
                <div> <QuestionSkeleton /> </div>
            );
        }
    }

    private onChange(event: any, optionId: string|number) {
        const formData = this?.props?.formData || [];

        if (event.target.checked) {
            this.onChangeWithValidation([...formData, {value: event?.target?.value, label: optionId}]);
        } else {
            const filteredFormData = formData.filter((item: IOptionSelected) => item?.label !== optionId);
            this.onChangeWithValidation(filteredFormData);
        }
    }
}

const CheckBoxGroup = injectIntl(withLocaleContext<ICheckBoxGroupProps<IFormData, IMetadata>>(CheckBoxGroupInternal));
export {CheckBoxGroup};
