import * as React from "react";
import {FormInputTextareaAutoresize} from "@folksam-digital/ui";
import {InputSuffix} from "./inputSuffix";

interface IInputRenderer {
    className: string;
    id: string;
    activeSuggestion: number | null | undefined;
    loading: boolean;
    autoComplete: string;
    value: string;
    onChange: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    onKeyDown: () => void;
    required?: boolean;
    disabled?: boolean;
    invalid?: boolean;
    placeholder?: string;
    ref?: React.RefObject<any>;
}

export type IInputRendererFn = (object: IInputRenderer) => React.ReactElement;

export function inputRenderer(this: any, {
    className,
    id,
    loading,
    autoComplete,
    value,
    onChange,
    required,
    disabled,
    invalid,
    placeholder,
    ref
}: IInputRenderer): React.ReactElement {
    return (
        <div className="custom-wrapper">
            <FormInputTextareaAutoresize
                id={id}
                value={value}
                disabled={disabled}
                required={required}
                invalid={invalid}
                autoComplete={autoComplete}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={this.handleBlur}
                suffix={<InputSuffix loading={loading}
                                     mobileView={this.metadata?.googleMapOptions && this.props.viewContext.isMobile}
                                     isModalOpen={this.state.isModalOpen}
                                     mobileViewOnIconClick={this.handleGoogleMapIconCallback}/>}
                name={id}
                minRows={1}
                maxLength={this.schema?.maxLength || 150}
            />
        </div>
    );
}
