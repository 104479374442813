import * as React from "react";
import {FlexRow, FormInputToggleButton, QuestionSkeleton} from "@folksam-digital/ui";
import {ChoiceComponentBase, IChoiceComponentMetadataBase, IChoiceComponentStateBase} from "./ChoiceComponentBase";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {withLocaleContext} from "../withLocaleContext";
import {IFormComponentProps} from "../FormComponentBase";
import {injectIntl, IntlShape, WrappedComponentProps} from "react-intl";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import sanitizeField from "./helpers/sanitizeField";
import {IBreakPoint} from "../../layout/helpers";
import {castArray, isUndefined} from "lodash";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {FieldGroup} from "../../../formFieldLayout/FieldGroup";

interface IMetadata extends IChoiceComponentMetadataBase {
    help?: string;
    classNames?: string;
    isDisabled?: boolean;
    intl: IntlShape;
    groupVertically?: boolean;
    breakpoints?: IBreakPoint;
    checkDisabledForm?: boolean;
}

interface IToggleButtonInternalProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
}

class ToggleButtonInternal extends ChoiceComponentBase<number, IMetadata, IChoiceComponentStateBase, IToggleButtonInternalProps<number, IMetadata>> {
    constructor(props: IToggleButtonInternalProps<number, IMetadata>) {
        super(props);

        this.state = {};
        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const {
            name,
            formData,
            intl
        } = this.props;

        const {isDisabled, classifierId, groupVertically, breakpoints, valuesModelPath, checkDisabledForm} = this.metadata;
        const {values} = this.state;
        const {disableButton} = this.context;

        const toggleButtons: JSX.Element[] | undefined = values?.map((value: any) =>
            <FormInputToggleButton
                inline={true}
                key={`${name}-${value.id}`}
                id={`${name}-${value.id}`}
                name={`${name}-${value.id}`}
                ariaLabelledBy={`${name}-${value.id}-label ${name}-label`}
                label={!classifierId && !valuesModelPath ? intl.formatMessage({id: value.title}) : value.title}
                value={value.id}
                checked={!isUndefined(formData) && formData.toString() === value.id.toString()}
                disabled={isDisabled || (checkDisabledForm && disableButton)}
                invalid={this.isInvalid()}
                onChange={() => this.onChange(value.id)}
            />
        );

        if (toggleButtons) {
            const layoutProps = this.getLayoutProps();

            return (
                <FormFieldGroupLayout {...layoutProps} spaceBetween={false}
                                      breakpoints={toggleButtons?.length === 3
                                          ? getBreakpoints(defaultBreakpoints.toggleButton3Options, breakpoints)
                                          : getBreakpoints(defaultBreakpoints.toggleButton, breakpoints)}>
                    <div>
                        <FieldGroup spaceBetween={!!groupVertically} {...layoutProps}>
                            {groupVertically ?
                                toggleButtons
                                :
                                <FlexRow>
                                    {toggleButtons}
                                </FlexRow>
                            }
                        </FieldGroup>
                    </div>
                </FormFieldGroupLayout>
            )
        } else {
            return (
                <div>
                    <QuestionSkeleton/>
                </div>
            );
        }
    }

    onChange(value: any) {
        if (castArray(this.schema.type).includes("string")) {
            this.onChangeWithValidation(sanitizeField(value.toString()));
        } else {
            this.onChangeWithValidation(Number(value));
        }
    }
}

const ToggleButton = injectIntl(withLocaleContext<IToggleButtonInternalProps<number, any>>(ToggleButtonInternal));

export {ToggleButton};
