import {Contact} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IAuthenticationService} from "../../IAuthenticationService";
import {Workflow} from "./Workflow";
import {WorkflowServiceBase} from "./WorkflowServiceBase";

@injectable()
export class AuthenticationService extends WorkflowServiceBase implements IAuthenticationService {
    public async isAuthenticated(): Promise<boolean> {
        const data = await super.execute<any, { isAuthenticated: boolean }>(
            Workflow.Authentication.IsAuthenticated
        );
        return data.isAuthenticated;
    }

    public async getAuthenticatedUser(): Promise<Contact> {
        const data = await super.execute<any, { contact: Contact }>(
            Workflow.Authentication.GetAuthenticatedUser
        );
        return data.contact;
    }
}
