import {FormFieldGroup, FormLabel} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import React from "react";
import {IFormFieldGroupLayoutProps} from "../FormFieldGroupLayout";
import {IFormFieldGroupProps} from "../../../types/@folksam-digital/ui/lib/FormFieldGroup";
import {Layout} from "../FormFieldLayout";

interface IFieldGroup extends IFormFieldGroupLayoutProps, Pick<IFormFieldGroupProps, "spaceBetween"> {
}

export const FieldGroup = ({spaceBetween, children, id, title, defaultTranslation, translateTitle, required, ...props}: IFieldGroup) => {

    const legendTranslation = new Layout({id, ...props}).renderTranslatedField(title, defaultTranslation, translateTitle);
    const legendTranslationWithOptionalLabel = (<>
        {legendTranslation}
        <FormLabel.OptionalText
            required={required}
            optionalText={<FormattedMessage id={"general.optionalText"}/>}
        />
    </>);

    return (
        <FormFieldGroup spaceBetween={spaceBetween} legend={legendTranslationWithOptionalLabel} legendId={id}>
            {children}
        </FormFieldGroup>
    );
};
