import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {RestClientBase} from "./RestClientBase";
import {IWorkflowServiceConfig} from "./workflowApi/WorkflowServiceBase";
import {IDigitalApiServiceConfig} from "./digitalApi/DigitalApiServiceBase";
import {IStorage} from "../IStorage";
import {ISessionContext, ISessionContextManager} from "../ISessionContextManager";
import {StorageKeys} from "../IDraftService";
import {Constants} from "@folksam-digital/model";
import {SESSION_CONTEXT_KEY} from "./SessionContextManager";


export abstract class SharedServiceBase extends RestClientBase {
    protected readonly config: IDigitalApiServiceConfig | IWorkflowServiceConfig;
    private readonly sessionStorage: IStorage;
    private readonly sessionContextManager: ISessionContextManager;

    constructor(config: IDigitalApiServiceConfig | IWorkflowServiceConfig,
                sessionStorage: IStorage,
                sessionContextManager: ISessionContextManager
    ) {
        super();

        this.config = config;
        this.sessionStorage = sessionStorage;
        this.sessionContextManager = sessionContextManager;
    }

    /**
     * Overwrites create config
     * @param config
     */
    protected create(config: AxiosRequestConfig): AxiosInstance {
        const params = this.sessionStorage.get<object>(StorageKeys.PARAMS, {}) as {[key: string]: string};
        if (!config.headers) {
            config.headers = {};
        }

        config.headers[Constants.RequestHeader.persistentOriginReferer] = params[Constants.RequestHeader.persistentOriginReferer];

        return super.create(config);
    }

    /**
     * Get transaction / session context
     */
    protected getContext(): Record<string, string> | undefined {
        return this.sessionContextManager.getSessionContext() as Record<string, string> | undefined;
    }

    /**
     * Update transaction / session context
     * @param response
     */
    protected updateContext<TOutput>(response: AxiosResponse<TOutput>) {
        if(response.headers.transactionid) {
            this.saveContext({
                transactionId: response.headers.transactionid
            });
        }
    }

    /**
     * Persist transaction / session context
     * @param context
     */
    protected saveContext(context: ISessionContext) {
        this.sessionStorage.set(SESSION_CONTEXT_KEY, context);
    }
}
