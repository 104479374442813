import {IBannerIconBrandLogoGenerator} from "./bannerIconBrandLogoHelper";
import {IntlShape} from "react-intl";
import {Constants} from "@folksam-digital/model";
import {iconLogoRenderer} from "./bannerIconLogoRenderer";
import {CoopIcon} from "@folksam-digital/ui";
import {findKey} from "lodash";
import {FeatureToggleUtils} from "@folksam-digital/services";

export const coopBannerIconLogoGenerator: IBannerIconBrandLogoGenerator = (formData, cmsContext, intl: IntlShape) => {
    const {policy} = formData || {};

    if (policy?.partner !== Constants.Partners.Coop || !FeatureToggleUtils.isFeatureEnabled("coop")) {
        return;
    }

    const constants = {[`${Constants.Partners.Coop}`]: `${findKey(Constants.Partners, (partner) => partner === Constants.Partners.Coop)}`};

    return iconLogoRenderer({
        constants,
        iconsCollection: {},
        itemKey: policy?.partner,
        itemName: "brandName",
        IconComponent: CoopIcon,
        cmsContext,
        intl
    });
};
