import React from "react";
import {FormattedMessage} from "react-intl";
import {defaultTheme} from "@folksam-digital/ui";
import {createUseStyles} from "react-jss";

interface IProps {
    messageId?: string
};

const useStyles = createUseStyles(() => ({
    discountTitle: {
        fontWeight: defaultTheme.fontWeights.bold,
        fontSize: defaultTheme.textSizes.sm
    },
}));

export const DiscountsTitle = (props: IProps): React.ReactElement => {
    const {messageId} = props;
    const classes = useStyles();

    return (
        <div className={classes.discountTitle}>
            <FormattedMessage id={messageId ? messageId : "general.discountedDiscounts.title"}/>
        </div>
    );
};
