import * as React from "react";
import {Headings} from "@folksam-digital/ui";

export interface IPolicyListGroupProps {
    children?: React.ReactNode;
    style?: { borderBottom?: string; margin?: number; lineHeight?: string };
}

export default class PolicyGroupHeader extends React.Component<IPolicyListGroupProps> {
    public render() {
        return (
            <Headings.H4 style={this.props.style}>
                {this.props.children}
            </Headings.H4>
        )
    }
};
