import * as React from "react";
import {InputComponentBase} from "./InputComponentBase";
import {scroller} from "react-scroll/modules";
import {AddButton, IAddButtonMetaData} from "./AddButton";

interface IMetaData extends IAddButtonMetaData {
    fieldId: string;
}

export class AnchorButton extends InputComponentBase<any, IMetaData, any> {
    onChange = () => {
        scroller.scrollTo(this.metadata.fieldId, {smooth: true, duration: 300});
    };

    render() {
        return (
            <AddButton
                {...this.props}
                onClick={this.onChange}
            />
        );
    }
}
