import {RouteComponentProps, withRouter} from "react-router";
import * as React from "react";
import flowRight from "lodash/flowRight";
import {CmsContext, ICmsContext} from "../../cms";
import ErrorBase, { IErrorBaseProps, IErrorBaseState } from "../ErrorBase";
import {DirectDebit} from "@folksam-digital/model/lib";
import ErrorPageView from "./ErrorPageView";
import {IOnTriggerDataLayerEvent, SatelliteEventTransactionTypes, SatelliteEvents} from "../../analytics/new/BaseAnalytics";
import withDataAnalytics from "../../components/general/withDataAnalytics";
import withCmsProvider from "../../components/general/withCmsProvider";
import {StorageKeys} from "../../services";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {UrlHelper} from "@folksam-digital/services";
import {getAnalytics, AnalyticsType} from "../../analytics/AnalyticsSwitch";

class SigningErrorPageInternal extends ErrorBase<IErrorBaseProps & IOnTriggerDataLayerEvent & RouteComponentProps<any, any>, IErrorBaseState> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    constructor(props: IErrorBaseProps & IOnTriggerDataLayerEvent & RouteComponentProps<any, any>, context: ICmsContext) {
        super(props, context);

        this.onTryAgain = this.onTryAgain.bind(this);
    }

    public get data(): DirectDebit {
        return this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY) as DirectDebit;
    }

    public get params(): DirectDebit {
        return this.draftService.getDraft<DirectDebit>(StorageKeys.PARAMS) as DirectDebit;
    }

    public async componentDidMount() {
        // Trigger Analytics Error Lead Start
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            journeyId: "directDebit",
            currentStep: "error",
            data: this.data,
            transactionType: SatelliteEventTransactionTypes.Lead,
            event: SatelliteEvents.Start,
            errorMessage: "contactForm.bankIdError.description"
        });
    }

    public onTryAgain() {
        this.props.history.push(UrlHelper.getUrl(["direct-debit", "set-up"]));
    }

    public render(): React.ReactNode {
        const data = this.data;
        const params = this.params;

        return (
            <ErrorPageView
                formData={{...data, ...params}}
                description={CmsHelper.withPrefix(this.context, "contactForm.bankIdError.description")}
                onTryAgain={this.onTryAgain}
                headerMessages={{
                    header: CmsHelper.withPrefix(this.context, "banner.heading"),
                    headerText: CmsHelper.withPrefix(this.context, "title.message"),
                }}
            />
        );
    }
}

const SigningErrorPage = flowRight(
    withCmsProvider("directDebitError"),
    withDataAnalytics(getAnalytics(AnalyticsType.DirectDebit)),
    withRouter
)(SigningErrorPageInternal);

export default SigningErrorPage;
