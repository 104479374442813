import React from "react";
import {isBoolean} from 'lodash';
import {PolicySearchResult, Constants} from "@folksam-digital/model";
import {
    defaultTheme,
    CoverCard as CoverCardComponent,
    IconCheckmark,
    B,
    Animate,
    ConstrainWidth,
    FormError,
    List,
    DescriptionList
} from "@folksam-digital/ui";
import {
    ProductGroupAccidentIcon,
    ProductGroupLongTermHealthCareIcon,
    ProductGroupLifeInsuranceIcon,
    ProductGroupProAthletesIcon,
    ProductGroupDiagnosisIcon,
    ProductGroupExpenseInsuranceIcon,
    ProductGroupShortTermIllnessIcon,
    ProductGroupLegalAdviceIcon
} from "@folksam-digital/ui";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../../FormattedMarkdown";

export interface IOptCardProps {
    index: number;
    group: string;
    onChange: (data: IOptCardPolicy, index: number) => void;
    error?: string;
    formData: IOptCardPolicy;
    defaultValue: boolean | string;
}

export interface IOptCardPolicy extends PolicySearchResult {
    index: number;
}

const TRUE_VALUE_STRING = 'true';
const FALSE_VALUE_STRING = 'false';

class OptCardInternal extends React.Component<IOptCardProps & WrappedComponentProps, any> {
    constructor(props: Readonly<IOptCardProps & WrappedComponentProps>) {
        super(props);

        this.state = {
            selected: props.defaultValue,
            monthlyPremium: Math.round(props.formData.monthlyPremium!)
        };

        this.onChange = this.onChange.bind(this);
    }

    public onChange(event: any) {
        const newFormData = {...this.props.formData, selected: this.isTrueValue(event.target.value)};
        this.props.onChange(newFormData, this.props.index);
    }

    public render() {
        const {formData, defaultValue, intl, error, index, group} = this.props;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const value = isBoolean(formData.selected) ? formData.selected : defaultValue;

                    return <div>
                        <CoverCardComponent
                            id={`${group}-${index}`}
                            value={String(value)}
                            onChange={this.onChange}
                            checked={this.isTrueValue(value)}
                            disabled={this.isFalseValue(value)}
                        >
                            <CoverCardComponent.Header icon={this.renderProductIcon()}
                                                       heading={this.renderPriceTitle()}
                                                       borderBottom={true}
                                                       fontWeight={defaultTheme.fontWeights.regular}
                                                       lineHeight={defaultTheme.leading['0']}
                                                       disabled={this.isFalseValue(value)}

                            />
                            {this.renderMaxInsuranceAmountBody(this.props.formData)}
                            {formData?.productTextList && <CoverCardComponent.Body
                                spacingTop={'2'}
                                title={formData.productTextList?.join(" ")}
                                disabled={this.isFalseValue(value)}
                            />}
                            {formData?.coverTextList && <CoverCardComponent.Body
                                spacingTop={'4'}
                                spacingBottom={'5'}
                                disabled={this.isFalseValue(value)}
                            >
                                <List checkBlue={true} list={formData.coverTextList}/>
                            </CoverCardComponent.Body>}
                            {/* @ToDo - select input implementation, when it's clear, where provided/implemented in webservice*/}
                            <CoverCardComponent.Footer
                                borderTop={true}
                                spacingTop={'5'}
                                disabled={false}
                                buttonOptions={[
                                    {
                                        icon: IconCheckmark,
                                        text: intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `optCard.yes`)}),
                                        checked: !!value,
                                        disabled: false,
                                        onChange: () => this.onChange({target: {value: TRUE_VALUE_STRING}})
                                    },
                                    {
                                        icon: IconCheckmark,
                                        text: intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `optCard.no`)}),
                                        checked: this.isFalseValue(value),
                                        disabled: false,
                                        onChange: () => this.onChange({target: {value: FALSE_VALUE_STRING}})
                                    }
                                ]}
                            />
                        </CoverCardComponent>
                        {this.renderError(error)}
                    </div>
                }}
            </CmsContext.Consumer>
        )
    }

    private renderError(error?: string) {
        return error ?
            (
                <Animate.Drop>
                    <ConstrainWidth>
                        <FormError><FormattedMessage id={error}/></FormError>
                    </ConstrainWidth>
                </Animate.Drop>
            )
            : null;
    }

    private isTrueValue(value: boolean | string) {
        return String(value) === 'true';
    }

    private isFalseValue(value?: boolean | string) {
        return String(value) === 'false';
    }

    private renderProductIcon() {
        const {formData} = this.props;
        switch (formData.productId) {
            case Constants.Product.GroupAccidentAdult.id:
            case Constants.Product.GroupAccidentChildren.id:
            case Constants.Product.GroupAccidentSenior.id:
                return ProductGroupAccidentIcon;
            case Constants.Product.GroupDiagnosisAdult.id:
            case Constants.Product.GroupDiagnosisSenior.id:
            case Constants.Product.GroupDiagnosisLifeAndIllness.id:
                return ProductGroupDiagnosisIcon;
            case Constants.Product.GroupLifeInsuranceAdult.id:
            case Constants.Product.GroupLifeInsuranceSenior.id:
                return ProductGroupLifeInsuranceIcon;
            case Constants.Product.GroupExpenseInsurance.id:
                return ProductGroupExpenseInsuranceIcon;
            case Constants.Product.GroupShortTermIllness.id:
                return ProductGroupShortTermIllnessIcon;
            case Constants.Product.GroupHealthCareChild.id:
            case Constants.Product.GroupHealthCareAdult.id:
            case Constants.Product.GroupLongTermIllness.id:
                return ProductGroupLongTermHealthCareIcon;
            case Constants.Product.GroupProfessionalAthlete.id:
                return ProductGroupProAthletesIcon;
            case Constants.Product.GroupLegalAdvice.id:
                return ProductGroupLegalAdviceIcon;
            default:
                return undefined;
        }

    }

    private renderMaxInsuranceAmountBody(policy: IOptCardPolicy): React.ReactNode {
        if (policy.insuranceAmount) {
            const {intl} = this.props;
            return (
                <CoverCardComponent.Body spacingTop={'2'} spacingBottom={'2'}>
                    <DescriptionList.Row spacingTop={'0'} spacingBottom={'0'}>
                        <DescriptionList.Content>
                            <DescriptionList.Term>
                                {
                                    policy.mainCoverNumberOfUnits
                                        ? <FormattedMessage
                                            id={CmsHelper.withGroupCommonPrefix("insuranceAmount.title")}/>
                                        : <FormattedMessage
                                            id={CmsHelper.withGroupCommonPrefix("singleCover.title")}/>
                                }
                            </DescriptionList.Term>
                            <DescriptionList.Definition>
                                {
                                    policy.mainCoverNumberOfUnits
                                        ?
                                        <FormattedMessage
                                            id={CmsHelper.withGroupCommonPrefix("insuranceAmount.units")}
                                            values={{
                                                amount: intl.formatNumber(policy.insuranceAmount),
                                                numberOfUnits: Math.round(Number(policy.mainCoverNumberOfUnits))
                                            }}
                                        />
                                        :
                                        <FormattedMessage
                                            id={CmsHelper.withGroupCommonPrefix("singleCover.price")}
                                            values={{
                                                amount: intl.formatNumber(policy.insuranceAmount)
                                            }}
                                        />
                                }
                            </DescriptionList.Definition>
                        </DescriptionList.Content>
                    </DescriptionList.Row>
                </CoverCardComponent.Body>
            );
        }

        return null;
    }

    private renderPriceTitle() {
        const {intl, formData} = this.props;
        return (
            <span>
                <FormattedMarkdown messageKey={CmsHelper.withGroupCommonPrefix(`coverCard.title`)}
                                   messageValue={{title: formData.productTitle}}
                />
                {" "}
                <B>
                    <FormattedMessage id={CmsHelper.withGroupCommonPrefix(`price.monthly`)}
                                      values={{monthlyPremium: intl.formatNumber(this.state.monthlyPremium)}}
                    />
                </B>
                {/* @ToDo - enabled based on condition, when it's clear how  freePolicyPeriod provided in webservice*/}
                {/*{" "}*/}
                {/*{<FormattedMessage id={CmsHelper.withGroupCommonPrefix(`coverCard.price.monthly.freePeriod`)}/>}*/}
            </span>
        );
    }
}

export default injectIntl(OptCardInternal);
