import * as React from "react";
import {FormattedMessage} from "react-intl";
import {
    Button,
    defaultTheme as theme,
    Headings,
    Spacing,
    ArrowRightNewIcon,
    DisplayIcon
} from "@folksam-digital/ui";

interface IDirectDebitButtonProps {
    handleDirectDebitRedirect: () => void;
}

const DirectDebitButton: React.FC<IDirectDebitButtonProps> = ({handleDirectDebitRedirect}) => {
    return (
        <div style={{
            color: theme.colors.black
        }}>
            <Spacing type={"padding"} bottom={'4'}/>
            <Headings.H4>
                <FormattedMessage id="general.success.bannerPanel.directDebit.header"/>
            </Headings.H4>
            <Headings.H5><FormattedMessage id="general.success.bannerPanel.directDebit.subheader"/></Headings.H5>
            <Button full={true} primary={true} onClick={handleDirectDebitRedirect}>
                <DisplayIcon style={{marginRight: 8}} baseline={true} large={false} icon={<ArrowRightNewIcon/>}/>
                <FormattedMessage id={"general.success.bannerPanel.directDebit.button.title"}/>
            </Button>
        </div>
    );
}

export default DirectDebitButton;
