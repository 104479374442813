import {BaseErrorMessage} from "./BaseErrorMessage";

export class DefaultJourneyError extends BaseErrorMessage {
    protected determineMessage() {
        this.message = "contactForm.description";
    }

    public getMessage(): string {
        return this.message;
    }
}
