import * as React from "react";
import {FormInputSelect, IconCheckMarkV2, QuestionSkeleton, withViewContext} from "@folksam-digital/ui";
import {ChoiceComponentBase, IChoiceComponentMetadataBase, IChoiceComponentStateBase} from "./ChoiceComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {withFormContext} from "../withFormContext";
import isNil from "lodash/isNil";
import {withLocaleContext} from "../withLocaleContext";
import {IFormComponentProps} from "../FormComponentBase";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {IBreakPoint} from "../../layout/helpers";
import sanitizeField from "./helpers/sanitizeField";
import castArray from "lodash/castArray";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {getBreakpoints} from "./helpers/breakpoints/getBreakpoints";
import {isEmpty} from "lodash";
import {IOptionMultiSelected, IOptionSelected} from "@folksam-digital/model";

export interface IDropDownMetadata extends IChoiceComponentMetadataBase {
    defaultValueId: number;
    classNames?: string;
    placeholder?: string;
    help?: string;
    isDisabled?: boolean;
    full?: boolean;
    breakpoints?: IBreakPoint;
    isSearchable?: boolean;
    withMobilePositionRelative?: boolean;
    inputMode?: string;
    checkDisabledForm?: boolean;
}

interface IDropDownState extends IChoiceComponentStateBase {

}

export interface IDropDownInternalProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
}

export class DropDownInternal extends ChoiceComponentBase<number | string | IOptionSelected[] | IOptionMultiSelected, IDropDownMetadata, IDropDownState, IDropDownInternalProps<number | string | IOptionSelected[] | IOptionMultiSelected, IDropDownMetadata>> {
    protected isMulti: undefined | true;

    constructor(props: IDropDownInternalProps<number | string | IOptionSelected[] | IOptionMultiSelected, IDropDownMetadata>) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {};
    }

    public render() {
        const {breakpoints} = this.metadata;

        return (
            <FormFieldLayout {...this.getLayoutProps()}
                             breakpoints={getBreakpoints(defaultBreakpoints.dropdown, breakpoints)}>
                {this.renderDropdown()}
            </FormFieldLayout>
        );
    }

    private renderDropdown(): React.ReactElement {
        if (this.state.values) {
            const {name, viewContext} = this.props;
            const isMobile = viewContext.isMobile;

            const {
                isDisabled,
                placeholder,
                isSearchable,
                withMobilePositionRelative,
                inputMode,
                checkDisabledForm
            } = this.metadata;
            const {disableButton} = this.context;
            return (
                <FormInputSelect
                    position={withMobilePositionRelative && isMobile && 'relative'}
                    id={name}
                    selected={this.value}
                    invalid={this.isInvalid()}
                    onChange={this.onChange}
                    placeholder={placeholder ? this.props.intl.formatMessage({id: placeholder}) : this.props.intl.formatMessage({id: "general.form.dropdown.pleaseSelect.placeholder"})}
                    options={this.inputOptions}
                    disabled={isDisabled || (checkDisabledForm && disableButton)}
                    isSearchable={isSearchable}
                    isMulti={this.isMulti}
                    inputMode={inputMode}
                    showIconPrefix={this.showCheckmarkIconCondition(!isDisabled || !(checkDisabledForm && disableButton))}
                />
            );
        } else {
            return (
                <div>
                    <QuestionSkeleton/>
                </div>
            );
        }
    }

    private showCheckmarkIconCondition(enabled: boolean) {
        return !!this.value && enabled && this.isValid() && !this.isMulti ? <IconCheckMarkV2 /> : null
    }

    protected get value() {
        const {formData} = this.props;
        const types = castArray(this.schema.type);

        if (types.includes('object') && !isEmpty(formData)) {
            return formData;
        }

        if (!isNil(formData) && this.state?.values) {
            const result = this.state.values.find(option => option.id === formData.toString());
            if (result) {
                return {
                    label: !this.metadata.classifierId && !this.metadata.valuesModelPath ? this.props.intl.formatMessage({id: result?.title}) : result?.title,
                    value: result?.id.toString()
                };
            }
        }
        return null;
    }

    private get inputOptions() {
        if (this.state.values) {
            return this.state.values.map((value) => {
                const title = !this.metadata.classifierId && !this.metadata.valuesModelPath ? this.props.intl.formatMessage({id: value.title}) : value.title;
                return {
                    label: title,
                    value: value.id
                };
            });
        }
        return undefined;
    }

    protected onChange(event: any): void {
        const types = castArray(this.schema.type);

        if (types.includes("string")) {
            this.onChangeWithValidation(sanitizeField(event.value));
        } else if (types.includes("object")) {
            this.onChangeWithValidation(event);
        } else {
            this.onChangeWithValidation(parseInt(event.value, 10));
        }
    }
}

const DropDown = injectIntl(withLocaleContext<IDropDownInternalProps<number | string, any>>(withFormContext(withViewContext(DropDownInternal))));
export {DropDown};
