import * as React from "react";
import {B, defaultTheme, FormInputRadioButton, QuestionSkeleton, Spacing} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {FormContext, IFormContext} from "../FormContext";
import {CoverPlan} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {PbbHelper} from "@folksam-digital/services";

interface IMetadata {
    mainCoverId?: number;
    classNames?: string;
    help?: string;
}

class PackageSelectionRadioGroupInternal extends InputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        if (this.context?.data?.policy?.premium?.coverPlans) {
            const coverPlans: CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);

            return (
                <FormFieldGroupLayout {...this.getLayoutProps()}>
                    <>
                        {
                            coverPlans.map((coverPlan: CoverPlan) => {
                                return coverPlan?.id && this.renderCoverPlan(coverPlan.id);
                            })
                        }
                    </>
                </FormFieldGroupLayout>
            );
        } else {
            return <QuestionSkeleton/>;
        }
    }

    private renderCoverPlan(coverPlanId: string) {
        const {
            name,
            formData
        } = this.props;

        const { mainCoverId } = this.metadata;

        const coverPlanDetails = PbbHelper.getCoverPlanDetails(this.context.data, coverPlanId, mainCoverId);

        return (
            <Spacing type={"margin"} top={'2'} key={`${name}-${coverPlanId}`}>
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) =>
                        <FormInputRadioButton
                            id={`${name}-${coverPlanId}`}
                            name={`${name}-${coverPlanId}`}
                            label={<>
                                <B><FormattedMessage id={CmsHelper.withPrefix(cmsContext, "sizeOfInsurance.package.labelTemplateDescription")}
                                                     values={coverPlanDetails}/></B>
                                <span style={{color: defaultTheme.colors.senary2}}>
                                <FormattedMessage id={CmsHelper.withPrefix(cmsContext, "sizeOfInsurance.package.labelTemplateNote")}
                                                  values={coverPlanDetails}/>
                            </span>
                            </>}
                            ariaLabelledBy={`${name}-${coverPlanId}-label ${name}-label`}
                            value={coverPlanId}
                            onChange={() => this.onChangeWithValidation(coverPlanId)}
                            checked={formData === coverPlanId}
                        />
                    }
                </CmsContext.Consumer>
            </Spacing>
        );
    }
}

const PackageSelectionRadioGroup = injectIntl(PackageSelectionRadioGroupInternal);
export {PackageSelectionRadioGroup};
