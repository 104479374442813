import * as React from "react";

import {OutputComponentBase} from "./OutputComponentBase";
import {BannerSkeleton} from "@folksam-digital/ui";

interface IMetadata {
    title: string;
    url: string;
    classNames?: string;
}

export class Loader extends OutputComponentBase<void, IMetadata, {}> {
    public styles: any;

    constructor(props: any, contextType: any) {
        super(props, contextType);

        this.styles = {
            div: {
                width: '100%',
                minHeight: '63px'
            },
        }
    }

    render() {
        return (
            <div className={this.metadata.classNames} style={this.styles.div}><BannerSkeleton theme={3} sm/></div>
        )
    }
}
