import {IDigitalClaimService} from "../../IDigitalClaimService";
import {ClaimBase} from "@folksam-digital/model";
import {injectable} from "inversify";
import {FilterRequestPayload} from "@folksam-digital/services";
import {SharedServiceBase} from "../SharedServiceBase";
import {AxiosInstance} from "axios";

@injectable()
export class DigitalClaimService extends SharedServiceBase implements IDigitalClaimService {

    public async creatClaim(product: string, data: ClaimBase<any>, locale: string): Promise<ClaimBase<any>> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        const path: string = `claim/protected/${product}/create`;
        const http = this.getHttpClient();
        const response = await http.post(path, {locale, data, journeyId: product}, {headers: this.getContext()});
        this.updateContext(response);

        return response.data;
    }

    private getHttpClient(): AxiosInstance {
        return this.create({
            baseURL: this.config.baseProtectedUrl || this.config.baseUrl
        });
    }
}
