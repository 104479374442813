import * as React from "react";
import {P, defaultTheme as theme, Spacing} from "@folksam-digital/ui";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../../FormattedMarkdown";

interface IOptOutInContainerFooterProps {
    pbbPolicy?: any
}

export const OptOutInContainerFooter: React.FC<IOptOutInContainerFooterProps> = ({pbbPolicy}) => {
    return <>
        {!!pbbPolicy &&
        <P style={{color: theme.colors.senary2, marginTop: theme.margin['3'], marginBottom: theme.margin['5']}}>
            <FormattedMarkdown messageKey={CmsHelper.withGroupCommonPrefix("priceBaseAmountsInfoText")}/>
        </P>}
        {!pbbPolicy && <Spacing type={"margin"} bottom={'5'}/>}
    </>
};
