import {Contact} from "@folksam-digital/model";
import * as React from "react";

export interface IAuthenticationContext {
    isAuthenticated: boolean,
    getAuthenticatedUser: () => Promise<Contact>
}

export const AuthenticationContext = React.createContext<IAuthenticationContext>({
    isAuthenticated: false,
    getAuthenticatedUser: () => Promise.reject()
});
