import {init as initApm} from "@elastic/apm-rum";
import {ConfigHelper} from "@folksam-digital/services";

export const apm = initApm({
    active: ConfigHelper.getBooleanValue("ELASTIC_APM_ACTIVE"),
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: ConfigHelper.getValue("SERVICE_NAME"),

    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: ConfigHelper.getValue("ELASTIC_APM_SERVER_URL"),
    environment: ConfigHelper.getValue("ENVIRONMENT"),
    // Set service version (required for sourcemap feature)
    serviceVersion: ConfigHelper.getValue("VERSION"),

    distributedTracingOrigins: [
        ConfigHelper.getValue("DISTRIBUTED_TRACING_ORIGINS", "")
    ]
});
