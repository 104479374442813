import * as React from "react";
import {CardNew as CardNewFUI} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {

}

class CardNew extends PanelComponentBase<string, IMetadata, {}> {
    render() {
        return(
            <CardNewFUI>
                {this.props.properties?.map(p => p.content)}
            </CardNewFUI>
        );
    }
}

export { CardNew }
