import * as React from "react";
import { injectIntl } from "react-intl";
import { ExtendedDescription, Spacing } from "@folksam-digital/ui";
import { OutputComponentBase } from "./OutputComponentBase";
import { Markdown } from "../../../Markdown";
import {MarkdownListTypes} from "@folksam-digital/model";

interface IMetadata {
    content: string;
    classNames?: string;
    listType?: MarkdownListTypes;
}

class ExtendedDescriptionWidgetInternal extends OutputComponentBase<void, IMetadata, {}> {
    public render() {
        const {
            name,
            schema,
            intl
        } = this.props;

        const title = schema.title;
        const { content, classNames, listType } = this.metadata;

        return (
            <div className={classNames}>
                <ExtendedDescription
                    id={name}
                    indented={false}
                    linkText={intl.formatMessage({id: title})}
                    isExpandable={true}
                >
                    <Spacing type={"margin"} bottom={"sm"}>
                        <Markdown listType={listType} source={intl.formatMessage({id: content})} />
                    </Spacing>
                </ExtendedDescription>
            </div>
        );
    }
}

const ExtendedDescriptionWidget = injectIntl(ExtendedDescriptionWidgetInternal);
export { ExtendedDescriptionWidget };
