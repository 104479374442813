import {BaseErrorMessage} from "./BaseErrorMessage";
import {Constants} from "@folksam-digital/model";

export class IncorrectRegistrationNumberType extends BaseErrorMessage {
    protected determineMessage(): void {
        switch (this.journeyId) {
            case Constants.Journey.Moped.Id:
                this.message = "contactForm.moped.incorrectRegistrationNumberType.description";
                break;
            default:
                this.message = "contactForm.description";
        }
    }

    public getMessage(): string {
        return this.message;
    }
}
