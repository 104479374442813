import * as React from "react";
import {CardNew as CardNewFUI} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";

interface IMetadata {
    column?: boolean;
}

class CardNewSection extends PanelComponentBase<string, IMetadata, {}> {
    render() {

        const { column } = this.metadata;

        return(
            <CardNewFUI.Section column={column}>
                {this.props.properties?.map(p => p.content)}
            </CardNewFUI.Section>
        );
    }
}

export { CardNewSection }
