import * as React from "react";
import {PanelComponentBase} from "./PanelComponentBase";
import {
    defaultTheme,
    DescriptionListResponsive,
    Headings,
    Spacing,
    SummaryCard,
    withViewContext
} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import {Icon} from "../output";

class CoverQuestionnairePanelInternal extends PanelComponentBase<any, any, any> {
    public render() {
        const title = this.schema.title;
        const {
            spacingTop,
            spacingBottom,
            iconId,
        } = this.metadata;

        const titleResult = (<Headings.H4 style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 0
        }} id={this.uiSchema.name}>
            {iconId && <Icon iconId={iconId} attrs={{width: 30, height: 30, fill: defaultTheme.colors.primary1}}/>}
            <span style={{verticalAlign: "middle"}}>
                <FormattedMessage id={title}/>
            </span>
        </Headings.H4>);

        return (
            <Spacing type={"margin"} top={spacingTop} bottom={spacingBottom}>
                <DescriptionListResponsive>
                    <SummaryCard theme={2}>
                        {title !== undefined && title !== "" &&
                        <SummaryCard.Header noPaddingSide={false}>
                            <SummaryCard.HeaderText>{titleResult}</SummaryCard.HeaderText>
                        </SummaryCard.Header>}
                        <SummaryCard.Body>
                            <Spacing  type={"padding"} top={"4"} bottom={"4"}>
                                {this.props.properties?.map(p => p.content)}
                            </Spacing>
                        </SummaryCard.Body>
                    </SummaryCard>
                </DescriptionListResponsive>
            </Spacing>
        );
    }
}

export const CoverQuestionnairePanel = withViewContext(CoverQuestionnairePanelInternal);
