import * as React from "react";
import {FormattedMessage} from "react-intl";
import {PanelComponentBase} from "./PanelComponentBase";
import {Icon} from "../output";
import {Grid, Headings, Spacing} from "@folksam-digital/ui";
import {IStepMetadata, StepItemOutput} from "./StepItemOutput";
import {FormContext, IFormContext} from "../FormContext";
import get from "lodash/get";

interface IMetadata {
    iconId?: string;
    iconAttrs?: any;
    classNames?: string;
    dataPath?: string;
}

export class PanelSectionHeader extends PanelComponentBase<string, IMetadata & IStepMetadata, {}> {
    public static contextType = FormContext;
    context!: IFormContext;

    public render() {
        const {
            iconId,
            iconAttrs,
            classNames,
            dataPath,
        } = this.metadata;
        const {name} = this.uiSchema;

        let values;
        if (dataPath) {
            values = get(this.context.data, dataPath);
        }

        const body = (<Grid>
            <Grid.Row>
                <Grid.Col lg={12} md={12} xs={12}>
                    <Headings.H4 style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                                 id={name}>
                        {iconId && <Icon iconId={iconId} attrs={iconAttrs}/>}
                        <span style={{verticalAlign: "middle"}}>
                                {dataPath ?
                                    <FormattedMessage id={this.props.title} values={values}/>
                                    :
                                    <FormattedMessage id={this.props.title}/>
                                }
                            </span>
                    </Headings.H4>
                    <StepItemOutput {...this.props} />
                </Grid.Col>
            </Grid.Row>
        </Grid>);

        return (
            classNames === "marginTop" ?
                <Spacing type="padding" top={"lg"}>{body}</Spacing> :
                classNames ?
                    <div className={classNames}>{body}</div> :
                    <Spacing type="padding" bottom={"lg"}>{body}</Spacing>
        );
    }
}
