import {FormLabel} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import React from "react";

export const Label = ({id, htmlFor, required, tooltip, theme, invalid, children}: any) => {
    return (
        <FormLabel
            id={id}
            htmlFor={htmlFor}
            required={required}
            tooltip={tooltip}
            theme={theme}
            optionalText={<FormattedMessage id={"general.optionalText"}/>}
            invalid={invalid}
        >
            {children}
        </FormLabel>
    );
};
