import {FormComponentBase, IFormComponentProps} from "../FormComponentBase";
import {IFormFieldGroupLayoutProps} from "../../../FormFieldGroupLayout";
import {FormContext, IFormContext} from "../FormContext";
import {InputMode} from "@folksam-digital/model";

export interface IInputComponentProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata> {
    inputMode?: InputMode;
}

export abstract class InputComponentBase<TValue, TMetadata, TState,
    TProps extends IInputComponentProps<TValue, TMetadata> = IInputComponentProps<TValue, TMetadata>>
    extends FormComponentBase<TValue, TMetadata, TState, TProps> {

    public static contextType = FormContext;
    public context!: IFormContext;

    /**
     * Is component data valid
     */
    protected isValid(): boolean {
        return this.props.rawErrors === undefined || this.props.rawErrors.length === 0;
    }

    /**
     * Is component data invalid
     */
    protected isInvalid(): boolean {
        return this.props.rawErrors && this.props.rawErrors.length > 0;
    }

    /**
     * Get first error
     */
    protected getError(): string | undefined {
        if (this.isValid()) {
            return undefined;
        }
        return this.props.rawErrors[0];
    }

    protected getLayoutProps(): IFormFieldGroupLayoutProps {
        // @ts-ignore
        return {
            ...this.props,
            ...this.schema,
            ...this.metadata,
            id: this.props.name,
            error: this.getError(),
            invalid: this.isInvalid()
        }
    }

    protected onChangeWithValidation(value: any) {
        this.props.onChange(value);
        if (this.props.idSchema) {
            this.context.fieldValidation(this.props.idSchema, this.props.uiSchema, value);
        }
    }

    protected onBlurWithValidation(event: React.FocusEvent<any>, formData: any) {
        if (this.props.idSchema) {
            const relatedTarget = event?.relatedTarget as HTMLButtonElement;
            if (!this.isSubmitButtonClickEvent(relatedTarget)) {
                this.context.fieldValidation(this.props.idSchema, this.props.uiSchema, formData);
            }
        }
    }

    /**
     * If user clicks on submit button, then field validation should not be called
     * Reason: onBlur event triggers form rerender and actual click event does not happen on submit button
     *
     * @param relatedTarget - target html element where mouse is clicked
     */
    private isSubmitButtonClickEvent(relatedTarget?: HTMLButtonElement) {
        return (relatedTarget?.tagName?.toUpperCase() === "BUTTON" || relatedTarget?.type?.toUpperCase() === "SUBMIT");
    }
}
