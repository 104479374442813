import {FormComponentBase, IFormComponentProps} from "../../../journey/form/FormComponentBase";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {DescriptionListResponsive, withViewContext} from "@folksam-digital/ui";
import {GroupInsurance} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface ISelectedProductListBaseState {
}

interface ISelectedProductListTotalProps extends IFormComponentProps<any, IMetadata>, WrappedComponentProps {
    viewContext: any,
    type?: TotalTypes,
}

interface IMetadata {
}

interface ITotals {
    monthly: number,
    yearly: number
}

export enum TotalTypes {
    All,
    Policy,
    Proposal,
}

class SelectedProductListTotals extends FormComponentBase<any, IMetadata, ISelectedProductListBaseState, ISelectedProductListTotalProps> {
    public static contextType = CmsContext;
    public context!:  ICmsContext;

    public render(): React.ReactNode {
        const {intl} = this.props;
        return (
            <FormContext.Consumer>
                {(context: IFormContext) => {
                    const {monthly, yearly} = this.getTotals(context.data);
                    return (
                        <>
                            <DescriptionListResponsive.Row separated={true} balanced={true}>
                                <DescriptionListResponsive.Definition>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.monthly.title")}/>
                                </DescriptionListResponsive.Definition>
                                <DescriptionListResponsive.Definition>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.monthly.price")}
                                        values={{amount: intl.formatNumber(Math.round(monthly))}}/>
                                </DescriptionListResponsive.Definition>
                            </DescriptionListResponsive.Row>
                            <DescriptionListResponsive.Row separated={true} balanced={true}>
                                <DescriptionListResponsive.Definition>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.yearly.title")}/>
                                </DescriptionListResponsive.Definition>
                                <DescriptionListResponsive.Definition>
                                    <FormattedMessage
                                        id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.yearly.price")}
                                        values={{amount: intl.formatNumber(yearly, {maximumFractionDigits: 2})}}/>
                                </DescriptionListResponsive.Definition>
                            </DescriptionListResponsive.Row>
                        </>
                    );
                }}
            </FormContext.Consumer>
        );
    }

    private getTotals(data: GroupInsurance): ITotals {
        return  {monthly: data?.premium?.monthlyPremium || 0, yearly: data?.premium?.yearlyPremium || 0};
    }
}

export default injectIntl<"intl", ISelectedProductListTotalProps>(withViewContext(SelectedProductListTotals));
