import React from "react";
import {FormNote, Spacing, defaultTheme} from "@folksam-digital/ui";

export const LabelHelpText = ({checkBoxMargin, children, style}: any) => {
    return (<FormNote>
        <Spacing type={"margin"} left={checkBoxMargin && "lg"}>
            <div style={{ fontFamily: defaultTheme.fonts.mono, ...style }}>{children}</div>
        </Spacing>
    </FormNote>);
}
