import * as React from "react";
import {withRouter} from "react-router";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import flowRight from "lodash/flowRight";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import {Constants, GroupInsurance, IJourneySchema, OfferBase, ShoppingCartContact} from "@folksam-digital/model";
import {
    defaultTheme as theme,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {IJourneySuccessBaseProps} from "../../product/success/JourneySuccessBase";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FailedToPurchaseProductList, ProposalProductList} from "../../../../components/shoppingCart/form/container";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {DirectDebitButton} from "../success/DirectDebitButton";
import {
    IOnTriggerDataLayerEvent,
    SatelliteEvents,
    SatelliteEventTransactionTypes
} from "../../../../analytics/new/BaseAnalytics";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {IDraftService, StorageKeys} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {MyPagesButton, ProductContactUsSection} from "../../../../components/journey/layout/Success";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {UrlHelper} from "@folksam-digital/services";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";
import ShoppingCartProductList from "../../../../components/shoppingCart/form/container/ShoppingCartProductList";
import {ListRowItem, RowPrice} from "../../product/common/ListRowItem";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

interface IShoppingCartSuccessProps extends IJourneySuccessBaseProps, IOnTriggerDataLayerEvent, WrappedComponentProps {
}

interface IShoppingCartSuccessState {
    successfulPolicyCreation: boolean;
    memberHasSomeSelectedPolicy: boolean;
}

class ShoppingCartSuccessPageInternal extends React.Component<IShoppingCartSuccessProps, IShoppingCartSuccessState> {
    public static contextType = CmsContext;
    context!: ICmsContext;

    private draftService: IDraftService;

    constructor(props: IShoppingCartSuccessProps, context: ICmsContext) {
        super(props, context);

        this.draftService = container.get<IDraftService>(Types.DraftService);
        const data = this.data;

        if (!data) {
            const schema = {} as IJourneySchema;
            this.draftService.updateDraft({
                journeyId: Constants.Journey.ShoppingCart.Id,
                backUrl: schema?.backUrl
            }, StorageKeys.JOURNEY_ERROR_DATA_KEY);

            this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
            return;
        } else {
            setJourneyCompleted(this.draftService, Constants.Journey.ShoppingCart.Id);
        }

        const memberHasSomeSelectedPolicy = this.hasAnySelectedPolicy(data.member);
        const successfulPolicyCreation = this.hasCreatedAnyPolices(data);

        this.state = {
            successfulPolicyCreation,
            memberHasSomeSelectedPolicy
        };
    }

    public get data(): GroupInsurance {
        return this.draftService.getDraft<GroupInsurance>();
    }

    public async componentDidMount(): Promise<void> {
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: this.data,
            journeyId: Constants.Journey.ShoppingCart.Id,
            currentStep: "success",
            transactionType: SatelliteEventTransactionTypes.TransactionPage,
            event: SatelliteEvents.Complete
        });
    }

    public render() {
        const data = this.data;
        if (isEmpty(data)) {
            return <></>;
        }
        return (
            <FormContext.Provider value={{data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                        translations={{
                            header: CmsHelper.withGeneralPrefix("success.banner.heading"),
                            subheader: CmsHelper.withPrefix(this.context, "success.banner.subheading"),
                            headerText: <FormattedMessage
                                id={`${CmsHelper.withPrefix(this.context, "pageTitle.headerText")}`}/>
                        }}
                        navButton={CloseModalButton}
                        journeyId={Constants.Journey.ShoppingCart.Id}
                        formData={{contact: data.member.contact, groupName: data.group.name}}
                        backUrl={Constants.Journey.ShoppingCart.backUrl + data.group.name}
                        doScroll={true}
                    >

                        <SuccessHeaderMessageBox
                            message={CmsHelper.withPrefix(this.context, "success.banner.information")}/>
                        <SuccessHeaderTable
                            email={data.member.contact.emailAddress}
                        />
                        <DirectDebitButton
                            cmsContext={this.context}
                            journeyData={data}
                            history={this.props.history}
                            stepBack={true}
                            successfulPolicyCreation={this.state.successfulPolicyCreation}
                            noBanner={true}
                        />
                        <Spacing type={"padding"} bottom={'4'}/>
                        <MyPagesButton/>
                    </SuccessHeaderLayout>
                    <ComponentBackgroundWrapper theme={1}>
                        <MaxWidthWrapper compact={true}>
                            <Step.SectionBody>
                                <Spacing type={"padding"} y={"md"}>

                                    <Spacing type="padding" top={'8'}>
                                        {/*Basic Information */}
                                        <SummaryCard theme={2}>
                                            <SummaryCard.Header>
                                                <SummaryCard.HeaderText>
                                                    <FormattedMessage
                                                        id={CmsHelper.withGeneralPrefix("paymentAndContactDetails.title")}/>
                                                </SummaryCard.HeaderText>
                                                <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={theme.colors.secondarySuccess4}/>}/>
                                            </SummaryCard.Header>

                                            <SummaryCard.Body>
                                                <ListRowItem
                                                    id="general.paymentMethod.title">
                                                    <FormattedMessage
                                                        id={`general.policy.paymentDetails.paymentMethod.${data.paymentDetails?.paymentMethod}`}/>
                                                    /
                                                    <FormattedMessage
                                                        id={`general.policy.paymentDetails.paymentFrequency.${data.paymentDetails?.paymentFrequency}`}/>
                                                </ListRowItem>
                                                {data.member.contact.phoneNumber &&
                                                <ListRowItem
                                                    id="general.phoneNumber.title">
                                                    <FormattedMessage
                                                        id={data.member.contact.phoneNumber}/>
                                                </ListRowItem>}
                                                <ListRowItem
                                                    id="general.email.title">
                                                    <FormattedMessage
                                                        id={data.member.contact.emailAddress}/>
                                                </ListRowItem>

                                            </SummaryCard.Body>

                                        </SummaryCard>
                                    </Spacing>
                                    {/*Member and Partner rows */}
                                    <Spacing type="padding" top={"8"}>

                                        <ShoppingCartProductList context={{data} as IFormContext}/>

                                        <Spacing type="padding" top={'8'}>
                                            {/*Totals Information */}
                                            <SummaryCard theme={3} headerText={'Totals'}>
                                                <SummaryCard.Body>
                                                    <RowPrice premium={data.premium!}/>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                        </Spacing>
                                    </Spacing>
                                    <Spacing top={'8'}>
                                        <FormattedMarkdown
                                            messageKey="general.form.priceDisclaimer.helpText"/>
                                    </Spacing>


                                    {/* TODO: Update the components to work with the new data structures */}
                                    {/*<PurchasedProductList data={data}*/}
                                    {/*                      memberHasSomeSelectedPolicy={this.state.memberHasSomeSelectedPolicy}/>*/}

                                    <ProposalProductList data={data}/>

                                    <FailedToPurchaseProductList data={data}/>

                                </Spacing>
                            </Step.SectionBody>
                        </MaxWidthWrapper>
                        {/*Footer*/}

                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            <ProductContactUsSection/>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        )
    }

    private hasCreatedAnyPolices(data: GroupInsurance): boolean {
        let shouldRender: boolean | undefined;

        shouldRender = data.member.offerList && data.member.offerList.some((offer: OfferBase) => {
            return offer.policy && !offer.policy.errors;
        });
        if (!shouldRender) {
            shouldRender = data.partner && data.partner.offerList && data.partner.offerList.some((offer: OfferBase) => {
                return offer.policy && !offer.policy.errors;
            });
        }
        if (!shouldRender) {
            shouldRender = data.children && data.children.some((child: ShoppingCartContact) => {
                return child.offerList && child.offerList.some((offer: OfferBase) => {
                    return offer.policy && !offer.policy.errors;
                });
            });
        }
        return !!shouldRender;
    }

    private hasAnySelectedPolicy(data: ShoppingCartContact): boolean {
        return some(data.offerList, {selected: true});
    }
}

const ShoppingCartSuccessPage = flowRight(
    withCmsProvider("shoppingCart"),
    withDataAnalytics(getAnalytics(AnalyticsType.ShoppingCart)),
    withRouter,
    withHistoryBackConstraint,
    injectIntl
)(ShoppingCartSuccessPageInternal);

export default ShoppingCartSuccessPage;
