import {injectable} from "inversify";
import {IValidation, ValidatorBase} from "./ValidatorBase";
import {REGEXP} from "@folksam-digital/model";

@injectable()
export class ContentHeaderVehicleFieldValidator extends ValidatorBase {
    protected readonly validations: {[key: string]: IValidation} = {
        "ssn": {
            "required": true
        },
        "registrationNumber": {
            "required": true,
            "regex": {
                "pattern": REGEXP.VEHICLE_REGISTRATION_NUMBER,
                "errorMessage": "general.form.registrationNumber.error.pattern",
            }
        }
    };
}
