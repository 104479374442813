import {Constants} from "@folksam-digital/model";
import {SatelliteEvents} from "../new/BaseAnalytics";
import {BaseAnalytics, BaseMapping, IOnTriggerDataLayerEventParams} from "./BaseAnalytics";

const productAnalyticsCodes: {[key: string]: string} = {
    [`${Constants.LegacyProductCodes.IncreasedSalary}`]: "GMI_PRODUCTS_INCOME-MEMBER_SALARY",
    [`${Constants.LegacyProductCodes.AdditionalTime}`]: "GMI_PRODUCTS_INCOME-MEMBER_TIME",
};

interface JourneyMapping extends BaseMapping {
    product_id: string[];
    transaction_id: string;
    age: string;
    product_units: number[];
    product_price: number[];
    union: string;
}

export class IncomeAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.product.${params.journeyId!.toString()}`];
        const pageName: string = this.getPageName(params);
        const pageStart = params.messages[`general.adobe.product.pageStart`];
        const pageType = params.messages[`general.adobe.product.pageType`];

        const dataLayerObj: JourneyMapping = {
            age: params?.data?.contact?.age?.toString() || "",
            customer_id: await this.getUserId(params.data?.contact?.externalContactNumber),
            pagetype: journeyName,
            pagename: `${pageStart}|${pageType}|${journeyName}|${pageName}`,
            sitesection1: `${pageStart}`,
            sitesection2: `${pageStart}|${pageType}`,
            sitesection3: `${pageStart}|${pageType}|${journeyName}`,
            sitesection4: `${pageStart}|${pageType}|${journeyName}|${pageName}`,
            sitesection5: `${pageStart}|${pageType}|${journeyName}|${pageName}|`,
            product_id: [productAnalyticsCodes[params?.data?.product]],
            transaction_id: this.getTransactionId(),
            product_units: [1],
            product_price: [params?.data?.cover?.inputSetValue],
            union: params?.data?.groupId?.toString()
        };

        let trackKey = `${params.transactionType}_${params.event}`;
        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        this.setDataLayer(dataLayerObj, trackKey);
    }
}
