import * as React from "react";
import { Spinner, defaultTheme } from "@folksam-digital/ui";
import { FormattedMessage } from "react-intl";

interface ILoaderLayoutProps {
    isLoading: boolean;
    children?: React.ReactNode;
}

export class LoaderLayout extends React.Component<ILoaderLayoutProps, {}> {
    public render() {

        const containerStyles = {
            position: "fixed" as "fixed",
            zIndex: 99,
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            backgroundColor: "#FFFFFF",
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: "90%"
        };

        const loaderModalStyles = {
            backgroundColor: "#FFF",
            padding: "20px",
            borderRadius: "10px",
            fontFamily: defaultTheme.fonts.serif,
            fontSize: defaultTheme.textSizes.base,
            fontWeight: defaultTheme.fontWeights.semiBold,
            lineHeight: '23.2px'
        };

        const loaderStyles = {
            display: "flex",
            justifyContent: "center",
            margin: "15px 0px"
        };

        if (!this.props.isLoading) {
            return null;
        }

        return (
            <div style={containerStyles}>
                <div style={loaderModalStyles}>
                    <div style={loaderStyles}>
                        <Spinner large={true}/>
                    </div>
                    {this.getMessage()}
                </div>
            </div>
        );
    }

    private getMessage(): React.ReactNode {
        if(this.props.children) {
            return this.props.children
        }
        return <FormattedMessage id={"general.loader.description"}/>;
    }
}
