import * as React from 'react';
import {FormContext, IFormContext} from './FormContext';

export function withFormContext<P extends {formContext?: IFormContext}>(Component: React.ComponentType<P>) {
    return function FormComponent(props: Pick<P, Exclude<keyof P, keyof 'formContext'>>) {
        return (
            <FormContext.Consumer>
                {(contexts: any) => <Component {...props as P} formContext={{...contexts}} />}
            </FormContext.Consumer>
        )
    }
}
