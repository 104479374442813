import {injectable} from "inversify";
import {IWorkflowServiceConfig, WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";
import {IPolicyService} from "../../IPolicyService";
import {
    DigitalGetGroupDetailsRequest,
    DigitalGetGroupDetailsResponse,
    IDigitalGroupDetails,
    JourneyBase,
    PolicySearchResponse,
    QuestionnaireResponse
} from "@folksam-digital/model";
import {JourneyHelper, SsnFormatter, FilterRequestPayload} from "@folksam-digital/services";
import {IStorage} from "../../IStorage";
import {ISessionContextManager} from "../../ISessionContextManager";
import {IDigitalPolicyService} from "../../IDigitalPolicyService";

@injectable()
export class PolicyService extends WorkflowServiceBase implements IPolicyService {
    private readonly policyService: IDigitalPolicyService;

    public constructor(config: IWorkflowServiceConfig, sessionStorage: IStorage, sessionContextManager: ISessionContextManager, policyService: IDigitalPolicyService) {
        super(config, sessionStorage, sessionContextManager);

        this.policyService = policyService;
    }

    public async createPolicy(product: string, data: JourneyBase<any>, locale: string): Promise<JourneyBase<any> | undefined> {
        return this.policyService.createPolicy(product, data, locale);
    }

    public async calculatePremium(product: string, data: JourneyBase<any>): Promise<JourneyBase<any> | undefined> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        return super.executeUnique<JourneyBase<any>, JourneyBase<any>>(
            Workflow.forProduct(JourneyHelper.getObfuscatedJourneyId(product), data?.targetEnv).PremiumCalculate,
            data
        );
    }

    public async calculatePremiumByCoverPlans(product: string, data: JourneyBase<any>): Promise<JourneyBase<any> | undefined> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        return super.execute<JourneyBase<any>, JourneyBase<any>>(
            Workflow.forProduct(JourneyHelper.getObfuscatedJourneyId(product), data?.targetEnv).PremiumCalculateByCoverPlans,
            data
        );
    }

    public async searchGroupPoliciesBySsn(data: { identityNumber: string, groupId: string }): Promise<PolicySearchResponse | undefined> {
        data.identityNumber = SsnFormatter.convertFormat(data.identityNumber, true);

        return super.execute<any, any>(
            Workflow.Group.GetGroupPoliciesBySSN,
            data
        );
    }

    public async getQuestionnaire(product: string, data: JourneyBase<any>): Promise<QuestionnaireResponse | undefined> {
        data = FilterRequestPayload.filterBlacklistedObjects(data);
        return super.execute<JourneyBase<any>, QuestionnaireResponse>(
            Workflow.forProduct(JourneyHelper.getObfuscatedJourneyId(product)).GetQuestionnaire,
            data
        );
    }

    public async getGroupDetails(groupId: string, journeyId: string, ssn: string, groupAgreementId: string): Promise<IDigitalGroupDetails> {
        const groupData: DigitalGetGroupDetailsResponse = await super.execute<DigitalGetGroupDetailsRequest, DigitalGetGroupDetailsResponse>(
            Workflow.Group.GetGroupDetails,
            {groupId, journeyId, ssn, groupAgreementId}
        );

        return groupData.groupDetails;
    }
}
