import get from "lodash/get";
import {IFormContext} from "../../FormContext";
import {LegacyCoverPlan} from "@folksam-digital/model";

export interface IMetadata {
    [key: string]: string;
}

export function getMessageValues(metadata: IMetadata, context: IFormContext): Record<string, any> {
    const values: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(metadata)) {
        values[key] = get(context.data, value);
    }

    return values;
}

export function getHomeMessageValues(metadata: IMetadata, context: IFormContext, coverPlansPath: string): Record<string, any> {
    const values: { [key: string]: string } = getMessageValues(metadata, context);
    const coverPlans = get(context?.data, coverPlansPath, []) as LegacyCoverPlan[];

    for (const coverPlan of coverPlans) {
        if (coverPlan?.included && coverPlan?.id) {
            values["coverPlan"] = coverPlan.id;
            break;
        }
    }

    return values;
}
