import {PlaceTypes} from "@folksam-digital/model";

interface IComponentRestrictionsType {
    country: string | string[];
}

interface ILatLngType {
    lat: number,
    lng: number
}

export interface IAutocompletionRequestType {
    componentRestrictions: IComponentRestrictionsType;
    location: ILatLngType;
    fields: string[]; // @TODO: add actual list of possible fields
    offset: number;
    radius: number;
    types: string[];
}

const autocompletionRequestBuilder = (autocompletionRequest: any) => {
    const res = { ...autocompletionRequest };

    if (autocompletionRequest.bounds) {
        res.bounds = new window.google.maps.LatLngBounds(...autocompletionRequest.bounds);
    }

    if (autocompletionRequest.location) {
        res.location = new window.google.maps.LatLng(autocompletionRequest.location);
    }

    if (autocompletionRequest?.types?.includes(PlaceTypes.Country)) {
        res.types = [PlaceTypes.Regions];
    }

    return res;
};

export default autocompletionRequestBuilder;
