export abstract class BaseErrorMessage {
    protected journeyId: string;
    protected message: string;

    constructor(journeyId: any) {
        this.journeyId = journeyId;

        this.determineMessage();
    }

    protected abstract determineMessage(): void;

    public getMessage(): string {
        return this.message;
    }
}
