import OptInOptOutProductListBase from "./OptInOptOutProductListBase";
import * as React from "react";
import {Spacing, Headings, defaultTheme, DescriptionListResponsive} from "@folksam-digital/ui";
import {GroupOptInOptOut, InsuredPersonContact, PolicySearchResult, Constants} from "@folksam-digital/model/lib";
import {FormattedMessage} from "react-intl";
import  * as OptInOptOutProductListBaseFunctions from "./helpers/OptInOptOutProductListBaseFunctions";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface SuccessPageFailedProductListProps {
    data: GroupOptInOptOut;
}
export default class SuccessPageFailedProductList extends React.Component<SuccessPageFailedProductListProps, {}> {
    public static contextType = CmsContext;
    public context!: ICmsContext;

    public render() {
        const { data } = this.props;

        const contact = data.member.contact as InsuredPersonContact;
        const failedPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getAllFailedOptInPolicies(data);
        const memberPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getMemberPolicies(failedPolicies);
        const partnerPolicies: PolicySearchResult[] = OptInOptOutProductListBaseFunctions.getPartnerPolicies(failedPolicies);

        return (
            contact && failedPolicies && failedPolicies.length > 0 &&
            <Spacing type={'padding'} top={'sm'}>
            <DescriptionListResponsive themeId={Constants.Themes.LightPink}>
                <DescriptionListResponsive.Header>
                    <Headings.H2 style={{
                        color: defaultTheme.colors.tertiary1,
                        marginBottom: defaultTheme.margin['3'],
                        marginTop: defaultTheme.margin["3"]
                    }}>
                        <strong>
                            <FormattedMessage id={CmsHelper.withPrefix(this.context, "success.errorList.title")}/>
                        </strong>
                    </Headings.H2>
                </DescriptionListResponsive.Header>
                <div style={{
                    padding: "0 15px",
                    backgroundColor: defaultTheme.colors.quinary3
                }}>
                    <Spacing bottom={"md"} top={"sm"}>
                            <DescriptionListResponsive.Row>
                                <FormattedMarkdown messageKey={CmsHelper.withPrefix(this.context, "success.errorList.description")}/>
                            </DescriptionListResponsive.Row>
                    </Spacing>
                    {memberPolicies.length > 0 &&
                    <OptInOptOutProductListBase type={"optIn"} policies={memberPolicies} contact={contact} displayFreePeriod={false}
                                                customMessageFormatId={CmsHelper.withPrefix(this.context, "success.summaryList.policiesForNamed")}/>}
                    {partnerPolicies.length > 0 &&
                    <OptInOptOutProductListBase type={"optIn"} policies={partnerPolicies} contact={data.partner?.contact ? data.partner.contact : {}} displayFreePeriod={false}
                                                customMessageFormatId={CmsHelper.withPrefix(this.context, "success.summaryList.policiesForNamed")}/>}
                </div>
            </DescriptionListResponsive>
            </Spacing>
        );
    }
}
