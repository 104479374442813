import {BaseErrorMessage} from "./BaseErrorMessage";
import {Constants} from "@folksam-digital/model";

export class HomeBuildingsTooManyError extends BaseErrorMessage {
    protected determineMessage(): void {
        switch (this.journeyId) {
            case Constants.Journey.HomeBuilding.Id:
                this.message = "contactForm.homeBuildingTooManyBuildings.description";
                break;
            default:
                this.message = "contactForm.description";
        }
    }
}
