import React from "react";
import Journey from "@folksam-digital/model/lib/constants/Journey";
import {Constants} from "@folksam-digital/model";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {FormattedMessage} from "react-intl";
import {ICmsContext} from "../../cms";
import {capitalize} from "lodash";

interface ICustomHeaderTextMap {
    [key: string]: HeaderTextGeneratorFn;
}

type GetHeaderTextFn = (journeyId: string, data: any, context: ICmsContext) => React.ReactNode;
type HeaderTextGeneratorFn = (data: any, context: ICmsContext) => React.ReactNode;

const carHeaderTextGenerator: HeaderTextGeneratorFn = (data, context) => {
    if (data?.policy?.insuredObject?.carInsuranceType) {
        const {policy: {insuredObject: {brand, carInsuranceType}}} = data;

        let messageKey: string;

        switch (carInsuranceType) {
            case Constants.Car.InsuranceType.Brand:
                messageKey = "pageTitle.brand.headerText";
                break;
            case Constants.Car.InsuranceType.BrandOther:
                messageKey = "pageTitle.brandOther.headerText";
                break;
            default:
                messageKey = "pageTitle.headerText";
        }

        const brandNames: Record<string, string> = {
            ...Constants.Car.CategoryType.Brand,
            ...Constants.Car.CategoryType.Partner,
            ...Constants.Car.CategoryType.BrandOther
        };

        return <FormattedMessage id={CmsHelper.withPrefix(context, messageKey)}
                                 values={{brandName: brandNames[brand]}}/>;
    }
};

const illnessDuringTravelHeaderTextGenerator: HeaderTextGeneratorFn = (data, context) => {

    return <FormattedMessage id={CmsHelper.withPrefix(context, `pageTitle.headerText${data?.targetEnv === Constants.TargetEnvironment.Idit ? capitalize(data.targetEnv) : ""}`)}/>;
};

export const headerTextCustomKeys: ICustomHeaderTextMap = {
    [Journey.Car.Id]: carHeaderTextGenerator,
    [Journey.IllnessDuringTravel.Id]: illnessDuringTravelHeaderTextGenerator,
};

export const getHeaderTextByJourneyId: GetHeaderTextFn = (journeyId, data, context): React.ReactNode => {
    if (headerTextCustomKeys.hasOwnProperty(journeyId)) {
        return headerTextCustomKeys[journeyId](data, context);
    } else {
        const headerKey = CmsHelper.withPrefix(context, "pageTitle.headerText");
        return <FormattedMessage id={headerKey}/>;
    }
};
