import React from "react";
import { Spacing } from "@folksam-digital/ui";
import { IFormFieldLayoutProps, FormFieldLayout } from "./FormFieldLayout";

export interface IFormFieldGroupLayoutProps extends IFormFieldLayoutProps {
    spaceBetween?: boolean;
}

export class FormFieldGroupLayout extends React.Component<IFormFieldGroupLayoutProps> {

    public render() {
        return (
            <FormFieldLayout {...this.props}>
                {this.renderChildren(this.props.children)}
            </FormFieldLayout>
        );
    }

    private renderChildren(children?: React.ReactElement | React.ReactElement[]): React.ReactElement | React.ReactElement[] | undefined {
        if(children) {
            const spaceBetween = this.props.spaceBetween === undefined || this.props.spaceBetween === true;

            return React.Children.map(children!, (child: React.ReactElement<any, any>) => (
                spaceBetween ? (
                    <Spacing key={child!.key} y={'1'}>
                        {child}
                    </Spacing>
                ) : (
                    child
                )
            ));
        }
        return undefined;
    }
}
