import {ClaimCompensation} from "@folksam-digital/model";

export default function getCompensationSum(compensations?: ClaimCompensation[]): number {
    let total: number = 0;
    for (const row of compensations as ClaimCompensation[]) {
        if (typeof row.amount === "number") {
            total += row.amount;
        }
    }
    return total;
}
